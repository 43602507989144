import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import PostMediaContent from '../../_components/Feed/PostMediaContent';
import { breakpoints } from '../../_helpers/breakpoints';
import Image from '../../_components/Feed/Image';
import Video from '../../_components/Feed/Video';

import Actions from '../../_components/Feed/Actions';
import DrawerPost from '../../_components/Feed/DrawerPost';
import { CommentContext, ContentContext } from '../../_context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 2px solid #CECECE;
  background-color: #FFFFFF;
  width: 100%;
  height: 123vw;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
  .post_video{
    display: flex;
    flex-direction: row;
    align-items: center;
    //height: 269px;
    height: 93.2vw;
    background-color: black;
    .carousel-item{
      height: auto!important;
      //display: flex!important;
      .video_player{
        width: 100%!important;
        height: auto!important;
      }
    }
  }
  .box_actions{
    width: calc(100% - 10px);
    margin-top: 15px;
    .button_message{
      margin-right: 0!important;
    }
  }
  .text{
    box-sizing: border-box;
    padding: 0 2vw 0 2vw;
    margin-top: 3vw;
    font-size: 2.8vw;
    line-height: 3.4vw;
    font-weight: 600;
  }
  .ver_mas{
    color: #FFD200;
  }
  .carousel-item{
    height: 93.2vw!important;
    //display: flex!important;
    .video_player{
      display: flex;
      width: 100%!important;
      height: auto!important;
    }
  }
  @media ${breakpoints.xs} {
    height: 385px;
    .text{
      padding: 0 10px 0 10px;
      margin-top: 10px;
      font-size: 14px;
      line-height: 18px;
    }
    .carousel-item{
      height: 264px!important;
    }
    .post_video{
      height: 264px;
    }
  }
  @media ${breakpoints.sm} {
    height: 355px;
    .carousel-item{
      height: 215px!important;
    }
    .post_video{
      height: 214px;
    }
  }
  @media ${breakpoints.md} {
    height: 450px;
    .carousel-item{
      height: 327px!important;
    }
    .post_video{
      height: 327px;
    }
  }
  @media ${breakpoints.lg} {
    height: 537px;
    .carousel-item{
      height: 411px!important;
    }
    .post_video{
      height: 411px;
    }
  }
`

const Muro = (props) => {

  const { data, onClick, shellBoxWall, setShellBoxWall, onClickMedia } = props;
  const { getComments, postComment, onSubmitComment } = useContext(CommentContext);

  const { sendLike, sendDislike } = useContext(ContentContext);


  const { login,
          isAuthLoading,
          loginError } = useContext(AuthContext);

  const truncate = (input, ending) => {

    const limit = 70;
    if(input.length > limit){
      if(ending === '...'){
        return `${input.substring(0, limit)}${ending}`;
      }
      if(ending === '...ver más'){
        return <div>{input.substring(0, limit)}<span className="ver_mas">...ver más</span></div>;
      }
    }else{
      return input;
    }

  }

  const [ like, setLike ] = useState(false);
  const [ postLikeStatus, setPostLikeStatus ] = useState(false);
  const [ openDrawer, setOpenDrawer ] = useState(false);


  const handleLike = (post_id) => {

    //alert("likePost: " + post_id)

    const payload = {
      id_post: post_id
    }

    sendLike(payload);

    let postEdited = shellBoxWall.map(post => (
      post.id === post_id ? {...post, liked: true, likes: post.likes+1} : post
    ));

    setShellBoxWall(postEdited);

  }

  const handleDislike = (post_id) => {

    const payload = {
      id_post: post_id
    }

    sendLike(payload);

    let postEdited = shellBoxWall.map(post => (
      post.id === post_id ? {...post, liked: false, likes: post.likes-1} : post
    ));
    setShellBoxWall(postEdited);

  }


  const handleDrawerComment = () => {
    setOpenDrawer(!openDrawer);
  }
  const handleComment = (post_id) => {
    getComments(post_id);
    handleDrawerComment();
  }


  return (
    <Wrapper>

        {data.media[0].type === "image" &&
          <Image post={data} onDrawer={false} sideControls={false} />
        }
        {data.media[0].type === "video" &&
          <Video post={data} onDrawer={false} />
        }
        {/*<PostMediaContent post_type={data.post_type} post={data} onDrawer={false} sideControls={false} />*/}
        <div className="box_actions">
          <Actions
            post={data}
            post_id={data.id}
            accept_comments={data.accept_comments}
            accept_likes={data.accept_likes}
            like={like}
            liked={postLikeStatus}
            handleComment={handleComment}
            handleLike={handleLike}
            handleDislike={handleDislike}
            handleOnClickMedia={onClickMedia}
            onDrawer={false}
            onShellbox={true}
          />
        </div>

        <div className="text">
          {truncate(data.text, '...')}
        </div>

        <DrawerPost
          post={data}
          post_id={data.id}
          handleComment={() => handleComment()}
          handleLike={handleLike}
          handleDislike={handleDislike}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          getComments={getComments}
        />


    </Wrapper>
  )
}

export default React.memo(Muro);
