import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    //height: 100%;
    //background-color: skyblue;

    @media ${breakpoints.sm} {
      /* width: 448px;
      margin-left: 20px; */
    }
    @media ${breakpoints.md} {
      /* width: 672px;
      margin-left: 19px; */
    }
    @media ${breakpoints.lg} {
      /* width: 840px;
      margin-left: 20px; */
    }
`

const SideRight = ({children, className}) => {

  return (
    <Wrapper className={className}>
      {children}
    </Wrapper>
  )

}

export default SideRight;
