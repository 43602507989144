import React, { useEffect, useRef } from "react";
import styled from 'styled-components';

import { BsFillVolumeDownFill, BsFillVolumeMuteFill, BsPlayFill, BsFillPauseFill } from "react-icons/bs";
import { GoMute, GoUnmute } from "react-icons/go";
import CircularProgress from '@material-ui/core/CircularProgress';

import Song from "./Song";
import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";

import useAudioPlayer from './useAudioPlayer';



const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .bt{
    background-color: #FFFFFF;
    border: 0;
    padding: 5px 12px 5px 12px;
    font-size: 23px;
    outline: none;
    :hover{
      background-color: #FFD200;
    }
  }
  .player{
    display: flex;
    width: 100%;
    height: 44.5px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    margin-top: 1px;
    .loader{
      display: flex;
      flex-direction: row;
      svg{
        color: #FFD200;
      }
    }
    .controls {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .player__button {
      width: fit-content;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
        svg {
          color: greenyellow;
        }
      }

      svg {
        font-size: 4em;
        color: white;
      }
    }

    .bar {
      user-select: none;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 15px 0 15px;

      .bar__time {
        color: #000000;
        font-size: 16px;
      }

      .bar__progress {
        flex: 1;
        border-radius: 5px;
        margin: 0 20px;
        height: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .bar__progress__knob {
          position: relative;
          height: 16px;
          width: 16px;
          border: 1.5px solid white;
          border-radius: 50%;
          background-color: #FFD200;
        }
      }
    }
  }
`


function AudioPLayer(props) {

  const { handleViewedContent,
          url,
          _bars = 300,
          _bar_x = 7,
          _bar_width = 4,
          newCampus = false,
          playStatus,
          setPlayStatus} = props;

  const { curTime, setCurTime, duration, playing, setPlaying, muted, setMuted, setClickedTime, isLoaded, onAudioEnded, stopPlayer, setStopPlayer } = useAudioPlayer();
  const analyzerCanvas = useRef();
  const audioPlayer = useRef();

  useEffect(() => {
    let context = new AudioContext();
    let analyser = context.createAnalyser();
    let canvas = analyzerCanvas.current;
    let ctx = canvas.getContext('2d');
    let audio = audioPlayer.current;
    audio.crossOrigin = "anonymous";
    let audioSrc = context.createMediaElementSource(audio);
    audioSrc.connect(analyser);
    audioSrc.connect(context.destination);
    analyser.connect(context.destination);

    function renderFrame(){
        let freqData = new Uint8Array(analyser.frequencyBinCount)
        requestAnimationFrame(renderFrame)
        analyser.getByteFrequencyData(freqData)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        //console.log(freqData)
        ctx.fillStyle = '#FFFFFF';
        let bars = _bars;
        for (var i = 0; i < bars; i++) {
            let bar_x = i * _bar_x;
            let bar_width = _bar_width;
            let bar_height = -(freqData[i] / 3);
            ctx.fillRect(bar_x, canvas.height, bar_width, bar_height)
        }
        /*
        let bars = 300;
        for (var i = 0; i < bars; i++) {
            let bar_x = i * 7;
            let bar_width = 4;
            let bar_height = -(freqData[i] / 3);
            ctx.fillRect(bar_x, canvas.height, bar_width, bar_height)
        }
        */
    };
    renderFrame()

    return () => {

    }
  }, [])

  useEffect(() => {

    if(onAudioEnded){
      handleViewedContent();
    }

    return () => {

    }
  }, [onAudioEnded])

  useEffect(() => {
    if(newCampus){
      if(playing === true && playStatus === false){
        //setCurTime(0);
        setPlaying(false);
        setStopPlayer(true);
      }
    }

    return () => {}
  }, [playStatus])


//<Pause handleClick={() => setPlaying(false)} />
  return (

    <Wrapper>

    <canvas
        ref={analyzerCanvas}
        id="analyzer"
        className="analyzer"
        width="900"
        height="80"
      >
      </canvas>

    <div className="player">

      <audio id="audio" ref={audioPlayer}>
        <source src={url} />
        Your browser does not support the <code>audio</code> element.
      </audio>

      {isLoaded ?
      <div className="controls">
        {playing ?

          <button className="bt play" onClick={() => setPlaying(false)}>
            <BsFillPauseFill />
          </button>
          :
          <button className="bt pause" onClick={() => {setPlaying(true); newCampus && setPlayStatus(true); setStopPlayer(false);}}>
            <BsPlayFill />
          </button>
        }
        <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)}/>

        {muted ?

        <button className="bt mute" onClick={() => setMuted(false)}>
          <GoMute />
        </button>
        :
        <button className="bt unmute" onClick={() => setMuted(true)}>
          <GoUnmute />
        </button>
        }
      </div>
      :
      <div className="loader">
        <CircularProgress size={30} />
      </div>
      }
    </div>

    </Wrapper>
  );
}

export default AudioPLayer;
