import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const Wrapper = styled(Button)`
    &.MuiButtonBase-root{
      outline: none;
      display: flex;
      width: ${props => props?.css_styles?.width ? props.css_styles.width : '100%'};
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 6px;
      height: 40px;
      font-weight: 600;
      color: #000000;
      border: 1px solid #FFD200;
      background-color: ${props => props?.css_styles?.background?.normal ? props.css_styles.background.normal : '#FFD200'};
      cursor: pointer;

      p{
        margin: 0;
        font-size: 15px;
      }
      :hover{
        outline: none;
        border: 1px solid #FFD200;
        background-color: #FFFFFF;
        text-decoration: none;
        p{
          color: #FFD200;
          font-size: 15px;
        }
      }
    }
    &.Mui-disabled{
      color: rgba(255, 255, 255, 0.6)!important;
    }

`

const Yellow = ({ children, css_styles, className, onClick, disabled }) => {

  return (
    <Wrapper css_styles={css_styles} className={className} onClick={onClick} disabled={disabled}>
      { children }
    </Wrapper>
  )

}

export default Yellow;
