import React, { useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '~/_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '~/_elements';

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import { ContentContext } from '~/_context';
import { AuthContext } from '~/_context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
`
const WrapperLogin = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 768px;//calc(100% - 30px);
  padding-top: 10px;
  padding-bottom: 50px;
  //background-color: gray;

  @media ${breakpoints.sm} {
    width: 768px;
  }
  @media ${breakpoints.md} {
    width: 951px;
  }
  @media ${breakpoints.lg} {
    width: 1160px;
  }
`

const ModalOtpStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .inner_modal_validate_phone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 450px;
    padding: 25px;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;
    .loader_code{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      svg{
        color: #FFD200;
      }
    }
    .contentPassword{
      .bt_send_password{
        width: 100%!important;
      }
    }
    .field{
      display: flex;
      flex-direction: column;
      width: 100%;
      label{
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
      }
      input{
        border: 1px solid #e1e3eb;
        padding: 10px 15px 10px 15px;
      }
      .is_invalid{
        border: 1px solid red;
      }
      .aclaracion{
        margin-top: 5px;
        font-size: 12px;
      }
      .box_auth_code{
        width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input{
          width: 60px;
          height: 70px;
          border-radius: 10px;
          box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.12);
          font-size: 40px;
          line-height: 70px;
          font-weight: 500;
          text-align: center;
        }
      }
    }
    .actions{
      margin-top: 10px;
      .loader{
        svg{
          width: 33px;
          height: 33px;
          color: #CCCCCC!important;
        }
      }
    }

    .errorMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      border: 1px dashed #F20000;
      background-color: #FF9494;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
      //color: #F20000;
      p{

      }
    }
    .successMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      background-color: #B8FF6D;
      //#F7DECC,#AD8D75,#B8FF6D,#1B110A,#E0C0A8,#F7DECC,#AD8D75,#B8FF6D
      border: 1px dashed #33E46D;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
    }
    .error_code_message{
      width: 100%;
      text-align: center;
      font-weight: 600;
      color: #000000;
    }
  }
`

const RegisterPhone = (props) => {

  const { openOtp, setOpenOtp, dni } = props;

  const { userData,
          setUserData,
          editUserData,
          needYouPhone,
          setNeedYouPhone } = useContext(ContentContext);

  const { changePasswordRecover } = useContext(AuthContext);

  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loadingFormPhone, setLoadingFormPhone] = useState(false);
  const [successFormPhone, setSuccessFormPhone] = useState(null);
  const [showAddPhone, setShowAddPhone] = useState(false);

  const [ haveError, setHaveError ] = useState({
    phone: { status: false, message: "" },
	});

  const handleSendPhone = (e) => {
    e.preventDefault();

    let error = false;

    let haveErrorProcess = {...haveError};

    if(phone.length <= 0){

      error = true;
      haveErrorProcess.phone.status = true;

    }else{

      //if(phone.length != 0){

        if(phone.length > 3){

          let pluscincuenta = phone.substr(0, 3);

          if(pluscincuenta != "+54"){
            error = true;
            haveErrorProcess.phone.status = true;
          }

        }
        else{

          error = true;
          haveErrorProcess.phone.status = true;

        }

        /*
        let pluscincuenta = phone.substr(0, 5);
        let quince = phone.substr(5, 2);
        let incompleto = phone.substr(5, 6);
        if(pluscincuenta != "+5411"){
          error = true;
          haveErrorProcess.phone.status = true;
        }
        if(quince == "15"){
          error = true;
          haveErrorProcess.phone.status = true;
        }
        if(incompleto.length <= 0){
          error = true;
          haveErrorProcess.phone.status = true;
        }
        */
      //}

    }




    setHaveError(haveErrorProcess);

    if(error){
      return null;
    }

    setLoadingFormPhone(true);
    const payload = { phone: phone, }

    const response = editUserData(payload);
    response.then(res => {

      if(res.status){
        const updateUserData = {...userData};
        updateUserData.phone = res.data.single.phone;
        setUserData(updateUserData);
        setSuccessFormPhone(true);
        setLoadingFormPhone(false);
        setTimeout(handleOpenRegisterPhone, 2000);
      }

    })

  }



  useEffect(() => {

    if(open == false){

      setPhone("");

      setLoadingFormPhone(false);
      setSuccessFormPhone(null);

      setShowAddPhone(false);

      setHaveError({
        phone: { status: false, message: "" },
      });
    }

    return () => {}
  }, [open])

  useEffect(() => {
    //alert(needYouPhone);
    //+541165809884
    if(needYouPhone){
      setTimeout(handleOpenRegisterPhone, 500);
    }

    return () => {}
  }, [needYouPhone])

  const handleOpenRegisterPhone = () => {

    setOpen(!open);

  }

  return (
    <Wrapper breakpoints={breakpoints}>

      <ModalOtpStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>

          <div className="inner_modal_validate_phone">

            <div className="aclaracion_telefono">
              <p>
              Registrá tu número celular para poder recuperar tu contraseña por mensaje de texto.
              </p>
            </div>

            <div className="field">
                <label htmlFor="phone">Ingresá tu teléfono</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  className={haveError.phone.status ? "is_invalid" : ""}
                />
                <span className="aclaracion">El número debe contener +54 (Código de área) sin el 15<br/>(ej: +5411987654321 para AMBA)</span>
            </div>


            {successFormPhone != null && !successFormPhone ?
              <div className="errorMessages">
                <div className="innerErrorMessages">
                  Hubo un error, inténtalo más tarde o para recuperar tu cuenta, te solicitamos que escribas a contacto@somosprotagonistas.com.ar con tus datos personales.
                </div>
              </div>
              : <></>
            }

            {successFormPhone != null && successFormPhone ?
              <div className="successMessages">
                <div className="innerSuccessMessages">
                  LOS DATOS SE GUARDARON CON ÉXITO.
                </div>
              </div>
              : <></>
            }


            <div className="actions">

            {loadingFormPhone ?
            <div className="loader">
              <CircularProgress size={33} />
            </div>
            :

              <ButtonYellow
                className="bt_save"
                onClick={handleSendPhone}
                disabled={false}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
              >
                <p>Enviar</p>
              </ButtonYellow>
            }
            </div>

          </div>

        </Fade>

      </ModalOtpStyled>
      <div id="recaptcha-container"></div>
    </Wrapper>
  )

}

export default RegisterPhone;
