import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
//import { ResponsivePie } from '@nivo/pie';
import { ContentContext } from '../../_context';
import { ButtonYellow } from '../../_elements';
import { COLORS } from '../../_helpers/constants';
import useMediaQuery from "../../_hooks/useMediaQuery";

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    //margin-top: 30px;
    margin-bottom: 20px;

    color: ${props => props.colorPallete.primary};//${COLORS.primary};

    .box_data_campus{

      border: 1px solid ${props => 'rgba('+props.colorPallete.border+', 1)'};
      background-color: ${props => props.colorPallete.background};
      -webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.7);
      box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.7);

      .label_items{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 12px;
        padding-bottom: 10px;
        .label_item{
          display: flex;
          flex-direction: column;
          align-items: center;
          .label{
            font-size: 12px;
            line-height: 12px;
            font-weight: 500;
          }
          .value{
            font-size: 32px;
            line-height: 32px;
            font-weight: 800;
            margin-top: 6px;
          }
        }
      }

    }

    .bt_show_more{
      display: flex;
      width: 100%!important;
      border-top-left-radius: 0!important;
      border-top-right-radius: 0!important;
    }

    @media ${breakpoints.xs} {

    }
    @media ${breakpoints.sm} {
      .bt_show_more{
        //display: none;
      }
    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {

    }




    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`

const CampusStats = (props) => {

  const isSm = useMediaQuery('(min-width: 768px)');

  const { data } = props;


  const { trainings,
          coursesTotal,
          coursesCompleteTotal,
          updateTrainingStates,
          setShowMoreStats,
          showMoreStats,
          colorPallete } = useContext(ContentContext);


  useEffect(() => {
    if(trainings){
      updateTrainingStates(trainings);
    }
    return () => {}
  }, [trainings])

  const cursos_total_count = 31;
  const cursos_complete = 11;
  const cursos_pending = 20;

  const handleShowMoreStats = () => {
    setShowMoreStats(true);
  }

  return (
    <Wrapper breakpoints={breakpoints} colorPallete={colorPallete}>

      <div className="box_data_campus">
        <div className="label_items">
          <div className="label_item">
            <div className="label">Cursos totales</div>
            <div className="value">{coursesTotal}</div>
          </div>
          <div className="label_item">
            <div className="label">Realizados</div>
            <div className="value">{coursesCompleteTotal}</div>
          </div>
          <div className="label_item">
            <div className="label">Por hacer</div>
            <div className="value">{coursesTotal - coursesCompleteTotal}</div>
          </div>
        </div>




      </div>

      {!isSm && !showMoreStats &&
      <ButtonYellow
          className="bt_show_more"
          onClick={() => handleShowMoreStats()}
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
        >
          <p>Ver más</p>
      </ButtonYellow>
      }

    </Wrapper>
  )

}

export default CampusStats;
