import React, { useEffect, useContext, useState } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { path, defaultTo } from 'ramda';
import { AuthContext } from './_context';
import storage from 'local-storage-fallback';
import styled from 'styled-components';
//import Encuesta from './_pages/Encuesta';
//import Fixture from './_pages/Fixture';
import VideoLanding from './_pages/VideoLanding';
import SecurityDay from './_pages/SecurityDay';
import ChallengeBox from './_pages/ChallengeBox';
import IncentivoTiendas from './_pages/IncentivoTiendas';
import IncentivoAutitos from './_pages/IncentivoAutitos';
import Login from './_pages/Login';
//import Contact from './pages/Contact';
//import ForgotPassword from './pages/ForgotPassword';
//import { LINKS } from './constants';
import Routes from './_Routes';
import TermsContainer from '../src/_components/Maintenance/TermsContainer'

// isAuth should be false, otherwise (if it's falsy but null or undefined) it means that it wasn't yet determined
const haveToken = defaultTo('', storage.getItem('protagonistas_token'));
const isNotAuth = authContext => path(['isAuth'], authContext) === false;
//const allowedRoutes = [LINKS.CONTACT, '/forgot-password'];
//const allowedRoutes = ['/encuesta'];
//const allowedRoutes = ['/qatar2022'];
//const allowedRoutes = ['/challengebox', '/dia-de-la-seguridad', '/video-landing'];
//const allowedRoutes = ['/triplica-box'];
const allowedRoutes = ['', 'trivia', 'campus', 'catalogo', 'perfil', 'semana-de-la-seguridad', 'baristas', "incentivo-tiendas", "incentivo-autitos"];
const allowedOfflineRoutes = ['', 'semana-de-la-seguridad', 'incentivo-autitos'];

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  >div{
    width: 100%;
  }
`

const Auth = ({ history }) => {

  const authContext = useContext(AuthContext);
  const [ isTttcAgree, setIsTttcAgree ] = useState(null);

  useEffect(() => {
    /*
    if(!authContext.isAuth && !defaultTo('', storage.getItem('protagonistas_token')).length){
      if(!allowedRoutes.includes(path(['location', 'pathname'], history))){
        history.push('/');
      }
    }
    */

    let splited_history = (history?.location?.pathname?.split('/'))[1];

    if(authContext.isAuth && defaultTo('', storage.getItem('protagonistas_token')).length){
      
      if(!allowedRoutes.includes(splited_history)){
        history.push('/');
      }

    }else{
      
      if(!allowedOfflineRoutes.includes(splited_history)){
        if(authContext.isAuth !== null && authContext.isAuth === false){
          history.push('/');
        }
      }

    }

    

    //if (isNotAuth(authContext) && !allowedRoutes.includes(path(['location', 'pathname'], history))) history.push('/');
  }, [authContext, history]);

  
  //console.log("history ->", splited_history)
  useEffect(() => {
    console.log("isNotAuth", authContext.isAuth)

    return () => {}
  }, [isNotAuth])


  return (
    <Wrapper>
      {authContext.isLoading && (
        <div className="spinner-wrapper">
          <div className="spinner-loader" />
        </div>
      )}
      {
        authContext.isAuth ?
        authContext.tttcAgree ?
          <Routes />
        : <TermsContainer />
        : <></>
      }
      {!authContext.isAuth /*isNotAuth(authContext)*/ && (

        <Switch>
          <Route path="/semana-de-la-seguridad" exact component={SecurityDay} />
          <Route path="/incentivo-autitos" exact component={IncentivoAutitos} />
          {/*
          <Route path="/triplica-box" exact component={IncentivoTiendas} />
          
          <Route path="/contacto" exact component={Contact} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/encuesta" exact component={Encuesta} />
          <Route path="/qatar2022" exact component={Fixture} />
          
          <Route path="/video-landing" exact component={VideoLanding} />
          
          <Route path="/challengebox" exact component={ChallengeBox} />
          */}


          <Route path="/" exact component={Login} />
        </Switch>
      )}

    </Wrapper>
  );
};

export default withRouter(Auth);
