import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { IcoChevronLeft } from '../../_helpers/constants';
import { ContentContext } from '../../_context';
import { AiTwotoneStar } from "react-icons/ai";
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .inner{
    width: calc(100% - 100px);
  }
`
const CircularProgressStyled = styled(CircularProgress)`
  margin-right: 2px;
  svg{
    color: #FFD200!important;
  }
`
const StyledContainerTriviaRanking = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: #000000;
  .inner{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 80px);
  }
  .ranking_heads{
    display: flex;
    flex-direction: row;
  }
  .ranking_elements{
    display: flex;
    flex-direction: column;

    width: 100%;

  }

  .top3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4vw;

    .top{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 32%;
      background-color: #ffffff;
      padding: 4vw 3vw 3vw 3vw;
      border-radius: 16px;
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.35);
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.35);
      -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.35);

      .name{
        width: 100%;
        text-align: center;
        font-size: 6vw;
        line-height: 6vw;
        font-weight: 600;
      }
      .position{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 16vw;
        height: 16vw;
        .number{
          color: #000000;
          position: absolute;
          font-size: 6vw;
          font-weight: 800;
          z-index: 2;
        }
        .star{
          position: absolute;
          z-index: 1;
          svg{
            font-size: 16vw;
            line-height: 16vw;
          }
        }
      }
      &.top_1{
        .position{
          margin-top: 2vw;
          width: 17vw;
          height: 17vw;
          .number{
            color: #FFFFFF;
          }
          .star{
            position: absolute;
            z-index: 1;
            svg{
              font-size: 21vw;
              line-height: 21vw;
              fill: #151e4d;
            }
          }
        }
      }
      &.top_2{
        .position{
          margin-top: 3vw;
          width: 14.8vw;
          height: 14.8vw;
          .number{
            color: #FFFFFF;
          }
          .star{
            position: absolute;
            z-index: 1;
            svg{
              font-size: 17vw;
              line-height: 17vw;
              fill: #57ecea;
            }
          }
        }
      }
      &.top_3{
        .position{
          margin-top: 3.4vw;
          width: 14vw;
          height: 14vw;
          .number{
            color: #FFFFFF;
          }
          .star{
            position: absolute;
            z-index: 1;
            svg{
              font-size: 15vw;
              line-height: 15vw;
              fill: #e95eef;
            }
          }
        }
      }
    }
  }
  @media ${breakpoints.xs} {

    .top3{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      .top{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 32%;
        padding: 22px 15px 15px 15px;
        border-radius: 16px;

        .name{
          width: 100%;
          text-align: center;
          font-size: 30px;
          line-height: 30px;
          font-weight: 600;
        }
        .position{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 80px;
          height: 80px;
          .number{
            position: absolute;
            font-size: 30px;
            font-weight: 800;
            z-index: 2;
          }
          .star{
            position: absolute;
            z-index: 1;
            svg{
              font-size: 80px;
              line-height: 80px;
            }
          }
        }
        &.top_1{
          .position{
            margin-top: 10px;
            width: 85px;
            height: 85px;
            .star{
              position: absolute;
              z-index: 1;
              svg{
                font-size: 105px;
                line-height: 105px;
              }
            }
          }
        }
        &.top_2{
          .position{
            margin-top: 15px;
            width: 74px;
            height: 74px;
            .star{
              position: absolute;
              z-index: 1;
              svg{
                font-size: 85px;
                line-height: 85px;
              }
            }
          }
        }
        &.top_3{
          .position{
            margin-top: 17px;
            width: 70px;
            height: 70px;
            .star{
              position: absolute;
              z-index: 1;
              svg{
                font-size: 75px;
                line-height: 75px;
              }
            }
          }
        }
      }
    }

  }
  @media ${breakpoints.sm} {




  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const StyledRankingElement = styled.div`

    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 2vw;
    padding-bottom: 2vw;
    .ranking_position{
      font-size: 4vw;
      font-weight: 800;
      width: 10vw;
      //background-color: pink;
    }


    .name{
      font-size: 4vw;
      width: calc(100% - 54vw);
      margin: 0;
      margin-right: 2vw;
      &.header{
        font-weight: 800;
      }
    }
    .corrects{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 26vw;
      font-size: 3.6vw;
      line-height: 3.6vw;
      text-align: center;
      //background-color: pink;
      &.header{
        font-weight: 800;
      }
    }
    .time{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 26vw;
      font-size: 3.6vw;
      line-height: 3.6vw;
      font-weight: 600;
      text-align: center;
      //background-color: skyblue;
      &.header{
        font-weight: 800;
      }
    }



    p{
      font-size: 4vw;
      width: calc(100% - 52vw);
      margin: 0;
    }
    .ranking_progress_bar{
      width: 42vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: pink;
      .ranking_progress_bar_inner{
        width: 30vw;
        display: flex;
        background-color: rgba(0, 0, 0, 0.4);
        margin-right: 2vw;

        .bar{
          height: 13px;
          width: ${props => props.percent}%;
          background-color: skyblue;
        }
      }
      .percent{
        width: 16vw;
        font-size: 3.2vw;
        font-weight: 700;
        margin-right: 2vw;
        background-color: grey;
        text-align: center;
        //background-color: #000000;
      }
      .average_time{
        width: 22vw;
        font-size: 20px;
        font-weight: 700;
        text-align: right;
        //background-color: grey;
      }
    }


    @media ${breakpoints.xs} {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      .ranking_position{
        font-size: 20px;
        width: 60px;
      }
      .name{
        font-size: 20px;
        width: calc(100% - 270px);
        margin: 0;
        margin-right: 10px;
        &.header{
          font-weight: 800;
        }
      }
      .corrects{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 130px;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        //background-color: pink;
        &.header{
          font-weight: 800;
        }
      }
      .time{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 130px;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        //background-color: skyblue;
        &.header{
          font-weight: 800;
        }
      }


      .ranking_progress_bar{
        width: 210px;
        .ranking_progress_bar_inner{
          width: 150px;
          margin-right: 10px;
          .bar{
            height: 13px;
          }
        }
        .percent{
          width: 80px;
          font-size: 16px;
          margin-right: 10px;
        }
        .average_time{
          width: 110px;
          font-size: 20px;
        }

      }
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {

    }

`
const StyledHeaderCategory = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 3vw;
  //height: 450px;
  p{
    color: #000000;
    margin: 0;
    font-size: 5.5vw;
    line-height: 6vw;
    font-weight: 400;
    margin-left: 2vw;
  }
  .image{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .button_back{
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    p{
      padding: 0;
      margin: 0;
      font-size: 3vw;
      line-height: 3vw;
      margin-left: 1vw;
      color: #000000;
    }
    .icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 2px solid #000000;
      width: 7vw;
      height: 7vw;
      border-radius: 3.5vw;

      svg{
        height: 3vw;
        path{
          fill: #000000!important;
        }
      }
    }

    :hover{
      .icon{
        border: 2px solid #FFD200;
        svg{
          path{
            fill: #FFD200!important;
          }
        }
      }
      p{
        color: #FFD200;
      }
    }

  }

  @media ${breakpoints.xs} {
    margin-top: 15px;
    p{
      font-size: 26px;
      line-height: 30px;
      margin-left: 10px;
    }
    .button_back{
      p{
        font-size: 16px;
        line-height: 16px;
        margin-left: 7px;
      }
      .icon{
        width: 34px;
        height: 34px;
        border-radius: 17px;

        svg{
          height: 15px;
        }
      }
    }
  }
  @media ${breakpoints.sm} {
    margin-top: 0;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const TriviaRanking = (props) => {

  const { history, location } = props;

  const { getTriviaRanking, triviaRanking } = useContext(ContentContext);

  const handleBack = () => {
    history.push('/trivia');
  }

  useEffect(() => {
    getTriviaRanking();
  }, [])

  const secondsToHms = (d) => {

    let unit = '';
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h < 10 ? "0"+h : h) : "00";
    var mDisplay = m > 0 ? m + (m < 10 ? "0"+m : m) : "00";
    var sDisplay = s > 0 ? s + (s < 10 ? "0"+s : s) : "00";

    let segundos = '00';
    if(s < 10){
      segundos = '0'+s ;
    }else{
      segundos = s;
    }

    let minutos = '00';
    if(m < 10){
      minutos = '0'+m;
    }else{
      minutos = m;
    }

    let horas = '00';
    if(h < 10){
      horas = '0'+h;
    }else{
      horas = h;
    }


    if(s){
      unit = 's';
    }
    if(m){
      unit = 'm';
    }
    if(h){
      unit = 'h';
    }

    return minutos +":"+ segundos + unit;
  }


  return (
    <StyledWrapper>

      <StyledHeaderCategory>
        <div className="button_back" onClick={handleBack}>
          <div className="icon">
            <IcoChevronLeft />
          </div>
        </div>
        <div className="">
          <p>Ranking</p>
        </div>
      </StyledHeaderCategory>

      <StyledContainerTriviaRanking>

        {triviaRanking != null ?
          triviaRanking.length > 0 ?
            <>
              <div className="top3">

                <div className="top top_2">
                  <div className="name">{triviaRanking[1] ? triviaRanking[1].id_user.name.split(" ")[0] : '-'}</div>
                  <div className="position">
                    <div className="number">2</div>
                    <div className="star">
                      <AiTwotoneStar />
                    </div>
                  </div>
                </div>

                <div className="top top_1">
                  <div className="name">{triviaRanking[0] ? triviaRanking[0].id_user.name.split(" ")[0] : '-'}</div>
                  <div className="position">
                    <div className="number">1</div>
                    <div className="star">
                      <AiTwotoneStar />
                    </div>
                  </div>
                </div>

                <div className="top top_3">
                  <div className="name">{triviaRanking[2] ? triviaRanking[2].id_user.name.split(" ")[0] : '-'}</div>
                  <div className="position">
                    <div className="number">3</div>
                    <div className="star">
                      <AiTwotoneStar />
                    </div>
                  </div>
                </div>

              </div>

              <div className="ranking_elements">

                <StyledRankingElement>
                    <div className="ranking_position"></div>
                    <p className="name header">Nombre</p>
                    <div className="corrects header">Correctas</div>
                    {/*<div className="time header">Tiempo</div>*/}
                  </StyledRankingElement>

              { triviaRanking.map((position, index) => {
                return(
                  <StyledRankingElement percent={Math.round(position.progress_points)}>
                    <div className="ranking_position">
                      {index + 1} -
                    </div>
                    <p className="name">{position.id_user.name + ' ' + position.id_user.last_name}</p>
                    <div className="corrects">{position.total_correct}</div>
                    {/*<div className="time">{secondsToHms(position.seconds)}</div>*/}
                    {/*
                    <div className="ranking_progress_bar">
                      <div className="ranking_progress_bar_inner">
                        <div className="bar"></div>
                      </div>
                      <div className="percent">{position.count_questions}</div>
                      <div className="average_time">{secondsToHms(position.avg_time_seconds)}</div>
                    </div>
                    */}

                  </StyledRankingElement>
                )
              })}
              </div>
            </>
          :
          <>Por el momento no hay ranking para mostrar</>
          :
          <CircularProgressStyled className="loader" size={40} />
        }
      </StyledContainerTriviaRanking>

    </StyledWrapper>
  )
}

export default TriviaRanking;
