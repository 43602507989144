import React, { useState,
                useContext,
                Fragment } from 'react';
import { path } from 'ramda';
import storage from 'local-storage-fallback';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';

import CircularProgress from '@material-ui/core/CircularProgress';

import { AuthContext,
         ContentContext } from '../../_context';



const Password = styled.div`
  display: flex;
  flex-direction: column;
  /*margin-top: 20px;*/
  width: 100%;

  .bt_send_password{
    margin-top: 10px;
  }
  .title{
    display: flex;
    width: 100%;
    p{
      padding-left: 20px;
      margin: 0;
      margin-bottom: 15px;
      font-size: 25px;
      font-weight: 500;
    }
  }
  .content_change_password{
    display: flex;
    width: 100%;
    flex-direction: column;
    /*background-color: #F5F6FA;

    padding: 10px 20px 20px 20px;*/

    .field{
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      label{
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
      }
      input{
        border: 1px solid #F5F6FA;
        padding: 10px 15px 10px 15px;
      }
      &.cero_margin_top{
        margin-top: 0;
      }
    }
  }
  .errorMessages{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    border: 1px dashed #F20000;
    background-color: #FF9494;
    color: #000000;
    padding: 6px 10px 6px 10px;
    font-size: 15px;
    line-height: 19px;
    //color: #F20000;
    p{

    }
  }
  .successMessages{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    background-color: #B8FF6D;
    //#F7DECC,#AD8D75,#B8FF6D,#1B110A,#E0C0A8,#F7DECC,#AD8D75,#B8FF6D
    border: 1px dashed #33E46D;
    color: #000000;
    padding: 6px 10px 6px 10px;
    font-size: 15px;
    line-height: 19px;
  }
  .loader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;//width250px;
    height: 40px;
    margin-top: 10px;
    svg{
      color: #FFD200;
    }
  }

`

const ChangePassword = (props) => {

  const { change_user_password } = useContext(AuthContext);

  const { requestTo: changePassword, dni, scope } = props;

  const [ passwordActual, setPasswordActual ] = useState("");
  const [ passwordNuevo, setPasswordNuevo ] = useState("");
  const [ passwordRepite, setPasswordRepite ] = useState("");
  const [ loadingFormPass, setLoadingFormPass ] = useState(false);
  const [ successFormPassword, setSuccessFormPassword ] = useState(null);
  const [ responseMessageFormPassword, setResponseMessageFormPassword ] = useState("");


  const [ selectedDate, handleDateChange ] = useState(new Date());

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


    const handleChangePassword = () => {
      ///<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=;

      let hayError = false;
      let spChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if(scope !== "forgot"){
        if(passwordActual.length <= 0 || passwordNuevo.length <= 0 || passwordRepite.length <= 0){
          handleFormPasswordResponseStatus(0, 'Todos los campos son obligatorios');
          return null;
        }
        if(passwordActual.length <= 0){
          handleFormPasswordResponseStatus(0, 'La contraseña ingresada es incorrecta');
          return null;
        }
      }else{
        if(passwordNuevo.length <= 0 || passwordRepite.length <= 0){
          handleFormPasswordResponseStatus(0, 'Todos los campos son obligatorios');
          return null;
        }
      }
      if(passwordNuevo.length < 8){
        handleFormPasswordResponseStatus(0, 'Debe tener 8 o más caracteres');
        return null;
      }
      if(passwordNuevo !== passwordRepite){
        handleFormPasswordResponseStatus(0, 'Las contraseñas no coinciden');
        return null;
      }

      if(!spChars.test(passwordNuevo)){
        handleFormPasswordResponseStatus(0, 'Revisa que tu contraseña contenga caracteres especiales (ej: @#.)');
        return null;
      }



      if(hayError){
        return null;
      }

      const token = storage.getItem('protagonistas_token');
      const access_token = storage.getItem('protagonistas_access_token');

      const payload = {edit_pass: true,
                       pass: passwordActual,
                       new_pass: passwordNuevo,
                       access_token: access_token}


      setLoadingFormPass(true);
      const response = changePassword(payload);
      response.then(res => {

        const changed_password_status = path(['data', 'single', 'respPassword', 'status'], res);
        if(changed_password_status){
          handleFormPasswordResponseStatus(changed_password_status, "La contraseña se actualizo con éxito.");
          setPasswordActual("");
          setPasswordNuevo("");
          setPasswordRepite("");
        }else{
          handleFormPasswordResponseStatus(changed_password_status, "Hubo un problema al intentar modificar tu contraseña.");
        }
        //handleFormPasswordResponseStatus(res.data.status, res.data.mensaje);



      })


    }

    const handleFormPasswordResponseStatus = (status, message) => {
      if(status === 1){
        setSuccessFormPassword(true);
      }else{
        setSuccessFormPassword(false);
      }
      setResponseMessageFormPassword(message);
      setTimeout(closeFormPasswordErrorMessage, 3500);
      setLoadingFormPass(false);
    }

    function closeFormPasswordErrorMessage() {
      setResponseMessageFormPassword("");
      setSuccessFormPassword(null);
     }



  return (

      <Password className="contentPassword">



        <div className="content_change_password">

          {scope !== "forgot" ?
          <div className="field">
            <label htmlFor="first_name">Contraseña</label>
            <input
              type="password"
              id="password_actual"
              name="password_actual"
              onChange={(e) => setPasswordActual(e.target.value)}
              value={passwordActual}
              className={""}
            />
          </div>
          : <></>
          }

          <div className={`field ${scope == 'forgot' ? 'cero_margin_top' : '' }`}>
            <label htmlFor="first_name">Nueva Contraseña</label>
            <input
              type="password"
              id="nuevo_password"
              name="nuevo_password"
              onChange={(e) => setPasswordNuevo(e.target.value)}
              value={passwordNuevo}
              className={""}
            />
          </div>

          <div className="field">
            <label htmlFor="first_name">Repetir Contraseña</label>
            <input
              type="password"
              id="password_repite"
              name="password_repite"
              onChange={(e) => setPasswordRepite(e.target.value)}
              value={passwordRepite}
              className={""}
            />
          </div>


          {successFormPassword !== null ?
            successFormPassword ?
            <div className="successMessages">
              <div className="innerSuccessMessages">
                {responseMessageFormPassword}
              </div>
            </div>
            :
            <div className="errorMessages">
              <div className="innerErrorMessages">
                {responseMessageFormPassword}
              </div>
            </div>
            : <></>
          }
          {loadingFormPass &&
          <div className="loader">
            <CircularProgress size={30} />
          </div>
          }
          {!loadingFormPass &&
          <ButtonYellow
            className="bt_send_password"
            onClick={() => handleChangePassword()}
            disabled={false}
            css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
          >
            <p>GUARDAR</p>
          </ButtonYellow>
          }

        </div>

      </Password>


  )
}

export default ChangePassword;

