import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ButtonYellow } from '../../_elements';

import { IcoCheck } from '../../_helpers/constants';


const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    &.spacebetween{
      justify-content: space-between;
    }
`

const VideoPager = (props) => {

  const { courseVideos, handlChangeVideo } = props;

  return (

    (!courseVideos && courseVideos > 1) &&
      <Wrapper>
      {courseVideos && courseVideos.map((item, index) => {
        return(
          <ButtonYellow
            className="bt_videos"
            onClick={() => {
              //if(!item?.status){
                handlChangeVideo(index, item)
              //}
            }}
            //disabled={item?.status ? true : false}
            css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
          >

            <p>{ 'VIDEO ' + (index+1) }</p>
            {item?.already_seen ?
            <div className="ico">
              <IcoCheck />
            </div>
            :<></>
            }
          </ButtonYellow>
        )
      })}

      </Wrapper>

  )
}

export default VideoPager;
