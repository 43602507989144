import React, { useState, useContext, useEffect, Fragment, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RiSendPlane2Line } from "react-icons/ri";
import { ContentContext } from '../../_context';
import Reactions from './Reactions';
import Media from './Media';
import CommentInput from './CommentInput';
import CommentBox from './CommentBox';

import { ButtonHeader, ImageRound, ButtonActionRounded, ButtonYellow} from '../../_elements';

import { Navigation, Pagination } from 'swiper';

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;

  .box_add_comment{
    margin-top: 10px;
    .title{
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
    .input_comment{
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      width: 100%;
    }
    .avatar{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      overflow: hidden;
      border: 1px solid #DADADA;
      margin-right: 10px;
      img{
        float: left;
        width: 100%;
      }
    }
    .input{
      width: calc(100% - 100px);
      input{
        width: 100%;
        border-radius: 4px;
        border: 1px solid #DADADA;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 7px 7px;
      }
    }
    .bt_send{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      width: 40px;
      height: 40px;
      margin-left: 10px;
      cursor: pointer;
      :hover{
        color: #FFC600;
      }
    }
  }

  .box_comments{
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin-top: 30px;
    .comment_counter{
      font-size: 16px;
      font-weight: 600;
    }
    .comments_list{
      list-style: none;
      margin: 10px 0 0 0;
      padding: 0;
      /* .comment{
        display: flex;
        flex-direction: row;
        margin: 0 0 10px 0;
        padding: 10px;
        border-radius: 6px;
        box-sizing: border-box;
        background-color: #FFFFFF;
        .left{
          width: 40px;
          margin-right: 10px;
          .avatar{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            overflow: hidden;
            img{
              float: left;
              width: 100%;
            }
          }
        }
        .right{
          display: flex;
          flex-direction: column;
          width: calc(100% - 50px);
          .box_comment_response{
            .actions{
              display: flex;
              flex-direction: row;
              font-size: 14px;
              line-height: 14px;
              margin-top: 10px;
              .more_answers{
                cursor: pointer;
                &:hover{
                  color: #FFC600;
                }
              }
              .divider{
                margin-left: 5px;
                margin-right: 5px;
              }
              .bt_response{
                cursor: pointer;
                &:hover{
                  color: #FFC600;
                }
              }
            }
          }
          .author{
            display: flex;
            flex-direction: row;
            font-size: 14px;
            line-height: 28px;
            .name{
              font-weight: 600;
              margin-right: 5px;
            }
            .date{
              font-weight: 400;
            }
          }
          .text{
            font-size: 16px;
            line-height: 20px;
          }
        }
      } */
    }
    .loader{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 20px;
      svg{
        color: #FFC600;
      }
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const LoaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  .loader{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-right: 15px;
    svg{
      color: #FFC600;
    }
  }
`

const CommentsDrawer = (props) => {

  const { data,
          posts,
          setPosts,
          className,
          isLoading,
          onClick,
          medias,
          activeReactions,
          handleGetCommentsCounter } = props;

  const { add_comment_wall,
          userData,
          get_commnents_post,
          get_commnent_responses } = useContext(ContentContext);


  const [ loadingComments, setLoadingComments ] = useState(false);
  const [ showReactions, setShowReactions ] = useState(false);
  const [ inputText, setInputText ] = useState("");
  const [ comments, setComments ] = useState(null);
  const [ commentAnswers, setCommentAnswers ] = useState([]);

  const handleCloseReactions = () => {
    setShowReactions(false);
  }

  useEffect(() => {
    
    setLoadingComments(true);
    console.log("data", data)
    if(data !== null){
      if(Object.keys(data).length){
        handleGetComments();
      }
    }
    /*
    const response = get_commnents_post(data._id);
    response.then(res => {
      setLoadingComments(false);
      console.log("res", res.data)
      setComments(res.data.single.comments)
      const post_updated = posts.map((p, i) => {
        
        // if(p._id === data._id){
        //   p.comments_counter = res.data.single.data.comments_counter;
        //   p.users_comments = res.data.single.data.users_comments;
        // }
        
        return p;
        
      })
      setPosts(post_updated);

    })
    */
  
    return () => {}
  }, [data])

  const handleGetComments = () => {

    const response = get_commnents_post(data._id);
    response.then(res => {
      setLoadingComments(false);
      console.log("res", res.data)
      setComments(res.data.single.comments)
      const post_updated = posts.map((p, i) => {
        /*
        if(p._id === data._id){
          p.comments_counter = res.data.single.data.comments_counter;
          p.users_comments = res.data.single.data.users_comments;
        }
        */
        return p;
        
      })
      setPosts(post_updated);

    })

  }

  const handleMoreAnswers = (comment_id) => {
    const commnent_responses = get_commnent_responses(comment_id);
    commnent_responses.then(res => {
      setCommentAnswers(res.data.single.comments);
      console.log("res", res);
    })
  }

  const handleResponse = () => {
    alert("handleResponse")
  }
  

  if(!comments){
    return (
      <LoaderStyled>
        <div className='loader'>
          <CircularProgress size={35} />
        </div>
      </LoaderStyled>
    )
  }

  return (

    <WrapperStyled
      className={className}
      isLoading={isLoading}
      //onClick={() => { !isLoading && onClick()}}
    >

      <CommentInput
        data={data}
        posts={posts}
        setPosts={setPosts}
        setComments={setComments}
        handleGetCommentsCounter={handleGetCommentsCounter}
      />

      <div className='box_comments'>
        <div className='comment_counter'>
          Comentarios ({comments.length})
        </div>
        {loadingComments ? 
          <div className='loader'>
            <CircularProgress size={35} />
          </div>
          : 
          <ul className='comments_list'>
            
            {comments.map((c, i) => {//reverse().
              //if(!c.status){ return null; }
              return(
                <CommentBox 
                  data={data}
                  comment={c} 
                  handleGetCommentsCounter={handleGetCommentsCounter}
                />
                // <li className='comment'>
                //   <div className='left'>
                //     <div className='avatar'>
                //       {c.avatar_image ?
                //         <img src={c.avatar_image} />
                //       :
                //         <img src={"./images/noavatar.jpg"} />
                //       }
                //     </div>
                //   </div>
                //   <div className='right'>
                //     <div className='author'>
                //       <div className='name'>{c.name}</div>
                //       <div className='date'>{c.created_date}</div>
                //     </div>
                //     <div className='text'>{c.comment}</div>
                //     <div className='box_comment_response'>
                //       {commentAnswers.length ?
                //       <div className='comment_answers'>
                //         {
                //           commentAnswers.map(canswers => {
                //             return(
                //               <>canswers</>
                //             )
                //           })
                //         }
                //       </div>
                //       : ""}
                //       <div className='actions'>
                //         {c.replies_counter ? 
                //         <div className='more_answers' onClick={() => handleMoreAnswers(c._id)}>Ver {c.replies_counter} {c.replies_counter > 1 ? " respuestas" : " respuesta" } </div>
                //         : ""}
                //         {c.replies_counter ? <div className='divider'>-</div> : ""}
                //         <div className='bt_response' onClick={() => handleResponse()}>Responder</div>
                //       </div>
                //     </div>
                //   </div>
                // </li>
              )
            })}
          </ul>
        }
      </div>

    </WrapperStyled>

  )
}

export default CommentsDrawer;
