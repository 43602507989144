import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Logo,
         Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import { ContentContext } from '../../_context/ContentContext';
import { CommentProvider, FeedProvider } from '../../_context';

import storage from 'local-storage-fallback';
import { breakpoints } from '../../_helpers/breakpoints';
import Login from '~/_components/Login/Login';
import Retrieve from '~/_components/Login/Retrieve';

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import Destacado from '~/_components/LatamBox/Destacado';
import Muro from '~/_components/LatamBox/Muro';
import Contenido from '~/_components/LatamBox/Contenido';
import Cursos from '~/_components/LatamBox/Cursos';
import ContentContainer from '~/_components/LatamBox/ContentContainer';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModalPostMedia from '../../_components/Feed/ModalPostMedia';

import useMediaQuery from "../../_hooks/useMediaQuery";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.scss';
import { Navigation, Pagination } from 'swiper';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  //overflow: hidden;


  .box_container_section{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    .loader{
      padding-top: 10px;
      padding-left: 10px;
    }
    .nopost{
      font-size: 16px;
      line-height: 16px;
      padding-left: 5px;
      padding-top: 10px;
      padding-bottom: 50px;
    }
    >.header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
      border-bottom: 1px solid #D12E26;
      padding-bottom: 7px;
      .title{
        display: flex;
        flex-direction: row;
        align-items: center;
        .text{
          display: flex;
          height: 17px;
          img{
            float: left;
            height: 100%;
          }
        }
        .ico{
          width: 45px;
          margin-right: 10px;
          img{
            float: left;
            width: 100%;
          }
        }
        p{
          font-size: 20px;
          line-height: 20px;
          font-weight: 600;
          padding: 0;
          margin: 0;
        }
      }
      .bt_vermas{
        width: 110px;
        a{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 30px;
          background-color: #FFD200;
          border-radius: 6px;
          text-decoration: none;
          p{
            color: #000000;
            font-weight: 600;
            padding: 0;
            margin: 0;
          }
          :hover{
            background-color: #FFFFFF;
          }
        }
      }
    }
    .container_muro{
      /*
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      */
      display: grid;
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
      box-sizing: border-box;
      padding: 10px 0 13px 0;
      /*
      .carousel-item{
        min-height: 213px!important;
      }
      */

    }
  }

  .filters{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7px;
    width: 94vw;
    .title{
      font-size: 14px;
      font-weight: 500;
      width: 100px;
    }
    .swiper{
      width: calc(94vw - 70px);
    }
    .filter{
      background-color: #FFCD00;
      border: 1px solid #FFCD00;
      border-radius: 6px;
      cursor: pointer;
      &.selected{
        //border-color: #c69e00;
        border-color: #D8010F;
        background-color: #D8010F;
        color: #FFFFFF;
      }
      .text{
        padding: 3px 8px 3px 8px;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
  .hide{
    color: #F5F6FA;
  }
  @media ${breakpoints.xs} {
    .filters{
      width: 546px;
      .swiper{
        width: calc(546px - 70px);
      }
    }
    .box_container_section{

      >.header{
        width: 100%;
        margin-bottom: 5px;
        padding-bottom: 7px;
        .title{
          .text{
            height: 17px;
          }
          .ico{
            width: 45px;
            margin-right: 10px;
          }
          p{
            font-size: 20px;
            line-height: 20px;
          }
        }
        .bt_vermas{
          width: 110px;
          a{
            height: 30px;
            border-radius: 6px;
          }
        }
      }
      .container_muro{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        box-sizing: border-box;
        padding: 10px 0 13px 0;
        /*
        .carousel-item{
          min-height: 213px!important;
        }
        */
      }
    }
  }
  @media ${breakpoints.sm} {
    .filters{
      width: 448px;
      .swiper{
        width: calc(448px - 70px);
      }
    }
    .box_container_section{

      >.header{
        width: 100%;
        margin-bottom: 5px;
        padding-bottom: 7px;
        .title{
          .text{
            height: 17px;
          }
          .ico{
            width: 45px;
            margin-right: 10px;
          }
          p{
            font-size: 20px;
            line-height: 20px;
          }
        }
        .bt_vermas{
          width: 110px;
          a{
            height: 30px;
            border-radius: 6px;
          }
        }
      }
      .container_muro{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        box-sizing: border-box;
        padding: 10px 0 13px 0;
        /*
        .carousel-item{
          min-height: 213px!important;
        }
        */
      }
    }
  }
  @media ${breakpoints.md} {
    .filters{
      width: 672px;
      .swiper{
        width: calc(672px - 70px);
      }
    }
    .box_container_section{

      >.header{
        width: 100%;
        margin-bottom: 5px;
        padding-bottom: 7px;
        .title{
          .text{
            height: 17px;
            margin-left: 5px;
          }
          .ico{
            width: 45px;
            margin-right: 10px;
          }
          p{
            font-size: 20px;
            line-height: 20px;
          }
        }
        .bt_vermas{
          width: 110px;
          a{
            height: 30px;
            border-radius: 6px;
          }
        }
      }
      .container_muro{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        box-sizing: border-box;
        padding: 10px 0 13px 0;
        /*
        .carousel-item{
          min-height: 213px!important;
        }
        */
      }
    }
  }
  @media ${breakpoints.lg} {
    .filters{
      width: 840px;
      .swiper{
        width: calc(840px - 70px);
      }
    }
  }
`
const SwiperStyled = styled(Swiper)`
  width: 93vw;
  padding: 10px 0 13px 0;
  //overflow: hidden;
  .content-hide{
    display: none;
  }
  .content-show{
    display: block;
  }
  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;

    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    margin-top: -13px;
    position: absolute;
    top: 69px;
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: 10px;
  }
  &.swiper_destacado{
    .prev, .next{
      top: 108px;
    }
  }
  &.swiper_contenido{
    .prev, .next{
      top: 88px;
    }
  }
  @media ${breakpoints.xs} {
    width: 544px;
  }
  @media ${breakpoints.sm} {
    width: 448px;
  }
  @media ${breakpoints.md} {
    width: 672px;
  }
  @media ${breakpoints.lg} {
    width: 840px;
  }

  /*position: absolute;*/
`

const SwiperFilterStyled = styled(Swiper)`
  display: flex;
  margin-left: 0;
  //width: 93vw;
  //padding: 10px 0 13px 0;
  //overflow: hidden;
  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;

    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    margin-top: -13px;
    position: absolute;
    top: 69px;
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: 10px;
  }
  @media ${breakpoints.xs} {
    //width: 544px;
  }
  @media ${breakpoints.sm} {
    //width: 448px;
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }

  /*position: absolute;*/
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #FFD200!important;
  }
`

const LatamBoxContainer = () => {

  const history = useHistory();

  const { getLatamBoxMuro,
          getShellBoxContent,
          shellBoxWall, setShellBoxWall,
          shellBoxFeatured, setShellBoxFeatured,
          shellBoxContents, setShellBoxContents,
          shellBoxCourses, setShellBoxCourses,
          shellBoxContentFilter, setShellBoxContentFilter,
          userData } = useContext(ContentContext);


  const [ muro, setMuro ] = useState(null);
  const [ totalMuro, setTotalMuro ] = useState(null);
  const [ muroShowMoreStatus, setMuroShowMoreStatus ] = useState(null);
  const [ showAll, setShowAll ] = useState(false);
  const [ postSelected, setPostSelected ] = useState(null);
  const [ contentFilters, setContentFilters ] = useState([]);
  const [ contentHideElement, setContentHideElement ] = useState([]);
  const [ contentSelected, setContentSelected ] = useState(null);
  const [ quantity, setQuantity ] = useState(0);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const boxContent = useRef(null);

  const isXxs = useMediaQuery('(max-width: 575px)');
  const isXs = useMediaQuery('(min-width: 576px)');
  const isSm = useMediaQuery('(min-width: 768px)');
  const isMd = useMediaQuery('(min-width: 992px)');
  const isLg = useMediaQuery('(min-width: 1200px)');



  useEffect(() => {

    if(isLg){
      setQuantity(2);
      !showAll && handleShowMuroElements(2);

      if(shellBoxWall){
        if(shellBoxWall.length <= 2){
          setMuroShowMoreStatus(null);
        }
      }
    }else if(isMd){
      setQuantity(2)
      !showAll && handleShowMuroElements(2);

      if(shellBoxWall){
        if(shellBoxWall.length <= 2){
          setMuroShowMoreStatus(null);
        }
      }
    }else if(isSm){
      setQuantity(2)
      !showAll && handleShowMuroElements(2);
      showAll ? setMuroShowMoreStatus(true) : setMuroShowMoreStatus(false);
    }else if(isXs){
      setQuantity(2);
      !showAll && handleShowMuroElements(2);
      showAll ? setMuroShowMoreStatus(true) : setMuroShowMoreStatus(false);
    }else if(isXxs){
      setQuantity(1);
      !showAll && handleShowMuroElements(1);
      showAll ? setMuroShowMoreStatus(true) : setMuroShowMoreStatus(false);
    }
    return () => {}
  }, [isXxs, isXs, isSm, isMd, isLg])

  useEffect(() => {

    const result = getShellBoxContent();
    result.then((res) => {
      setTotalMuro(res);
    })
    return () => {}

  }, [])


  useEffect(() => {

    if(shellBoxWall){
      handleShowMuroElements(quantity);

      if(shellBoxWall.length > 2){
        setMuroShowMoreStatus(false);
      }
    }

  }, [shellBoxWall])


  const handleShowMuroElements = (muroShowLength) => {

    if(shellBoxWall && shellBoxWall.length){

      let muroShowLength_ = muroShowLength;
      if(muroShowLength > shellBoxWall.length){
        muroShowLength_ = shellBoxWall.length;
      }

      const muroElements = [];
      for(let i = 0; i < muroShowLength_; i++){
        muroElements.push(shellBoxWall[i]);
      }
      setMuro(muroElements);
    }else{
      setMuro([]);
    }
  }

  const handleShowMoreMuro = () => {
    setMuroShowMoreStatus(true);
    setShowAll(true);
    handleShowMuroElements(shellBoxWall.length);
  }
  const handleShowLessMuro = () => {
    setMuroShowMoreStatus(false);
    setShowAll(false);
    handleShowMuroElements(quantity);
  }

  const handleOnClickMedia = (post) => {
    setPostSelected(post);
  }

  useEffect(() => {

    const contentList = [];
    contentFilters.forEach(filter => {
      shellBoxContents.forEach(content => {
        const haveTag = content.tags.filter(tag => tag.title === filter);
        if(haveTag.length) {
          if(!contentList.filter(citem => citem === content.title).length){
            contentList.push(content.title);
          }
        };
      });
    });
    setContentHideElement(contentList);
    return () => {

    }
  }, [contentFilters])


  useEffect(() => {

    console.log("------- muro")
    console.log(muro);
    console.log("------- muro")

    return () => {

    }
  }, [muro])

  const handleFilter = (filter) => {

    let newContentFilters = [...contentFilters];

    if(contentFilters.filter((cf) => cf === filter).length){

      newContentFilters = contentFilters.filter((cf) => cf !== filter);

    }else{

      newContentFilters.push(filter);

    }

    setContentFilters(newContentFilters);

    /*
    return null;
    //const actualFilters = contentFilters.filter(cf == cf === filter);

    const contentFiltered = [];
    console.log(filter);
    console.log("handleFilter ----------------")
    let filters = shellBoxContents.map((content, ci) => {
      content.tags.map((tag, ti) => {
        if(tag.title == filter){
          contentFiltered.push(content.title);
          console.log(content.title);// + ": "+tag.title
        }

      })
    });
    console.log(contentFiltered);
    console.log("handleFilter ----------------")
    //contentHideElement,
    //setContentHideElement(filters);
    */
  }

  const handleContentClick = (content) => {
    setContentSelected(content);
    document.getElementById("root").scrollTo(0,0);
  }

  return (
    <>

    {postSelected !== null ?
      <ModalPostMedia post={postSelected} setPostSelected={setPostSelected}/>
    :""}

    <Wrapper id="boxContent" className="boxContent" ref={boxContent}>

      {contentSelected !== null ?
        <ContentContainer
          data={contentSelected}
          setContentSelected={setContentSelected}
          shellBoxCourses={shellBoxCourses}
          setShellBoxCourses={setShellBoxCourses}
          scope="shellbox" />
      :

      <>
      <div className="box_container_section">
        <div className="header">
          <div className="title">
            {/*
            <div className="ico">
              <img src="/images/shellbox-aceite.png" />
            </div>
            */}
            <div className="text">
              <img src="/images/shellbox-destacados.svg" />
            </div>
            {/*<p>Destacado</p>*/}
          </div>
        </div>
        { shellBoxFeatured === null ?
          <div className="loader">
            <CircularProgressStyled />
          </div>
        :
          shellBoxFeatured.length ?
          <SwiperStyled
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}

            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={"auto"}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            className="swiper_destacado"
          >
            {
              shellBoxFeatured.map((e, i) => {
                return(
                <SwiperSlide>
                  <Destacado data={e} />
                </SwiperSlide>
                )
              })
            }

            <div ref={prevRef} className="prev">
              <FaChevronLeft />
            </div>
            <div ref={nextRef} className="next">
              <FaChevronRight />
            </div>

          </SwiperStyled>
          :
          <div className="nopost">No hay destacados para mostrar</div>
        }
      </div>




      <div className="box_container_section">
        <div className="header">
          <div className="title">
            <div className="text">
              <img src="/images/shellbox-contenidos.svg" />
            </div>
          </div>
        </div>

        {shellBoxCourses === null ?
          <div className="loader">
            <CircularProgressStyled />
          </div>
        :
          shellBoxCourses.length ?
          <>
          {shellBoxContentFilter && shellBoxContentFilter.length ?
          <div className="filters">
            <div className="title">
              Filtrar por:
            </div>

            <SwiperFilterStyled
              spaceBetween={5}
              slidesPerView={"auto"}
              centeredSlides={false}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {shellBoxContentFilter.map((e, i) => {
                return(
                  <SwiperSlide>
                    <div className={`filter ${contentFilters.filter(f => f === e.title).length ? 'selected' : ''}`} onClick={() => handleFilter(e.title)}>
                      <div className="text">{e.title}</div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </SwiperFilterStyled>
          </div>
          : ""
          }
          <SwiperStyled
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}

            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={"auto"}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log("")}
            className="swiper_contenido"
          >
            {shellBoxCourses.map((e, i) => {
              return(
              <SwiperSlide key={'contenido'+i}>
                <Cursos data={e} handleContentClick={handleContentClick} />
              </SwiperSlide>
              )
            })}

            <div ref={prevRef} className="prev">
              <FaChevronLeft />
            </div>
            <div ref={nextRef} className="next">
              <FaChevronRight />
            </div>

          </SwiperStyled>
          </>
          :
          <div className="nopost">Tareas de mantenimiento</div>
        }
      </div>









      <div className="box_container_section">
        <div className="header">
          <div className="title">
            <div className="text">
              <img src="/images/shellbox-posteos.svg" />
            </div>
          </div>
          {muroShowMoreStatus !== null
          ?
          muroShowMoreStatus ?
            <div className="bt_vermas" onClick={() => handleShowLessMuro()}>
              <a href="javascript:void(0)">
                <p>ver menos</p>
              </a>
            </div>
          :
            <div className="bt_vermas" onClick={() => handleShowMoreMuro()}>
              <a href="javascript:void(0)">
                <p>ver más</p>
              </a>
            </div>
          :
          <></>
          }

        </div>

        <div className="container_muro">
          {muro !== null && muro.length > 0 ?
            muro.map((el, i) => {
                return(
                    <CommentProvider>
                      <Muro key={'muro'+i} data={el} shellBoxWall={shellBoxWall} setShellBoxWall={setShellBoxWall} onClickMedia={handleOnClickMedia} />
                    </CommentProvider>
                )
              })
            :
            <div className="loader">
              <CircularProgressStyled />
            </div>
          }
        </div>
      </div>




      </>
      }

    </Wrapper>

    </>
  )
}

export default LatamBoxContainer;
