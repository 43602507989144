import React, { useState, useContext, useEffect, Fragment, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../_elements';
import ReactPlayer from 'react-player';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Scrollbar } from 'react-scrollbars-custom';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  //padding: 7px 7px;
  .ScrollbarsCustom-TrackY{
    height: 100%!important;
    top: 0!important;
  }

  .ScrollbarsCustom{
    //height: ${props => props.scrollHeight ? `calc(100% - ${props.scrollHeight}px)` : '270px'}!important;
  }

  .inner_answers{
    width: calc(100% - 10px);
    padding-left: 10px;
    .answer{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #c19f00;
      padding: 3vw 3vw;
      font-weight: 600;
      font-size: 3.25vw;
      line-height: 4.25vw;
      border-radius: 10px;
      background-color: #FFFFFF;
      color: #000000;
      margin-bottom: 5px;
      text-transform: uppercase;
      cursor: pointer;
      :last-child{
        margin-bottom: 0;
      }
      :hover{
        border: 1px solid ${props => props.is_red ? '#FFFFFF' : '#FFD200'};
        background-color: rgba(255, 255, 255, .5);
        color: ${props => props.is_red ? '#FFFFFF' : '#000000'}
      }
      &.selected{
        border: 1px solid ${props => props.is_red ? '#FFFFFF' : '#FFD200'};
        background-color: rgba(255, 255, 255, .5);
        color: ${props => props.is_red ? '#FFFFFF' : '#000000'}
      }
    }
  }
  //class="ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
  @media ${breakpoints.xs} {//576px
    .inner_answers{
      width: calc(100% - 10px);
      padding-left: 10px;
      .answer{
        width: 100%;
        padding: 15px 15px;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 5px;
      }
    }
  }
  @media ${breakpoints.sm} {//768px
  }
  @media ${breakpoints.md} {//992px
  }
  @media ${breakpoints.lg} {//1200px
  }
  @media ${breakpoints.xl} {//1366px
  }
  @media ${breakpoints.xxl} {//1440px
  }
  @media ${breakpoints.xxxl} {//1680px
  }
  @media ${breakpoints.xxxxl} {//1920px
  }

`

const SingleChoice = (props) => {

  const { answers,
          scope,
          multiple,
          className,
          isLoading,
          onClick,
          questionRef,
          selectedAnswers,
          setSelectedAnswers,
          isRed = false } = props;

  const [ questionSelected, setQuestionSelected ] = useState(null);


  const handleAnswer = (_id) => {
    if(multiple){
      if(!selectedAnswers.includes(_id)){
        setSelectedAnswers([...selectedAnswers, _id])
      }else{
        const filtered = selectedAnswers.filter(answer => answer != _id);
        setSelectedAnswers(filtered);
      }
    }else{
      setSelectedAnswers([_id]);
    }
  }

  useEffect(() => {
    console.log("isRed", multiple)
  
    return () => {}
  }, [multiple])
  

  return (

    <WrapperStyled
      scope={scope}
      className={className}
      isLoading={isLoading}
      is_red={isRed}
      //scrollHeight={scrollHeight}
    >
      <Scrollbar style={{ width: "100%" }}>
        <div className='inner_answers'>
          {
            answers.map((answer, index) => {
              return(
                <div className={`answer${selectedAnswers.includes(answer._id) ? ' selected' : ""}`} onClick={() => handleAnswer(answer._id)}>{ answer.label }</div>
              )
            })
          }
        </div>
      </Scrollbar>

    </WrapperStyled>

  )
}

export default SingleChoice;
