import React, { useState, useContext, useEffect } from 'react';
import { ContentContext } from '~/_context';
import styled from 'styled-components';
import moment from 'moment';
import CommenResponsetInput from './CommenResponsetInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Avatar from './Avatar';

const CommentStyled = styled.li`
    //.comment{
        display: flex;
        flex-direction: row;
        margin: 0 0 10px 0;
        padding: 10px;
        border-radius: 6px;
        box-sizing: border-box;
        background-color: #FFFFFF;
        .left{
            width: 40px;
            margin-right: 10px;
            .avatar{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 50px;
                overflow: hidden;
                img{
                    float: left;
                    width: 100%;
                }
            }
        }
        .right{
            display: flex;
            flex-direction: column;
            width: calc(100% - 50px);
            .box_comment_response{
                .comment_answers{
                    margin-top: 15px;
                    margin-bottom: 15px;
                    .box_answers{
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 15px;
                        .left{
                            width: 40px;
                            margin-right: 10px;
                            .avatar{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                width: 40px;
                                height: 40px;
                                border-radius: 50px;
                                overflow: hidden;
                                img{
                                    float: left;
                                    width: 100%;
                                }
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
                .actions{
                    display: flex;
                    flex-direction: row;
                    font-size: 14px;
                    line-height: 14px;
                    margin-top: 10px;
                    .more_answers{
                        cursor: pointer;
                        &:hover{
                            color: #FFC600;
                        }
                    }
                    .divider{
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                    .bt_response{
                        cursor: pointer;
                        &:hover{
                            color: #FFC600;
                        }
                    }
                }
            }
            .author{
                display: flex;
                flex-direction: row;
                font-size: 14px;
                line-height: 28px;
                .name{
                    font-weight: 600;
                    margin-right: 5px;
                }
                .date{
                    font-weight: 400;
                }
            }
            .text{
                font-size: 16px;
                line-height: 20px;
            }
        }
    //}
`

const CommentBox = (props) => {

    const { data, comment, action, handleGetCommentsCounter } = props;

    const { get_commnent_responses,
            getImageStogeUrl,
            getAvatarImage } = useContext(ContentContext);

    const [ commentResponses, setCommentResponses ] = useState([]);
    const [ showCommentResponses, setShowCommentResponses ] = useState(false);
    const [ showResponse, setShowResponse ] = useState(false);
    const [ commentReliesCounter, setCommentReliesCounter ] = useState(0);
    const [ isLoadingCommentResponses, setIsLoadingCommentResponses ] = useState(false);

    const handleMoreResponses = (comment_id) => {
        
        setIsLoadingCommentResponses(true);
        setShowCommentResponses(true);
        const commnent_responses = get_commnent_responses(comment_id);
        commnent_responses.then(res => {
            setCommentResponses(res.data.single.comments);
            setIsLoadingCommentResponses(false);
        })
        
    }

    const handleHideMoreResponses = () => {
        console.log("handleHideMoreResponses", handleHideMoreResponses)
        setShowCommentResponses(false);
        setCommentResponses([]);

    }

    const handleResponse = () => {

        setShowResponse(!showResponse);

    }

    // const ImageAvatar = (props) => {
        
    //     const { comment } = props;
    //     const [ image, setImage ] = useState("");

    //     const imagen = getAvatarImage(comment.avatar_image);
    //     imagen.then(img => {
    //         setImage(img);
    //     })
        
    //     return(
    //         <img src={`${image}`} />
    //     )

    // }
    

    useEffect(() => {
        
        if(comment){
            setCommentReliesCounter(parseInt(comment.replies_counter));
        }
    
      return () => {}
    }, [comment])
    

    return (
        <CommentStyled className='comment'>
            <div className='left'>
                <div className='avatar'>
                    {comment.avatar_image ?
                        <Avatar
                            comment={comment}
                        />
                    :
                        <img src={"./images/noavatar.jpg"} />
                    }
                </div>
            </div>
            <div className='right'>
              <div className='author'>
                <div className='name'>{comment.name}</div>
                <div className='date'>{moment(comment.created_date).fromNow()}</div>
              </div>
              <div className='text'>{comment.comment}</div>
              <div className='box_comment_response'>

                {showCommentResponses && isLoadingCommentResponses ?
                <div className='loader'>
                    <CircularProgress size={20} />
                </div> : ""}

                {(commentResponses.length && showCommentResponses) ?
                <Scrollbars style={{ height: 180 }}>
                    <div className='comment_answers'>
                        
                    {
                        commentResponses.map(canswers => {//reverse()
                        return(
                            <div className='box_answers'>
                                <div className='left'>
                                    <div className='avatar'>
                                        {comment.avatar_image ?
                                            <Avatar
                                                comment={canswers}
                                            />
                                        :
                                            <img src={"./images/noavatar.jpg"} />
                                        }
                                    </div>
                                </div>
                                <div className='rigth'>
                                    <div className='author'>
                                        <div className='name'>{canswers.name}</div>
                                        <div className='date'>{moment(canswers.created_date).fromNow()}</div>
                                    </div>
                                    <div className='text'>{canswers.comment}</div>
                                </div>
                            </div>
                        )
                        })
                    }
                    
                    </div>
                </Scrollbars>
                : ""}

                {showResponse ? 
                <CommenResponsetInput
                    post={data}
                    comment={comment}
                    setShowResponse={setShowResponse}
                    setCommentResponses={setCommentResponses}
                    showCommentResponses={showCommentResponses}
                    setShowCommentResponses={setShowCommentResponses}
                    commentReliesCounter={commentReliesCounter}
                    setCommentReliesCounter={setCommentReliesCounter}
                    handleGetCommentsCounter={handleGetCommentsCounter}
                    /*data={data}
                    posts={posts}
                    setPosts={setPosts}*/
                /> : ""}

                <div className='actions'>
                  {commentReliesCounter ? 
                  <div className='more_answers' onClick={() => showCommentResponses ? handleHideMoreResponses() : handleMoreResponses(comment._id)}>{showCommentResponses ? "Ocultar " : "Ver "} {commentReliesCounter} {commentReliesCounter > 1 ? " respuestas" : " respuesta" } </div>
                  : ""}
                  {commentReliesCounter ? <div className='divider'>-</div> : ""}
                  <div className='bt_response' onClick={() => handleResponse()}>Responder</div>
                </div>
              </div>
            </div>
        </CommentStyled>
    );
};

export default CommentBox;