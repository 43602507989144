import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';

import { } from '../../_elements';

import { ContentContext } from '../../_context';

const QuestionStyled = styled.div`

  .evaluation_answers{
    margin-top: 20px;
    .evaluation_answer{
      border: 1px solid #FFC600;
      box-sizing: border-box;
      padding: 15px 35px;
      border-radius: 15px;
      margin-bottom: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      :last-child{
        margin-bottom: 0;
      }
      :hover{
        background-color: #FFC600;
        //color: #FFFFFF;
      }
      &.selected{
        background-color: #FFC600;
      }
    }
  }
  .image{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    >.inner{
      width: 70vw;
      img{
        float: left;
        width: 100%;
      }
    }
  }
  @media ${breakpoints.xs} {
    .image{
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      >.inner{
        width: 350px;
      }
    }
  }
  @media ${breakpoints.sm} {
  }
`

const MultipleChoice = (props) => {

  const { multiple,
          secondary_actions,
          order,
          answersSelected,
          setAnswersSelected,
          answerEvaluation,
          setAnswerEvaluation,
          question = null } = props

  const { evaluationActive,
          setEvaluationActive,
          evaluation,
          setEvaluation,
          take_user_evaluation } = useContext(ContentContext);

  const handleAnswer = (_id) => {
    if(multiple){
      if(!answersSelected.includes(_id)){
        setAnswersSelected([...answersSelected, _id])
      }else{
        const filtered = answersSelected.filter(answer => answer != _id);
        setAnswersSelected(filtered);
      }
    }else{
      setAnswersSelected([_id]);
    }
  }

  return (
    <QuestionStyled className={''}>

      {(question && question?.media?.length) ?
        <div className='image'>
          <div className='inner'>
            <img src={question.media} />
          </div>
        </div>
      : ""}

      <div className='evaluation_answers'>
        {secondary_actions && secondary_actions.map((answer, index) => {
          return(
            <div className={`evaluation_answer${answersSelected.includes(answer._id) ? ' selected' : ""}`} onClick={ () => handleAnswer(answer._id) }>
              {answer.label}
            </div>
          )
        })}
      </div>

    </QuestionStyled>
  )
}

export default MultipleChoice;
