import React from "react";
import IncentivoAutitosContainer from "./IncentivoAutitosContainer";

const IncentivoAutitos = (props) => {
  return (
      <IncentivoAutitosContainer location={props.location} history={props.history} match={props.match}/>
  );
};

export default IncentivoAutitos;
