import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { COLORS } from '../../_helpers/constants';
import { ButtonYellow } from '../../_elements';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;

    .product_download{
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      .bt_ficha_tecnica{
      }
    }
    .product_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 20px 20px 20px;
        //background-color: ${props => props.isModal ? '#FFFFFF' : COLORS.secondary};
        border-radius: 10px;
        .product_item_image{
          display: flex;
          width: 100%;
          img{
            width: 100%;
          }
        }
        .product_item_description{
          margin-top: 20px;
          color: ${COLORS.primary};
          p{
            padding: 0;
            margin: 0;
            color: ${props => props.isModal ? '#000000' : COLORS.primary};
            &.product_name{
              font-size: 18px;
              font-weight: 600;
            }
            &.product_description{
              font-size: 14px;
              font-weight: 500;
              margin-top: 10px;
            }
          }

        }

      }

    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`

const Item = (props) => {

  const { product, name, click, index, isModal } = props;

  const handleDownload = (file) => {
    window.open(file, '_blank').focus();
  }

  return (
    <Wrapper isModal={isModal}>
      <div className="product_item" onClick={() => click(index) }>
        <div className="product_item_image">
          <LazyLoadImage
            alt={product.image_big.alt}
            height={product.image_big.height}
            src={product.image_big} // use normal <img> attributes as props
            width={product.image_big.width}
            effect="blur"
          />
        </div>
        <div className="product_item_description">
          <p className="product_name">{ name }</p>
          <p className="product_description">{ product.description_long }</p>
        </div>
      </div>
      {product.data_sheet ?
      <div className="product_download">
        <ButtonYellow
          className="bt_ficha_tecnica"
          onClick={() => handleDownload(product.data_sheet) }
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
        >
          <p>Ficha Técnica</p>
        </ButtonYellow>
      </div>
      :
        <></>
      }
    </Wrapper>
  )

}

export default Item;
