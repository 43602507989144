import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { Spinner, COLORS } from '../../_helpers/constants';
import Video from './Video';
import Image from './Image';
import Actions from './Actions';

import Button from 'react-bootstrap/Button';
import SendIcon from '@material-ui/icons/Send';
import malasPalabras from './CommentsSeccion/bad-words-list';
import DrawerPost from './DrawerPost';
import { CommentContext } from '../../_context';

const Wrapper = styled.div`

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;

  .caution{
    margin-top: 25px;
  }

  @media ${breakpoints.xs} {
    margin-bottom: 40px;
  }
  @media ${breakpoints.sm} {
    margin-bottom: 40px;
  }
  @media ${breakpoints.md} {
    margin-bottom: unset;
  }
  @media ${breakpoints.lg} {
    margin-bottom: unset;
  }


`

const Content = styled.div`

  display: flex;
  width: 100%;
  flex-direction: column;

  position: relative;

  /*
  @media ${breakpoints.sm} {
    width: 200px;
  }
  @media ${breakpoints.md} {
    width: 300px;
  }
  @media ${breakpoints.lg} {
    width: 300px;
  }
  */

`

const Title = styled.div`

  display: flex;
  width: 100%;
  margin-top: 10px;
  p{
    color: ${props => props.onDrawer ? '#000000' : COLORS.primary};
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }
  //flex-direction: row;

  /*
  @media ${breakpoints.sm} {
    width: 200px;
  }
  @media ${breakpoints.md} {
    width: 300px;
  }
  @media ${breakpoints.lg} {
    width: 300px;
  }
  */

`

const Description = styled.div`

  display: flex;
  width: 100%;
  margin-top: 5px;
  p{
    color: ${props => props.onDrawer ? '#000000' : COLORS.primary};
    font-weight: 400;
    font-size: 13px;
    margin: 0;
  }
  a{
    font-weight: 700;
    color: #D8010F;//#FFD200;//
  }

  //flex-direction: row;

  /*
  @media ${breakpoints.sm} {
    width: 200px;
  }
  @media ${breakpoints.md} {
    width: 300px;
  }
  @media ${breakpoints.lg} {
    width: 300px;
  }
  */

`


/*
const Image = styled.div`

  display: flex;

  @media ${breakpoints.lg} {
    width: 440px;
    img{
      width: 100%;
    }
  }

`
*/

const PostContent = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;

  width: ${props => props.onDrawer ? '95vw!important' : "94.8vw!important"};
  //height: ${props => props.onDrawer ? '95vw!important' : "94.8vw!important"};
  height: auto!important;

  align-content: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ECECEC;

  .video_player{
    //display: none !important;
    width: 94.8vw!important;
    height: 94.8vw!important;
  }

  @media ${breakpoints.xs} {
    width: ${props => props.onDrawer ? '530px!important' : "546px!important"};
    //height: ${props => props.onDrawer ? '530px!important' : "546px!important"};


    .video_player{
      width: ${props => props.onDrawer ? '530px!important' : "546px!important"};
      height: ${props => props.onDrawer ? '530px!important' : "546px!important"};
    }
  }
  @media ${breakpoints.sm} {
    width: ${props => props.onDrawer ? '530px!important' : "448px!important"};
    //height: ${props => props.onDrawer ? '530px!important' : "448px!important"};

    .video_player{
      width: ${props => props.onDrawer ? '530px!important' : "448px!important"};
      height: ${props => props.onDrawer ? '530px!important' : "448px!important"};
    }
  }
  @media ${breakpoints.md} {
    width: ${props => props.onDrawer ? '530px!important' : "316px!important"};
    //height: ${props => props.onDrawer ? '530px!important' : "316px!important"};

    .video_player{
      width: ${props => props.onDrawer ? '530px!important' : "316px!important"};
      height: ${props => props.onDrawer ? '530px!important' : "316px!important"};
    }
  }
  @media ${breakpoints.lg} {
    width: ${props => props.onDrawer ? '530px!important' : "410px!important"};
    //height: ${props => props.onDrawer ? '530px!important' : "410px!important"};

    .video_player{
      width: ${props => props.onDrawer ? '530px!important' : "410px!important"};
      height: ${props => props.onDrawer ? '530px!important' : "410px!important"};
    }
  }
`
const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ECECEC;
  margin-top: 10px;
  //background-color: pink;
  .inner{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
`
const MessageTextArea = styled.textarea`
  display: flex;
  width: 100%;
  height: 68px;
  border: 0;
  resize: none;
  font-size: 16px;
  line-height: 21px;

  :focus {
    outline: none !important;
  }
`
const CommentActions = styled.div`
  display: flex;
  margin-left: 10px;
  width: 50px;
  height: 68px;
  .btn-primary{
    background-color: rgb(255, 210, 0);
    border-color: rgb(234, 193, 5);
  }

  .button-publicar{
    //display: none!important;
    background-color: rgb(255, 210, 0);
    border-color: rgb(234, 193, 5);
    :hover{
      background-color: rgb(234, 189, 0);
      border-color: rgb(234, 193, 5);
    }
    &.btn-primary:not(:disabled):not(.disabled):active,
    &.btn-primary:not(:disabled):not(.disabled).active,
    &.show > .btn-primary.dropdown-toggle{
      background-color: rgb(234, 189, 0);
        border-color: rgb(234, 193, 5);
    }
  }
`
const Loader =  styled.div`
  //display: none;
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0.375rem 0.75rem;
  svg{
    width: 30px;
    height: 30px;
  }

`

const Error = styled.div`
  display: flex;
  width: 100%;
  //background-color: #EC1C24;
  p{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #EC1C24;
    margin: 0;
    margin-top: 5px;
    text-align: center;
  }

`

const PostMediaContent = ({post_type, post, onDrawer}) => {
  switch (post_type) {
    case 'image':
      return (
        <Image post={post} onDrawer={onDrawer} />
      );
      break;
    case 'video':
      return (
        <Video post={post} onDrawer={onDrawer} />
      );
      break;
    default:
      return null;
      break;
  }

}



const Post = (props) => {

  const textareaRef = useRef(null);
  const [isFormCommentValid, setIsFormCommentValid] = useState(false);
  const [showElementoMensajeSpinner, setShowElementoMensajeSpinner] = useState(false);
  const [ openDrawer, setOpenDrawer ] = useState(false);
  const [ commentStatusError, setCommentStatusError ] = useState(false);
  const [ commentStatusErrorMessage, setCommentStatusErrorMessage ] = useState('');
  const [ postLikeStatus, setPostLikeStatus ] = useState(null);
  const [ isSendingMessage, setIsSendingMessage ] = useState(false);

  const { getComments, postComment, onSubmitComment } = useContext(CommentContext);


  const showMensajeSpinner = { display: showElementoMensajeSpinner ? 'block' : 'none' };
  const showMensajeButton = { display: showElementoMensajeSpinner ? 'none' : 'block', textAlign: 'center' };

  const {
    post,
    handleLike,
    handleDislike,
    onDrawer,
    onClickMedia
  } = props;

  const {
    post_type,
    like,
    id: post_id
  } = post;

  function checkBadWords(comment) {
    let keyReplacement;
    let lowerString;
    let string = comment.replace(/<(?:.|\n)*?>/gm, '');
    let replacements = 0;

    lowerString = string.toLowerCase();
    // loop through each key in the dictionary and search for matches
    // (seems like it'd be faster to indexOf on all keys and run replace on matches, rather than replace all)
    malasPalabras.forEach(item => {
      const lowerItem = item.toLowerCase(); // lowercase the bad word
      let index = lowerString.indexOf(lowerItem); // find it in the string
      while (index !== -1) {
        // Esta es la validación que había antes, obviamente como es un textarea no se puede introducir HTML. Queda como fix para el futuro.
        keyReplacement = `<span class="bad-word">${string.substr(index, item.length)}</span>`; // replace the bad word with a span to style it
        string = string.substr(0, index) + keyReplacement + string.substr(index + item.length); // add the replaced bad word to the string
        lowerString = string.toLowerCase(); // get the new string replaced
        index = lowerString.indexOf(lowerItem, index + keyReplacement.length); // find other occurrences
        // eslint-disable-next-line no-plusplus
        replacements++; // keep the count
      }
    });

    comment = string; // assign the filtered string
    return { replacements, comment };
  }

  const handleMensajeSubmit = (mensajeSubmitEvent, post_id) => {

    mensajeSubmitEvent.preventDefault();
    setCommentStatusError(false);
    setCommentStatusErrorMessage('');
    const puteadas = checkBadWords(textareaRef.current.value);

    if (puteadas.replacements) {
      setCommentStatusError(true);
      setCommentStatusErrorMessage('Por favor cuida tus palabras.');
      return false;
    }

    setIsSendingMessage(true);

    postComment({
      id_post: post_id,
      text: textareaRef.current.value,
    })
    .then(() => {
      textareaRef.current.value = '';
      onSubmitComment(post_id);
      setIsSendingMessage(false);
    });

  };

  const handleCommentChange = e => {
    setIsFormCommentValid(!!e.target.value.length);
  };

  const handleDrawerComment = () => {
    setOpenDrawer(!openDrawer);
  }
  const handleComment = (post_id) => {
    getComments(post_id);
    handleDrawerComment();
  }


  return (
    <>
    <Wrapper breakpoints={breakpoints}>
        <PostContent className="PostContent" onDrawer={onDrawer}>

          <PostMediaContent post_type={post_type} post={post} onDrawer={onDrawer} />

        </PostContent>

        <Content>

          <Actions
            post={post}
            post_id={post_id}
            accept_comments={post.accept_comments}
            accept_likes={post.accept_likes}
            like={like}
            liked={postLikeStatus}
            handleComment={handleComment}
            handleLike={handleLike}
            handleDislike={handleDislike}
            handleOnClickMedia={onClickMedia}
            onDrawer={onDrawer}
          />

          <Title onDrawer={onDrawer}>
            <p>{post.title}</p>
          </Title>
          <Description onDrawer={onDrawer}>
            <p dangerouslySetInnerHTML={{ __html: post.text }}></p>
          </Description>
          {onDrawer && post.accept_comments &&
          <>
          <CommentBox>
            <div className="inner">

              <MessageTextArea
                name="mensaje"
                ref={textareaRef}
                type="text"
                placeholder="Escribí un comentario&hellip;"
                className="input-mensajes"
                rows="2"
                onChange={handleCommentChange}
              />

              <CommentActions>
                {isSendingMessage ?
                <Loader className="loader" >
                  <Spinner />
                </Loader>
                :
                <Button style={showMensajeButton} className="button-publicar" onClick={(e) => handleMensajeSubmit(e, post.id)} disabled={!isFormCommentValid}>
                  <SendIcon />
                </Button>
                }
              </CommentActions>


            </div>
          </CommentBox>
          {commentStatusError &&
            <Error>
              <p>{ commentStatusErrorMessage }</p>
            </Error>
          }
          </>
          }


        </Content>

    </Wrapper>
    <DrawerPost
      post={post}
      post_id={post_id}
      handleComment={() => handleComment()}
      handleLike={handleLike}
      handleDislike={handleDislike}
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      //loading={loading}
      getComments={getComments} />
    </>
  )

}

export default Post;
