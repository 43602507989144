import React, { useState, useContext, useEffect, Fragment, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../../_elements';
import ReactPlayer from 'react-player';
import useMediaQuery from '@mui/material/useMediaQuery';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-sizing: border-box;
  width: 100%;
  background-color: #000000;
  //padding: 7px 7px;
  //border-radius: 25px;
  >.inner{
    width: 100%;
    height:  ${props => props.scope == "drawer" ? "100vw" : props.scope == "modal_media" ? "800px" : "87vw"};
    :first-child{
      padding: 0!important;
    }
    .react-player{
      padding: 0!important;
    }
  }
  @media ${breakpoints.xs} {//576px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "500px" : props.scope == "modal_media" ? "800px" : "546px"};
    }
  }
  @media ${breakpoints.sm} {//768px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "600px" : props.scope == "modal_media" ? "800px" : "363px"};
    }
  }
  @media ${breakpoints.md} {//992px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "600px" : props.scope == "modal_media" ? "800px" : "390px"};
    }
  }
  @media ${breakpoints.lg} {//1200px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "600px" : props.scope == "modal_media" ? "800px" : "490px"};
    }
  }
  @media ${breakpoints.xl} {//1366px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "600px" : props.scope == "modal_media" ? "800px" : "385px"};
    }
  }
  @media ${breakpoints.xxl} {//1440px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "600px" : props.scope == "modal_media" ? "800px" : "410px"};
    }
  }
  @media ${breakpoints.xxxl} {//1680px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "600px" : props.scope == "modal_media" ? "800px" : "485px"};
    }
  }
  @media ${breakpoints.xxxxl} {//1920px
    >.inner{
      height:  ${props => props.scope == "drawer" ? "600px" : props.scope == "modal_media" ? "800px" : "420px"};
    }
  }

`

const Reactions = (props) => {

  const { data,
          scope,
          className,
          isLoading,
          onClick } = props;

  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const [ _playing, set_Playing ] = useState(false);
  const [ playStatus, setPlayStatus ] = useState(false);



  const _player = useRef(null);

  const handleOnReady = (e) => {

  }
  const handleVideoSeek = (e) => {
  }

  const handleVideoStop = () => {

  }

  const handleVideoStart = () => {

  }

  const handleVideoPlay = () => {
    setPlayStatus(true);
  }

  const handleVideoProgress = (progress) => {
    if(!onVideoEnded && progress.played >= 0.95){
      setOnVideoEnded(true);
    };
  }
  const handleVideoEnded = () => {

    const payload = {
      "id_content": "contentID",
    }
  }

  return (

    <WrapperStyled
      scope={scope}
      className={className}
      isLoading={isLoading}
      //onClick={() => { !isLoading && onClick()}}
    >
      <div className='inner'>

        <ReactPlayer
          ref={_player}
          className={`react-player`}
          playing={playStatus}
          url={data.media_url}
          width='100%'
          height='100%'
          controls={true}
          onProgress={(progress) => { handleVideoProgress(progress)}}
          onReady={handleOnReady}
          onPlay={handleVideoPlay}
          onStart={handleVideoStart}
          onStop={handleVideoStop}
          onEnded={handleVideoEnded}
          onSeek={handleVideoSeek}
          config={{ file: {
            attributes: {
              controlsList: 'nodownload'  //<- this is the important bit
            }
          }}}
        />

      </div>

    </WrapperStyled>

  )
}

export default Reactions;
