import React, { useContext, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { breakpoints } from '../../_helpers/breakpoints';
import { IcoCheck,
         IcoChevronLeft,
         IcoPDFFile,
         COLORS } from '../../_helpers/constants';

import { ImageRound,
         ButtonYellow,
         AudioPlayer } from '../../_elements';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import TrainingData from '../Trainings/TrainingData';


const Wrapper = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    //padding-top: 15px;
    padding-bottom: 15px;
    //border-bottom: 3px solid #FFFFFF;
    //cursor: pointer;
    .loader{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

`
const HeaderTraining = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  color: ${COLORS.primary};
  .cateogry_title{
    display: flex;
    margin-left: 10px;
    p{
      color: ${props => Object.keys(props.color).length ? props.color.back.text.normal : ''}!important;
      margin: 0;
      font-size: 26px;
      line-height: 26px;
      font-weight: 400;
    }
  }
  .button_back{
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    p{
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 16px;
      margin-left: 7px;
      color: ${props => Object.keys(props.color).length ? props.color.back.text.normal : ''}!important;
    }
    .icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 2px solid ${props => Object.keys(props.color).length ? props.color.back.ico.normal : ''};
      //border: 2px solid color: ${COLORS.primary};
      width: 34px;
      height: 34px;
      border-radius: 17px;

      svg{
        height: 15px;
        path{
          fill: ${props => Object.keys(props.color).length ? props.color.back.ico.normal : ''}!important;//#5B5E5F!important;
          //fill: #FFFFFF!important;
        }
      }
    }

    :hover{
      .icon{
        border: 2px solid ${props => Object.keys(props.color).length ? props.color.back.ico.hover : ''};
        svg{
          path{
            fill: ${props => Object.keys(props.color).length ? props.color.back.ico.hover : ''}!important;
          }
        }
      }
      p{
        color: ${props => Object.keys(props.color).length ? props.color.back.text.hover : ''};
      }
    }

  }
`
const TrainingPodcast = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .image_podcast{
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    img{
      float: left;
      width: 100%;
    }
  }
  .audio_player{
    width: 100%;
    position: absolute;
    bottom: 0;
  }
`
const TrainingVideo = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 25px;

    .react-player{
      display: flex;
      width: 100%;
      min-height: 473px;
    }
`
const TrainingPdf = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 25px;

  .image_training{
    display: flex;
    width: 100%;
    min-height: 473px;
    img{
      width: 100%;
    }
  }
`
const NoMedia = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 25px;

  .image_training{
    display: flex;
    width: 100%;
    min-height: 473px;
    img{
      width: 100%;
    }
  }
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #FFD200!important;
  }
`

const ContentContainer = (props) => {

  const { data,
          setContentSelected,
          shellBoxCourses,
          setShellBoxCourses,
          scope } = props;

  const { setContentViewed,
          getEvaluationCourse,
          getInternalCourses,
          setVideoViewed } = useContext(ContentContext);

  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const [ materials, setMaterials ] = useState(null);
  const [ quizId, setQuizId ] = useState(null);
  const [ colors, setColors ] = useState({});
  const [ isContentPending, setIsContentPending ] = useState(false);
  const [ contentType, setContentType ] = useState("");
  const [ trainingData, setTrainingData ] = useState(null);
  const [ moduloID, setModuloID ] = useState(null);
  const [ contentID, setContentID ] = useState(null);
  const [ loader, setLoader ] = useState(false);

  const trainingEl = useRef(null);

  const handleVideoStart = () => {
    /*
    setOnVideoEnded(false);
    handleOnEndedCalled.current = false;
    setOnVideoProgressStatus(true);
    */
  }

  const handleVideoStop = () => {
    /*
    setOnVideoEnded(true);
    handleOnEndedCalled.current = true;
    setOnVideoProgressStatus(false);
    */
  }

  const handleVideoProgress = ((progress) => {

    //console.log(!onVideoEnded && progress.played >= 0.95);
    //console.log(onVideoEnded +":"+ progress.played);
    if(!onVideoEnded && progress.played >= 0.95){
      setOnVideoEnded(true);
    };

  });

  useEffect(() => {
    if(onVideoEnded){
      handleVideoEnded();
    }
    return () => {}
  }, [onVideoEnded])

  useEffect(() => {

    setLoader(true);

    if(data){

      const training_data = getInternalCourses(data._id);
      training_data.then(res => {

        setLoader(false);

        if(res.materials.length){
          setMaterials(res.materials);
        }

        setModuloID(res.modulos[0]._id);
        setContentID(res.modulos[0].contenidos[0]._id);
        setTrainingData(res.modulos[0].contenidos[0]);
        setContentType(res.modulos[0].contenidos[0].tipo_contenido);
      })

      // SI EXISTE UNA EVALUACION Y AUN NO ESTA COMPLETA SE GUARDA EL QUIZ ID PARA ENVIARSELO AL COMPONENTE TRAININGDATA
      if(!data.evaluation_finish){
        setQuizId("613b6e3704d0484171d1840a");
        /*
        const evaluation = getEvaluationCourse("613b6e3703d0484171d18404");
        evaluation.then(result => {

          //evuationData
          //setQuestions(result.questions);
          //setQuestionSelected(result.questions[0]);
          //setQuestionsetIndexQuestionSelected(0);

          console.log("***** * * * * * * * * * * * * * * * * *");
          console.log(result);
          console.log("***** * * * * * * * * * * * * * * * * *");

        })
        */
      }

    }
    return () => {}
  }, [data])

  const handleViewedPodcastContent = () => {
    handleVideoEnded();
  }

  const getCourseData = (trainingIndex) => {

    const response = getInternalCourses(trainingIndex);
      response.then(res => {
        //setCourse(res);
      })
  }

  const handleVideoEnded = () => {

    const payload = {
      "id_module": moduloID,//moduloIDVideoSelected,
      "id_content": contentID,//contentIDVideoSelected,
    }

    const response = setVideoViewed(payload);
    response.then(res => {

      const update = shellBoxCourses.map((el, i) => {
        if(el._id == data._id){
          el.viewed = true;
        }
        return el;
      })
      setShellBoxCourses(update);
      getCourseData(data._id);
      /*
      if(!videoSelected.already_seen){
        const courses = courseVideos.map((item, index) => {
          if(item._id === videoSelected._id){
            item.already_seen = true;
          }
          return item;
        })
        setCourseVideos(courses);

      }
      showQuiz(course.modulos[0].contenidos);
      */

    })



    /*
    const payload = { "id_content_viewed": data._id }
    const response = setContentViewed(payload);
    response.then(res => {

      const update = shellBoxContents.map((el, i) => {
        if(el._id == data._id){
          el.viewed = true;
        }
        return el;
      })
      setShellBoxContents(update);
    })
    */

  }

  const onClickTrigger = () => {
    handleVideoEnded();
  }

  useEffect(() => {
    switch (scope) {
      case "shellbox":
        setColors({title: '#000000', module: '#333333', description: '#000000', back: {ico: {normal: "#000000", hover: "#FFD200"}, text: {normal: "#000000", hover: "#FFD200"}}});
        break;
      default:
        setColors({title: '#000000', module: '#BEBEBE', description: '#000000', back: {ico: {normal: "#5B5E5F", hover: "#FFD200"}, text: {normal: "#000000", hover: "#FFD200"}}});
        break;
    }
    return () => {}
  }, [])

  return (
    <Wrapper>


      <HeaderTraining color={colors}>
        <div className="button_back" onClick={() => setContentSelected(null)}>
          <div className="icon">
            <IcoChevronLeft />
          </div>
          {/*<p>Volver</p>*/}
        </div>
        <div className="cateogry_title">
          <p>Volver</p>
        </div>
      </HeaderTraining>

      {loader ?
      <div className="loader">
        <CircularProgressStyled />
      </div>

      :
      <>
      {contentType === 'image' &&
        <NoMedia>
          <div className="image_training">
            <img src={trainingData.media} />
          </div>
        </NoMedia>
      }

      {contentType === 'video' &&
        <TrainingVideo>
          <ReactPlayer
            className='react-player'
            url={trainingData.media}
            width='100%'
            height='100%'
            controls={true}
            onProgress={(progress) => { handleVideoProgress(progress)}}
            onPlay={handleVideoStart}
            onStart={handleVideoStart}
            onStop={handleVideoStop}
            config={{ file: {
              attributes: {
                controlsList: 'nodownload'
              }
            }}}
          />
        </TrainingVideo>
      }

      {contentType === 'archivos' &&
        <TrainingPdf>
          <div className="image_training">
            <img src={data.portada} />
          </div>
        </TrainingPdf>
      }

      {contentType === 'podcast' &&
        <TrainingPodcast>
          <div className="image_podcast">
            <img src={data.portada} />
          </div>
          <div className="audio_player">
            <AudioPlayer
              elRef={trainingEl}
              handleViewedContent={handleViewedPodcastContent}
              url={data.media}
            />
          </div>
        </TrainingPodcast>
      }

      <TrainingData
        title={data.title}
        description={data.description_long}
        icon={data.icon}
        courseVideos={null}
        materials={materials}
        quizId={quizId}
        colors={colors}
        onClickTrigger={onClickTrigger}
      />
      </>
      }

    </Wrapper>

  )
}

export default ContentContainer;
