import React, { useContext } from 'react';
import styled from 'styled-components';
import Image from 'react-bootstrap/Image';
import { CommentContext } from '../../../_context';
import { Spinner } from '../../../_helpers/constants';

const StyledComments = styled.div`
  //background-color: pink;
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  flex-direction: column-reverse;

  .row-container-comment{
    display: flex;
    flex-direction: row;
  }
  .comments-load-more{
    display: flex;
    width: 100%;
    text-align: center;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    background-color: #ffd110;
    padding-top: 10px;
    padding-bottom: 10px;
    :hover{
      background-color: #E6BC0E;
    }
  }
  .no_comments{
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #000000;
  }
`;
const Comment = styled.div`
  //background-color: skyblue;
  color: #000000;
  margin-bottom: 15px;
  border-bottom: 1px dashed #ECECEC;
  padding-bottom: 15px;
  .comment-username{
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
  .comment-text{
    font-size: 14px;
  }
  .timestamp{
    font-size: 12px;
  }
  .content-wrap{
    width: calc(100% - 60px);
    margin-left: 10px;
  }
`;
const Loader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #000000;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 10px;
  font-weight: 600;
  p{
    margin: 0;
    padding: 0;
  }
  &.new-comment-loading,
  &.comments-loading{
    display: flex;
    justify-content: flex-start;
    margin-top: 0;
    background-color: #F7F7F2;
    p{
      margin: 0;
      padding: 0;
      color: #BEBEB6;
    }
    svg{
      margin: unset!important;
      width: 25px;
      height: 25px;
      margin-right: 5px!important;
      rect{
        fill: #BEBEB6;
      }

    }
  }
`;


const CommentsList = (props) => {

  const { comments, getComments, loading, refetching } = useContext(CommentContext);

  const { post } = props;

  return (
    <StyledComments className="CommentsSeccion comments-list-wrapper">

      {comments.list.length > 0 ?
        comments.list.map((comment, index) => (
          comment.user &&
          <Comment className="row-container-comment" key={'comment-'+index}>
            <Image src={comment?.user?.media ? comment.user.media : `${process.env.PUBLIC_URL}/images/noavatar.jpg` } width="50" height="50" roundedCircle className="profile-image" />
            <div className="content-wrap">
              <p className="comment-username">{`${comment?.user?.name} ${comment?.user?.last_name}`}</p>
              <div className="comment-text">{comment?.text}</div>
              <div className="timestamp">{comment?.formattedDate}</div>
            </div>
          </Comment>
      ))
      :
      <></>
      }

      {loading ?
        <Loader className="new-comment-loading">
          <Spinner fill="#EAB676" />
          <p>Cargando...</p>
        </Loader>
        :
        !comments.list.length ? <div className="no_comments">No hay comentarios aún, se el primero en comentar.</div> : ''
      }

    </StyledComments>
  );
};

export default CommentsList;
