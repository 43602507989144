import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import Video from './Video';
import Image from './Image';

const Wrapper = styled.div`

    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: black;

    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`

const PostMediaContent = (props) => {

  const { post_type, post, onDrawer, sideControls } = props;
  //console.log(post_type, post, onDrawer, sideControls);
  const SetContent = ({post_type, post, onDrawer, sideControls}) => {
    switch (post_type) {
      case 'image':
        return (
          <Image post={post} onDrawer={onDrawer} sideControls={sideControls} />
        );
        break;
      case 'video':
        return (
          <Video post={post} onDrawer={onDrawer} sideControls={sideControls} />
        );
        break;
      default:
        return null;
        break;
    }

  }

  return (
      <SetContent post_type={post_type} post={post} onDrawer={onDrawer} sideControls={sideControls} />
  )

}

export default PostMediaContent;
