// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
/*
const firebaseConfig = {
  apiKey: "AIzaSyB1NMmQgzvKEyNXzuSakgcK6jd5xD7B0Tc",
  authDomain: "staging-somos-protagonistas.firebaseapp.com",
  databaseURL: "https://staging-somos-protagonistas.firebaseio.com",
  projectId: "staging-somos-protagonistas",
  storageBucket: "staging-somos-protagonistas.appspot.com",
  messagingSenderId: "422794217496",
  appId: "1:422794217496:web:3ecc6325a4033cbf451b6d"
};
*/
const firebaseConfig = {
  apiKey: "AIzaSyDjLMCxRaq665eF15wuJM3Rublhy17JrDE",
  authDomain: "somos-protagonistas-2d8c7.firebaseapp.com",
  projectId: "somos-protagonistas-2d8c7",
  storageBucket: "somos-protagonistas-2d8c7.appspot.com",
  messagingSenderId: "563549390963",
  appId: "1:563549390963:web:86bd314c3ed281d6950206",
  measurementId: "G-40KPNGQ78K"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
