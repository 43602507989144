import { path } from 'ramda';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { TRAINING_STATUSES, ROLES, GENDERS } from '~/constants';

const trainingStatus = t => path(['user_data', 'training_status'], t);

const getTotalFinished = trainings => trainings.reduce((a, t) => (trainingStatus(t) === TRAINING_STATUSES.FINISHED ? a + 1 : a), 0);

export const slug = str => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

export const throwIfProd = (message = 'Not allowed in production') => {
  if (process.env.NODE_ENV === 'production') throw new Error(message);
};

export const getTotalCertifications = (trainings = [], isRedCO) => {
  const trainingsFiltered = trainings.filter(training => {
    if (isRedCO) {
      if (training.category.id !== 9) {
        return training;
      }
    } else {
      return training;
    }
  });

  return trainingsFiltered.length ? `${getTotalFinished(trainingsFiltered)}/${trainingsFiltered.length}` : '';
};

export const getTrainingFiltered = (trainings = [], isRedCO) => {
  const trainingsFiltered = trainings.filter(training => {
    if (isRedCO) {
      if (training.category.id !== 9) {
        return training;
      }
    } else {
      return training;
    }
  });

  return trainingsFiltered;
};

export const capitalize = (str = '') => (typeof str !== 'string' ? '' : str.charAt(0).toUpperCase() + str.slice(1));

const formatNameComponent = (nameComponent = '') =>
  Array.prototype.map.call(nameComponent, (char, index) => (index === 0 ? char.toUpperCase() : char.toLowerCase())).join('');

const lastCharIsSpace = (str = '') => str.lastIndexOf(' ') === str.length - 1;

export const formatName = (name = '') => {
  const formattedName = name
    .split(' ')
    .map(formatNameComponent)
    .join(' ');

  return lastCharIsSpace(formattedName) ? formattedName.substring(0, formattedName.length - 1) : formattedName;
};

export const isEnter = event => {
  const { which, charCode, keyCode, key } = event;

  const code = which || charCode || keyCode || 0; // compatibility

  return code === 13 || key === 'Enter';
};

export const waitMs = async (ms = 1000) => new Promise(resolve => setTimeout(resolve, ms));

const getLoggedUserRoles = authContext => path(['protagonista', 'data', 'roles'], authContext) || [];

const hasRole = (userRoles, roleToCheck) => userRoles.findIndex(r => r.description === roleToCheck) !== -1;

export const loggedUserShell = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.USERSHELL.DESCRIPTION);
};

export const loggedUserIsCaptain = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.CAPTAIN.DESCRIPTION);
};

export const loggedUserIsTerritory = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.TERRITORY.DESCRIPTION);
};

export const loggedUserIsDistrict = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.DISTRICT.DESCRIPTION);
};

export const loggedUserIsManager = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.MANAGER.DESCRIPTION);
};

export const loggedUserIsExpert = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.EXPERT.DESCRIPTION);
};

export const loggedUserIsChief = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.CHIEF.DESCRIPTION);
};

export const loggedUserIsManagerOrTerritoryManager = authContext => {
  const userRoles = getLoggedUserRoles(authContext);

  return hasRole(userRoles, ROLES.MANAGER.DESCRIPTION) || hasRole(userRoles, ROLES.TERRITORY.DESCRIPTION);
};

export const loggedUserStationIsRedCO = authContext => {
  const isCoNetwork = path(['protagonista', 'data', 'stations', 0, 'red_co'], authContext);
  return isCoNetwork;
};

export const loggedUserStationID = authContext => {
  const stationId = path(['protagonista', 'data', 'stations', 0, 'id'], authContext);
  return stationId;
};

export const loggedUserStations = authContext => {
  const stations = path(['protagonista', 'data', 'stations'], authContext);
  return stations;
};

// If the user is not from CO_NETWORK is DO (gas station owner)
export const loggedUserIsRedDO = authContext => {
  const isCoNetwork = path(['protagonista', 'data', 'stations', 0, 'red_co'], authContext);

  return !isCoNetwork;
};

export const loggedUserIsGasStationOwner = authContext => {
  return loggedUserIsManagerOrTerritoryManager(authContext) && loggedUserIsRedDO(authContext);
};

export const getWelcomeMessage = (authContext, isCaptain) => {
  const base = `¡Hola ${formatName(path(['protagonista', 'data', 'first_name'], authContext))}!`;

  const isWoman = path(['protagonista', 'data', 'profile', 'gender', 'id'], authContext) === GENDERS.WOMAN.ID;

  if (isCaptain) {
    return `${base} ¡Bienvenid${isWoman ? 'a' : 'o'} capit${isWoman ? 'ana' : 'án'}!`;
  }

  return base;
};

export const isTrainingFinished = training => path(['user_data', 'training_status'], training) === 6;

export const logQuizAnswersOnDevelopment = questions => {
  if (process.env.NODE_ENV !== 'development') return;
  if (!path(['length'], questions)) return;

  let answers = ``;

  questions.forEach(question => {
    question.answers.forEach((answer, index) => {
      if (answer.correct) answers += `${index} `;
    });
  });

  console.log(`Quiz answers (only development): ${answers}`);
};

const removeAccents = (string = '') => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const replaceSpacesForDashes = (string = '') => string.replace(/ /g, '-');

export const downloadFileFromUrl = (url, fileName = 'descarga') => {
  saveAs(url, replaceSpacesForDashes(removeAccents(fileName)), { autoBom: true });
};

/**
 * Every axios instance in this app has a common authorization header
 * the problem is that it's not always needed and sometimes it causes CORS errors
 * so getAuthHeader, setAuthHeader and deleteAuthHeader helpers are intended to persist the header in localStorage
 * and set it again when needed.
 */
const getAuthHeader = () => {
  return localStorage ? localStorage.getItem('authHeader') : window.authHeader;
};

const setAuthHeader = authHeader => {
  if (localStorage) localStorage.setItem('authHeader', authHeader);
  else window.authHeader = authHeader;
};

const deleteAuthHeader = () => {
  if (localStorage) localStorage.removeItem('authHeader');
  else delete window.authHeader;
};

export const persistAuthHeaderAndDeleteFromAxios = () => {
  setAuthHeader(axios.defaults.headers.common.Authorization);

  delete axios.defaults.headers.common.Authorization;
};

export const moveAuthHeaderToAxios = () => {
  const authHeader = getAuthHeader();

  if (authHeader) {
    axios.defaults.headers.common.Authorization = authHeader;
    deleteAuthHeader();
  }
};

export const isNullish = value => value === undefined || value === null;
