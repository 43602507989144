import React, { useContext, useEffect, useState, useRef } from 'react';
import { path } from 'ramda';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { HiArrowRight } from "react-icons/hi";
import { FiDownload } from "react-icons/fi";
import { ContentContext } from '../../_context';
import { breakpoints } from '../../_helpers/breakpoints';

import { getModulesStatusFromCourse,
         STATUS_COURSE_BLOCKED,
         STATUS_COURSE_UNLOCKED,
         STATUS_COURSE_VIEWED,
         STATUS_COURSE_COMPLETED,
         STATUS_COURSE_REPROVED,
         STATUS_COURSE_PENDING_CONNECTION,
         STATUS_COURSE_IN_PROGRESS } from '~/_helpers/commons';

import { Badge } from '../../_elements';


const WrapperStyled = styled.div`
  display: flex;
  width: 100%;
  .title_tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    >p{
      font-size: 15px;
      line-height: 15px;
      margin: 0;
      padding: 0;
      padding-top: 1px;
    }
  }
  .MuiTab-root{
    color: #212529!important;
    .Mui-selected{
      color: #212529!important;
      font-weight: 600;
    }
  }
  .MuiTabs-indicator{
    background-color: #FFC600!important;
  }
  .tabs{
    width: 100%;
    //background-color: pink;
    .tab{
      width: 50%;
      max-width: 50%!important;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .panel_tab{
    padding: 1vw 2vw;
    .elements{
      display: flex;
      flex-direction: column;
      width: 100%;
      .no_content{
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        padding-top: 30px;
        width: 100%;
        text-align: center;
      }
      .element{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /* align-items: center; */
        width: 100%;
        box-sizing: border-box;
        padding: 16px 0;
        cursor: pointer;

        border-bottom: 1px solid #DADADA;

        .icon{
          font-size: 20px;
        }
        :hover{
          background-color: #FAFAFD;
          //background-color: rgba(255, 198, 0, 0.1);
        }
        .texts{
          display: flex;
          flex-direction: column;
          margin-right: 15px;
          width: calc(100% - 185px);
          .title{
            font-size: 3.5vw;
            line-height: 4vw;
            font-weight: 600;
            margin-top: 2px;
          }
          .description{
            font-size: 2.8vw;
            line-height: 3.4vw;
            margin-top: 1vw;
          }
          &.block{
            opacity: .4;
          }
        }
        .image{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          margin-right: 15px;
          overflow: hidden;
          background-color: #F6F6F6;
          img{
            float: left;
            width: 100%;
          }
          &.block{
            opacity: .4;
          }
        }
        .icon_arrow{
          display: flex;
          /* width: 25px; */
          width: 105px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          margin-left: auto;
          font-size: 20px;
          color: #212529;

          .label_status{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            border-radius: 4px;
            color: #FFFFFF;
            font-size: 13px;
            line-height: 13px;
            font-weight: 800;

            .retry,
            .pending,
            .viewed,
            .approved,
            .blocked{
              padding: 5px 7px;
              box-sizing: border-box;
              border-radius: 6px;
              margin-right: 0;
              margin-bottom: 2vw;
            }
            .retry{
              border: 2px solid #C5000D;
              background-color: #C5000D;
            }
            .pending{
              border: 2px solid #616161;
              background-color: #616161;
            }
            .viewed{
              border: 2px solid #FFC600;
              background-color: #FFC600;
            }
            .approved{
              border: 2px solid #006F45;
              background-color: #006F45;
            }
            .blocked{
              color: #C5000D;
              border: 2px solid #C5000D;
              background-color: transparent;
              margin-right: 0;
            }
          }

        }
        &.block{
          cursor: default;
          :hover{
            background-color: #FFFFFF;
            //background-color: rgba(255, 198, 0, 0.1);
          }
        }
      }
    }
  }
  @media ${breakpoints.xs} {
    //width: 544px;
    .panel_tab{
      padding: 8px 16px;
      .elements{
        .no_content{
          font-size: 16px;
          line-height: 16px;
          padding-top: 30px;
          width: 100%;
        }
        .element{
          width: 100%;
          padding: 16px 20px;
          .texts{
            margin-right: 15px;
            width: calc(100% - 215px);
            .title{
              font-size: 16px;
              line-height: 19px;
              margin-top: 2px;
            }
            .description{
              font-size: 14px;
              line-height: 17px;
              margin-top: 3px;
            }
          }
          .image{
            width: 50px;
            height: 50px;
            margin-right: 15px;
          }
          .icon_arrow{
            display: flex;
            width: 150px;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin-left: auto;
            .label_status{
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 13px;
              line-height: 13px;

              .retry,
              .pending,
              .viewed,
              .approved,
              .blocked{
                padding: 5px 7px;
                border-radius: 6px;
                margin-right: 15px;
                margin-bottom: 0;
              }
            }

          }
        }
      }
    }
  }
  @media ${breakpoints.sm} {
    //width: 448px;
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }
`
function CustomTabPanel(props) {
  const { children,
          value,
          index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Content = (props) => {

  const { save_content_viewed,
          take_user_evaluation,
          get_course_by_id,
          setEvaluation } = useContext(ContentContext);

  const { handleRefreshData,
          content,
          history,
          location,
          match } = props;

  const [ modules, setModules ] = useState(null);

  const [ value, setValue ] = useState(0);
  const [ archives, setArchives ] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(content){
      const _modules = [];
      // setModules(content.moduls);
      if(content.moduls.length){
        const arr_media_archive = [];
        const arr_media_multimedia = [];
        content.moduls.map((module, i) => {
          if(module.media_slides.length){
            if(content._id !== "655384c2c1266700089b5700"){

              const media_archive = module.media_slides.filter((media => media.content_type.toLowerCase() == "archives" || media.content_type.toLowerCase() == "pdf"));
              if(media_archive.length){
                arr_media_archive.push({cover_image: module.cover_image,
                                        description_short: module.description_short,
                                        module_title: module.title,
                                        content_type: media_archive[0].content_type,
                                        media: media_archive[0].media,
                                        media_name: media_archive[0].media_name,
                                        module: module})
              }
              const media_multimedia = module.media_slides.filter((media => media.content_type.toLowerCase() == "image" || media.content_type.toLowerCase() == "video" || media.content_type.toLowerCase() == "podcast"));
              if(media_multimedia.length){
                arr_media_multimedia.push(module);
              }

            }else{

              arr_media_multimedia.push(module);

            }



          }else{
            /*
            if(module.content_type === "Image" || module.content_type === "Video" || module.content_type === "Podcast"){

            }
            if(module.content_type === "Archives" || module.content_type === "Video" || module.content_type === "Podcast"){

              arr_media_archive.push({cover_image: module.cover_image,
                                      description_short: module.description_short,
                                      module_title: module.title,
                                      content_type: module.content_type,
                                      media: module.media,
                                      media_name: module.media_name,
                                      module: module})

            }
            */
          }
        })
        //const archivesArr = content.moduls.filter((module => module.content_type == "Archives"));
        setArchives(arr_media_archive);
        setModules(arr_media_multimedia);
      }
    }

    return () => { }
  }, [content])


  const handleOpenFile = (url, module) => {
    //const payload = { id_content: module._id }
    //let modules_arr = [];

    window.open(url, "_blank", "noreferrer");
    
    if(content.status === STATUS_COURSE_COMPLETED || content.status === STATUS_COURSE_VIEWED){
      return null;
    }

    const status_data = getModulesStatusFromCourse(content, module._id);
    const payload = { course_id: content._id, moduls: JSON.stringify(status_data.modules_arr), status: status_data.course_status }
    const response = save_content_viewed(payload);
    response.then(res => {
      handleRefreshData(payload);
      /*
      get_course_by_id(content._id);
      const r = take_user_evaluation("", content._id);
      r.then((res) => {
        setEvaluation(path(['data', 'single'], res));
      })
      */
    })
    
  };

  const handleClickModule = (_id) => {
    history.push(`${location.pathname}/module/${_id}`);
  }

  return (
    <WrapperStyled
      block={module.block}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs'>
            <Tab label={<div className='title_tab'><p>Contenidos</p> {(modules && modules.length) ? <Badge value={modules.length} /> : ""}</div>} className='tab' />
            {content?._id !== "655384c2c1266700089b5700" ?
              <Tab label={<div className='title_tab'><p>Descargables</p> {(archives && archives.length) ? <Badge value={archives.length} /> : ""}</div>} className='tab' />
            : ""}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} className='panel_tab'>
        {content?._id !== "655384c2c1266700089b5700" ?
          <div className='elements'>
            {modules == null ?
              <>carga</>
            :
            modules.length ?
              modules.map((module, index) => {
                console.log("module.status", module.status)
                return(
                  <div className={`element ${module.block ? 'block' : ''}`} onClick={() => { module.status !== 0 && handleClickModule(module._id) }} >
                    <div className={`image ${module.block ? 'block' : ''}`}>
                      <img src={ module.cover_image } />
                    </div>
                    <div className={`texts ${module.block ? 'block' : ''}`}>
                      <div className='title'>{ module.title }</div>
                      <div className='description'>{ module.description_short }</div>
                    </div>

                    <div className='icon_arrow'>

                      <div className='label_status'>
                        { module.status === 0 ? <div className='blocked'>BLOQUEADO</div> : "" }
                        { module.status === -1 ? <div className='retry'>REINTENTAR</div> : "" }
                        { module.status === 1 ? <div className='pending'>PENDIENTE</div> : "" }
                        { module.status === 2 ? <div className='viewed'>VISTO</div> : "" }
                        { module.status === 3 ? <div className='approved'>COMPLETO</div> : "" }
                      </div>

                      {!module.block ?
                      <HiArrowRight />
                      :""}
                    </div>
                  </div>
                )
              })
            :
              <div className='no_content'>No hay contenido para mostrar</div>
            }
          </div>
        :
        <div className='elements'>
          {modules == null ?
            <>carga</>
          :
          modules.length ?
            modules.map((module, index) => {
              return(
                <div
                  className={`element ${module.block ? 'block' : ''}`}
                  onClick={() => {
                    if(module.content_type.toLowerCase() === 'pdf' || module.content_type.toLowerCase() === 'archives'){
                      handleOpenFile(module.media, module)
                    }else{
                      !module.block && handleClickModule(module._id)
                    }
                  }}
                >
                  <div className={`image ${module.block ? 'block' : ''}`}>
                    <img src={ module.cover_image } />
                  </div>
                  <div className={`texts ${module.block ? 'block' : ''}`}>
                    <div className='title'>{ module.title }</div>
                    <div className='description'>{ module.description_short }</div>
                  </div>

                  <div className='icon_arrow'>

                    <div className='label_status'>
                      { module.status === 0 ? <div className='blocked'>BLOQUEADO</div> : "" }
                      { module.status === -1 ? <div className='retry'>REINTENTAR</div> : "" }
                      { module.status === 1 ? <div className='pending'>PENDIENTE</div> : "" }
                      { module.status === 2 ? <div className='viewed'>VISTO</div> : "" }
                      { module.status === 3 ? <div className='approved'>COMPLETO</div> : "" }
                    </div>

                    {!module.block ?
                      (module.content_type.toLowerCase() === 'pdf' || module.content_type.toLowerCase() === 'archives') ?
                        <FiDownload />
                      :
                        <HiArrowRight />

                    :""}
                  </div>
                </div>
              )
            })
          :
            <div className='no_content'>No hay contenido para mostrar</div>
          }
        </div>
        }

        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} className='panel_tab'>

          <div className='elements'>
            {archives == null ?
              <>carga</>
            :
            archives.length ?
            archives.map((archive, index) => {
                return(
                  <div className='element' onClick={() => handleOpenFile(archive.media, archive.module)}>
                    <div className='image'>
                      <img src={ archive.cover_image } />
                    </div>
                    <div className='texts'>
                      <div className='title'>{ archive.module_title }</div>
                      <div className='description'>{ archive.description_short }</div>
                    </div>
                    <div className='icon_arrow'>

                      <div className='label_status'>
                        { archive.module.status === 0 ? <div className='blocked'>BLOQUEADO</div> : "" }
                        { archive.module.status === -1 ? <div className='retry'>REINTENTAR</div> : "" }
                        { archive.module.status === 1 ? <div className='pending'>PENDIENTE</div> : "" }
                        { archive.module.status === 2 ? <div className='viewed'>VISTO</div> : "" }
                        { archive.module.status === 3 ? <div className='approved'>COMPLETO</div> : "" }
                      </div>
                      <FiDownload />

                    </div>
                  </div>
                )
              })
            :
              <div className='no_content'>No hay contenido para mostrar</div>
            }
          </div>

        </CustomTabPanel>
      </Box>
    </WrapperStyled>
  );
}

export default Content;
