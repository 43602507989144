import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { path } from 'ramda';
import { IcoChevronLeft,
         IcoChevronRight } from '../../_helpers/constants';

const Wrapper = styled.div`
  position: relative;
  width: ${props => props.onDrawer ? '95vw!important' : "100%!important"};
  &.w-controls {
    //padding-bottom: 1.8rem;
    //background: linear-gradient(to bottom, transparent, #757575);
  }
  /*
  &.w-copy-status {
    background: linear-gradient(to bottom, transparent, #666666);
  }
  */
  .arrow_prev{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 25px;
    svg{
      padding-right: 4px;
      display: flex;
      height: 28px;
    }
    :hover{
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .arrow_next{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 25px;
    svg{
      padding-left: 4px;
      display: flex;
      height: 28px;
    }
    :hover{
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .carousel-inner {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 50%;
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
    opacity: 1;
    width: ${props => props.sideControls ? '10%' : "15%"}
  }

  .carousel-indicators {
    bottom: -2.8rem;

    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #EC1C24;
      margin: 0 0.4rem;
    }
  }

  .image{
    width: 100%;
  }

  @media ${breakpoints.xs} {
    width: ${props => props.onDrawer ? '530px!important' : "100%!important"};
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {
  }
  @media ${breakpoints.lg} {
  }
`;

const CarouselCounter = styled.div`
  max-height: 24px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.8rem;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 0.5rem;
  z-index: 999;
  line-height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Imagen = ({ post, onDrawer, sideControls }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const media = path(['media'], post);
  const showControls = media && media.length > 1;
  const carouselProps = {
    controls: showControls,
    indicators: showControls,
    interval: null,
    nextIcon: (sideControls ? <span className="arrow_next"><IcoChevronRight /></span> : null),
    prevIcon: (sideControls ? <span className="arrow_prev"><IcoChevronLeft /></span> : null),
    //prevIcon: (sideControls ? <span aria-hidden="true" className="carousel-control-prev-icon" /> : null),
    onSlid: eventKey => setActiveIndex(eventKey),
  };

  return (
    <Wrapper className={`PostImagen ${showControls ? 'w-controls' : ''}`} onDrawer={onDrawer} sideControls={sideControls} breakpoints={breakpoints}>
      {showControls ? (
        <CarouselCounter>
          {activeIndex + 1} / {media.length}
        </CarouselCounter>
      ) : null}
      <Carousel {...carouselProps}>
        {media.map((item, i) => {
          return (
            <Carousel.Item key={i}>
              <Card.Img variant="top" src={item.url} className="image" />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default Imagen;

