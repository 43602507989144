import React from "react";
import CatalogoContainer from "./CatalogoContainer";

const Catalogo = (props) => {
  return (
      <CatalogoContainer location={props.location} history={props.history}/>
  );
};

export default Catalogo;
