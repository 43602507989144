import { number } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';
import Countdown from 'react-countdown';

const StyledWrapper = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: center;

  .counter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 5px solid #FFD200;
    border-radius: 50%;
    margin-bottom: 25px;
    p{
      margin: 0;
      font-size: 25px;
      font-weight: 600;
      color: #FFFFFF;
    }
  }

  @media ${breakpoints.xs} {
  }
  @media ${breakpoints.sm} {
  }
  @media ${breakpoints.md} {
  }
  @media ${breakpoints.lg} {
  }
`

const GameTimer = (props) => {

  const { timerSeconds,
          setTimeEnded,
          startClock,
          stopClock,
          setStopClock,
          endClock,
          setEndClock } = props;

  const [ timeLeft, setTimeLeft ] = useState(180);
  const [ intervalId, setIntervalId ] = useState(null);

  const handleStartGame = () => {

    const id = setInterval(() => {

      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    setIntervalId(id);

  }

  useEffect(() => {
    if(timeLeft == 0){
      clearInterval(intervalId)
      setEndClock(true);
    }

    return () => {}
  }, [timeLeft]);

  useEffect(() => {
    if(stopClock){
      setTimeLeft(timeLeft);
      clearInterval(intervalId);
    }
    return () => {}
  }, [stopClock]);


  useEffect(() => {
    if(startClock){
      handleStartGame();
    }
    return () => {}
  }, [startClock])


  return (
    <StyledWrapper>
      <div className="counter">
        <p>{timeLeft}</p>
      </div>
    </StyledWrapper>
  )
}

export default GameTimer;
