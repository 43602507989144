import React from 'react';
import styled from 'styled-components';
import AudioSpectrum from "react-audio-spectrum";

const Wrapper = styled.div`
    p{
        margin: 0;
        color: ${props => props.css_styles?.font?.color ? props.css_styles.font.color : "#313233" };
        font-size: ${props => props.css_styles?.font?.size ? props.css_styles.font.size : "16px" };
        font-weight: ${props => props.css_styles?.font?.weight ? props.css_styles.font.weight : "500" };
        letter-spacing: -0.38px;
    }
`
const AudioSpectrumStyled = styled(AudioSpectrum)`
    display: none;
    width: 100%!important;
    height: 100px!important;

`

const AudioSpectrumComponent = (props) => {

  const { height, width } = props;

  return (
    <AudioSpectrumStyled
      className="spectrum"
      id="audio-canvas"
      height={height}
      width={width}
      audioId={'audio-element'}
      capColor={'#FFD200'}
      capHeight={2}
      meterWidth={2}
      meterCount={512}
      meterColor={[
        {stop: 0, color: '#FFFFFF'},
        {stop: 0.5, color: '#FFFFFF'},
        {stop: 1, color: '#FFD200'}
      ]}
      gap={4}
    />
  )

}

export default AudioSpectrumComponent;
