import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC600;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  margin-left: 5px;
  p{
    font-size: 11px;
    line-height: 11px;
    color: #000000;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-top: 1px;
  }
`

const Badge = (props) => {

  const { value } = props;

  return (
    <Wrapper>
      <p>{value}</p>
    </Wrapper>
  )

}

export default Badge;
