import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { ResponsivePie } from '@nivo/pie';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    //background-color: pink;

    .box_trivia_count_text{
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
    }
    .box_trivia_pie{
      width: 285px;
      height: 285px;
      svg{
        g{
          text{
            fill: rgb(255,255,255)!important;
            font-weight: 700;
          }
        }
      }
    }
    .box_trivia_pie_label{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 15px;
      .box_win{
        display: flex;
        flex-direction: row;
        align-items: center;
        .bullet{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: rgba(255, 210, 0, 1);
        }
        .label{
          font-size: 15px;
          font-weight: 500;
          margin-left: 4px;
        }
      }
      .box_lose{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 15px;
        .bullet{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: rgba(216, 1, 15, 1);
        }
        .label{
          font-size: 15px;
          font-weight: 500;
          margin-left: 4px;
        }
      }
    }


    /*
    .box_trivia_count{
      width: 285px;
      height: 285px;
      .box_trivia_count_text{
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 16px;
        //font-family: 'Roboto';
      }

    }
    */


    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`

const TriviaStats = (props) => {

  const trivia_total_count = 31;
  /*
  const trivia_total_win = 11;
  const trivia_total_lose = 20;
  const trivia_total_win_percent = Math.round((trivia_total_win * 100) / trivia_total_count);
  const trivia_total_lose_percent = Math.round((trivia_total_lose * 100) / trivia_total_count);
  */
  const data = [
    {
      "id": "ganados",
      "label": "Ganadas",
      "value": 65,
      "color": "hsl(275, 70%, 50%)"
    },
    {
      "id": "perdidos",
      "label": "Perdidos",
      "value": 35,
      "color": "hsl(11, 70%, 50%)"
    }
  ];

  const MyResponsivePie = ({ data /* see data tab */ }) => (
    <ResponsivePie
        data={data}
        enableArcLinkLabels={false}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}

        //borderColor="#f60909"
        //arcLinkLabelsTextOffset={2}
        //arcLinkLabelsTextColor="#FFFFFF"
        //arcLinkLabelsOffset={-24}
        //arcLinkLabelsDiagonalLength={30}
        //arcLinkLabelsStraightLength={10}
        //arcLinkLabelsThickness={2}
        //arcLinkLabelsColor={{ from: 'color' }}
        //arcLabelsSkipAngle={10}
        //arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        theme={{
          "fontColor": "rgb(255, 255, 255)",
          "fontSize": 20,
          "fontFamily": "Roboto",
      }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'rgba(255, 210, 0, 1)',
                color: 'rgba(255, 255, 255, 0.2)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'rgba(216, 1, 15, 1)',
                color: 'rgba(0, 0, 0, 0.1)',
                rotation: -45,
                lineWidth: 3,
                spacing: 5
            }
        ]}
        fill={[
            {
                match: {
                    id: 'ganados'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'perdidos'
                },
                id: 'lines'
            }
        ]}
        /*
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 20,
                translateY: 40,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 10,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
        */
    />
)

  return (
    <Wrapper breakpoints={breakpoints}>

      <div className="box_trivia_count_text">
        Partidas Jugadas: {trivia_total_count}
      </div>
      <div className="box_trivia_pie">
        <MyResponsivePie data={data} />
      </div>
      <div className="box_trivia_pie_label">
        <div className="box_win">
          <div className="bullet"></div>
          <div className="label">Ganados</div>
        </div>
        <div className="box_lose">
          <div className="bullet"></div>
          <div className="label">Perdidos</div>
        </div>
      </div>

    </Wrapper>
  )

}

export default TriviaStats;
