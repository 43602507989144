import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { breakpoints } from '../../_helpers/breakpoints';

const Wrapper = styled.ul`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;

    .description{
      font-size: 13px;
      font-weight: 600;
    }


`


const QuizQuestionCounter = (props) => {

  const { count,
          total,
          className } = props;

  return (
    <Wrapper className={className} breakpoints={breakpoints}>
      <div className="description">
        {`Pregunta ${count}/${total}`}
      </div>
    </Wrapper>
  )

}

export default QuizQuestionCounter;
