import React from "react";
import EvaluationContainer from "./EvaluationContainer";

const Evaluation = (props) => {
  return (
      <EvaluationContainer
        {...props}
        //location={props.location}
        //history={props.history}
      />
  );
};

export default Evaluation;
