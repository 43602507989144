import React from "react";
import TriviaContainer from "./TriviaContainer";

const Trivia = (props) => {
  return (
      <TriviaContainer location={props.location} history={props.history}/>
  );
};

export default Trivia;
