import React, { useState, useContext, useEffect, Fragment, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import { ButtonHeader, ImageRound, ButtonActionRounded, ButtonYellow} from '../../_elements';
import { RiSendPlane2Line } from "react-icons/ri";
import Reactions from './Reactions';
import Media from './Media';


import { checkBadWords } from '../../_helpers/commons';

import { Navigation, Pagination } from 'swiper';

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  .bad_words_error{
    color: red;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .box_add_comment{
    margin-top: 0px;
    margin-bottom: 25px;

    .title{
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
    .input_comment{
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      width: 100%;
    }
    .avatar{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      overflow: hidden;
      border: 1px solid #DADADA;
      margin-right: 10px;
      img{
        float: left;
        width: 100%;
      }
    }
    .input{
      width: calc(100% - 100px);
      input{
        width: 100%;
        border-radius: 4px;
        border: 1px solid #DADADA;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 7px 7px;
      }
    }
    .bt_send{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      width: 40px;
      height: 40px;
      margin-left: 10px;
      cursor: pointer;
      :hover{
        color: #FFC600;
      }
    }
    .loader_comment{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      width: 40px;
      height: 40px;
      margin-left: 5px;
      svg{
        color: #FFC600;
      }
    }
  }

  .box_comments{
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin-top: 30px;
    .comment_counter{
      font-size: 16px;
      font-weight: 600;
    }
    .comments_list{
      list-style: none;
      margin: 10px 0 0 0;
      padding: 0;
      .comment{
        display: flex;
        flex-direction: row;
        margin: 0 0 10px 0;
        padding: 10px;
        border-radius: 6px;
        box-sizing: border-box;
        background-color: #FFFFFF;
        .left{
          width: 40px;
          margin-right: 10px;
          .avatar{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            overflow: hidden;
            img{
              float: left;
              width: 100%;
            }
          }
        }
        .right{
          display: flex;
          flex-direction: column;
          width: calc(100% - 50px);
          .author{
            display: flex;
            flex-direction: row;
            font-size: 14px;
            line-height: 28px;
            .name{
              font-weight: 600;
              margin-right: 5px;
            }
            .date{
              font-weight: 400;
            }
          }
          .text{
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const CommenResponsetInput = (props) => {

  const { /*data,
          posts,
          setPosts,*/
          post,
          comment,
          className,
          isLoading,
          setShowResponse,
          setCommentResponses,
          showCommentResponses,
          setShowCommentResponses,
          setCommentReliesCounter,
          handleGetCommentsCounter } = props;

  const { add_comment_wall,
          userData } = useContext(ContentContext);

  const [ showReactions, setShowReactions ] = useState(false);
  const [ isFormCommentValid, setIsFormCommentValid ] = useState(true);
  const [ isLoadingComments, setIsLoadingComment ] = useState(false);
  const [ commentStatus, setCommentStatus ] = useState(null);
  const [ inputText, setInputText ] = useState("");

  const handleInputText = (text) => {
    let puteadas = checkBadWords(text);
    if (puteadas.replacements) {
      setCommentStatus('Por favor cuida tus palabras.');
      setIsFormCommentValid(false);
      //return false;
    }else{
      setCommentStatus(null);
      setIsFormCommentValid(true)
    }

    setInputText(text);
  }

  const handleSendMessage = (messange) => {
    
    if(!isFormCommentValid){
      return null;
    }

    setIsLoadingComment(true);

    const payload = { post_id: post._id, parent_comment_id: comment._id, message: inputText }
    console.log("payload", payload);
    //return null;
    const response = add_comment_wall(payload);
    response.then(res => {

      handleGetCommentsCounter(post._id);

      setInputText("");
    
      setCommentResponses(res.data.single.users_comments);
      setCommentReliesCounter(res.data.single.users_comments.length);
      setShowResponse(false);
      
      setIsLoadingComment(false);
      

    })
      
  }

  return (

    <WrapperStyled
      className={className}
      isLoading={isLoading}
      //onClick={() => { !isLoading && onClick()}}
    >

      <div className='box_add_comment'>
        <div className='title'>
          Agrega tu respuesta:
        </div>
        <div className='input_comment'>
          <div className='avatar'>
            {userData ?
            <ImageRound className="image_round" css_styles={{width: 40, height: 40}} file={userData?.media}>
              {userData && userData.avatar ?
                <img src={userData.media} />
              :
                <img src="/images/noavatar.jpg" />
              }
            </ImageRound> : ""}
          </div>
          <div className='input'>
            <input type="text"
              onChange={(e) => handleInputText(e.currentTarget.value)}
              value={inputText}
              placeholder='Escribir respuesta ...'
            />
          </div>
          {!isLoadingComments ?
          <div className='bt_send' onClick={handleSendMessage}>
            <RiSendPlane2Line />
          </div>
          :
          <div className='loader_comment'>
            <CircularProgress size={20} />
          </div>
          }
        </div>
      </div>
      {commentStatus ?
      <div className='bad_words_error'>{commentStatus}</div>
      : ""}
    </WrapperStyled>

  )
}

export default CommenResponsetInput;
