import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { breakpoints } from '../../../_helpers/breakpoints';

const Wrapper = styled.ul`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 10px 0;
    padding: 0;


`
const AnswerOption = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  cursor: pointer;
  width: calc(50% - 5px);
  overflow: hidden;

  .image{
    width: 100%;
    img{
      width: 100%;
    }
  }

  :hover{
    border: 1px solid #FFD200;
    background-color: #FFD200;
    .image{
      opacity: 0.8;
    }
  }

  &.selected{
    border: 1px solid #FFD200;
    background-color: #FFD200;
    .image{
      opacity: 0.8;
    }
  }


  /*
  :last-child {
    margin-bottom: 0;
  }

  .bullet{
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #ccc;
  }
  .description{
    display: flex;
    margin-left: 7px;
    font-size: 16px;
    font-weight: 600;
  }
  :hover{
    background-color: #FFD200;
    .bullet{
      border-color: #FFFFFF;
    }
  }
  &.selected{
    background-color: #FFD200;
    .bullet{
      border-color: #FFFFFF;
      background-color: #FFFFFF;
    }
  }
  */
`

const MultipleChoice = (props) => {

  const { answers, setAnswerSelected, answerSelected } = props;
  //const [ selectedOption, setSelectedOption ] = useState(null);

  const handleClick = useCallback((id) => {
    //setSelectedOption(id);
    setAnswerSelected(id);
    /*


    */
  }, []);

  return (
    <Wrapper breakpoints={breakpoints}>

      {answers ?
        answers.map((item, index) => {
          return(
            <AnswerOption key={index} className={answerSelected == item.id ? 'selected' : ''} onClick={(e) => handleClick(item.id)}>
              <div className="image">
                <img src={item.url} />
              </div>
            </AnswerOption>
          )
        })
        :
        <div>loading</div>
      }


    </Wrapper>
  )

}

export default MultipleChoice;
