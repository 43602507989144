import React, { useContext, useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { ButtonYellow } from '../../_elements';
import axios from 'axios';
import { AuthContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useLocation } from 'react-router-dom';
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { usePageVisibility } from "../../_hooks/usePageVisibility";
import Countdown, { zeroPad } from 'react-countdown';

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import Video from './VideoVimeo';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    min-height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .box_content{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95vw;
      margin-top: ${props => props.isAuth ? "0" : "4vw"};
    }
    .event_container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-image: url("/images/back_videolanding.jpg");
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 110vw;
      border-radius: 10px;
      overflow: hidden;
      .logo{
        width: 46vw;
        margin-bottom: 6vw;
        //margin-left: 20px;
        margin-top: 6vw;
        img{
          float: left;
          width: 100%;
        }
      }
      .bt_open_video{
        width: 40vw;
        margin-top: 4vw;
        :disabled{
          color: #000000!important;
          opacity: 0.5;
        }
      }
      .counter_new{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85vw;
        margin-top: 2vw;
        .countdown{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .timer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 18vw;
            .minutes,
            .seconds{
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .value{
              font-weight: 700;
              font-size: 7vw;
              line-height: 7vw;

            }
            .label{
              font-weight: 700;
              font-size: 2.3vw;
              color: #FFD200;
            }
          }
        }
      }
      .event_ended,
      .event_ongoing,
      .event_notstarted{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //text-align: center;
        color: #FFFFFF;
        width: 100%;
        p.title{
          //margin: 70px 0 0 0;
          margin: 0;
          font-size: 4.1vw!important;
          line-height: 4.6vw;
          font-weight: 700;
          color: #FFD200;
          text-align: center;
        }
        p.description{
          margin: 0;
          font-size: 3.4vw;
          line-height: 4.3vw;
          font-weight: 500;

        }
        p.subtitle{
          width: 80vw;
          font-size: 4vw;
          line-height: 5vw;
          font-weight: 700;
          color: #FFFFFF;
          margin: 2vw 0 1vw 0;
          text-align: left;
        }
        .bullets{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 80vw;
          .bullet{
            margin-top: 1vw;
            margin-bottom: 1vw;
            font-size: 3.4vw;
            line-height: 4.3vw;
            font-weight: 500;
          }

        }
        .highlight{
            font-weight: 800;
            color: #FFD200;
          }
        .counter{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.5);
          width: 160px;
          height: 160px;
          border-radius: 90px;
          border: 7px solid red;

          box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);
          -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);
          -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);

          p{
            font-size: 80px;
            line-height: 80px;
            font-weight: 600;
            text-shadow: 0px 0px 16px rgba(0,0,0,1);
            margin: 0;
            padding: 0;
          }

        }
      }
      .event_ended{
        margin-top: 10px;
        p.title{
          margin-top: 4vw;
        }
        p.description{
          margin-top: 15px;
          text-align: center;
          width: 80vw;
        }
      }
      .event_notstarted{
        p.title{
          font-size: 4.3vw;
          line-height: 5.3vw;
        }
        p.description{
          width: 120vw;
          font-size: 3.4vw;
          line-height: 4.3vw;
          margin-top: 10px;
        }
        .countdown_modal_title{
          width: 85vw;
          color: #FFD200;
          font-size: 4vw;
          line-height: 5vw;
          font-weight: 800;
          text-align: center;
        }
        .countdown_modal_text{
          color: #FFFFFF;
          font-size: 3.4vw;
          margin-top: 3vw;
          font-weight: 600;
        }
        .countdown_modal_close{
          color: #FFD200;
          font-size: 3.6vw;
          font-weight: 800;
          text-align: center;
          margin-top: 3vw;
        }
      }
      .event_ongoing{
        .bt_open_video{
          margin-top: 14vw;
        }
      }
    }

    .bt_play{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      width: 100px;
      height: 50px;
      margin-top: 10px;
      border: 1px solid #000000;
      p{
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        color: #FFFFFF;
        margin: 0;
      }
      cursor: pointer;
      :hover{
        background-color: #FFFFFF;
        border: 1px solid #000000;
        p{
          color: #000000;
        }
      }
    }

    @media ${breakpoints.xs} {
      .box_content{
        width: 100%;
        margin-top: ${props => props.isAuth ? "0" : "20px"};
      }
      .event_container{
        width: 546px;
        height: 480px;
        .logo{
          width: 230px;
          margin-bottom: 30px;
          margin-top: 40px;
        }
        .bt_open_video{
          width: 200px;
          margin-top: 20px;
        }
        .counter_new{
          width: 430px;
          margin-top: 10px;
          .countdown{
            .timer{
              width: 90px;
              .value{
                font-size: 35px;
                line-height: 35px;
              }
              .label{
                font-size: 11px;
              }
            }
          }
        }
        .event_ended,
        .event_ongoing,
        .event_notstarted{
          p.title{
            font-size: 22px!important;
            line-height: 28px;
          }
          p.description{
            font-size: 16px;
            line-height: 24px;
          }
          p.subtitle{
            width: 500px;
            font-size: 18px;
            line-height: 24px;
            margin: 10px 0 5px 0;
          }
          .bullets{
            width: 500px;
            .bullet{
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .counter{
            width: 160px;
            height: 160px;
            p{
              font-size: 80px;
              line-height: 80px;
              margin: 0;
              padding: 0;
            }
          }
        }
        .event_ended{
          p.title{
            margin-top: 20px;
          }
          p.description{
            margin-top: 15px;
            width: 500px;
          }
        }
        .event_notstarted{
          p.title{
            font-size: 22px;
            line-height: 28px;
          }
          p.description{
            width: 600px;
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
          }
          .countdown_modal_title{
            width: 430px;
            color: #FFD200;
            font-size: 20px;
            line-height: 24px;
            font-weight: 800;
            text-align: center;
          }
          .countdown_modal_text{
            color: #FFFFFF;
            font-size: 16px;
            margin-top: 15px;
            font-weight: 600;
          }
          .countdown_modal_close{
            color: #FFD200;
            font-size: 18px;
            font-weight: 800;
            text-align: center;
            margin-top: 15px;
          }
        }
        .event_ongoing{
          .bt_open_video{
            margin-top: 70px;
          }
        }
      }
    }
    @media ${breakpoints.sm} {
      .box_content{}
      .event_container{
        width: 448px;
        height: 500px;
        .logo{
          width: 230px;
          margin-bottom: 30px;
          margin-top: 40px;
        }
        .bt_open_video{
          width: 200px;
          margin-top: 20px;
        }
        .counter_new{
          width: 380px;
          margin-top: 10px;
          .countdown{
            .timer{
              width: 90px;
              .value{
                font-size: 35px;
                line-height: 35px;
              }
              .label{
                font-size: 11px;
              }
            }
          }
        }
        .event_ended,
        .event_ongoing,
        .event_notstarted{
          p.title{
            width: 410px;
            font-size: 18px!important;
            line-height: 26px;
          }
          p.description{
            font-size: 16px;
            line-height: 24px;
          }
          p.subtitle{
            width: 410px;
            font-size: 18px;
            line-height: 24px;
            margin: 10px 0 5px 0;
          }
          .bullets{
            width: 410px;
            .bullet{
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 14px;
              line-height: 20px;
            }
          }
          .counter{
            width: 160px;
            height: 160px;
            p{
              font-size: 80px;
              line-height: 80px;
              margin: 0;
              padding: 0;
            }
          }
        }
        .event_ended{
          p.title{
            margin-top: 20px;
          }
          p.description{
            margin-top: 15px;
            width: 400px;
          }
        }
        .event_notstarted{
          p.title{
            font-size: 22px;
            line-height: 28px;
          }
          p.description{
            width: 600px;
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
          }
          .countdown_modal_title{
            width: 380px;
            color: #FFD200;
            font-size: 20px;
            line-height: 24px;
            font-weight: 800;
            text-align: center;
          }
          .countdown_modal_text{
            color: #FFFFFF;
            font-size: 16px;
            margin-top: 15px;
            font-weight: 600;
          }
          .countdown_modal_close{
            color: #FFD200;
            font-size: 18px;
            font-weight: 800;
            text-align: center;
            margin-top: 15px;
          }
        }
        .event_ongoing{
          .bt_open_video{
            margin-top: 70px;
          }
        }
      }
    }
    @media ${breakpoints.md} {
      .box_content{
        width: ${props => props.isAuth ? "100%" : "900px"};
      }
      .event_container{
        width: 672px;
        height: 500px;
        .logo{
          width: 230px;
          margin-bottom: 30px;
          margin-top: 40px;
        }
        .bt_open_video{
          width: 200px;
          margin-top: 20px;
        }
        .counter_new{
          width: 600px;
          margin-top: 10px;
          .countdown{
            .timer{
              width: 90px;
              .value{
                font-size: 35px;
                line-height: 35px;
              }
              .label{
                font-size: 11px;
              }
            }
          }
        }
        .event_ended,
        .event_ongoing,
        .event_notstarted{
          p.title{
            font-size: 22px;
            line-height: 28px;
          }
          p.description{
            font-size: 16px;
            line-height: 24px;
          }
          p.subtitle{
            width: 500px;
            font-size: 18px;
            line-height: 24px;
            margin: 10px 0 5px 0;
          }
          .bullets{
            width: 500px;
            .bullet{
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .counter{
            width: 160px;
            height: 160px;
            p{
              font-size: 80px;
              line-height: 80px;
              margin: 0;
              padding: 0;
            }
          }
        }
        .event_ended{
          p.title{
            margin-top: 20px;
          }
          p.description{
            margin-top: 15px;
            width: 500px;
          }
        }
        .event_notstarted{
          p.title{
            font-size: 22px;
            line-height: 28px;
          }
          p.description{
            width: 600px;
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
          }
          .countdown_modal_title{
            width: 530px;
            color: #FFD200;
            font-size: 20px;
            line-height: 24px;
            font-weight: 800;
            text-align: center;
          }
          .countdown_modal_text{
            color: #FFFFFF;
            font-size: 16px;
            margin-top: 15px;
            font-weight: 600;
          }
          .countdown_modal_close{
            color: #FFD200;
            font-size: 18px;
            font-weight: 800;
            text-align: center;
            margin-top: 15px;
          }
        }
        .event_ongoing{
          .bt_open_video{
            margin-top: 70px;
          }
        }
      }
    }
    @media ${breakpoints.lg} {
      .box_content{
        width: ${props => props.isAuth ? "100%" : "1100px"};
      }
      .event_container{
        width: 840px;
        height: 490px;
        .logo{
          width: 300px;
          margin-bottom: 30px;
          margin-top: 40px;
        }
        .bt_open_video{
          width: 200px;
          margin-top: 20px;
        }
        .counter_new{
          margin-top: 10px;
          .countdown{
            .timer{
              width: 90px;
              .value{
                font-size: 35px;
                line-height: 35px;
              }
              .label{
                font-size: 11px;
              }
            }
          }
        }
        .event_ended,
        .event_ongoing,
        .event_notstarted{
          p.title{
            font-size: 22px;
            line-height: 28px;
          }
          p.description{
            font-size: 16px;
            line-height: 24px;
          }
          p.subtitle{
            width: 500px;
            font-size: 18px;
            line-height: 24px;
            margin: 10px 0 5px 0;
          }
          .bullets{
            width: 500px;
            .bullet{
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .counter{
            width: 160px;
            height: 160px;
            p{
              font-size: 80px;
              line-height: 80px;
              margin: 0;
              padding: 0;
            }
          }
        }
        .event_ended{
          p.title{
            margin-top: 20px;
          }
          p.description{
            margin-top: 15px;
            width: 500px;
          }
        }
        .event_notstarted{
          p.title{
            font-size: 22px;
            line-height: 28px;
          }
          p.description{
            width: 600px;
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
        .event_ongoing{
          .bt_open_video{
            margin-top: 70px;
          }
        }
      }
    }

`
const ModalVideoStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999!important;

  background-image: url("/images/back_videolanding.jpg");
  background-size: cover;
  background-position: center center;

  .inner_modal_validate_phone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: ${props => props.isFullscreen ? "100%" : "95vw"};
    height: ${props => props.isFullscreen ? "100%" : "53vw"};
    padding: 0;
    overflow: hidden;
    /*background-color: #FFFFFF;*/
    outline: 0!important;
    border-radius: ${props => props.isFullscreen ? "0" : "16px"};



    box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);

  }
  @media ${breakpoints.xs} {
    .inner_modal_validate_phone{
      width: ${props => props.isFullscreen ? "100%" : "495px"};
      height: ${props => props.isFullscreen ? "100%" : "275px"};
    }
  }
  @media ${breakpoints.sm} {
    .inner_modal_validate_phone{
      width: ${props => props.isFullscreen ? "100%" : "695px"};
      height: ${props => props.isFullscreen ? "100%" : "385px"};
    }
  }
  @media ${breakpoints.md} {
    .inner_modal_validate_phone{
      width: ${props => props.isFullscreen ? "100%" : "895px"};
      height: ${props => props.isFullscreen ? "100%" : "500px"};
    }
  }
  @media ${breakpoints.lg} {
    .inner_modal_validate_phone{
      width: ${props => props.isFullscreen ? "100%" : "995px"};
      height: ${props => props.isFullscreen ? "100%" : "560px"};
    }
  }
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`

const VideoLandingContainer = () => {

    const { isAuth } = useContext(AuthContext);

    const isVisible = usePageVisibility()

    const history = useHistory();
    const { search, pathname } = useLocation();
    const [ uid, setUid ] = useState(null);
    const [ oid, setOid ] = useState(null);
    const [ data, setData ] = useState(null);
    const [ secondsPass, setSecondsPass ] = useState(0);
    const [ transmisionEnded, setTransmissionEnded ] = useState(null);
    const [ transmisionStatus, setTransmisionStatus ] = useState(null);
    const [ playing, setPlaying ] = useState(false);
    const [ seekTo, setSeekTo ] = useState(null);
    const [ timeLeft, setTimeLeft ] = useState(null);
    const [ timeToStart, setTimeToStart ] = useState();
    const [ timePassed, setTimePassed ] = useState(0);
    const [ intervalId, setIntervalId ] = useState(null);
    const [ intervalTimePassedId, setIntervalTimePassedId ] = useState(null);
    const [ intervalStartId, setIntervalStartId ] = useState(null);
    const [ intervalPassedId, setIntervalPassedId ] = useState(null);
    const [ videoIsActive, setVideoIsActive ] = useState(false);
    const [ isFullscreen, setIsFullscreen ] = useState(null);
    const [ releaseVideo, setReleaseVideo ] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const startOnMinute = 1 * 60;
    const videoTimeDuration = 522;

    useEffect(() => {
        if(pathname){
            if(pathname == '/video-landing'){
                if(search.length > 0) {
                    const query = new URLSearchParams(search);

                    if(query.get("uid") && query.get("uid").length > 0){
                        setUid(query.get("uid"));
                    }else{
                      //setUid("9999999999999");
                    }
                }else{}
            }
        }
        return () => {}
    }, [pathname])

    const getData = async () => {

      //const requestURL = `http://localhost/protagonistas/videolanding.php`;
      const requestURL = `https://fabianhr.com/protagonistas/videolanding.php`;

      try {
        const params = new URLSearchParams()
              params.append('type', "get_time");
              params.append('uid', uid);

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        const result = await axios.post(requestURL, params, config);
        //console.log("UID", result.data);

        return result;

      } catch (error) {

      }
    }

    const setDataAction = async (action, release) => {

      //const requestURL = `http://localhost/protagonistas/videolanding.php`;
      const requestURL = `https://fabianhr.com/protagonistas/videolanding.php`;

      try {
        const params = new URLSearchParams()
              params.append('type', "set_time");
              params.append('action', action);
              params.append('scope', release ? "repeat" : "stream");
              params.append('uid', uid);

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        const result = await axios.post(requestURL, params, config);
        //console.log("UID", result.data);

        return result;

      } catch (error) {

      }
    }

    useEffect(() => {
      /*
      //console.log("isVisible ", isVisible)
      if(isVisible){
        if(uid){
          startLanding();
        }
      }else{
        clearInterval(intervalId);
        clearInterval(intervalTimePassedId);

        setIntervalTimePassedId(null);
        setIntervalId(null);

        setOpen(false);
        setPlaying(false);
        setTransmisionStatus(null);
        setTimePassed(0);
        setData(null);
      }
      */
      return () => {}
    }, [isVisible, uid])

    useEffect(() => {


      return () => {
        clearInterval(intervalId);
        clearInterval(intervalTimePassedId);
      }
    }, [])

    const startLanding = () => {
      /*
      const response = getData();
        response.then(res => {

          const minutosSplitted = res.data.minutos.split(":");
          const seconds = (parseInt(minutosSplitted[0]) * 60) + parseInt(minutosSplitted[1]);

          if(res.data.video_activo){
            console.log("parseInt(res.data.segundos_pasados) < videoTimeDuration", parseInt(res.data.segundos_pasados) +"<"+ videoTimeDuration);
            if(parseInt(res.data.segundos_pasados) < videoTimeDuration){
              //console.log("--> entra")
              setTransmisionStatus("on_going");
              setTransmissionEnded(false);
              setSecondsPass(parseInt(res.data.segundos_pasados));
              setSeekTo((parseInt(res.data.segundos_pasados) * 100 / videoTimeDuration) / 100);
              setVideoIsActive(true);

              if(!intervalTimePassedId){
                const _id = setInterval(() => {
                  setTimePassed(prevTime => prevTime + 1);
                }, 1000);

                setIntervalTimePassedId(_id);
              }


            }else{

              setTransmisionStatus("ended");
              setTransmissionEnded(true);

            }

          }else{

            setTimeLeft(parseInt(res.data.segundos));
            const id = setInterval(() => {
              setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            setIntervalId(id);

            setTransmisionStatus("not_started");
            console.log("aun no empieza");

          }

          setData(res.data);
        })
        */
    }

    const handleStartVideo = () => {

      //console.log("--> ("+timePassed +"+"+ secondsPass +")* 100 / "+ videoTimeDuration + " / 100" + " ---> " + (((timePassed + secondsPass) * 100 / videoTimeDuration) / 100))
      return ((timePassed + secondsPass) * 100 / videoTimeDuration) / 100;

      /*
      if(videoIsActive){
        console.log("--> ("+timePassed +"+"+ secondsPass +")* 100 / "+ videoTimeDuration + " / 100" + " ---> " + (((timePassed + secondsPass) * 100 / videoTimeDuration) / 100))
        return ((timePassed + secondsPass) * 100 / videoTimeDuration) / 100;
      }else{
        console.log("--> 0")
        return 0;
      }
      */
    }

    const handleGetTimeVideo = () => {

      return timePassed + secondsPass;

    }


    useEffect(() => {
      //console.log("timeLeft", timeLeft);
      if(timeLeft != null && timeLeft <= 0){
        clearInterval(intervalId);
        setTransmisionStatus("on_going");
        setTransmissionEnded(false);
        setSeekTo(0);

        if(!intervalTimePassedId){
          const _id = setInterval(() => {
            setTimePassed(prevTime => prevTime + 1);
          }, 1000);

          setIntervalTimePassedId(_id);
        }

        //setPlaying(true);
        //console.log("play")
      }
      return () => {}
    }, [timeLeft])




    useEffect(() => {
      //console.log("timeToStart", timeToStart);
      if(timeToStart <= 0){
        clearInterval(intervalStartId);
      }
      return () => {}
    }, [timeToStart])



    useEffect(() => {
      //console.log("timePassed", timePassed);
      const close = handleStartVideo();
      if(close >= 1){
        clearInterval(intervalTimePassedId);
        setTransmisionStatus("ended");
      }
      return () => {}
    }, [timePassed])

    const handleOpenVideo = () => {

      handleStartVideo();
      setOpen(true);
      setPlaying(true);
      setDataAction("start", true);

    }

    useEffect(() => {

      //setData(null);

      return () => {}
    }, [])

    // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {

    return (
      <>
      {zeroPad(days) == 0 && zeroPad(hours) == 0 && zeroPad(minutes) <= 4 ?
        <>
          {/*<p className="title">¡EL EVENTO ESTÁ PRÓXIMO A EMPEZAR!</p>*/}
          <div className="countdown_modal_title">¡EL EVENTO ESTÁ PRÓXIMO A EMPEZAR!</div>

          <div className='counter_new'>
            <div className="countdown">
              <div className='timer'>
                <div className='minutes'>
                  <div className='value'>{zeroPad(minutes)}</div>
                  <div className='label'>{minutes == 1 ? "MIN" : "MINS"}</div>
                </div>
                <div className='dots'>:</div>
                <div className='seconds'>
                  <div className='value'>{zeroPad(seconds)}</div>
                  <div className='label'>{seconds == 1 ? "SEG" : "SEGS"}</div>
                </div>
              </div>
            </div>
          </div>


          <div className="countdown_modal_text">
          - La información que estás a punto de visualizar es confidencial.<br/>El lanzamiento oficial a nuestros clientes es el <span className='highlight'>12 de junio</span><br/>
          - Revisá tu conexión de internet y salida de audio.<br/>
          - Si registraste algún problema, podrás volver a ingresar o  acceder a la repetición a partir de las 15hs.
          </div>

          {/*<p className="subtitle">Mientras, te compartimos las siguientes indicaciones</p>
          <p className="bullets">
          <span className="bullet">- La información que estás a punto de visualizar es confidencial.<br/>El lanzamiento oficial a nuestros clientes es el <span className='highlight'>12 de junio</span></span>
          <span className="bullet">- Revisá tu conexión de internet y salida de audio.</span>
          <span className="bullet">- Si registraste algún problema, podrás volver a ingresar o  acceder a la repetición a partir de las 16hs.</span>
          </p>*/}
        </>

        :
        <>
          <div className="countdown_modal_title">¡CON EL POWER DE SIEMPRE EL EVENTO LO REPROGRAMAMOS PARA EL 1 DE JUNIO A LAS 13HS!</div>
          <div className="countdown_modal_text">
          Tomá en cuenta las siguientes recomendaciones:<br/>
          1) Ingresar unos minutos antes para poder revisar tu acceso, conexión de internet y salida de audio.<br/>
          2) Chequear tu última actualización de App si vas a acceder desde ese dispositivo.<br/>
          3) Si tuviste algún problema podrás disfrutar de la repetición a las 15hs.<br/>
          </div>
          <div className="countdown_modal_close">¡Gracias a todos, los esperamos!</div>
        </>
      }
      </>
    );

  };


    return (
        <Wrapper isAuth={isAuth}>
          <div className='box_content'>
          {/*open ?
          <ModalVideoStyled
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            isFullscreen={isFullscreen}
          >
            <Fade in={open}>

              <div className="inner_modal_validate_phone">

                <Video
                  //secondsPass={secondsPass}
                  //startOnMinute={startOnMinute}
                  //videoTimeDuration={videoTimeDuration}
                  isFullscreen={isFullscreen}
                  setIsFullscreen={setIsFullscreen}
                  setDataAction={setDataAction}
                  transmisionEnded={transmisionEnded}
                  release={true}
                  //setTransmissionEnded={setTransmissionEnded}
                  //playing={playing}
                  //setPlaying={setPlaying}
                  open={open}
                  setOpen={setOpen}
                  //seekto={seekTo}
                  setTransmisionStatus={setTransmisionStatus}
                  //handleStartVideo={handleStartVideo}
                  handleGetTimeVideo={handleGetTimeVideo}

                />

              </div>

            </Fade>

          </ModalVideoStyled>
          : ""
          */}


            <div className='event_container'>

              <div className="logo">
                <img src="/images/logo-shellvpower.png" />
              </div>

              <div className="event_ended">

              <p className="description">El lanzamiento oficial a nuestros clientes es el <span className="highlight" style={{whiteSpace:"nowrap"}}>12 de junio</span>.<br/>
              Podés ingresar a Campus, categoría <span style={{whiteSpace:"nowrap"}}>Shell V-Power</span>, para ver la presentación y poner a prueba tus conocimientos.
              </p>

              </div>

            {/*
              <div className="event_ended">

              <p className="description">El lanzamiento oficial a nuestros clientes es el <span className="highlight" style={{whiteSpace:"nowrap"}}>12 de junio</span>.<br/>
Próximamente pondrán a prueba sus conocimientos para estar listos y ofrecer con todo la <span className="highlight" style={{whiteSpace:"nowrap"}}>NUEVA SHELL V-POWER</span><br/>
</p>

                <ButtonYellow
                  className="bt_open_video"
                  onClick={handleOpenVideo}
                  disabled={false}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                >
                  <p>Ingresar</p>
                </ButtonYellow>
              </div>

              data == null ?
                <CircularProgressStyled />
                :
                <>
                {
                  transmisionStatus && transmisionStatus == "on_going" &&
                  <div className="event_ongoing">
                    <ButtonYellow
                      className="bt_open_video"
                      onClick={handleOpenVideo}
                      disabled={false}
                      css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                    >
                      <p>Play</p>
                    </ButtonYellow>
                  </div>
                }

                {
                  transmisionStatus && transmisionStatus == "not_started" &&
                  <div className="event_notstarted">

                      <div className='counter_new'>
                        <Countdown
                          zeroPadDays={2}
                          zeroPadTime={2}
                          renderer={renderer}
                          date={new Date(1685635560000)}//1685548800000
                        />
                      </div>

                  </div>
                }

                {
                  transmisionStatus && transmisionStatus == "ended" &&
                  <div className="event_ended">

                    <p className="title">¡La transmisión ha finalizado!</p>
                    <p className="description">Recordá que el lanzamiento oficial es el <span className="highlight">12 de junio</span>.<br/>
Próximamente pondrán a prueba sus conocimientos para estar listos y ofrecer con todo la <span style={{whiteSpace:"nowrap"}}>NUEVA SHELL V-POWER</span><br/>
<span className="highlight">Podés ingresar a la repetición a partir de las 15hs.</span></p>


                    <ButtonYellow
                      className="bt_open_video"
                      onClick={handleOpenVideo}
                      disabled={data.release ? false : true}
                      css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                    >
                      <p>Ingresar</p>
                    </ButtonYellow>

                  </div>
                }
                </>
              */}

            </div>




          </div>
        </Wrapper>
    )
}

export default VideoLandingContainer;
