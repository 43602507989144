import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { path } from 'ramda';
import axios from 'axios';
import connect from '../_helpers/connect';
import _connect from '../_helpers/new_connect';
import storage from 'local-storage-fallback';
import { AuthContext } from './index';

import { Auth, Storage } from 'aws-amplify'
import { auth_config } from '~/_helpers/commons'

Storage.configure(auth_config)

export const ContentContext = React.createContext({});

export const ContentProvider = ({ children }) => {
//export const CommentProvider = props => {

  const page = useRef(1);
  const [trainings, setTrainings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetching] = useState(false);

  const [ trainingCategorySelected, setTrainingCategorySelected ] = useState(null);
  const [ trainingSelected, setTrainingSelected ] = useState(null);
  const [ courseData, setCourseData ] = useState(null);
  const [ userData, setUserData ] = useState(null);
  const [ studyLevelData, setStudyLevelData ] = useState(null);
  const [ studyStatusData, setStudyStatusData ] = useState(null);
  const [ sessionsStatData, setSessionsStatData ] = useState(null);
  const [ coursesStatData, setCoursesStatData ] = useState(null);
  const [ trainingsFeatured, setTrainingsFeatured ] = useState(null);
  const [ notifications, setNotifications ] = useState(null);
  const [ campusCategorySelectedData, setCampusCategorySelectedData ] = useState(null);
  const [ catalogue, setCatalogue ] = useState(null);

  const [ dashboardStatCertificates, setDashboardStatCertificates ] = useState(null);
  const [ coursesTotal, setCoursesTotal ] = useState(0);
  const [ coursesCompleteTotal, setCoursesCompleteTotal ] = useState(0);
  const [ needYouPhone, setNeedYouPhone ] = useState(null);
  const [ visibilityMenuMobile, setVisibilityMenuMobile ] = useState("hide");
  const [ openModalContacto, setOpenModalContacto ] = useState(false);
  const [ openModalEmbajadores, setOpenModalEmbajadores ] = useState(false);
  const [ showMoreStats, setShowMoreStats ] = useState(false);

  const [ triviaGame, setTriviaGame ] = useState(null);
  const [ trivia, setTrivia ] = useState(null);
  const [ triviaRanking, setTriviaRanking ] = useState(null);
  const [ triviaProgress, setTriviaProgress ] = useState(null);
  const [ colorPallete, setColorPallete ] = useState({type: 'default', primary: '#000000', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '204, 204, 204', background: '#FFFFFF'});

  const [ shellBoxWall, setShellBoxWall ] = useState(null);
  const [ shellBoxFeatured, setShellBoxFeatured ] = useState(null);
  const [ shellBoxContents, setShellBoxContents ] = useState(null);
  const [ shellBoxCourses, setShellBoxCourses ] = useState(null);
  const [ shellBoxContentFilter, setShellBoxContentFilter ] = useState(null);
  const [ counterLandingEnd, setCounterLandingEnd] = useState(false);

  const { logout } = useContext(AuthContext);

  const [ trainingHome, setTrainingHome ] = useState(null);
  const [ course, setCourse ] = useState(null);
  const [ module, setModule] = useState(null);
  const [ evaluation, setEvaluation ] = useState(null);
  const [ evaluationActive, setEvaluationActive ] = useState(false);
  const [ evaluationData, setEvaluationData ] = useState(null);
  const [ crumbs, setCrumbs ] = useState([]);
  const [ lastCourse, setLastCourse ] = useState(null);
  const [ gotoData, setGotoData ] = useState(null);
  const [ ecommerceBrands, setEcommerceBrands ] = useState(null);
  const [ ecommerceCategory, setEcommerceCategory ] = useState(null);
  const [ categoriesBrand, setCategoriesBrand ] = useState(null);
  const [ categoriesSelected, setCategoriesSelected ] = useState([]);
  const [ products, setProducts ] = useState([]);
  const [ product, setProduct ] = useState(null);
  const [ navLoading, setNavLoading ] = useState(false);
  const [ campus, setCampus ] = useState(null);
  const [ notificationPostSelected, setNotificationPostSelected ] = useState(null);


  // INI NUEVOS ENDPOINTS

  /*
  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.example.com/items?page=${page}`);
      const data = await response.json();

      setItems(prevItems => [...prevItems, ...data]);
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  */

  const putImageStorageUrl = async (payload) => {
    if (!Object.keys(payload).length) {
      return
    }
    try {
      /*
      headers: {
          'Content-Type': 'image/jpeg',
          'Content-Encoding': 'base64'
        },
      */
      const buffer = Buffer.from(payload.blob.replace(/^data:image\/\w+;base64,/, ""),'base64');
      const imageUrl = await Storage.put(payload.filename.slice(1), buffer, { contentType: 'image/jpeg', 'Content-Encoding': 'base64' })

      console.log("imageUrl", imageUrl.key);
      //return null;
      
      const imageProfileURL = imageUrl.key;//imageUrl.split('?')[0];

      const payload_profile_image = {avatar_image: imageProfileURL};
      const imageEdited = edit_user_profile(payload_profile_image);//{data_edit: JSON.stringify(payload_profile_image)}
      //const imageEdited = editUserData(payload_profile_image);
      imageEdited.then(res => {
        //const trainingsFeaturedData = path(['data', 'single', 'featured', 'courses'], result) || [];
        const user_avatar_response = getImageStogeUrl(res.data.single.user_data.avatar);
        user_avatar_response.then(user_avatar => {
          console.log("user_avatar", user_avatar)
          const mediaUserData = {...userData};
          mediaUserData.media = user_avatar;//res.data.single.user_data.avatar;
          setUserData(mediaUserData);

        })
        
      })
      setLoading(false);
      //return result;
      

      return imageUrl
    } catch (error) {
      console.error('Error al obtener la URL de la imagen:', error)
      return null
    }
  }
  
  const getImageStogeUrl = async (fileName) => {
    if (fileName == '') {
      return null
    }
    try {
      // Obtiene la URL pública de la imagen
      const imageUrl = await Storage.get(fileName, { level: 'public' })
      return imageUrl
    } catch (error) {
      console.error('Error al obtener la URL de la imagen:', error)
      return null
    }
  }

  const getAvatarImage = async (fileName) => {
    if (fileName == '') {
      return null
    }
    const imageUrl = await Storage.get(fileName, { level: 'public' })
    return imageUrl;
  }
  
  const get_url_save_photo = async (directory) => {

    //const url_uploads_ = "/sp/contents";
    //console.log("url_uploads_", url_uploads_.slice(1));
    const timestamp = Date.now()
    let save_directory;
    save_directory = `${directory}/${timestamp}.jpg`;
    return save_directory
    // Obtiene el perfil del usuario
    /*
    Auth.currentAuthenticatedUser().then((user) => {
      console.log('user email = ' + user.attributes.email);
    });
  */
    //const get_user_profile = await Auth.currentAuthenticatedUser()//UserServices.getUser()
    //console.log("get_user_profile", get_user_profile)
    
  /*
    if (get_user_profile && get_user_profile.url_uploads) {
        // Genera la URL de guardado usando el URL de cargas del perfil del usuario url_uploads
        save_directory = `${get_user_profile.url_uploads.slice(1)}${directory}/${timestamp}.jpg`;
        return save_directory
    } 
        */
}

  const ecommerce_products = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/ecommerce_products`;

    try {

      const token = storage.getItem('protagonistas_token');
      const res = await _connect.get(`${requestURL}`, {
        headers: {
          'category_id': payload.category_id,
        },
      });
      //setEcommerceCategory(path(['data', 'single', 'notifications'], res));
      return res;


    } catch (error) {}

  }, []);

  const ecommerce_brands = useCallback(async () => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/ecommerce_brands`;

    try {

      const token = storage.getItem('protagonistas_token');
      const res = await _connect.get(`${requestURL}`, {
        headers: {},
      });
      //console.log("ecommerce_brands", res);
      setEcommerceBrands(path(['data', 'single', 'notifications'], res));
      return res;


    } catch (error) {}

  }, []);

  const ecommerce_category = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/ecommerce_category`;

    // let headers = {};
    // if(id_content){
    //   headers.id_content = id_content;
    // }
    // if(id_course){
    //   headers.id_curse = id_course;
    // }

    try {

      const token = storage.getItem('protagonistas_token');
      const res = await _connect.get(`${requestURL}`, {
        headers: {
          'category_id': payload.category_id,
          'brand_id': payload.brand_id,
        },
      });
      setEcommerceCategory(path(['data', 'single', 'notifications'], res));
      return res;


    } catch (error) {}

  }, []);

  const contact_profile = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/contact_profile`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await _connect.post(requestURL, params, config);
    return result;

  }, []);

  const notification_status_change = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/oneapp/notification_status_change`;
    
    /*
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }
        */

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    
    if("notifications_ids" in payload){
      config.headers.notifications_ids = payload.notifications_ids;
    }
    if("notification_id" in payload){
      config.headers.notification_id = payload.notification_id;
    }
    if("status" in payload){
      config.headers.status = payload.status;
    }

    const result = await _connect.post(requestURL, {}, config);
    return result;

  }, []);

  const get_notifications = async () => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_notifications`;

    try {

      const token = storage.getItem('protagonistas_token');
      const res = await _connect.get(`${requestURL}`, {
        headers: {
          'change': true,
          'page': 0,//payload.page,
          'size': 50,//payload.size,
        },
      });
      setNotifications(path(['data', 'single', 'notifications'], res));
      return res;


    } catch (error) {

      //logout();

    }

  }

  const edit_user_profile = useCallback(async (payload) => {

    let edit_data = payload;

    const requestURL = `${process.env.REACT_APP_BASE_URL}/edit_user_profile`;
    /*
    const params = new URLSearchParams()
    
    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }
    */
    const config = {
        headers: {
            'Content-Type':'application/json'
            //'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    if("avatar_image" in edit_data){
      config.headers.avatar_image = edit_data.avatar_image;
      edit_data = {};
    }

    const result = await _connect.post(requestURL, edit_data, config);
    return result;

  }, []);

  const get_user_profile = useCallback(async () => {

    const config = {
      headers: {
        //'Content-Type':'application/json',
        'device': 'web',
        'system': 'web'
      }
    }

    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_user_profile`;
    _connect.get(`${requestURL}`, config).then(res => {
      let data = path(['data', 'single', 'user_data'], res);
      let data_updated = {...data};

      if(data_updated.avatar){
        const user_avatar_response = getImageStogeUrl(data_updated.avatar);
        user_avatar_response.then(user_avatar => {
          data_updated.media = user_avatar;//data_updated.avatar ? data_updated.avatar : null;
          setUserData(data_updated);
        })
      }else{
        data_updated.media = null;
        setUserData(data_updated);
      }
      
      
      


    }).catch(err => {

      //logout();

    });

  }, []);
  
  const get_post_by_id = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_post`;

    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    if("post_id" in payload){
      config.headers.post_id = payload.post_id;
    }

    const result = await _connect.get(requestURL, config);
    return result;

  }, []);

  const get_reactions_wall_according_id = useCallback(async (posts_id) => {
    if(!posts_id){
      return null;
    }
    console.log("posts_id", posts_id)
    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_reactions_wall_according_id`;

    const config = {
      headers: {
        'Content-Type':'application/json',
        'post_ids': JSON.stringify(posts_id/*[{_id: "6668d0cc4820730008e2bed4"}, {_id: "66678b2d1de3b90008dd6304"}, {_id: "66633432f7025f0008f6fffb"}, {_id: "666332e0f7025f0008f6fff9"}]*/)
      }
    }

    //params: {post_ids: [{_id: "6668d0cc4820730008e2bed4"}, {_id: "66678b2d1de3b90008dd6304"}, {_id: "66633432f7025f0008f6fffb"}, {_id: "666332e0f7025f0008f6fff9"}]} 
    const result = await _connect.get(requestURL, config);
    return result;

  }, []);

  const get_reactions_wall = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_reactions_wall`;

    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    if("post_id" in payload){
      config.headers.post_id = payload.post_id;
    }

    const result = await _connect.get(requestURL, config);
    return result;

  }, []);

  const add_comment_wall = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/add_comment_wall`;

    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    if("post_id" in payload){
      config.headers.post_id = payload.post_id;
    }
    if("parent_comment_id" in payload){
      config.headers.parent_comment_id = payload.parent_comment_id;
    }
    /*
    if("message" in payload){
      config.headers.message = payload.message;
    }
      */

    const result = await _connect.post(requestURL, {message: payload.message}, config);//JSON.stringify({answers: answers})
    return result;

  }, []);

  /*
  const add_comment_wall = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/add_comment_wall`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await _connect.post(requestURL, params, config);
    return result;

  }, []);
  */

  const add_like_wall = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/add_like_wall`;
    
    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    if("post_id" in payload){
      config.headers.post_id = payload.post_id;
    }
    if("reaction_id" in payload){
      config.headers.reaction_id = payload.reaction_id;
    }

    /*
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    */

    const result = await _connect.post(requestURL, {}, config);

    return result;

  }, []);

  /*
  const get_post_by_id = useCallback(async (payload) => {

    try {

      const requestURL = `${process.env.REACT_APP_BASE_URL}/get_wall`;

      const result = await _connect.get(`${requestURL}`, {
          headers: {
            'os_system': "",
            'model': "",
            'brand': "",
            'app_version': "",
            'token_notifications': "",
            'page': 0,
            'size': 1,
            'filter': "",
          },
      });

      return result;

    } catch (error) {

      //logout();
      return false;

    }

  }, []);
  */

  const get_wall = useCallback(async (payload) => {

    try {

      const requestURL = `${process.env.REACT_APP_BASE_URL}/get_wall`;

      const result = await _connect.get(`${requestURL}`, {
          headers: {
            'os_system': "",
            'model': "",
            'brand': "",
            'app_version': "",
            'token_notifications': "",
            'page': payload.page,
            'size': payload.size,
            'filter': payload.filter,
          },
      });

      return result;

    } catch (error) {

      //logout();
      return false;

    }

  }, []);

  const get_commnent_responses = useCallback(async (comment_id) => {

    try {

      const requestURL = `${process.env.REACT_APP_BASE_URL}/get_child_comments`;

      const result = await _connect.get(`${requestURL}`, {
          headers: {
            'comment_id': comment_id,
            'page': 1,
            'limit': 1000
          },
      });

      return result;

    } catch (error) {

      //logout();
      return false;

    }

  }, []);

  const get_commnents_post = useCallback(async (id_post) => {

    try {

      const requestURL = `${process.env.REACT_APP_BASE_URL}/get_comment_wall`;

      const result = await _connect.get(`${requestURL}`, {
          headers: {
            'post_id': id_post,
            'page': 0,
            'limit': 1000
          },
      });

      return result;

    } catch (error) {

      //logout();
      return false;

    }

  }, []);

  const save_content_viewed = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_progress_training`;//save_content_viewed
    const params = new URLSearchParams()
    /*
    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }
    */
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            course_id: payload.course_id,
            status: payload.status,
            moduls: payload.moduls
        }
    }

    const result = await _connect.post(requestURL, params, config);
    return result;

  }, []);


  /*
  const save_assesment = useCallback(async (payload, answers) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_assessment`;

    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    
    if("evaluation_id" in payload){
      config.headers.evaluation_id = payload.evaluation_id;
    }
    if("course_id" in payload){
      config.headers.course_id = payload.course_id;
    }
    if("content_id" in payload){
      config.headers.content_id = payload.content_id;
    }

    //const params = new URLSearchParams();
    //params.append("answers", JSON.stringify(payload.answers));
    console.log("JSON.stringify(answers)", JSON.stringify({answers: answers}));
    const result = await _connect.post(requestURL, JSON.stringify({answers: answers}), config);//params//JSON.stringify({answers: payload.answers})
    return result;

  }, []);
  */

  
  const save_grade_like = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_survey`;

    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    if("survey_id" in payload){
      config.headers.survey_id = payload.survey_id;
    }
    if("course_id" in payload){
      config.headers.course_id = payload.course_id;
    }
    if("answers" in payload){
      config.headers.answers = JSON.stringify(payload.answers);
    }

    const result = await _connect.post(requestURL, {}, config);//JSON.stringify({answers: answers})
    return result;

    //const result = await _connect.post(requestURL, params, config);
    //return result;

    /*
    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_grade`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await _connect.post(requestURL, params, config);
    return result;
    */
  }, []);
  /*
  const save_assesment = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_assessment`;

    const config = {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'//// 'application/json'
      }
    }
    
    if("evaluation_id" in payload){
      config.headers.evaluation_id = payload.evaluation_id;
    }
    if("course_id" in payload){
      config.headers.course_id = payload.course_id;
    }
    if("content_id" in payload){
      config.headers.content_id = payload.content_id;
    }
    
    // if("answers" in payload){
    //   config.headers.answers = payload.answers;
    // }
    
    const params = new URLSearchParams();

    params.append("answers", payload.answers);

    //console.log("requestURL, params, config", requestURL, params, config);
    //return null;
    
    // if(Object.keys(payload).length){
    //     Object.keys(payload).map(function(key) {
    //         params.append(key, payload[key]);
    //     })
    // }
    
    console.log("answers", payload.answers);
    const result = await _connect.post(requestURL, JSON.stringify({answers: payload.answers}), config);//params//JSON.stringify({answers: payload.answers})
    return result;

  }, []);
  */
  const save_assesment = useCallback(async (payload, answers) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_assessment`;

    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    
    if("evaluation_id" in payload){
      config.headers.evaluation_id = payload.evaluation_id;
    }
    if("course_id" in payload){
      config.headers.course_id = payload.course_id;
    }
    if("content_id" in payload){
      config.headers.content_id = payload.content_id;
    }

    //const params = new URLSearchParams();
    //params.append("answers", JSON.stringify(payload.answers));
    console.log("JSON.stringify(answers)", JSON.stringify({answers: answers}));
    const result = await _connect.post(requestURL, JSON.stringify({answers: answers}), config);//params//JSON.stringify({answers: payload.answers})
    return result;

  }, []);


  const save_survey = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_survey`;

    const config = {
      headers: {
        'Content-Type':'application/json'
      }
    }
    if("survey_id" in payload){
      config.headers.survey_id = payload.survey_id;
    }
    if("course_id" in payload){
      config.headers.course_id = payload.course_id;
    }
    if("post_id" in payload){
      config.headers.post_id = payload.post_id;
    }
    if("answers" in payload){
      config.headers.answers = JSON.stringify(payload.answers);
    }

    const result = await _connect.post(requestURL, {}, config);//JSON.stringify({answers: answers})
    return result;

  }, []);

  /*
  const save_survey = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_survey_question`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await _connect.post(requestURL, params, config);
    return result;

  }, []);
  */


  const set_add_like = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/curses/add_like`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await _connect.post(requestURL, params, config);
    return result;

  }, []);

  const take_user_evaluation = useCallback(async (id_content, id_course) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/take_user_evaluation`;

    let headers = {};
    if(id_content){
      headers.id_content = id_content;
    }
    if(id_course){
      headers.id_curse = id_course;
    }

    const result = await _connect.get(`${requestURL}`, {
        headers: headers
        // headers: {
        //   'id_content': id_content,
        //   'id_curse': id_course,
        // },
    });

    return result;

  }, []);

  const get_training_home = useCallback(async () => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_training_home`;
    _connect.get(`${requestURL}`).then(res => {

      setTrainingHome(path(['data', 'single'], res));

    }).catch(err => {

      //setHaveResponseError(true);
      //setHaveResponseErrorMessage(err.message);

    });

  }, []);


  const get_home_categorie = useCallback(async (id) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_home_categorie`;
    const result = _connect.get(`${requestURL}`, {
        headers: {
            'id': id,
        },
    });

    return result;

  }, []);

  const get_course_by_id = useCallback(async (id) => {

    setNavLoading(true);
    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_course_by_id`;
    _connect.get(`${requestURL}`, {
        headers: {
            'id': id,
        },
    }).then(res => {
        setNavLoading(false);
        setCourse(path(['data', 'single', 'course'], res));
        //

    }).catch(err => {

        //setHaveResponseError(true);
        //setHaveResponseErrorMessage(err.message);

    });

  }, []);

  const get_content_by_id = useCallback(async (id) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/get_content_by_id`;
    _connect.get(`${requestURL}`, {
        headers: {
            'id': id,
        },
    }).then(res => {
        setModule(path(['data', 'single', 'content'], res));
        //

    }).catch(err => {

        //setHaveResponseError(true);
        //setHaveResponseErrorMessage(err.message);

    });

  }, []);








  // END NUEVOS ENDPOINTS



  const getTriviaRanking = async () => {
    const requestURL = `${process.env.REACT_APP_API_SERVER}/games/get_ranking`;

    const token = storage.getItem('protagonistas_token');
    const result = await connect.get(`${requestURL}`, {
      headers: {
        'token': token,
      },
    });

    setTriviaRanking(path(['data', 'single', 'ranking'], result));

    return result;

  }

  const getTriviaTest = async () => {
    try {
      const token = storage.getItem('protagonistas_token');
      const requestURL = `${process.env.REACT_APP_API_SERVER}/games/get_game`;
      const result = await connect.get(`${requestURL}`, {
        headers: {
          'token': token,
        },
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  // const getTriviaGame = async (id) => {


  //   const requestURL = `${process.env.REACT_APP_BASE_URL}/oneapp/trivia/game`;
  //   _connect.get(`${requestURL}`, {
  //       headers: {
  //         id_game: id
  //       },
  //   }).then(res => {

  //     console.log("getTriviaGame", res)
  //     setTriviaGame(path(['data', 'single', 'trivia'], res));

  //   }).catch(err => {});

  // }

  const saveTrivia = useCallback(async (payload) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/save_trivia`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await _connect.post(requestURL, params, config);
    return result;

  }, []);

  const getTriviaGame = useCallback(async (id) => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/trivia/game`;

    const result = await _connect.get(`${requestURL}`, {
        headers: {
          'id_game': id
        },
    });

    return result;

  }, []);

  const getTrivia = async () => {

    const requestURL = `${process.env.REACT_APP_BASE_URL}/trivia`;
    _connect.get(`${requestURL}`, {
        headers: {},
    }).then(res => {

      console.log("setTrivia", res)
      setTrivia(path(['data', 'single', 'trivia'], res));
      setTriviaRanking(path(['data', 'single', 'ranking'], res));

    }).catch(err => {});

  }

  const getQuestionsTrivia = async (payload) => {
    const requestURL = `${process.env.REACT_APP_API_SERVER}/games/get_questions_game`;

    const token = storage.getItem('protagonistas_token');
    const result = await connect.get(`${requestURL}`, {
      headers: {
        'token': token,
        'id_category': payload.id_category,
        'id_game': payload.id_game,
      },
    });
      return path(['data', 'single'], result);

  }
  const setAnswerTrivia = async (payload) => {

    const today = new Date();
    const mes = new Date(today).getMonth() + 1;
    const date_init = today.getFullYear() + "-" + mes + "-" + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();


    const requestURL = `${process.env.REACT_APP_API_SERVER}/games/save_game_answer`;
    const token = storage.getItem('protagonistas_token');

    const params = new URLSearchParams()
          params.append('id_category', payload.id_category);
          params.append('id_game', payload.id_game);
          params.append('id_question', payload.id_question);
          params.append('id_options_select', payload.id_options_select);
          params.append('date_init', date_init);
          params.append('token', token);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await connect.post(requestURL, params, config);
      return result;

    /*
    {{ _.base_url }}/games/save_game_answer
POST  token,id_question,id_category,id_game,id_options_select,date_init

EJEMPLO de id_options_select


  const getNotifications = async () => {

id_options_select = [{"id":"613b647c03d0484171d1834b"}]

EJEMPLO de date_init

date_init = 2021-10-04 14:42:28
    */
  }

  const getEmbajadores = async () => {


    const requestURL = `${process.env.REACT_APP_API_SERVER}/objectives/get_objetives_sp`;
    //alert('async1')
    try {
      //alert('async')
      const token = storage.getItem('protagonistas_token');
      const result = await connect.get(`${requestURL}`, {
        headers: {
          'token': token,
        },
      });
      //setNotifications([{title: "title", message:"message", status: 1},{title: "title", message:"message", status: 0}])
      //setNotifications(path(['data', 'single'], result));

      return path(['data', 'single'], result);


    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }

  }

  const getNotifications = async () => {

    const requestURL = `${process.env.REACT_APP_API_SERVER}/notifications/get_notification_history`;
    //alert('async1')
    try {
      //alert('async')
      const token = storage.getItem('protagonistas_token');
      const result = await connect.get(`${requestURL}`, {
        headers: {
          'token': token,
        },
      });
      //setNotifications([{title: "title", message:"message", status: 1},{title: "title", message:"message", status: 0}])
      setNotifications(path(['data', 'single'], result));
      return result;


    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }

  }

  const setNotificationsViewed = async () => {

    const requestURL = `${process.env.REACT_APP_API_SERVER}/notifications/notifications_mark_seen`;

    try {

      const token = storage.getItem('protagonistas_token');

      const params = new URLSearchParams()
            params.append('id_notifications', '');
            params.append('all_see', true);
            params.append('token', token);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await connect.post(requestURL, params, config);

      return result;

    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }




  }

  // USER DATA

  const getUserData = async () => {

    setLoading(true);
    const requestURL = `${process.env.REACT_APP_BASE_URL}/interceptor/users/get_profile`;
    //alert('async1')
    try {
      //alert('async')
      const token = storage.getItem('protagonistas_token');
      const result = await _connect.get(`${requestURL}`, {
        headers: {
          'token': token,
        },
      });

      //setNeedYouPhone(result.data.need_you_phone);
      setNeedYouPhone(path(['data', 'single', 'phone'], result) ? false : true)

      setStudyLevelData(path(['data', 'dataEdit', 'study', 'level'], result) || {})
      setStudyStatusData(path(['data', 'dataEdit', 'study', 'status'], result) || {})

      const resultData = path(['data', 'single'], result) || {};
      setSessionsStatData(path(['data', 'single', 'sessions'], result) || null);

      const total = resultData.curses_user.length;
      const finish = resultData.courses_finish;
      const rest = total ? total - finish : 0;

      setCoursesStatData({total: total, finish: finish, rest: rest});
      setUserData(resultData);
      setLoading(false);

      return resultData;


    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }

  }

  const editUserData = async (payload) => {

    setLoading(true);
    const requestURL = `${process.env.REACT_APP_BASE_URL}/interceptor/users/edit_profile`;

    try {

      const token = storage.getItem('protagonistas_token');

      const params = new URLSearchParams()
            params.append('data_edit', JSON.stringify({...payload}));
            params.append('token', token);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await _connect.post(requestURL, params, config);

      setLoading(false);

      return result;

    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }

  }
  // CAMPUS

  const getCampus = useCallback(async () => {

    //try {

      const requestURL = `${process.env.REACT_APP_BASE_URL}/get_training_courses_home`;

      const result = await _connect.get(`${requestURL}`, {
          headers: {},
      });

      //setCampus(path(['data', 'single'], result));

      return result;
      
    /*
    } catch (error) {

      //logout();
      return false;

    }
    */

  }, []);
  /*
  const getCampus = async () => {
    alert("getCampus");
    const requestURL = `${process.env.REACT_APP_API_SERVER}/get_training_courses_home`;
    const token = storage.getItem('protagonistas_token');
    const result = await _connect.get(`${requestURL}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    console.log("getCampus", result)
  }
  */

  const getHomeTraining = async () => {
    setLoading(true);
    const requestURL = `${process.env.REACT_APP_API_SERVER}/training/get_home_training`;

    try {
      const token = storage.getItem('protagonistas_token');
      const result = await connect.get(`${requestURL}`, {
        headers: {
          'token': token,
        },
      });
      const trainingsData = path(['data', 'single', 'levels'], result) || [];
      const trainingsFeaturedData = path(['data', 'single', 'featured', 'courses'], result) || [];
      trainingsFeaturedData.reverse();

      const trainingFiltered = [];
      if(trainingsData.length){
        trainingsData.map((training, index) => {

            if(training.courses.length){

              training.courses.reverse();

              if(training.level != "Shell V-Power" && training.level != "Tiendas" && training.level != "Lubricantes"){
                if(training.level != "Promo" && training.level != "Motorizados" && training.level != "Promo Shell Advance"){
                  trainingFiltered.push(training);
                }else{
                  trainingFiltered.unshift(training);
                }
              }
            }

        })

        trainingsData.map((training, index) => {

          if(training.courses.length){

            if(training.level == "Shell V-Power"){

              const vpower_trainings = [];
              training.courses.map((course, index) => {
                if(course._id != "647e265263dbc900086e1717"){
                  vpower_trainings.push(course);
                }
              })
              training.courses.map((course, index) => {
                if(course._id == "647e265263dbc900086e1717"){
                  vpower_trainings.unshift(course);
                }
              })
              const training_update = {...training}
              training_update.courses = vpower_trainings;
              trainingFiltered.unshift(training_update);

            }
          }

        })

        trainingsData.map((training, index) => {
          if(training.courses.length){
            if(training.level == "Tiendas"){
              trainingFiltered.unshift(training);
            }
          }
        })

        trainingsData.map((training, index) => {
          if(training.courses.length){
            if(training.level == "Lubricantes"){
              trainingFiltered.unshift(training);
            }
          }
        })

      }

      setTrainingsFeatured(trainingsFeaturedData);
      setTrainings(trainingFiltered);
      setLoading(false);


    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }
  };

  const getInternalCourses = async (id_curse) => {
    setLoading(true);

    try {

      const token = storage.getItem('protagonistas_token');
      const response = await connect.get(`${process.env.REACT_APP_API_SERVER}/training/get_internal_courses`, {
        headers: {
          'token': token,
          'id_curse': id_curse,
        },
      });

      const trainingData = path(['data', 'single'], response) || [];

      setCourseData(trainingData);
      setLoading(false);

      return trainingData;

      /*
      if(posts && posts.length){
        setPosts(prevState => [...prevState, ...normalized]);
      }else{
        setPosts(normalized);
      }
      */


    } catch (error) {
      //alert("logout");
      setLoading(false);
      //logout();
      //console.error(error);
    }
  };

  const getEvaluationCourse = async (id_evaluation) => {

    setLoading(true);

    try {

      const token = storage.getItem('protagonistas_token');
      const response = await connect.get(`${process.env.REACT_APP_API_SERVER}/training/get_evaluation`, {
        headers: {
          'token': token,
          'id_evaluation': id_evaluation,
        },
      });

      const evuationData = path(['data', 'single'], response) || [];

      setLoading(false);

      return evuationData;


    } catch (error) {
      //alert("logout");
      setLoading(false);
      //logout();
      //console.error(error);
    }
  }

  const addUserAnswer = async (id_evaluation, id_question, id_options_select, date_init) => {

    setLoading(true);

    try {

      const token = storage.getItem('protagonistas_token');

      const params = new URLSearchParams()
            params.append('id_evaluation', id_evaluation)
            params.append('id_question', id_question)
            params.append('id_options_select', id_options_select)
            params.append('date_init', date_init)
            params.append('token', token)

            const config = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }

      //const response = await connect.post(`https://dev.1onesolutions.com/training/add_user_answer`, params, config);
      //const response = await axios.post(`https://stage.1onesolutions.com/training/add_user_answer`, params, config);
      //const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/training/add_user_answer`, params, config);
      const response = await connect.post(`${process.env.REACT_APP_API_SERVER}/training/add_user_answer`, params, config);

      const resultData = path(['data', 'single'], response) || [];

      setLoading(false);

      return resultData;

    } catch (error) {
      //alert("logout");
      setLoading(false);
      //logout();
      //console.error(error);
    }

  }

  const getCatalogue = async () => {

    setLoading(true);

    try {

      const token = storage.getItem('protagonistas_token');
      const response = await connect.get(`${process.env.REACT_APP_API_SERVER}/catalogue/get_brands`, {
        headers: {
          'token': token,
        },
      });

      const result = {brands: null};
      const catalogueData = path(['data', 'single', 'brands'], response) || null;
      result.brands = catalogueData;
      setCatalogue(result);
      setLoading(false);

      return catalogueData;

    } catch (error) {
      //alert("logout");
      setLoading(false);
      //logout();
      //console.error(error);
    }
  }

  const getByPassImageUploadRoute = async (payload) => {

    setLoading(true);

    try {

      const token = storage.getItem('protagonistas_token');
      const params = new URLSearchParams()
            params.append('image_name', payload.image_name)
            params.append('path', 'profile/avatar/')
            params.append('token', token)

            const config = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }

      const response = await _connect.post(`${process.env.REACT_APP_BASE_URL}/generateUploadURL`, params, config);
      //const response = await connect.post(`${process.env.REACT_APP_API_SERVER}/utils/generateUploadURL`, params, config);

      const imageProfileUploadURL = path(['data', 'single', 'uploadURL',], response) || [];
      const type = payload.file.split(';')[0].split('/')[1];
      const buffer = Buffer.from(payload.file.replace(/^data:image\/\w+;base64,/, ""),'base64');

      const result = await axios.put(imageProfileUploadURL, buffer, {
        headers: {
          'Content-Type': 'image/jpeg',
          'Content-Encoding': 'base64'
        },
      });
      const imageProfileURL = imageProfileUploadURL.split('?')[0];

      // const payload_profile_image = {avatar_url: imageProfileURL};
      // const payload =


      const payload_profile_image = {avatar_url: imageProfileURL};
      const imageEdited = edit_user_profile(payload_profile_image);//{data_edit: JSON.stringify(payload_profile_image)}
      //const imageEdited = editUserData(payload_profile_image);
      imageEdited.then(res => {
        //const trainingsFeaturedData = path(['data', 'single', 'featured', 'courses'], result) || [];
        const mediaUserData = {...userData};
        mediaUserData.media = res.data.single.user.avatar;
        setUserData(mediaUserData);
      })
      setLoading(false);
      return result;

      return response;

      /*
      if(posts && posts.length){
        setPosts(prevState => [...prevState, ...normalized]);
      }else{
        setPosts(normalized);
      }
      */


    } catch (error) {
      //alert("logout");
      setLoading(false);
      //logout();
      //console.error(error);
    }

  }


  const setVideoViewed = async (payload) => {

    setLoading(true);
    const requestURL = `${process.env.REACT_APP_API_SERVER}/training/viewed_content`;

    try {

      const token = storage.getItem('protagonistas_token');

      const params = new URLSearchParams()
            params.append('id_module', payload.id_module);
            params.append('id_content', payload.id_content);
            params.append('token', token);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await connect.post(requestURL, params, config);

      setLoading(false);

      return result;

    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }

  }

  const setContentViewed = async (payload) => {

    setLoading(true);

    //const requestURL = "https://dev.1onesolutions.com/mark_viewedcontent";
    const requestURL = `${process.env.REACT_APP_API_SERVER}/mark_viewed_content`;


      const token = storage.getItem('protagonistas_token');

      const params = new URLSearchParams()
            params.append('id_content_viewed', payload.id_content_viewed);
            params.append('token', token);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await connect.post(requestURL, params, config);

      setLoading(false);

      return result;

  }

  const changePassword = async (payload) => {
    setLoading(true);
    const requestURL = `${process.env.REACT_APP_API_SERVER}/users/change_password`;

    try {

      const token = storage.getItem('protagonistas_token');

      const params = new URLSearchParams()
            params.append('password', payload.password);
            params.append('new_password', payload.new_password);
            params.append('re_password', payload.re_password);
            params.append('token', token);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await connect.post(requestURL, params, config);

      setLoading(false);

      return result;

    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }

  }

  const sendContactMessage = async (payload) => {

    const requestURL = `${process.env.REACT_APP_API_SERVER}/contact/save_contact`;

    try {

      const token = storage.getItem('protagonistas_token');

      const params = new URLSearchParams()
            params.append('id_canal', payload.id_canal);
            params.append('title', payload.title);
            params.append('mensaje', payload.mensaje);
            params.append('token', token);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await connect.post(requestURL, params, config);

      return result;

    } catch (error) {
      //alert("logout");
      //logout();
      //console.error(error);
    }

  }

  const updateTrainingCertificates = (trainings, course_id = null) => {

    if(course_id){
      trainings.map((training, index) => {
        training.courses.map((course, index) => {
          if(course._id == course_id){
            course.status = 1;
          }
        })
      })
    }

    let arrCertificates = [];
    trainings.map((training, index) => {

      let arrCourses = [];

      let training_status = true;
      training.courses.map((course, index) => {
        if(course._id != "62b9d45dd760fa0009af4a4c"){
          if(course.status === 0){
            training_status = false;
          }

          arrCourses.push({name: course.name,
                            status: course.status,
                            icon: course.icon,
                            _id: course._id})
        }
      })

      //objCertificates.title = training.level;
      //objCertificates.courses = [];
      arrCertificates.push({_id: training._id, level: training.level, status: training_status, courses: arrCourses});
    })

    setDashboardStatCertificates(arrCertificates);

    return arrCertificates;

  }

  const updateTrainingStates = (trainings) => {

    let coursesTotalCount = 0;
    let coursesCompleteTotalCount = 0;
    trainings.map((training, index) => {
      training.courses.map((course, index) => {
        if(course._id != "62b9d45dd760fa0009af4a4c"){
          coursesTotalCount++;
          if(course.status){
            coursesCompleteTotalCount++;
          }
        }
        /*
        if(course._id == course_id){
          course.status = 1;
        }
        */
      })
    })
    setCoursesCompleteTotal(coursesCompleteTotalCount);
    setCoursesTotal(coursesTotalCount);

  }

  const normalizeDataMuro = (data) => {
    const normalized = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      let media = element.media.length > 0 ? element.media : null;

      if(media){
        const mediaArray = [];
        for (let n = 0; n < media.length; n++) {
          const mediaObject = {
            id: media[n]._id,
            type: media[n].type,
            url: media[n].media
          }
          mediaArray.push(mediaObject);
        }
        //media =
        media = mediaArray;
      }
      //if(media[0].type === 'image'){
        const fixed_data = {id: element._id,
                            post_type: media ? media[0].type : "",//element.post_type,
                            title: element.titulo,//element.title,
                            text: element.descripcion ? element.descripcion : '',
                            date_publish: element.date_publish,
                            media: media,
                            likes: element.likes,
                            liked: element.liked,
                            accept_comments: true,//element.accept_comments,
                            accept_likes: true,/*element.accept_likes*/}

        normalized.push(fixed_data);
      //}

    }
    return normalized;
  }

  const getLatamBoxMuro = async () => {

    const requestURL = `${process.env.REACT_APP_API_SERVER}/wall/get_post`;
    const token = storage.getItem('protagonistas_token');
    const result = await connect.get(requestURL, {
      headers: {
        'token': token,
      },
    });

    return normalizeDataMuro(path(['data', 'single'], result));

  }

  const getShellBoxContent = async () => {

    const requestURL = `https://prod.1onesolutions.com/get_landing`;

    const token = storage.getItem('protagonistas_token');
    const result = await connect.get(requestURL, {
      headers: {
        'token': token,
      },
    });

    const wallArray = path(['data', 'single', 'wall'], result);
    const wall = wallArray.length ? normalizeDataMuro(path(['data', 'single', 'wall'], result)) : [];

    setShellBoxWall(wall);
    setShellBoxFeatured(path(['data', 'single', 'featured'], result));
    setShellBoxContents(path(['data', 'single', 'contents'], result));
    setShellBoxContentFilter(path(['data', 'single', 'content_filter'], result));

    const courses = reorderShellBoxCourses(path(['data', 'single', 'courses'], result));
    setShellBoxCourses(courses);
    //setShellBoxCourses(path(['data', 'single', 'courses'], result));

    return result;

  }
  const reorderShellBoxCourses = (courses) => {

    //const finalList = [];
    const orderList = ["636a66fb855b8a403bf57245", "636ab238855b8a403bf57297", "6390bbcaaaa0bcb7640b1987", "6390bbd8aaa0bcb7640b1988", "6390bbe8aaa0bcb7640b1989", "636ab2e6855b8a403bf5729a", "636ab30f855b8a403bf5729b", "636ab32c855b8a403bf5729c", "636ab351855b8a403bf5729d"];
    const finalList = orderList.map((el, index) => {
      return (courses.filter(course => course._id == el))[0];
    })
    return finalList;
  }

  const sendLike = async (payload) => {

    const token = storage.getItem('protagonistas_token');

    const params = new URLSearchParams()
          params.append('id_post', payload.id_post)
          params.append('token', token)

          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }

    const result = await connect.post(`${process.env.REACT_APP_API_SERVER}/wall/add_liked`, params, config);

    return result;

  };

  const sendDislike = async (payload) => {
    const { like_id } = payload;
    return await connect.delete(`${process.env.REACT_APP_API_SERVER}/like/${like_id}`);
  };


  useEffect(() => {
    //getCampus();
    get_user_profile();
    
    //getUserData();
    return () => {

    }
  }, [])

  return (
    <ContentContext.Provider
      value={{
        campus,
        setCampus,
        getCampus,
        get_reactions_wall,
        get_wall,
        get_post_by_id,
        save_grade_like,
        set_add_like,
        save_survey,
        save_assesment,
        take_user_evaluation,
        get_training_home,
        get_home_categorie,
        get_course_by_id,
        get_content_by_id,
        save_content_viewed,
        add_like_wall,
        add_comment_wall,
        get_user_profile,
        edit_user_profile,
        get_notifications,
        contact_profile,
        notification_status_change,
        ecommerce_brands,
        ecommerce_category,
        ecommerce_products,
        get_commnents_post,
        get_commnent_responses,

        getNotifications,
        getHomeTraining,
        getInternalCourses,
        getEvaluationCourse,
        addUserAnswer,
        getCatalogue,
        getUserData,
        getByPassImageUploadRoute,
        setVideoViewed,
        setContentViewed,
        editUserData,
        changePassword,
        sendContactMessage,
        updateTrainingCertificates,
        updateTrainingStates,
        setNotificationsViewed,
        saveTrivia,
        getTrivia,
        getTriviaTest,
        getTriviaGame,
        getTriviaRanking,
        getQuestionsTrivia,
        setAnswerTrivia,
        getEmbajadores,
        getLatamBoxMuro,
        getShellBoxContent,
        sendLike,
        sendDislike,
        loading,
        userData, setUserData,
        trainings, setTrainings,
        campusCategorySelectedData, setCampusCategorySelectedData,
        trainingCategorySelected, setTrainingCategorySelected,
        trainingSelected, setTrainingSelected,
        courseData, setCourseData,
        trainingsFeatured, setTrainingsFeatured,
        catalogue, setCatalogue,
        sessionsStatData,
        coursesStatData,
        studyLevelData, setStudyLevelData,
        studyStatusData, setStudyStatusData,
        dashboardStatCertificates, setDashboardStatCertificates,
        coursesTotal, coursesCompleteTotal,
        needYouPhone, setNeedYouPhone,
        notifications, setNotifications,
        visibilityMenuMobile, setVisibilityMenuMobile,
        openModalContacto, setOpenModalContacto,
        openModalEmbajadores, setOpenModalEmbajadores,
        showMoreStats, setShowMoreStats,
        triviaGame, setTriviaGame,
        trivia, setTrivia,
        triviaProgress, setTriviaProgress,
        triviaRanking,
        colorPallete, setColorPallete,
        shellBoxWall, setShellBoxWall,
        shellBoxFeatured, setShellBoxFeatured,
        shellBoxContents, setShellBoxContents,
        shellBoxCourses, setShellBoxCourses,
        shellBoxContentFilter, setShellBoxContentFilter,



        trainingHome, setTrainingHome,
        course, setCourse,
        module, setModule,
        evaluation, setEvaluation,
        evaluationActive, setEvaluationActive,
        evaluationData, setEvaluationData,
        crumbs, setCrumbs,
        lastCourse, setLastCourse,
        gotoData, setGotoData,
        categoriesBrand, setCategoriesBrand,
        categoriesSelected, setCategoriesSelected,
        products, setProducts,
        product, setProduct,
        navLoading, setNavLoading,

        putImageStorageUrl, 
        getImageStogeUrl, 
        getAvatarImage,
        get_url_save_photo,
        get_reactions_wall_according_id,
        notificationPostSelected, setNotificationPostSelected
      }}>
      <div>{children}</div>
    </ContentContext.Provider>
  );
};

