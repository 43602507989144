import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  height: 45px;
  border: 1px solid #000000;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 0 15px 0 15px;
  cursor: ${props => props.isLoading ? 'default' : 'pointer'};
  >.loader{
    display: flex;
    margin-right: 5px;
    svg{
      color: #DD1D21;
    }
  }
  .icon{
    display: flex;
    font-size: 25px;
    .fill_heart{
      color: #DD1D21;
    }
    .outline_heart{
      color: #DD1D21;
    }
  }

  .likes{
    font-size: 16px;
    line-height: 16px;
    margin-left: 3px;
    font-weight: 600;
  }

  :hover{
    background-color: ${props => props.isLoading ? '#FFFFFF' : '#FFC600'};
    border: 1px solid ${props => props.isLoading ? '#000000' : '#FFC600'};
    .icon{
      .fill_heart{
        color: ${props => props.isLoading ? '#DD1D21' : '#FFFFFF'};
      }
      .outline_heart{
        color: ${props => props.isLoading ? '#DD1D21' : '#FFFFFF'};
      }
    }
    .likes{
      color: ${props => props.isLoading ? '#212529' : '#FFFFFF'};
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const Like = (props) => {

  const { liked,
          likes,
          className,
          isLoading,
          onClick } = props;
useEffect(() => {
  //console.log("isLoading", isLoading)

  return () => {}
}, [isLoading])

  return (

      <WrapperStyled
        className={className}
        isLoading={isLoading}
        onClick={() => { !isLoading && onClick()}}
      >
        {!isLoading ?
        <div className='icon'>
          {liked ?
            <AiFillHeart className='fill_heart' />
          :
            <AiOutlineHeart className='outline_heart' />
          }
        </div>
        :
        <div className='loader'>
          <CircularProgress size={20} />
        </div>
        }
        <div className='likes'>
          { likes }
        </div>
      </WrapperStyled>

  )
}

export default Like;
