import React from "react";
import HomeContainer from "./HomeContainer";

const Home = (props) => {
  return (
      <HomeContainer location={props.location} history={props.history} match={props.match}/>
  );
};

export default Home;
