import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.styles.background.color.normal};
    width: 34px;
    height: 34px;
    border-radius: 17px;
    cursor: pointer;
    border: 0;

    .icon{
      display: flex;
      color: ${props => props.styles.icon.color.normal};
      svg{
        font-size: 20px;
      }
      &.notifications{
        svg{
          font-size: 25px;
        }
      }
      &.hamburger{
        svg{
          font-size: 25px;
        }
      }
    }

    :hover{
      color: black;
      background-color: ${props => props.styles.background.color.hover};

      .icon{
        color: ${props => props.styles.icon.color.hover};
      }
    }

    &.selected_comment_button{
      background-color: #FFFFFF;
      .icon{
        color: #FFD200;
      }
      :hover{
        background-color: #FFD200;

        .icon{
          color: #FFFFFF;
        }
      }
    }



`

const ActionRounded = ({ children, override_css, className, onClick }) => {

  const [ styles, setStyle ] = useState(null);


  useEffect(() => {

    setStyle({

      icon: { color: { normal: override_css?.icon?.color?.normal ? override_css.icon.color.normal : "#000000",
                       hover: override_css?.icon?.color?.hover ? override_css.icon.color.hover : "#FFFFFF" }},

      background: { color: { normal: override_css?.background?.color.normal ? override_css.background.color.normal : "#FFFFFF",
                             hover: override_css?.background?.color.hover ? override_css.background.color.hover : "#FFD200" }},


  });

    return () => {

    }
  }, [])


  return (
    <>
      {styles &&
      <Wrapper styles={styles} className={className} onClick={() => onClick()}>
          { children }
      </Wrapper>
      }
    </>
  )

}

export default ActionRounded;
