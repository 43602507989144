import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { IcoFelicitaciones,
         IcoRespondeMal } from '../../../_helpers/constants';
import { breakpoints } from '../../../_helpers/breakpoints';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0 0 40px 0;
    padding: 0;

    .description{
      font-size: 25px;
      line-height: 32px;
      text-align: center;
      margin-bottom: 20px;
    }
    .ico_graet{
      width: 120px;
    }
    .ico_bad{
      width: 120px;
    }


`


const MessageContainer = (props) => {

  const { className,
          win } = props;

  return (

    <Wrapper className={className} breakpoints={breakpoints}>

      {win !== null ?
        win ?
          <>
            <div className="description">¡Felicitaciones completaste<br/>el entrenamiento!</div>
            <IcoFelicitaciones className={"ico_graet"} />
          </>
            :
          <>
            <div className="description">¡Volvé a intentarlo!</div>
            <IcoRespondeMal className={"ico_bad"} />
          </>
        :
        <></>
      }

    </Wrapper>

  )

}

export default MessageContainer;
