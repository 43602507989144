import React, { useState, useContext, useEffect, Fragment, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../_elements';
import Image from './Medias/Image';
import Video from './Medias/Video';
import Podcast from './Medias/Podcast';
import Survey from './Medias/Survey';

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

import { Navigation, Pagination } from 'swiper';

const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  //padding: 7px 7px;
  //border-radius: 25px;
  .swiper-pagination-bullet-active{
    background: #FFC600!important;
  }
  .nav_prev,
  .nav_next{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
    cursor: pointer;
    z-index: 9;
    :hover{
      background-color: #FFC600;
      color: #000000;
    }
  }
  .nav_prev{
    left: 10px;
    top: calc(50% - 15px);
  }
  .nav_next{
    right: 10px;
    top: calc(50% - 15px);
  }

  @media ${breakpoints.xs} {//576px

  }
  @media ${breakpoints.sm} {//768px
  }
  @media ${breakpoints.md} {//992px
  }
  @media ${breakpoints.lg} {//1200px

  }
  @media ${breakpoints.xl} {//1366px

  }
  @media ${breakpoints.xxl} {//1440px

  }
  @media ${breakpoints.xxxl} {//1680px

  }
  @media ${breakpoints.xxxxl} {//1920px
  }

`

const SwiperStyled = styled(Swiper)`

  width: 100%;

/*
  width: 440px;
  .content-hide{
    display: none;
  }
  .content-show{
    display: block;
  }

  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;

    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    margin-top: -13px;
    position: absolute;
    top: 233px;
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: -30px;
    background-color: #000000!important;
  }
  .element{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 440px;
    margin-left: 0;
    :first-child{
      margin-left: 0;
    }
    .inner{
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      //background-color: pink;
    }
    >.image{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 440px;
      height: 440px;
      overflow: hidden;
      border-bottom: 5px solid white;
      img{
        float: left;
        height: 100%;
      }
    }
    .texts{
      margin-top: 7px;
      >.title{
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
      }
      >.description{
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
      }
    }
    :hover{
      .texts{
        >.title{
          color: #DD1D21;
        }
      }
      >.image{
        border-bottom: 5px solid #DD1D21;
      }
    }
  }
  */
  @media ${breakpoints.xs} {
    //width: 544px;
  }
  @media ${breakpoints.sm} {
    //width: 448px;
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }

  /*position: absolute;*/
`

const Media = (props) => {

  const { data,
          scope,
          className,
          isLoading,
          onClick,
          medias,
          posts,
          setPosts } = props;

  const sliderRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [ playStatus, setPlayStatus ] = useState(false);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    setPlayStatus(false);
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    setPlayStatus(false);
  }, []);

  useEffect(() => {
    //console.log("-> data", data)

    return () => {}
  }, [data])


  return (

    <WrapperStyled
      className={className}
      isLoading={isLoading}
      //onClick={() => { !isLoading && onClick()}}
    >

      {medias && medias.length > 1 ?
      <Fragment>
        <div className='nav_prev' onClick={() => { handlePrev() }}>
          <FaChevronLeft />
        </div>
        <SwiperStyled
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          ref={sliderRef}
          modules={[Navigation, Pagination]}
          spaceBetween={24}
          slidesPerView={"auto"}
          pagination={{ clickable: true }}
          //onSlideChange={() => console.log('slide change')}
          //onSwiper={(swiper) => console.log(swiper)}
          className=""
        >
          {
            medias.map((el, i) => {
              return(
                <SwiperSlide>
                  <div className='element'>
                    <div className='inner'>

                    {
                      el.media_type === "image" ?
                        <Image
                          data={el}
                          playStatus={playStatus}
                          setPlayStatus={setPlayStatus}
                        />
                      : ""
                    }

                    {
                      el.media_type === "video" ?
                        <Video
                          data={el}
                          scope={scope}
                          playStatus={playStatus}
                          setPlayStatus={setPlayStatus}
                        />
                      : ""
                    }

                    {
                      el.media_type === "podcast" ?
                        <Podcast
                          data={el}
                          playStatus={playStatus}
                          setPlayStatus={setPlayStatus}
                          background={"img/fondo-b.png"}
                        />
                      : ""
                    }

                    {
                      el.media_type === "survey" ?
                        <Survey
                          data={el}
                          post={data}
                          playStatus={playStatus}
                          setPlayStatus={setPlayStatus}
                          background={"img/fondo-b.png"}
                          posts={posts}
                          setPosts={setPosts}
                        />
                      : ""
                    }

                    </div>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </SwiperStyled>
        <div className='nav_next' onClick={() => { handleNext() }}>
          <FaChevronRight />
        </div>
      </Fragment>
      :
      medias[0].media_type === "image" ?
        <Image
          data={medias[0]}
          playStatus={playStatus}
          setPlayStatus={setPlayStatus}
        />
      : medias[0].media_type === "video" ?
        <Video
          data={medias[0]}
          scope={scope}
          playStatus={playStatus}
          setPlayStatus={setPlayStatus}
        />
      : medias[0].media_type === "podcast" ?
        <Podcast
          data={medias[0]}
          playStatus={playStatus}
          setPlayStatus={setPlayStatus}
          background={"img/fondo-b.png"}
        />
      : medias[0].media_type === "survey" ?
        <Survey
          data={medias[0]}
          post={data}
          playStatus={playStatus}
          setPlayStatus={setPlayStatus}
          background={"img/fondo-b.png"}
          posts={posts}
          setPosts={setPosts}
        />
      : ""


    }

    </WrapperStyled>

  )
}

export default Media;
