import React, { useState, useContext, useEffect, useLayoutEffect, Fragment, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../../_elements';
import ReactPlayer from 'react-player';
import useMediaQuery from '@mui/material/useMediaQuery';
import SingleChoice from '../../Survey/SingleChoice';
import { getAnswedData, answerSuccess } from '../../../_helpers/commons';

import { ContentContext } from '../../../_context';
import finishSurveyImage from '../../../_assets/images/finish_survey.svg';
import finishSurveyImageRed from '../../../_assets/images/finish_survey_red.svg';

const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${props => props.is_red ? '#D8010F' : '#FFD200'};
  color: #FFFFFF;
  box-sizing: border-box;
  //padding: 15px;
  //border-radius: 25px;
  .sending_overlay{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    >.loader{
      svg{
        color: #FFD200;
      }
    }
  }
  >.box_result{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: ${props => props.is_red ? '#D8010F' : '#FFD200'};
    .box_result_inner{
      display: flex;
      flex-direction: column;
      align-items: center;
      .image{
        width: 250px;
        svg{
          width: 100%;
          float: left;
        }
      }
      .title{
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      .description{
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        margin-top: 15px;
      }
    }
  }
  >.inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    //margin-top: 15px;
    width: 100%;//calc(100% - 20px);
    //height: calc(100% - 95px);
    .question{
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: ${props => props.is_red ? '#FFFFFF' : '#000000'};
      font-size: 4vw;
      line-height: 5vw;
      font-weight: 800;
      text-transform: uppercase;
      text-align: center;
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .answers{
      display: flex;
      flex: 3;
      //height: ${props => props.scrollHeight ? `calc(100% - ${props.scrollHeight}px)` : '270px'}!important;
      //margin-top: 10px;
    }

    >.actions{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex: 1;
      //position: absolute;
      //bottom: 0;
      //display: flex;
      width: 100%;
      //border-top: 1px solid #E8E8E8;
      .bt_next{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        background-color: #FFFFFF;
        color: #000000;
        font-size: 18px;
        line-height: 18px;
        font-weight: 800;
        text-transform: uppercase;
        opacity: 0.25;
        &.enabled{
          opacity: 1;
          cursor: pointer;
          :hover{
            background-color: rgba(255, 255, 255, .5);
            color: ${props => props.is_red ? '#FFFFFF' : '#000000'};
          }

        }

      }
    }
  }


  @media ${breakpoints.xs} {//576px
    >.inner{
      .question{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-size: 18px;
        line-height: 23px;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }
  }
  @media ${breakpoints.sm} {//768px
  }
  @media ${breakpoints.md} {//992px
  }
  @media ${breakpoints.lg} {//1200px
  }
  @media ${breakpoints.xl} {//1366px
  }
  @media ${breakpoints.xxl} {//1440px
  }
  @media ${breakpoints.xxxl} {//1680px
  }
  @media ${breakpoints.xxxxl} {//1920px
  }

`

const Survey = (props) => {

  const { data,
          post,
          scope,
          className,
          isLoading,
          onClick,
          posts,
          setPosts } = props;

  const { save_survey } = useContext(ContentContext);

  const [ questionSelected, setQuestionSelected ] = useState(null);
  const [ scrollHeight, setScrollHeight ] = useState(0);
  const [ selectedAnswers, setSelectedAnswers ] = useState([]);
  const [ order, setOrder ] = useState(0);
  const [ textButtonContinue, setTextButtonContinue ] = useState("Siguiente");
  const [ answerSurvey, setAnswerSurvey ] = useState([]);
  const [ surveySended, setSurveySended ] = useState(false);
  const [ surveySending, setSurveySending ] = useState(false);
  const [ answerSurveyObj, setAnswerSurveyObj ] = useState({});

  const questionRef = useRef(null);

  const red_survey = ['65d3b7f8261c280008a65b0d', '65dceac485278d000873148f'];

  const handleNextQuestion = () => {


    /*
    if(selectedAnswers){
      let obj_response_question = {};
      let secondary_actions_arr = [];
      selectedAnswers.map((a, i) => {
        const response = getAnswedData(data.survey.questions[order].secondary_actions, a);
        if(response){
          secondary_actions_arr.push(response);
        }
      })

      obj_response_question[data.survey.questions[order]._id] = secondary_actions_arr;

      setAnswerSurvey([...answerSurvey, obj_response_question]);

      let obj = {...answerSurveyObj};
      obj[data.survey.questions[order]._id] = secondary_actions_arr;
      setAnswerSurveyObj(obj)

      
      // const pointsGained = answerSuccess(data.survey.questions[order].score,
      //                                    data.survey.questions[order]?.penalty_score ? data.survey.questions[order]?.penalty_score : 0,
      //                                    data.survey.questions[order].secondary_actions,
      //                                    selectedAnswers);

      // if(pointsGained){
      //   obj_response_question["status"] = pointsGained.status;
      //   obj_response_question["score"] = pointsGained.score;

      //   if(pointsGained.status === 1){
      //     setAnswerCorrect([...answerCorrect, index]);
      //   }else{
      //     setAnswerIncorrect([...answerIncorrect, index]);
      //   }
      // }
      

    }else{

      return null;

    }
    
    const next = order + 1;
    data.survey.questions.length-1 == next && setTextButtonContinue("Enviar");

    if(data.survey.questions.length-1 >= next){

      setOrder(next);
      setSelectedAnswers([]);
      setQuestionSelected(data.survey.questions[next]);

    }
    */



    
    if(selectedAnswers){

      let obj_response_question = {};
      let arr_option_id = [];
      let arr_option_text = [];
      //let arr_option_media = [];

      selectedAnswers.map((a, i) => {
        const response = getAnswedData(data.survey.questions[order].secondary_actions, a);
        if(response){
          arr_option_id.push(response._id);
          arr_option_text.push(response.label);
          //arr_option_media.push(response.media);
        }
      })

      obj_response_question["media"] = data.survey.questions[order].media;
      obj_response_question["optionSelectedId"] = arr_option_id;
      obj_response_question["questionText"] = data.survey.questions[order].title;
      obj_response_question["_id"] = data.survey.questions[order]._id;
      

      if(data.survey.questions[order].input_type.title != 'single_choice_image' && data.survey.questions[order].input_type.title != 'multiple_choice_image'){
        obj_response_question["optionSelectedText"] = arr_option_text;
      }else{
        obj_response_question["optionSelectedText"] = [];
      }

      obj_response_question["status"] = 1;
      obj_response_question["date_init"] = data.survey.date_init;
      obj_response_question["date_finish"] = data.survey.date_finish;

      setAnswerSurvey([...answerSurvey, obj_response_question]);

    }else{

      return null;

    }
    const next = order + 1;

    data.survey.questions.length-1 == next && setTextButtonContinue("Enviar");
    //setQuestionSelected(data.survey.questions[0]);
    if(data.survey.questions.length-1 >= next){

      setOrder(next);
      setSelectedAnswers([]);
      setQuestionSelected(data.survey.questions[next]);

    }
    
  }

  const handleSendEvaluation = () => {
    setSurveySending(true);
    
    const payload = { survey_id: data.survey._id,
                      post_id: post._id,
                      answers: answerSurvey//JSON.stringify(answerSurvey)
                     }

    const response = save_survey(payload);
    response.then(res => {

      const update_posts = posts.map((p, index) => {
        if(post._id === p._id){
          p.medias[0].survey.completed = true;
        }
        return p;
      })
      setPosts(update_posts);

      setSurveySended(true);
      setSurveySending(false);
    })

  }



  useEffect(() => {

    if(data){
      if(data?.survey?.questions){
        setSurveySended(data.survey.completed);
        setQuestionSelected(data.survey.questions[order]);
      }
    }

    return () => {}
  }, [data])


  useEffect(() => {
    if (questionRef.current) {
      const altura = questionRef.current.offsetHeight + 5;
      setScrollHeight(altura);
    }
  }, [questionRef]);

  useLayoutEffect(() => {

    if (questionRef.current) {
      const altura = questionRef.current.offsetHeight + 5;
      setScrollHeight(altura);
    }

    return () => {};
  }, [questionRef])

  useEffect(() => {
    if(data && answerSurvey.length){
      if(answerSurvey.length == data.survey.questions.length){
        handleSendEvaluation();
      }
    }
    //65d39dfae29e7d00082f5da7
    return () => {}
  }, [data, answerSurvey])

  if(!post && !posts.length){
    return null;
  }

  return (

    <WrapperStyled
      scope={scope}
      className={className}
      isLoading={isLoading}
      scrollHeight={scrollHeight}
      is_red={red_survey.includes(post._id)}
    >
      {data ?
      /*surveySended*/ data.survey.completed ?
        <div className="box_result">
          <div className='box_result_inner'>
            <div className='title'>Enviado con éxito</div>
            <div className='image'>
              <img src={red_survey.includes(post._id) ? finishSurveyImageRed : finishSurveyImage} />
            </div>
            {/* <div className='description'>¡Muchas gracias por completar la encuesta!</div> */}
          </div>
        </div>
      :
        <Fragment>

          {(questionSelected) ?
          <div className='inner'>


              <div className="question" ref={questionRef}>
                { questionSelected.title }
              </div>

              <div className='answers'>
                {questionSelected.input_type.title === "single_choice" ?
                  <SingleChoice
                    multiple={false}
                    answers={questionSelected.secondary_actions}
                    selectedAnswers={selectedAnswers}
                    setSelectedAnswers={setSelectedAnswers}
                    isRed={red_survey.includes(post._id) ? true : false}
                    //questionRef={questionRef}
                  />
                  : ""
                }
              </div>


            <div className='actions'>
              <div className={`bt_next ${selectedAnswers.length ? "enabled" : ""}`} onClick={ () => { selectedAnswers.length && handleNextQuestion() } }>
                { textButtonContinue }
              </div>
            </div>

          </div>
          : ""}



          {/* <div className='inner'>
            {(questionSelected) ?
              <Fragment>
              <div className="question" ref={questionRef}>
                { questionSelected.title }
              </div>
              <div className='answers'>
                {questionSelected.input_type.title === "single_choice" ?
                  <SingleChoice
                    multiple={true}
                    answers={questionSelected.secondary_actions}
                    selectedAnswers={selectedAnswers}
                    setSelectedAnswers={setSelectedAnswers}
                    //questionRef={questionRef}
                  />
                  : ""
                }
              </div>
              </Fragment>
            : ""}
          </div>

          <div className='actions'>
            <div className={`bt_next ${selectedAnswers.length ? "enabled" : ""}`} onClick={ () => { selectedAnswers.length && handleNextQuestion() } }>
              { textButtonContinue }
            </div>
          </div> */}

          {surveySending ?
          <div className="sending_overlay">
              <div className='loader'><CircularProgress /></div>
          </div> : ""}

        </Fragment>
      : ""}
    </WrapperStyled>

  )
}

export default Survey;
