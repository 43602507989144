import { number } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';
import { FaLock, FaUnlock } from "react-icons/fa";
import { GiPodiumWinner } from "react-icons/gi";
import axios from 'axios';

const StyledWrapper = styled.div`
  width: 100%;
  .counter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 5px solid #FFD200;
    border-radius: 50%;
    margin-bottom: 25px;
    p{
      margin: 0;
      font-size: 25px;
      font-weight: 600;
      color: #FFFFFF;
    }
  }
  .inner_game{
    position: relative;
    width: 100%;
    margin-top: 25px;

    .overlay_game{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.85);
      //border-radius: 10px;
      z-index: 8;
      font-size: 45px;
      .game_locked{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;
        .game_locked_message{
          margin-top: 10px;
          width: 150px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          text-align: center;
          color: #FFFFFF;
        }
      }
      .game_unlocked{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;
        .bt_play{
          width: 150px;
          margin-top: 15px;
        }
      }
      .game_winner{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 80px;
        color: #FFD200;
        .game_winner_message{
          margin-top: 10px;
          width: 240px;
          .title{
            font-size: 24px;
            line-height: 30px;
            font-weight: 600;
            text-align: center;
            color: #FFFFFF;
          }
          .aclaration{
            font-size: 17px;
            line-height: 17px;
            font-weight: 600;
            text-align: center;
            color: #FFFFFF;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .box_image_game{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    .left_image{
      display: flex;
      border: 2px solid #000000;
      width: 100%;
      margin-bottom: 10px;
      //height: 250px;
      background-color: #FFD200;
      cursor: pointer;
      img{
        float: left;
        width: 100%;
      }
    }
    .left_image:hover{
      border: 2px solid #FFD200;
    }
    .right_image{
      display: flex;
      border: 2px solid #000000;
      width: 100%;
      //height: 250px;
      background-color: #FFD200;
      cursor: pointer;
      img{
        float: left;
        width: 100%;
      }
    }
    .right_image:hover{
      border: 2px solid #FFD200;
    }
  }

  @media ${breakpoints.xs} {
    .box_image_game{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left_image{
        width: calc(50% - 5px);
        margin-bottom: 0;
      }
      .right_image{
        width: calc(50% - 5px);
      }
    }
  }
  @media ${breakpoints.sm} {
  }
  @media ${breakpoints.md} {
  }
  @media ${breakpoints.lg} {
  }
`

const ImageCorrect = (props) => {

  const { uid,
          setTimeEnded,
          timerSeconds,
          setGameResult,
          setOpenResult,
          gameData,
          setGameData  } = props;

  const [ timeLeft, setTimeLeft ] = useState(60);
  const [ showOverlayGame, setShowOverlayGame ] = useState(true);
  const [ intervalId, setIntervalId ] = useState(null);
  const [ gameImage, setGameImage ] = useState({id:"0", image_left: "images/image_correct_temp.jpg", image_right: "images/image_correct_temp.jpg", win: ""});


  const handleStartGame = () => {

    const start = saveStartGame();
    start.then((res) => {
      if(res.data.success){

        const update = {...gameData};
        update.image_correct.can_play = false;
        update.image_correct.game_status = false;
        setGameData(update);

        startGame();
        setShowOverlayGame(false);
        const id = setInterval(() => {
          setTimeLeft(prevTime => prevTime - 1);
        }, 1000);
        setIntervalId(id);
      }
    })

  }

  const startGame = () => {

    const ordenamientoAleatorio = (a, b) => {
      return Math.random() - 0.5;
    }

    const gamePairs1 = {id:"1", image_left: "images/image_correct_a1.jpg", image_right: "images/image_correct_a2.jpg", win: "left"};
    //const gamePairs2 = {id:"2", image_left: "images/image_correct_b1.jpg", image_right: "images/image_correct_b2.jpg", win: "right"};
    //const gamePairs3 = {id:"3", image_left: "images/image_correct_c1.jpg", image_right: "images/image_correct_c2.jpg", win: "left"};

    if(!gameData.image_correct.game_status){

      const options = [gamePairs1];//, gamePairs2, gamePairs3
      const randomOption = Math.floor(Math.random() * (options.length));
      const optionSelected = options[randomOption];
      setGameImage(optionSelected);

    }
    /*
    if(gameData.image_correct.game_status){

      setGameImage(gamePairs0);

    }else{

      const options = [gamePairs1, gamePairs2, gamePairs3];
      const randomOption = Math.floor(Math.random() * (options.length));
      const optionSelected = options[randomOption];
      setGameImage(optionSelected);

    }
    */

  }

  const saveStartGame = async () => {

    //const requestURL = `http://localhost/protagonistas/diaseguridad.php`;
    const requestURL = `https://fabianhr.com/protagonistas/diaseguridad.php`;

    try {
      const params = new URLSearchParams()
            params.append('type', "set_start_game");
            params.append('uid', uid);
            params.append('game', "image_correct");

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await axios.post(requestURL, params, config);
      return result;

    } catch (error) {

    }
  }

  const handleSelectImage = (id, image) => {

    clearInterval(intervalId)
    setTimeLeft(timeLeft);

    if(gameImage.win == image){
      saveEndGame(true);
      const update = {...gameData};
      update.image_correct.can_play = false;
      update.image_correct.game_status = true;
      setGameData(update);
      setOpenResult(true);
      setGameResult("win");
    }else{
      saveEndGame(false);
      const update = {...gameData};
      update.image_correct.can_play = false;
      update.image_correct.game_status = false;
      setGameData(update);
      setOpenResult(true);
      setGameResult("lose");
    }

  }

  const saveEndGame = async (game_result) => {

    //const requestURL = `http://localhost/protagonistas/diaseguridad.php`;
    const requestURL = `https://fabianhr.com/protagonistas/diaseguridad.php`;

    try {
      const params = new URLSearchParams()
            params.append('type', "set_end_game");
            params.append('uid', uid);
            params.append('game', "image_correct");
            params.append('result', game_result);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await axios.post(requestURL, params, config);
      return result;

    } catch (error) {

    }
  }

  useEffect(() => {

    if(timeLeft == 0){

      clearInterval(intervalId);
      setTimeLeft(timeLeft);

      saveEndGame(false);
      const update = {...gameData};
      update.image_correct.can_play = false;
      update.image_correct.game_status = false;
      setGameData(update);
      setOpenResult(true);
      setGameResult("lose");

    }

    return () => {}
  }, [timeLeft]);

  return (
    <StyledWrapper>
      <div className="inner_game">

        <div className="counter">
          <p>{timeLeft}</p>
        </div>

        {showOverlayGame &&
          <div className='overlay_game'>
            {
              gameData.image_correct.game_status ?
                <div className='game_winner'>
                  <GiPodiumWinner />
                  <div className='game_winner_message'>
                    <div className='title'>¡FELICITACIONES!</div>
                    <div className='aclaration'>Ya completaste esta actividad</div>
                  </div>
                </div>
              :
                <div className='game_unlocked'>
                  <FaUnlock />
                  <ButtonYellow
                    className="bt_play"
                    onClick={() => handleStartGame()}
                    disabled={false}
                    css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                  >
                    <p>JUGAR</p>
                  </ButtonYellow>
                </div>
            }
            {/*
              gameData.image_correct.can_play ?
              <div className='game_unlocked'>
                <FaUnlock />
                <ButtonYellow
                  className="bt_play"
                  onClick={() => handleStartGame()}
                  disabled={false}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                >
                  <p>JUGAR</p>
                </ButtonYellow>
              </div>
            :
              gameData.image_correct.game_status ?
                <div className='game_winner'>
                  <GiPodiumWinner />
                  <div className='game_winner_message'>
                    <div className='title'>¡FELICITACIONES!</div>
                    <div className='aclaration'>Ya completaste esta actividad</div>
                  </div>
                </div>
              :
                <div className='game_locked'>
                  <FaLock />
                  <div className='game_locked_message'>
                    Ya jugaste tu partida de hoy.<br/>¡Volvé mañana!
                  </div>
                </div>
            */}
          </div>
        }

        {gameImage &&
        <div className="box_image_game">
          <div className="left_image" onClick={() => handleSelectImage(gameImage.id, "left")}>
            <img src={gameImage.image_left} />
          </div>
          <div className="right_image" onClick={() => handleSelectImage(gameImage.id, "right")}>
            <img src={gameImage.image_right} />
          </div>
        </div>
        }

      </div>
    </StyledWrapper>
  )
}

export default ImageCorrect;
