import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import ReactPlayer from 'react-player';
import { AudioPlayer } from '../../_elements';
import { MdZoomOutMap,
         MdZoomInMap } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../../_context';
import { getModulesStatusFromCourse,
         STATUS_COURSE_BLOCKED,
         STATUS_COURSE_UNLOCKED,
         STATUS_COURSE_VIEWED,
         STATUS_COURSE_COMPLETED,
         STATUS_COURSE_REPROVED,
         STATUS_COURSE_PENDING_CONNECTION,
         STATUS_COURSE_IN_PROGRESS } from '~/_helpers/commons';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .audio_player{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: relative;
    .audio_player_background{

      background-image: ${props => props.background ? 'url('+props.background+')' : ''};
      //background-image: ${props => props.background ? 'url('+props.background+')' : ''};

       /* Add the blur effect */
      //filter: blur(4px);
      //-webkit-filter: blur(4px);

      /* Full height */
      width: 100%;
      height: 100%;

      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .audio_player_inner{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      height: 100%;
      position: absolute;
      //background-color: skyblue;
      canvas{
        width: 500px!important;
        height: 250px!important;
      }
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const Podcast = (props) => {

  const { _id,
          index,
          media,
          course,
          className,
          history,
          location,
          background,
          playStatus,
          setPlayStatus,
          isLastSlide,
          lastSlideIndex,
          handleRefreshData,
          handleTakeEvaluation } = props;

  const { save_content_viewed } = useContext(ContentContext);

  const theme = useTheme();
  const [ openDialogImage, setOpenDialogImage ] = React.useState(false);
  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const [ _playing, set_Playing ] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const _player = useRef(null);
  const trainingEl = useRef(null);

  const handleOnReady = (e) => {

  }

  const handleViewedPodcastContent = () => {

    if(course.status === STATUS_COURSE_COMPLETED || course.status === STATUS_COURSE_VIEWED){
      return null;
    }

    const status_data = getModulesStatusFromCourse(course, _id);

    if(index === lastSlideIndex && isLastSlide){
      //const payload = { id_content: _id }
      const payload = { course_id: course._id, moduls: JSON.stringify(status_data.modules_arr), status: status_data.course_status }
      const response = save_content_viewed(payload);
      response.then(res => {
        handleRefreshData(payload);
        //handleTakeEvaluation(_id);
      })
    }
  }


  useEffect(() => {

    if(media.content_type.toLowerCase() == "podcast"){
      if(playStatus === false){
        //_player?.current.seekTo(0);
      }
    }

    return () => {}
  }, [playStatus])

  const handleClose = () => {
    setOpenDialogImage(false);
  };

  // useEffect(() => {
  //   if(index === lastSlideIndex && isLastSlide){
  //     console.log("Podcast - isLastSlide: ", isLastSlide);
  //   }
  //   return () => {}
  // }, [isLastSlide])


  return (

    <WrapperStyled background={background}>

      {media.content_type.toLowerCase() == "podcast" &&
        <div className="audio_player">
          <div className='audio_player_background'></div>
          <div className='audio_player_inner'>
            <AudioPlayer
              elRef={trainingEl}
              handleViewedContent={handleViewedPodcastContent}
              url={media.media}
              _bars={55}
              _bar_x={17}
              _bar_width={10}
              playStatus={playStatus}
              setPlayStatus={setPlayStatus}
              newCampus={true}
            />
          </div>
        </div>
      }

    </WrapperStyled>

  )
}

export default Podcast;
