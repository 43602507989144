import React, { useState, useEffect, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import { breakpoints } from '../../_helpers/breakpoints';
import { IcoCheck, COLORS } from '../../_helpers/constants';
import { RiCloseCircleLine } from "react-icons/ri";
import { ContentContext } from '../../_context';

//import { slug } from '../../_helpers/commons';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    color: ${props => props.colorPallete.primary};

    .box_certificates{
      .title{
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 16px;
      }
      .certificate_items{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 15px;
        .certificate_item{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 46px;
          height: unset;
          .outer{
            display: flex;
            width: 46px;
            height: 46px;
            //border: 5px solid #D8010F;
            //border-radius: 23px;
            .ico{
              display: flex;
              //padding: 7px;
              .ico_medal_1{
                display: flex;
                width: 20px;
              }
              .ico_medal_2{
                display: flex;
                width: 20px;
              }
            }
          }
          .label{
            width: 100%;
            text-align: center;
            font-size: 10px;
            margin-top: 3px;
            //white-space: nowrap;
          }
        }
        .certificate_more{
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 46px;
          border-radius: 23px;
          border: 1px solid #D8010F;
          .label{
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            color: #D8010F;
          }
          :hover{
            background-color: #FFD200;
            .label{
              //color: #FFFFFF;
            }
          }
        }
      }
    }

    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const ModalStyled = styled(Modal)`

  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0;
  position: absolute;
  overflow: scroll;
  z-index: 9999!important;

  .inner_modal_certificates{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    padding: 15px;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 0;
    margin-bottom: 0;
    .modal_certificates_title{
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 25px;
      border-bottom: 1px solid #CCCCCC;
      padding-bottom: 12px;
      padding-top: 6px;
    }
    .modal_certificates_category{
      display: flex;
      width: 100%;
      flex-direction: row;
      border: 1px dashed #CCCCCC;
      margin-top: 10px;
      padding: 10px;
      .certificate_category_status{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 160px;
        //background-color: pink;
        .certificate{
          display: flex;
          width: 70px;
          img{
            width: 100%;
          }
        }
        .category_name{
          display: flex;
          width: calc(100% - 20px);
          //background-color: yellow;
          margin-top: 5px;
          p{
            font-size: 15px;
            font-weight: 500;
            width: 100%;
            text-align: center;
            margin: 0;
          }
        }
      }
      .training_list{
        display: flex;
        width: 490px;
        flex-direction: column;
        .training{
          display: flex;
          flex-direction: row;
          .status_icon{
            width: 18px;
            height: 18px;
            margin-left: 5px;
            &.pending{
              svg{
                path{
                  fill: #E5E6EA!important;
                }
              }
            }
          }
          .training_name{
            p{
              margin: 0;
              font-size: 16px;
              margin-top: 1px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10px;
    position: absolute;
    overflow: scroll;
    z-index: 9999!important;

    .inner_modal_certificates{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 700px;
      padding: 15px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;
      margin-bottom: 20px;
    }
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`

const CertificatesStats = (props) => {

  //const { history, location } = useHistory();
  const history = useHistory();

  const { trainings,
          getHomeTraining,
          setTrainingSelected,
          setTrainingCategorySelected,
          setCampusCategorySelectedData,
          dashboardStatCertificates,
          setDashboardStatCertificates,
          updateTrainingCertificates,
          showMoreStats,
          colorPallete, } = useContext(ContentContext);

  const [ certificates, setCertificates ] = useState(null);
  const [ andMore, setAndMore ] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if(trainings){
      updateTrainingCertificates(trainings);
      if((trainings.length - 4) > 1){
        setAndMore(trainings.length - 4);
      }else{
        setAndMore(0);
      }
    }else{
      getHomeTraining();
    }
    return () => {}
  }, [trainings])

  const handleGoToTraining = (target, category_target, id_course, id_category, data_category) => {

    setTrainingSelected(id_course);
    setTrainingCategorySelected(id_category);
    setCampusCategorySelectedData(data_category);

    history.push('/campus/'+category_target+'/'+target);
  };

  if(!showMoreStats){
    return null;
  }

  return (
    <Fragment>

      <ModalStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >

        <Fade in={open}>
            <div className="inner_modal_certificates">

            <ButtonClose
              onClick={() => handleClose()}
            >
              <div className="icon">
                <RiCloseCircleLine />
              </div>
            </ButtonClose>

              <div className="modal_certificates_title">
                Tus Certificados
              </div>
              {dashboardStatCertificates !== null ?
                dashboardStatCertificates ?
                dashboardStatCertificates.map((item, index) => {
                  if(item.courses.length){
                    return(
                      <div key={'certificates-modal-'+index} className="modal_certificates_category">

                        <div className="certificate_category_status">
                          <div className="certificate">
                            {item.status ?
                            <img src="/images/ico_certificate_on.png" />
                            :
                            <img src="/images/ico_certificate_off.png" />
                            }
                          </div>
                          <div className="category_name">
                            <p>{item.level}</p>
                          </div>
                        </div>

                        <div className="training_list">
                          {item.courses.map((course, index) => {
                            return(
                              <div key={'trining_list'+index} className="training">
                                <div className={`status_icon ${course.status ? 'complete' : 'pending'}`}>
                                  <IcoCheck />
                                </div>
                                <div className="training_name">
                                  <p>{course.name}</p>
                                </div>
                              </div>
                            )
                          })}

                        </div>
                      </div>
                    )
                  }
                  })
              :<div>no hay resultados</div>
              :<div>cargando</div>

              }
            </div>
        </Fade>

      </ModalStyled>
      <Wrapper breakpoints={breakpoints} colorPallete={colorPallete}>

        <div className="box_certificates">
          <div className="title">
            Tus Certificados
          </div>

          <div className="certificate_items">
            {
              dashboardStatCertificates !== null ?
              dashboardStatCertificates ?
              dashboardStatCertificates.map((item, index) => {
                  if(item.courses.length){
                    if(index < 4){
                      return(
                        <div key={'certificates-'+index} className="certificate_item">
                          <div className="outer">
                            <div className="ico">
                              {item.status ?
                              <div className="ico_medal_2">
                                <img src="/images/ico_certificate_on.png" />
                                {/*<IcoMedal2 />*/}
                              </div>
                              :
                              <div className="ico_medal_1">
                                <img src="/images/ico_certificate_off.png" />
                                {/*<IcoMedal1 />*/}
                              </div>
                              }
                            </div>
                          </div>
                          <div className="label">
                            {item.level}
                          </div>
                        </div>
                      )
                    }
                  }
                  })
                :<div className="">no hay resultados</div>
              :<div className="">cargando</div>
            }

            <div key={'certificates-total'} className="certificate_more" onClick={() => handleOpen()}>
              <div className="label">
                +{andMore}
              </div>
            </div>

          </div>

        </div>

      </Wrapper>

    </Fragment>

  )

}

export default CertificatesStats;
