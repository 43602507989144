import React, { useState, useEffect, useContext, Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BsCheck } from "react-icons/bs";

import { } from '../../_elements';

import { ContentContext } from '../../_context';

const QuestionStyled = styled.div`

  .evaluation_answers{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .evaluation_answer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 40vw;
      height: 40vw;
      border: 2px solid #cccccc;
      box-sizing: border-box;
      margin: 1vw;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .over_answer{
        display: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding-right: 2px;
        top: 0;
        left: 0;
        width: 45px;
        height: 45px;
        background-color: rgba(255, 198, 0, 1);
        border-bottom-right-radius: 15px;
        color: #000000;
        font-size: 30px;
        line-height: 30px;
      }
      img{
        float: left;
        width: 100%;
      }
      :hover{
        border: 2px solid #FFC600;
        background-color: #FFC600;
        .over_answer{
          display: flex;
        }
      }
      &.selected{
        background-color: #FFC600;
        border: 2px solid #FFC600;
        .over_answer{
          display: flex;
        }
      }
    }

  }
  @media ${breakpoints.xs} {
    .evaluation_answers{
      margin-top: 20px;
      .evaluation_answer{
        width: 220px;
        height: 220px;
        margin: 5px;
      }
    }
  }
  @media ${breakpoints.sm} {
    .evaluation_answers{
      margin-top: 20px;
      .evaluation_answer{
        width: 250px;
        height: 250px;
        margin: 5px;
      }
    }
  }
`

const MultipleChoice = (props) => {

  const { multiple,
          secondary_actions,
          order,
          answersSelected,
          setAnswersSelected } = props

  const { evaluationActive,
          setEvaluationActive,
          evaluation,
          setEvaluation,
          take_user_evaluation } = useContext(ContentContext);

  const handleAnswer = (_id) => {
    if(multiple){
      if(!answersSelected.includes(_id)){
        setAnswersSelected([...answersSelected, _id])
      }else{
        const filtered = answersSelected.filter(answer => answer != _id);
        setAnswersSelected(filtered);
      }
    }else{
      setAnswersSelected([_id]);
    }
  }




  return (
    <QuestionStyled className={''}>

      <div className='evaluation_answers'>
        {secondary_actions && secondary_actions.map((answer, index) => {
          return(
            <Fragment>

            <div className={`evaluation_answer${answersSelected.includes(answer._id) ? ' selected' : ""}`} onClick={ () => handleAnswer(answer._id) }>
              <div className='over_answer'>
                <BsCheck />
              </div>
              <img src={`${answer.media}`} />
            </div>
            </Fragment>
          )
        })}
      </div>

    </QuestionStyled>
  )
}

export default MultipleChoice;
