import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 15px;
    width: ${props => props.css_styles?.width ? props.css_styles.width + "px" : "52px" };
    height: ${props => props.css_styles?.height ? props.css_styles.height + "px" : "52px" };
    overflow: hidden;

    img{
      display: flex;
      height: 100%;
    }

`

const Round = ({ children, css_styles, className }) => {

  return (
    <Wrapper css_styles={css_styles} className={className}>
      { children }
    </Wrapper>
  )

}

export default Round;
