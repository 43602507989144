import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { ContentContext } from '../../_context';
import { path } from 'ramda';
import { SideLeft, SideRight, DashboardStats, CatalogueItem } from '../../_components';
import { breakpoints } from '../../_helpers/breakpoints';
import { Modal, Fade, Backdrop, Box } from '@material-ui/core';
import { RiCloseCircleLine } from "react-icons/ri";
import { Spinner, COLORS } from '../../_helpers/constants';
import LazyLoad from 'react-lazyload';
import CircularProgress from '@material-ui/core/CircularProgress';


const Wrapper = styled.div`

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  //padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  .product{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px 20px;
    width: 100%;
    height: 100%;
    /* background-color: pink; */
    cursor: pointer;
    >.image{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 300px;
      img{
        display: flex;
        height: 100%;
        //float: left;
      }
    }
    >.title{
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 15px;
      text-align: center;
    }
    img{
      //float: left;
      //width: 100%;
    }
    :hover{
      border: 1px solid #ffd200;
    }
  }
  @media ${breakpoints.xs} {
    grid-template-columns: repeat(2, 1fr);
    .product{
      >.image{
        height: 220px;
      }
    }
  }
  @media ${breakpoints.sm} {
    grid-template-columns: repeat(3, 1fr);
    .product{
      >.image{
        height: 200px;
      }
    }
  }
  @media ${breakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${breakpoints.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${breakpoints.xxl} {
    grid-template-columns: repeat(5, 1fr);
  }
  @media ${breakpoints.xxxl} {
    grid-template-columns: repeat(6, 1fr);
  }
`

const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`

const ProductsContainer = (props) => {

  const { products } = props;

  const { loading,
          product,
          setProduct,
          ecommerce_brands,
          ecommerce_category } = useContext(ContentContext);

  const [ productsBrand, setProductsBrand ] = useState(null);
  const [ categoriesBrand, setCategoriesBrand ] = useState(null);
  const [ brandSelected, setBrandSelected ] = useState(null);
  const [ categorySelected, setCategorySelected ] = useState(null);
  const [ productSelectedData, setProductSelectedData ] = useState(null);
  const [ openModalProduct, setOpenModalProduct ] = useState(false);

  useEffect(() => {

    // const brands_response = ecommerce_brands();
    // brands_response.then((res) => {
    //   setCategoriesBrand(path(['data', 'single', 'categories'], res));
    //   console.log("res", res)
    // })

    return () => {}
  }, [])

  const handleProduct = (product) => {
    setProduct(product);
    //console.log("product_id", product);
  }


  return (
    <Wrapper>

      {products && products.map((product, index) => {
        return(
          <div className='product' onClick={() => handleProduct(product)}>
            <div className='image'>
              <img src={`${product.medias[0].media_url}`} alt="" />
            </div>
            <div className='title'>{product.name}</div>
          </div>
        )
      })}


    </Wrapper>
  )
}

export default ProductsContainer;
