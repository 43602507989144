import React from "react";
import ModuleContainer from "./ModuleContainer";

const Module = (props) => {
  return (
      <ModuleContainer location={props.location} history={props.history} match={props.match}/>
  );
};

export default Module;
