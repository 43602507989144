import React, { useState, useContext, useEffect, Fragment, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AiOutlineLink } from "react-icons/ai";
import { RxExternalLink } from "react-icons/rx";
import { getCoursePath } from '~/_helpers/commons';

import { ContentContext,
         AuthContext } from '../../_context';

const WrapperStyled = styled.div`
  margin-left: 15px;
  .icon{
    font-size: 23px;
    line-height: 23px;
    cursor: pointer;
  }
  :hover{
    .icon{
      color: #DD1D21;
      fill: #DD1D21;
    }
  }
  @media ${breakpoints.xs} {//576px
    //margin-left: 5px;
  }
  @media ${breakpoints.sm} {//768px
  }
  @media ${breakpoints.md} {//992px
  }
  @media ${breakpoints.lg} {//1200px

  }
  @media ${breakpoints.xl} {//1366px

  }
  @media ${breakpoints.xxl} {//1440px
    //margin-left: 15px;
  }
  @media ${breakpoints.xxxl} {//1680px

  }
  @media ${breakpoints.xxxxl} {//1920px
  }

`


const Goto = (props) => {

  let history = useHistory();
  let location = useLocation();

  const { campus,
          gotoData,
          setGotoData,
          setCourse } = useContext(ContentContext);

  const { setActiveRoute } = useContext(AuthContext);

  const { go_to,
          className } = props;

  /*
  useEffect(() => {

    console.log("go_to", go_to)

    return () => {}
  }, [go_to])
  */
  
  const handleClicGoto = () => {

    // getCoursePath(campus, go_to.id_content);
    // return null;
    
    if(go_to.type === "interno"){

      setGotoData({path: go_to.path, _id: go_to.id_content});

      if(go_to.path === "course"){
        setCourse(null);
        setActiveRoute("campus");
        history.push(`/campus/course/${go_to.id_content}`);
      }else{
        if(go_to.path === "catalogue"){
          history.push(`/catalogo`);
        }else{
          if(go_to.path === "categories"){
            setActiveRoute("catalogo");
            history.push(`/catalogo/${go_to.id_content}`);
          }else{
            setCourse(null);
            setActiveRoute("campus");
            history.push(`/campus/module/${go_to.id_content}`);
          }
        }
      }
      
    }

    if(go_to.type === "externo"){
      window.open(go_to.path, '_blank').focus();
    }

  }

  return (

    <WrapperStyled
      className={className}
      onClick={handleClicGoto}
    >
      <div className='icon'>
        <RxExternalLink />
      </div>

    </WrapperStyled>

  )
}

export default Goto;
