import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import { openInNewTab } from '../../_helpers/commons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #CECECE;
  width: 340px;
  height: 193px;
  border-radius: 10px;
  box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
  cursor: ${props => props.data.link ? "pointer" : "default"};
  >.inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-image: url(${props => props.data.image ? props.data.image : '/images/galaxia2.jpg'});
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    .content{
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        color: #FFFFFF;
        font-size: 19px;
        line-height: 22px;
        font-weight: 500;
      }
      .text{
        color: #FFFFFF;
        font-size: 15px;
        line-height: 15px;
        margin-top: 7px;
      }
    }
  }
  :hover{
    border-color: ${props => props.data.link ? "#FFD200" : "#CECECE"};
  }

`

const Destacado = (props) => {

  const { data } = props;

  const { login,
          isAuthLoading,
          loginError } = useContext(AuthContext);


  useEffect(() => {



    return () => {

    }
  }, [data])


  return (
    <Wrapper data={data} onClick={() => data.link ? openInNewTab(data.link) : null}>
      <div className="inner">
        <div className="content">

          {data.icon ?
            <div className="icon">
              <img src={data.icon} />
            </div>
          : '' }

          <div className="title">
            {data.title}
          </div>

          <div className="text">
            {data.description_short}
          </div>

        </div>
      </div>
    </Wrapper>
  )
}

export default Destacado;
