import { number } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { usePreview } from 'react-dnd-preview';
import GameTimer from "./GameTimer";


import { FaLock, FaUnlock } from "react-icons/fa";
import { GiPodiumWinner } from "react-icons/gi";
import axios from 'axios';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .timer{

  }
  .letter{
    margin-right: 1.5vw;
    margin-bottom: 0.9vw;
    margin-top: 0.9vw;
  }
  .word_correct{
    color: #FFD200;
  }
  .word{
    border: 1px dashed gray;
    background-color: white;
    padding: 1.2vw 2vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
    font-size: 3.5vw;
    color: #000000;
    cursor: move;
    float: left;
    &.correct{
      opacity: .5;
      cursor: pointer;
    }
  }
  .words{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .target_box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 7vw;
    width: 23vw;
    margin-right: 1vw;
    margin-left: 1vw;
    text-align: center;
    font-size: 5vw;
    color: black;
    font-weight: 600;
    line-height: 3vw;
    border: 1px dashed #FFD200;
  }
  .frase{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 4.5vw;
    line-height: 4.5vw;
    font-weight: 500;
    width: calc(100% - 6vw);
    box-sizing: border-box;
    padding: 4vw 3vw;
    border: 2px dashed rgba(255, 210, 0, 0.10);
  }
  .counter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 5px solid #FFD200;
    border-radius: 50%;
    margin-bottom: 25px;
    p{
      margin: 0;
      font-size: 25px;
      font-weight: 600;

    }
  }
  .inner_game{
    position: relative;
    width: 100%;
    margin-top: 25px;

    .overlay_game{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.85);
      //border-radius: 10px;
      z-index: 8;
      font-size: 45px;
      .game_locked{
        display: flex;
        flex-direction: column;
        align-items: center;
        .game_locked_message{
          margin-top: 10px;
          width: 150px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          text-align: center;
        }
      }
      .game_unlocked{
        display: flex;
        flex-direction: column;
        align-items: center;
        .bt_play{
          width: 150px;
          margin-top: 15px;
        }
      }
      .game_winner{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 80px;
        color: #FFD200;
        .game_winner_message{
          margin-top: 10px;
          width: 240px;
          .title{
            font-size: 24px;
            line-height: 30px;
            font-weight: 600;
            text-align: center;
            color: #FFFFFF;
          }
          .aclaration{
            font-size: 17px;
            line-height: 17px;
            font-weight: 600;
            text-align: center;
            color: #FFFFFF;
            margin-top: 5px;
          }
        }
      }
    }
  }

  @media ${breakpoints.xs} {
    .word{
      padding: 7px 10px;
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      font-size: 16px;
    }
    .letter{
      margin-right: 8px;
      margin-bottom: 4px;
      margin-top: 4px;
    }
    .frase{
      font-size: 25px;
      line-height: 25px;
      font-weight: 500;
      width: calc(100% - 30px);
      padding: 20px 15px;
    }
    .target_box{
      height: 36px;
      width: 120px;
      margin-right: 5px;
      margin-left: 5px;
      font-size: 25px;
      font-weight: 600;
      line-height: 16px;
    }
  }
  @media ${breakpoints.sm} {
  }
  @media ${breakpoints.md} {
  }
  @media ${breakpoints.lg} {
  }
`
const MyPreview = () => {
  const preview = usePreview();

  if (!preview.display) {
    return null
  }
  const {itemType, item, style} = preview;
  return <div className="word" style={style}>{item.name}</div>
}

const CompleteWord = (props) => {

  const { uid,
          setTimeEnded,
          timerSeconds,
          setGameResult,
          setOpenResult,
          gameData,
          setGameData  } = props;

  const [ corrects, setCorrects ] = useState([]);
  const [ gameFrase, setGameFrase ] = useState(null);
  const [ timeLeft, setTimeLeft ] = useState(180);
  const [ intervalId, setIntervalId ] = useState(null);
  const [ showOverlayGame, setShowOverlayGame ] = useState(true);
  const [ startClock, setStartClock ] = useState(false);
  const [ stopClock, setStopClock ] = useState(false);
  const [ endClock, setEndClock ] = useState(false);

  useEffect(() => {

    startGame();

    return () => {}
  }, [])

  const startGame = () => {

    const ordenamientoAleatorio = (a, b) => {
      return Math.random() - 0.5;
    }

    const option1 = {frase: "Los elementos que se deben disponer en la descarga son: [0] ABC, balde de material [1] , [2] , cadena perimetral, kit [3] del camión, [4] del transportista y del responsable de la E/S. Los [5] deberán ser ubicados a favor del [6] en un lugar que nos permita atacar todas las [7] de descarga del camión.", words: ["matafuegos", "absorbente", "vallas", "antiderrame", "EPPs", "extintores", "viento", "bocas"], order: [1, 2, 3, 4, 5, 6, 7, 8].sort(ordenamientoAleatorio)}
    //const option1 = {frase: "El [0] enfrenta a [1] desde las 19. El ganador va con [2] o [3]. Seguilo en Olé.", words: ["Xeneize", "Olimpo", "Barracas", "Estudiantes (BA)"], order: [1, 2, 3, 4].sort(ordenamientoAleatorio)};
    //const option2 = {frase: "Mirá los [0] entretelones del cruce entre [1] y [2] en la conferencia de prensa después del duelo con [3].", words: ["inéditos", "Leo", "Weghorst", "Países Bajos"], order: [1, 2, 3, 4].sort(ordenamientoAleatorio)};
    //const option3 = {frase: "[0] profundiza [1] electoral frente a [2] de Máximo Kirchner de competir en las PASO", words: ["Alberto Fernández", "su estrategia", "la decisión"], order: [1, 2, 3].sort(ordenamientoAleatorio)};
    //const option4 = {frase: "Los elementos que se deben disponer en la descarga son: 2 [1] ABC + 1 [2] de material absorbente + 2 [3] + la cadena perimetral + kit [4] del camión + [5] del transportista y de responsable de la E/S. Los [6] deberán ser ubicados a favor [7] en un lugar que nos permita atacar todas las [8] de descarga del camión.", words: ["matafuegos", "balde", "vallas metálicas", "antiderrame", "EPPs", "extintores", "del viento", "bocas"], order: [1, 2, 3, 4, 5, 6, 7, 8].sort(ordenamientoAleatorio)};

    if(gameData.word.game_status){

      setGameFrase(option1);

    }else{

      const options = [option1];
      const randomOption = Math.floor(Math.random() * (options.length));
      const optionSelected = options[randomOption];
      setGameFrase(option1);//optionSelected


    }



  }

  const DrodWord = ({name}) => {

    const [ correct, setCorrect ] = useState(false);

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: 'box',
      drop: () => ({ name: name }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }))
    const isActive = canDrop && isOver
    let backgroundColor = '#222'
    if (isActive) {
      backgroundColor = 'darkgreen'
    } else if (canDrop) {
      backgroundColor = 'darkkhaki'
    }

    return (
      <>
      {filterCorrect(name) ?
      <div className="word_correct">{name}</div>
      :
      <div ref={drop} className="target_box" data-testid="dustbin">
        {isActive ? '. . .' : '. . .'}
      </div>
      }
      </>
    )
  }

  const filterCorrect = (name) => {
    const isCorrect = corrects.filter(c => c == name);
    if(isCorrect.length){
      return true;
    }else{
      return false;
    }
  }


  const Word = function Box({ name, style }) {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'box',
      item: { name },
      canDrag: !filterCorrect(name),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        if (item && dropResult) {
          if(item.name === dropResult.name){
            const exist = corrects.filter(c => c === item.name);
            if(!exist.length) {
              const corrects_update = [...corrects, item.name];
              setCorrects(corrects_update);
            }

          //alert(`You dropped ${item.name} into ${dropResult.name}!`)
        }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }))
    const opacity = isDragging ? 0.4 : 1
    return (
      <div ref={drag} style={style} className={`word${filterCorrect(name) ? " correct" : ""}`} data-testid={`box`}>
        {name}
      </div>
    )
  }

  const Frase = (props) => {

    const { text, words } = props;

    const text_splitted = text.split(" ");

    let lala = text_splitted.map((t, index) => {

      let match = t.match(/\[(\d+)\]/);
      let num = null;
      if (match) {
        num = parseInt(match[1]);
        num = <DrodWord name={words[num]}/>;
      }else{
        num = t;
      }

      return <div className="letter">{num}</div>;

    })

    return <div className="frase">{lala}</div>;
    //.replace(/\[(.+?)\]/g, "["+num+"]")
    //Scaloni Gómez Retegui
  }

  useEffect(() => {

    if(gameFrase){
      if(corrects.length == gameFrase.words.length){

        saveEndGame(true);

        const update = {...gameData};
        update.word.can_play = false;
        update.word.game_status = true;
        setGameData(update);

        clearInterval(intervalId)
        setOpenResult(true);
        setGameResult("win");
        setStopClock(true);
        //setTimeLeft(timeLeft);

      }
    }

    return () => {}
  }, [corrects, gameFrase])

  const handleStartGame = () => {

    const start = saveStartGame();
    start.then((res) => {
      if(res.data.success){

        const update = {...gameData};
        update.word.can_play = false;
        update.word.game_status = false;
        setGameData(update);

        startGame();
        setShowOverlayGame(false);
        setStartClock(true);
      }
    })

  }

  useEffect(() => {

    //if(timeLeft == 0){
    if(endClock){
      //clearInterval(intervalId)

      if(corrects.length < gameFrase.words.length){
        saveEndGame(false);
        const update = {...gameData};
        update.word.can_play = false;
        update.word.game_status = false;
        setGameData(update);

        setOpenResult(true);
        setGameResult("lose");
      }

    }else{

    }

    return () => {}
  }, [/*timeLeft*/endClock]);

  const saveStartGame = async () => {

    //const requestURL = `http://localhost/protagonistas/diaseguridad.php`;
    const requestURL = `https://fabianhr.com/protagonistas/diaseguridad.php`;

    try {
      const params = new URLSearchParams()
            params.append('type', "set_start_game");
            params.append('uid', uid);
            params.append('game', "word");

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await axios.post(requestURL, params, config);
      return result;

    } catch (error) {

    }
  }

  const saveEndGame = async (game_result) => {

    //const requestURL = `http://localhost/protagonistas/diaseguridad.php`;
    const requestURL = `https://fabianhr.com/protagonistas/diaseguridad.php`;

    try {
      const params = new URLSearchParams()
            params.append('type', "set_end_game");
            params.append('uid', uid);
            params.append('game', "word");
            params.append('result', game_result);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await axios.post(requestURL, params, config);
      return result;

    } catch (error) {

    }
  }


  return (
    <StyledWrapper>



      <div className="inner_game">

        <GameTimer
          startClock={startClock}
          setStartClock={setStartClock}
          stopClock={stopClock}
          setStopClock={setStopClock}
          endClock={endClock}
          setEndClock={setEndClock}
        />

        {showOverlayGame &&
          <div className='overlay_game'>
            {
              gameData.word.game_status ?
                <div className='game_winner'>
                  <GiPodiumWinner />
                  <div className='game_winner_message'>
                    <div className='title'>¡FELICITACIONES!</div>
                    <div className='aclaration'>Ya completaste esta actividad</div>
                  </div>
                </div>
              :
                <div className='game_unlocked'>
                  <FaUnlock />
                  <ButtonYellow
                    className="bt_play"
                    onClick={() => handleStartGame()}
                    disabled={false}
                    css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                  >
                    <p>JUGAR</p>
                  </ButtonYellow>
                </div>
            }
            {/*
              gameData.word.can_play ?
              <div className='game_unlocked'>
                <FaUnlock />
                <ButtonYellow
                  className="bt_play"
                  onClick={() => handleStartGame()}
                  disabled={false}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                >
                  <p>JUGAR</p>
                </ButtonYellow>
              </div>
            :
              gameData.word.game_status ?
                <div className='game_winner'>
                  <GiPodiumWinner />
                  <div className='game_winner_message'>
                    <div className='title'>¡FELICITACIONES!</div>
                    <div className='aclaration'>Ya completaste esta actividad</div>
                  </div>
                </div>
              :
                <div className='game_locked'>
                  <FaLock />
                  <div className='game_locked_message'>
                    Ya jugaste tu partida de hoy.<br/>¡Volvé mañana!
                  </div>
                </div>
            */}
          </div>
        }

        <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>


        {gameFrase ?
          <>
            <div className="words">
              {gameFrase.words.map((w, i) => {
                return( <Word style={{order: gameFrase.order[i]}} name={w} /> )/*Math.floor(Math.random() * (gameFrase.words.length)) */
              })}
            </div>

            <MyPreview />

            <Frase
              text={gameFrase.frase}
              words={gameFrase.words}
              replace={gameFrase.words.length}
            />
          </>
        :
          <></>
        }

        </DndProvider>



      </div>

    </StyledWrapper>
  )
}

export default CompleteWord;
