import React from "react";
import LoginContainer from "./LoginContainer";

const Login = (props) => {
  return (
      <LoginContainer location={props.location} history={props.history}/>
  );
};

export default Login;
