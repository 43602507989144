import { useState, useEffect } from "react";

function useAudioPlayer() {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [onAudioEnded, setOnAudioEnded] = useState(false);
  const [stopPlayer, setStopPlayer] = useState(false);


  useEffect(() => {

    const audio = document.getElementById("audio");
    // state setters wrappers
    const setAudioData = () => {
      setIsLoaded(true);
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    }

    const setAudioTime = () => {

      let percent = (audio.currentTime / audio.duration)*100;
      if(!onAudioEnded && percent > 95){
        setOnAudioEnded(true);
        //console.log("termino audio");
      }
      if(percent >= 100){
        percent = 0;
        setPlaying(false);
        audio.pause();
      }
      //console.log(audio.currentTime + " ::: " + audio.duration + " ---> " + ((audio.currentTime / audio.duration)*100));
      setCurTime(audio.currentTime)
    };

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    audio.addEventListener("timeupdate", setAudioTime);

    //audio.addEventListener('progress', displayBufferedAmount);

    // React state listeners: update DOM on React state changes
    playing ? audio.play() : audio.pause();
    muted ? audio.muted = true : audio.muted = false;

    if(stopPlayer === true){
      //audio.pause();
      audio.currentTime = 0;
      //setOnAudioEnded(true);
      setCurTime(audio.currentTime)
    }

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }
    /*
    const displayBufferedAmount = () => {
      const bufferedAmount = Math.floor(audioPlayer.current.buffered.end(audioPlayer.current.buffered.length - 1));
      audio.style.setProperty('--buffered-width', `${(bufferedAmount / seekSlider.current.max) * 100}%`);
    }
    */

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    }
  });


  useEffect(() => {

    //console.log("playing: " + playing);

    return () => {

    }
  }, [playing])



  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

  return {
    curTime,
    setCurTime,
    duration,
    playing,
    setPlaying,
    muted,
    setMuted,
    setClickedTime,
    isLoaded,
    onAudioEnded,
    stopPlayer,
    setStopPlayer
  }
}

export default useAudioPlayer;
