import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import { breakpoints } from '../../_helpers/breakpoints';
import PostMediaContent from './PostMediaContent';

const Wrapper = styled.div`

    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: black;

    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  outline: 0!important;
  border-radius: 16px;

  position: absolute;
  top: 20px!important;
  overflow: scroll;
  margin: 0 auto;

  height: 100%;

  .inner{
    border-radius: 16px;
    overflow: hidden;
    width: 800px;
    background-color: #FFFFFF;
    outline: 0!important;

    -webkit-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0,0.5);
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0,0.5);

  }
  :focus{
    outline: none!important;
  }

  .carousel-control-prev-icon{
    width: 40px!important;
    height: 40px!important;
  }
  .carousel-control-next-icon{
    width: 40px!important;
    height: 40px!important;
  }

`

const ModalPostMedia = (props) => {

  const { post, setPostSelected } = props;
  const [ openModal, setOpenModal ] = useState(true);

  const handleClose = () => {
    setPostSelected(null);
    setOpenModal(false);
  }

  useEffect(() => {
    return () => {}
  }, [post])

  return (
    <ModalStyled
      disableAutoFocus={true}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      breakpoints={breakpoints}
    >
      <Fade in={openModal}>
        <div className="inner">
          <PostMediaContent post_type={post.post_type} post={post} onDrawer={false} sideControls={true} />
        </div>
      </Fade>

    </ModalStyled>
  )

}

export default ModalPostMedia;
