import React, { useState, useEffect, useContext } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ContentContext } from '~/_context';

const Avatar = (props) => {

    const { getAvatarImage } = useContext(ContentContext);

    const { comment } = props;
    const [ image, setImage ] = useState("");
    
    useEffect(() => {
      
        const img = getAvatarImage(comment.avatar_image);
        img.then(i => {
            setImage(i);
        })
    
      return () => {}
    }, [])
    

    return (
        <LazyLoadImage src={image}
            width={40} height={40}
        />
    );
};

export default Avatar;