import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';

import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  height: 60px;

  box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);

  border-radius: 10px;
  overflow: hidden;
  margin-left: 15px;
  box-sizing: border-box;
  cursor: pointer;

  :first-child{
    margin-left: 0;
  }

  .image{
    width: 60px;
    img{
      float: left;
      width: 100%;
    }
  }
  .title{
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    white-space: nowrap;
    padding-right: 20px;
    padding-left: 15px;
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const Feature = (props) => {

  const { title,
          image } = props;

  return (

      <WrapperStyled>
        <div className='image'>
          <img src={image} />
        </div>
        <div className='title'>
          { title }
        </div>
      </WrapperStyled>

  )
}

export default Feature;
