import React from "react";
import CategoryContainer from "./CategoryContainer";

const Category = (props) => {
  return (
      <CategoryContainer location={props.location} history={props.history} match={props.match}/>
  );
};

export default Category;
