import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    p{
        margin: 0;
        color: ${props => props.css_styles?.font?.color ? props.css_styles.font.color : "#313233" };
        font-size: ${props => props.css_styles?.font?.size ? props.css_styles.font.size : "16px" };
        font-weight: ${props => props.css_styles?.font?.weight ? props.css_styles.font.weight : "500" };
        letter-spacing: -0.38px;
        text-decoration: none;
    }
`

const Title = ({ children, css_styles, className }) => {

  return (
    <Wrapper className={className} css_styles={css_styles}>
      { children }
    </Wrapper>
  )

}

export default Title;
