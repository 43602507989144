import React from "react";
import PerfilContainer from "./PerfilContainer";

const Perfil = (props) => {
  return (
      <PerfilContainer location={props.location} history={props.history}/>
  );
};

export default Perfil;
