import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`

    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 3px solid #FFFFFF;
    cursor: pointer;

    .icon{
      display: flex;
      color: #5B5E5F;
      margin-bottom: 7px;
      svg{
        font-size: 22px;
      }
    }

    p{
      color: #5B5E5F;
      font-weight: 600;
      font-size: 11px;
      padding: 0;
      margin: 0;
    }

    :hover{
      color: black;
      border-bottom: 3px solid #D8010F;
      text-decoration: none;
      p{
        color: #D8010F;
      }
      .icon{
        color: #D8010F;
      }
    }

    &.selected{
      border-bottom: 3px solid #D8010F;
      p{
        color: #D8010F;
      }
      .icon{
        color: #D8010F;
      }
    }

`

const Header = ({ children, css_styles, className, onClick }) => {

  return (
    <Wrapper css_styles={css_styles} className={className} onClick={onClick}>
      { children }
    </Wrapper>
  )

}

export default Header;
