import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Transition, Spring, useTransition, animated, config, useSpring } from 'react-spring'
import { breakpoints } from '../../_helpers/breakpoints';
import { ContentContext } from '../../_context';
import { useLocation } from 'react-router-dom';
//import styles from './styles.module.css'

const Wrapper = styled.div`

    position: relative;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 600px;

    .ico_laptop{
      position: absolute;
      width: 100%;
      left: 30px;
      img{
        float: left;
        width: 100%;
      }
    }

    .copas{
      position: absolute;
      width: 270px;
      img{
        float: left;
        width: 100%;
      }
    }

    @media ${breakpoints.sm} {
      display: flex;
    }
    @media ${breakpoints.md} {
    }
    @media ${breakpoints.lg} {
    }

`

const slides = [ '/images/copafifa1.png', '/images/copafifa.png'];

const Qatar2022Addon = (props) => {

  const [index, set] = useState(0)
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 3000 },
  })
  useEffect(() => {
    const t = setInterval(() => set(state => (state + 1) % slides.length), 4000)
    return () => clearTimeout(t)
  }, [])


  return (
    <Wrapper breakpoints={breakpoints}>

      {transitions((style, i) => (
        <animated.div
          className="copas"
          style={{
            ...style,
          }}
        >
          <img src={slides[i]} />
        </animated.div>
      ))}

    </Wrapper>
  )

}

export default Qatar2022Addon;
