import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: ${props => props.css_styles?.width ? (props.css_styles.width+4 / 2)+"px" : "26px" };
    width: ${props => props.css_styles?.width ? props.css_styles.width + "px" : "52px" };
    height: ${props => props.css_styles?.height ? props.css_styles.height + "px" : "52px" };
    overflow: hidden;

    //background-color: pink;
    img{
      display: flex;
      height: ${props => props.isVertical ? 'auto' : '100%'};
      width: ${props => props.isVertical ? '100%' : 'auto'};
    }

`

const Round = ({ children, css_styles, className, onClick = null, file = null }) => {

  const [isVertical, setIsVertical] = useState(null);

  useEffect(() => {
    if(file){
      const image = new Image();
      image.src = file;

      image.onload = () => {
        if (image.naturalHeight > image.naturalWidth) {
          setIsVertical(true);
        } else {
          setIsVertical(false);
        }
      };
    }
  }, []);

  return (
    <Wrapper isVertical={isVertical} css_styles={css_styles} className={className} onClick={onClick}>
      { children }
    </Wrapper>
  )

}

export default Round;
