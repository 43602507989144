import React, { useState } from 'react';
import Auth from './_Auth';
import { breakpoints } from './_helpers/breakpoints';
import styled from 'styled-components';
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { IoLogoApple  } from "react-icons/io";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { PopApp } from '~/_components';
import { PopProblemsApp } from '~/_components';

const Desktop = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  @media ${breakpoints.sm} {
    display: flex;
    width: 100%;
    min-height: 100%;
  }
`
const App = () => {

  const [ openAppPop, setOpenAppPop ] = useState(true);

  return (

    <Desktop className="asdasd">
        {/* {(openAppPop) ? 
        <PopProblemsApp setOpenAppPop={setOpenAppPop} />
        : ""} */}
        {(isMobile && openAppPop) ? 
          <PopApp setOpenAppPop={setOpenAppPop} />
        : ""}
        <Auth />
    </Desktop>

  );
};

export default App;
