import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { ContentContext } from '../../_context';
import { path } from 'ramda';
import { SideLeft, SideRight, DashboardStats, CatalogueItem } from '../../_components';
import { breakpoints } from '../../_helpers/breakpoints';
import { Modal, Fade, Backdrop, Box } from '@material-ui/core';
import { RiCloseCircleLine } from "react-icons/ri";
import { Spinner, COLORS } from '../../_helpers/constants';
import LazyLoad from 'react-lazyload';
import CircularProgress from '@material-ui/core/CircularProgress';


const Wrapper = styled.div`

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  //padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 0;
  .label{
    width: 120px;
    margin-right: 5px;
    padding-top: 7px;
  }
  .list_items{
    /* display: flex;
    flex-direction: row;
    width: calc(100% - 125px);
    flex-wrap: wrap; */

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    width: 100%;
    margin-top: 15px;

    .bt_subcategory{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      font-size: 13px;
      font-weight: 600;
      //background-color: #FAFAFA;
      border: 2px solid #EAEAF0;
      box-sizing: border-box;
      padding: 5px 10px;
      color: #000000;
      /* margin-left: 15px;
      margin-bottom: 7px; */
      cursor: pointer;
      width: 100%;
      .image{
        width: 100%;
        img{
          float: left;
          width: 100%;
        }
      }
      &.selected{
        border: 2px solid #FFC600;
        //background-color: #FFC600;
      }
      :hover{
        border: 2px solid #FFC600;
        //background-color: #FFC600;
      }
      :first-child{
        margin-left: 0;
      }
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {
    .list_items{
      /* display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: calc(100% - 125px); */

      display: grid;
      //grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      width: 100%;

      .bt_subcategory{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        padding: 5px 10px;
        width: 100%;
        .image{
          width: 100%;
          img{
            float: left;
            width: 100%;
          }
        }
        &.selected{
          border: 2px solid #FFC600;
          //background-color: #FFC600;
        }
        :hover{
          border: 2px solid #FFC600;
          //background-color: #FFC600;
        }
        :first-child{
          margin-left: 0;
        }
      }

      /* .bt_subcategory{
        font-size: 16px;
        padding: 10px 20px;
        color: #000000;
        margin-left: 15px;
        margin-bottom: 5px;
      } */
    }
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {
    .list_items{
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media ${breakpoints.xl} {
    .list_items{
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media ${breakpoints.xxxl} {
    .list_items{
      grid-template-columns: repeat(6, 1fr);
    }
  }
  @media ${breakpoints.xxxl} {
    .list_items{
      grid-template-columns: repeat(7, 1fr);
    }
  }
`

const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`

const SubcategoriesContainer = (props) => {

  const { categories,
          handleSubcategories,
          handleProducts,
          handleCategorySelected } = props;

  const { loading,
          ecommerce_brands,
          ecommerce_category } = useContext(ContentContext);

  const [ productsBrand, setProductsBrand ] = useState(null);
  const [ categoriesBrand, setCategoriesBrand ] = useState(null);
  const [ brandSelected, setBrandSelected ] = useState(null);
  const [ categorySelected, setCategorySelected ] = useState(null);
  const [ productSelectedData, setProductSelectedData ] = useState(null);
  const [ openModalProduct, setOpenModalProduct ] = useState(false);

  useEffect(() => {

    // const brands_response = ecommerce_brands();
    // brands_response.then((res) => {
    //   setCategoriesBrand(path(['data', 'single', 'categories'], res));
    //   console.log("res", res)
    // })

    return () => {}
  }, [])

  // const handleSubcategories = (categories) => {
  //   console.log("categories", categories)
  // }

  return (
    <Wrapper>
      {/* <div className="label">Sub-categorías:</div> */}
      <div className='list_items'>
        {categories && categories.map((subcategory, index) => {
          return(
            <div className={`bt_subcategory ${handleCategorySelected(subcategory._id) ? ' selected' : ''}`} onClick={() => handleSubcategories(subcategory, subcategory._id, subcategory.level)/*subcategory.categories.length ? handleSubcategories(subcategory, subcategory._id, subcategory.level) : handleProducts(subcategory, subcategory._id)*/}>
              {/*subcategory.name*/}
              {subcategory.image ?
              <div className='image'>
                <img src={`${subcategory.image}`} />
              </div>
              :
              <div className="title">{subcategory.name}</div>
              }
            </div>
          )
        })}
      </div>

    </Wrapper>
  )
}

export default SubcategoriesContainer;
