import React, { useState, useEffect, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import moment from 'moment';
//import moment from 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@mui/material/Rating';
import { breakpoints } from '../../_helpers/breakpoints';
import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";

import Timer from './Timer';

import { } from '../../_elements';

import MultipleChoice from './MultipleChoice';
import MultipleChoiceWithImage from './MultipleChoiceWithImage';
import { getAnswedData,
         answerSuccess } from '../../_helpers/commons';

import { ContentContext } from '../../_context';

dayjs.extend(utc);
dayjs.extend(timezone);

const EvaluationStyled = styled.div`
  /* top: 0;
  left: 0;
  position: fixed; */
  display: flex;
  flex-direction: column;
  //align-items: center;
  //width: 100%;
  //height: 100%;
  //justify-content: flex-start;
  z-index: 10;
  padding-top: 0;
  //background-color: rgba(0, 0, 0, 0.75);
  position: relative;
  margin-bottom: 30px;

  .evaluation_inner{
    position: relative;
    width: 100%;
    min-height: ${props => props.isLoading ? "" : "360px"};
    border-radius: 0;
    background-color: ${props => props.win == null ? '#FFFFFF' : props.win.back };
    box-sizing: border-box;
    padding: 8vw 8vw 8vw 8vw;
    z-index: 12;
    .box_result_waiting{
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box_result{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .media{
        margin-top: 15px;
        margin-bottom: 15px;
        &.imgWin{
          width: 320px;
        }
        &.imgLose{
          width: 370px;
        }
        img{
          float: left;
          width: 100%;
        }
      }
      .title{
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        color: #FFFFFF;
      }
      .description{
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        text-align: center;
        margin-top: 20px;
        color: #FFFFFF;
      }
      .points{
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        text-align: center;
        margin-top: 25px;
        color: #FFFFFF;
      }
      .rating{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        label{
          margin-bottom: 0;
        }
        .MuiRating-root{
          .MuiRating-icon{
            color: #FFFFFF;
            &.MuiRating-iconHover,
            &.MuiRating-iconFocus,
            &.MuiRating-iconFilled{
              color: #FFC600;
            }
            .MuiSvgIcon-root{

            }
            :hover{
              .MuiSvgIcon-root{

              }
            }
          }
        }
        >.loader{
          flex-direction: row;
          display: flex;
          align-items: center;
          color: #556377;
          margin-left: 10px;
          svg{
            color: #faaf00;
          }
        }
      }
    }
    .evaluation_title{
      font-size: 15px;
      line-height: 15px;
      text-transform: uppercase;
      margin-bottom: 15px;
      text-align: center;
      color: ${props => props.win == null ? '#556377' : '#FFFFFF'};//#556377;
    }
    .loading,
    .loading_result{
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      svg{
        color: #FFC600;
      }
    }
    .loading_result{
      margin-top: 70px;
    }
  }

  .evaluation_question_tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .question_tab{
      height: 2px;
      width: ${props => props.questions_total ? `calc(100% / ${props.questions_total} - 10px)` : "0"};
      background-color: #BFBFBF;
      &.active{
        background-color: #FFC600;
      }
      &.correct{
        background-color: green;
      }
      &.incorrect{
        background-color: red;
      }
    }
  }

  .evaluation_question_rest{
    text-align: center;
    margin-top: 20px;
  }
  .evaluation_result{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .great{
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
    }
    .position{
      margin-top: 10px;
    }
    .points{
      margin-top: 10px;
      font-weight: 500;
    }
  }
  .evaluation_score{
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    margin-top: 50px;
  }

  .evaluation_question{
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }



  .bt_next{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 0 15px;
    background-color: #FFC600;
    border: 1px solid #FFC600;
    color: #000000;
    font-size: 17px;
    line-height: 17px;
    font-weight: 600;
    border-radius: 8px;
    margin-top: 65px;
    cursor: pointer;
    &.disabled{
      background-color: #E1E1E1;
      border: 1px solid #E1E1E1;
      color: #999999;
      cursor: default;
    }
    :hover{
      background-color: ${props => props.answer_selected ? '#FFFFFF' : '#E1E1E1'};
    }
  }
  .bt_close_trivia{
    margin-top: 50px;
    width: 200px;
  }
  .bt_close{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
    border: 1px solid #FFC600;
    background-color: #FFC600;
    color: #000000;
    font-size: 26px;
    cursor: pointer;
    :hover{
      background-color: #FFFFFF;
      color: #FFC600;
    }
  }
  .answer_result{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255,198,0,0.5);
    top: 0;
    left: 0;
    border-radius: 15px;
    z-index: 99;
    .icon{
      font-size: 55px;
      line-height: 55px;
    }
    .inner{
      width: 350px;
      box-sizing: border-box;
      padding: 20px 20px;
      background-color: #FFFFFF;
      border-radius: 15px;
      .correct,
      .incorrect{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .title{
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          margin-top: 15px;
        }
        .text,
        .correct_answer{
          font-size: 15px;
          line-height: 20px;
          margin-top: 10px;
          .correct_is{
            color: red;
          }
        }
        .actions{
          width: 100%;
          .bt_next{
            margin-top: 30px;
          }
        }
      }
      .correct{
        .icon{
          color: green;
        }
      }
      .incorrect{
        .icon{
          color: red;
        }
      }
    }
  }
  @media ${breakpoints.xs} {
    
    .evaluation_inner{
      width: 100%;
      padding: 20px 40px 20px 40px;
      
    }
  }
  @media ${breakpoints.sm} {
    padding-top: 30px;
    .evaluation_inner{
      width: 720px;
      border-radius: 15px;
    }
  }
  @media ${breakpoints.md} {
    .evaluation_inner{
      width: 800px;
      padding: 40px 80px 40px 80px;
    }
  }
  @media ${breakpoints.lg} {

  }
`

const TriviaContainer = (props) => {

  let { id_course, id_module } = useParams();

  const {_id,
         scope,
         title,
         handleClose,
         evaluationData,
         setEvaluationData,
         parentData,
         //evaluation,
         //setEvaluation,
         handleRefreshData } = props

  const { trivia,
          setTrivia,
          saveTrivia,
          getTrivia,
          getTriviaGame } = useContext(ContentContext);


  const [ countOf, setCountOf ] = useState();
  const [ questionTotal, setQuestionTotal ] = useState();
  const [ order, setOrder ] = useState(0);
  const [ answersSelected, setAnswersSelected ] = useState([]);
  const [ answerEvaluation, setAnswerEvaluation ] = useState([]);
  const [ textButtonContinue, setTextButtonContinue ] = useState("Siguiente");
  const [ points, setPoints ] = useState(0);
  const [ evaluation, setEvaluation ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isLoadingTrivia, setIsLoadingTrivia ] = useState(false);
  const [ win, setWin ] = useState(null);
  const [ onResult, setOnResult ] = useState(null);
  const [ onPlay, setOnPlay ] = useState(null);
  const [ evaluationRating, setEvaluationRating ] = useState(0);
  const [ answerCorrect, setAnswerCorrect ] = useState([]);
  const [ answerIncorrect, setAnswerIncorrect ] = useState([]);
  const [ ladingRating, setLadingRating ] = useState(false);
  const [ isRated, setIsRated ] = useState(false);
  const [ dateInit, setDateInit ] = useState("");
  const [ count, setCount ] = useState(0);
  const [ initTimer, setInitTimer ] = useState(0);
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ showResponseModal, setShowResponseModal ] = useState("");
  const [ timeOut, setTimeOut ] = useState(false);
  const [ scoreResult, setScoreResult ] = useState("");
  const [ categoryName, setCategoryName ] = useState("");
  

  const handleNextQuestion = () => {

    if(answersSelected){

      let obj_response_question = {};
      let arr_option_id = [];
      let arr_option_text = [];
      let arr_option_media = [];

      let isCorrect = true;
      answersSelected.map((a, i) => {
        const response = getAnswedData(evaluation.questions[order].secondary_actions, a);
        if(response){
          arr_option_id.push(response._id);
          arr_option_text.push(response.label);
          arr_option_media.push(response.media);
        }
        if(!response.correct){
          isCorrect = false;
        }
        //console.log("response", response)
      })

      obj_response_question["media"] = evaluation.questions[order].media;
      obj_response_question["optionSelectedId"] = arr_option_id;
      obj_response_question["questionText"] = evaluation.questions[order].title;
      obj_response_question["question_id"] = evaluation.questions[order]._id;

      if(evaluation.questions[order].input_type.title != 'single_choice_image' && evaluation.questions[order].input_type.title != 'multiple_choice_image'){
        obj_response_question["optionSelectedText"] = arr_option_text;
      }else{
        obj_response_question["optionSelectedText"] = [];
      }

      obj_response_question["status"] = '';
      obj_response_question["score"] = '';

      const pointsGained = answerSuccess(evaluation.questions[order].score,
                                         evaluation.questions[order]?.penalty_score ? evaluation.questions[order]?.penalty_score : 0,
                                         evaluation.questions[order].secondary_actions,
                                         answersSelected);
                                         
      if(isCorrect){
        setIsPlaying(false);
        setShowResponseModal("correct");
        setAnswerCorrect([...answerCorrect, evaluation.questions[order]._id]);
      }else{
        setIsPlaying(false);
        setShowResponseModal("incorrect");
        setAnswerIncorrect([...answerIncorrect, evaluation.questions[order]._id]);
      }
      //

      if(pointsGained){

        obj_response_question["status"] = pointsGained.status;
        obj_response_question["score"] = pointsGained.score;//pointsGained.status === 1 ? evaluation.evaluation.questions[order].score : 0;//evaluation.evaluation.questions[order].score;//pointsGained.origina_score;

        /*
        if(pointsGained.status === 1){
          setAnswerCorrect([...answerCorrect, evaluation.questions[order]._id]);
        }else{
          setAnswerIncorrect([...answerIncorrect, evaluation.questions[order]._id]);
        }
        */

        let newScore;
        if(pointsGained.action == "plus"){
          newScore = points + pointsGained.score;
        }
        if(pointsGained.action == "minus"){
          newScore = points - pointsGained.score;
        }
        setPoints(newScore);
      }
      
      setAnswerEvaluation([...answerEvaluation, obj_response_question]);

    }else{

      return null;

    }

    //showNextQuestion();

    /*
    const next = order + 1;
    evaluation.questions.length-1 == next && setTextButtonContinue("Enviar");

    if(evaluation.questions.length-1 >= next){
      setOrder(next);
      setAnswersSelected([]);
    }
    */
  }

  const showNextQuestion = () => {

    if(answerEvaluation.length == evaluation.questions.length){
      setShowResponseModal("");
      handleSendEvaluation();
    }else{
      setShowResponseModal("");
      setIsPlaying(true);
      const next = order + 1;
      evaluation.questions.length-1 == next && setTextButtonContinue("Enviar");

      if(evaluation.questions.length-1 >= next){
        setOrder(next);
        setAnswersSelected([]);
      }
    }
  }

  const handleSendEvaluation = () => {
    
    setIsLoadingTrivia(true);
    //let m = moment().utc()
    
    const payload = { id: evaluation._id,
                      date_start: dateInit,
                      date_finish: moment().format('YYYY-MM-DD HH:mm:ss'),//moment().utc().format(),//dayjs.tz(dayjs().format('YYYY-MM-DD HH:mm'), dayjs.tz.guess()).utc().toISOString(),//moment().utc().format(),//moment().format(),
                      //a: dayjs().utc(true).format('YYYY-MM-DD HH:mm'),
                      //b: dayjs().utc().toISOString(),
                      answers: JSON.stringify(answerEvaluation)
                     }
                    //  console.log("payload", moment().format('YYYY-MM-DD HH:mm:ss'));
                    //  console.log("payload", moment().utc().format());
// return null;
  const response = saveTrivia(payload);
    response.then(res => {
      
      getTrivia();
      setIsLoadingTrivia(false);
      setOnPlay(false);
      setOnResult(true);
      setShowResponseModal("");

      const trivia_update = trivia.map((t, i) => {
        if(t._id === _id){
          let status = 1;
          t.status = status;//(res.data.single.trivia.count_questions === res.data.single.trivia.answers.length) ? 0 : 1;
        }
        return t;
      })
      setTrivia(trivia_update);
      
      if(res.data.single.trivia.count_questions === res.data.single.trivia.answers.length){
        //console.log("GANO")
      }else{
        //console.log("PERDIO")
      }

      setScoreResult(res.data.single.trivia.score)

    })
  }

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const handlePauseClick = () => {
    setIsPlaying(false);
  };

  const handleResumeClick = () => {
    setIsPlaying(true);
  };

  
  const handleOnTimeEnd = () => {
    
    let obj_response_question_update = [];

    evaluation.questions.map((question, index) => {
      
      const exist = answerEvaluation.filter(answer => answer.question_id === question._id);
      if(!exist.length){
        
        let obj_response_question = {};
        let arr_option_id = [];
        let arr_option_text = [];
        let arr_option_media = [];

        obj_response_question["media"] = "";
        obj_response_question["optionSelectedId"] = [];
        obj_response_question["questionText"] = question.title;
        obj_response_question["question_id"] = question._id;

        if(question.input_type.title != 'single_choice_image' && question.input_type.title != 'multiple_choice_image'){
          obj_response_question["optionSelectedText"] = arr_option_text;
        }else{
          obj_response_question["optionSelectedText"] = [];
        }

        obj_response_question["status"] = -2;
        obj_response_question["score"] = '';
        
        obj_response_question_update.push(obj_response_question);

      }
    })
    
    setAnswerEvaluation([...answerEvaluation, ...obj_response_question_update]);
    //handleSendEvaluation();
    
  }

  useEffect(() => {
    //console.log("answerEvaluation --------> ", answerEvaluation)
    if(evaluation){
      if(answerEvaluation.length == evaluation.questions.length){
        if(timeOut){
          handleSendEvaluation();
        }
      }
    }

    return () => {}
  }, [answerEvaluation])

  useEffect(() => {

    const response = getTriviaGame(evaluationData.id);
    response.then(res => {

      setIsPlaying(true);
      //console.log("getTriviaGame ======> ", res)
      let trivia = path(['data', 'single', 'trivia'], res);
      
      //console.log("trivia.time", trivia.time)
      if(trivia.time != undefined){
        setInitTimer(trivia.time);
        setCount(trivia.time);

        // setInitTimer(250);
        // setCount(250);
      }
      
      setEvaluation(trivia);
      setQuestionTotal(trivia.questions.length);
      //setDateInit(moment().format(/*'YYYY-DD-MM hh:mm:ss'*/));
      //setDateInit(moment().utc().format());
      setDateInit(moment().format('YYYY-MM-DD HH:mm:ss'));
    })

    return () => {}
  }, [evaluationData])

  useEffect(() => {

    if(evaluation){
      setIsLoading(false);
      if(!onResult){
        setOnPlay(true);
      }
    }

    return () => {}
  }, [evaluation])

  useEffect(() => {
    //console.log("answerCorrect", answerCorrect)

    return () => {}
  }, [answerCorrect])

  useEffect(() => {
    //console.log("answerIncorrect", answerIncorrect)

    return () => {}
  }, [answerIncorrect])
  
  useEffect(() => {
    
    //console.log("_id", _id);
    trivia.map((t, i) => {
      if(t._id === _id){
        setCategoryName(t.name);
        //console.log("t", t)
      }
    })
    //console.log("trivia", trivia)
  
    return () => {}
  }, [trivia])

  useEffect(() => {
    // console.log("trivia", trivia)
    // console.log("dayjs.tz.guess()", dayjs.tz.guess());
    return () => {}
  }, [trivia])
  
  


  return (
    <EvaluationStyled
      questions_total={questionTotal}
      className={''}
      isLoading={isLoading}
    >

      

      <div className='evaluation_inner'>

        {showResponseModal.length ?
          <div className='answer_result'>
            {showResponseModal === "correct" ?
              <div className='inner'>
                <div className='correct'>
                  <div className='icon'>
                    <LuThumbsUp />
                  </div>
                  <div className='title'>¡Excelente!</div>
                  <div className='text'>Respondiste correctamente,<br/>¡Seguí así!</div>
                  <div className='actions'>
                    <div className={`bt_next`} onClick={ () => { answersSelected.length && showNextQuestion() } }>
                      Continuar
                    </div>
                  </div>
                </div>
              </div>
            :
              <div className='inner'>
                <div className='incorrect'>
                  <div className='icon'>
                    <LuThumbsDown />
                  </div>
                  <div className='title'>¡Lo siento!</div>
                  <div className='text'>No respondiste correctamente.</div>
                  {/* <div className='correct_answer'>La respuesta correcta es: <span className='correct_is'>{(evaluation.questions[order].secondary_actions.filter(op => op.correct === true))[0].label}</span></div> */}
                  <div className='actions'>
                    <div className={`bt_next`} onClick={ () => { answersSelected.length && showNextQuestion() } }>
                      Continuar
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          : ""
        }

        {isLoading ?
          <div className='loading'>
            <CircularProgress />
          </div> : ""}

          {evaluation && Object.keys(evaluation).length ?
          <Fragment>
            <div className='bt_close' onClick={handleClose}>
              <IoCloseSharp />
            </div>
            <div className='evaluation_title'>
              {title}
            </div>
            <div className='evaluation_question_tabs'>
              {evaluation.questions && evaluation.questions.map((question, index) => {
                //console.log("question._id", question._id)
                return(
                  <div className={`question_tab ${index == order ? 'active' : ''} ${answerCorrect.includes(question._id) ? ' correct' : ''} ${answerIncorrect.includes(question._id) ? ' incorrect' : ''}`}></div>
                  // <div className={`question_tab ${index <= order ? 'active' : ''}`}></div>
                )
              })}
            </div>
          </Fragment>
          : ""}

          {(!isLoading && onPlay && evaluation && !onResult) ?
          <Fragment>

            {(evaluation.time != undefined && !isLoadingTrivia) ?
            <Timer 
              isPlaying={isPlaying} 
              setIsPlaying={setIsPlaying} 
              count={count}
              setCount={setCount}
              initTimer={initTimer}
              onTimeEnd={handleOnTimeEnd}
              timeOut={timeOut}
              setTimeOut={setTimeOut}
            /> : "" }

            <div className='evaluation_question_rest'>
              <div className=''>Preguntas {order+1}/{questionTotal}</div>
            </div>
            
            
            {!isLoadingTrivia ?

              <Fragment>

                <div className='evaluation_question'>
                  {evaluation.questions[order].title}
                </div>

                {evaluation.questions[order].input_type.title == "single_choice" &&
                  <MultipleChoice
                    multiple={false}
                    secondary_actions={evaluation.questions[order].secondary_actions}
                    question={evaluation.questions[order]}
                    order={order}
                    answersSelected={answersSelected}
                    setAnswersSelected={setAnswersSelected}
                    answerEvaluation={answerEvaluation}
                    setAnswerEvaluation={setAnswerEvaluation}
                  />
                }

                {evaluation.questions[order].input_type.title == "multiple_choice" &&
                  <MultipleChoice
                    multiple={true}
                    secondary_actions={evaluation.questions[order].secondary_actions}
                    question={evaluation.questions[order]}
                    order={order}
                    answersSelected={answersSelected}
                    setAnswersSelected={setAnswersSelected}
                    answerEvaluation={answerEvaluation}
                    setAnswerEvaluation={setAnswerEvaluation}
                  />
                }

                {evaluation.questions[order].input_type.title == "single_choice_image" &&
                  <MultipleChoiceWithImage
                    multiple={false}
                    secondary_actions={evaluation.questions[order].secondary_actions}
                    question={evaluation.questions[order]}
                    order={order}
                    answersSelected={answersSelected}
                    setAnswersSelected={setAnswersSelected}
                    answerEvaluation={answerEvaluation}
                    setAnswerEvaluation={setAnswerEvaluation}
                  />
                }

                {evaluation.questions[order].input_type.title == "multiple_choice_image" &&
                  <MultipleChoiceWithImage
                    multiple={true}
                    secondary_actions={evaluation.questions[order].secondary_actions}
                    question={evaluation.questions[order]}
                    order={order}
                    answersSelected={answersSelected}
                    setAnswersSelected={setAnswersSelected}
                    answerEvaluation={answerEvaluation}
                    setAnswerEvaluation={setAnswerEvaluation}
                  />
                }
                <div className={`bt_next ${!answersSelected.length ? ' disabled' : ''}`} onClick={ () => { answersSelected.length && handleNextQuestion() } }>
                  { textButtonContinue }
                </div>
              </Fragment>

            : 
            <div className='loading_result'>
              <CircularProgress />
            </div>
            }
          </Fragment> 
          : 
            onResult !== null ?
            <Fragment>
              <div className='evaluation_question_rest'>
                <div className=''>Preguntas {order+1}/{questionTotal}</div>
              </div>
              <div className='evaluation_result'>
                <div className='great'>{`¡Ya realizaste tu partida diaria de la categoría ${categoryName}!`}</div> 
                <div className='position'>Revisa tu posición en el ranking</div> 
                <div className='points'>Puntaje: {scoreResult}</div> 

                <div className={`bt_next bt_close_trivia`} onClick={ handleClose }>
                  Cerrar
                </div>

              </div>
            </Fragment> : ""
              
            
          }


      </div>
    </EvaluationStyled>
  )
}

export default TriviaContainer;
