import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import ReactPlayer from 'react-player';
import { AudioPlayer } from '../../_elements';
import { MdZoomOutMap,
         MdZoomInMap } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../../_context';
import { getModulesStatus,
         getModulesStatusFromCourse,
         STATUS_COURSE_BLOCKED,
         STATUS_COURSE_UNLOCKED,
         STATUS_COURSE_VIEWED,
         STATUS_COURSE_COMPLETED,
         STATUS_COURSE_REPROVED,
         STATUS_COURSE_PENDING_CONNECTION,
         STATUS_COURSE_IN_PROGRESS } from '~/_helpers/commons';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .image_viewer{
    position: relative;
    width: 100%;
    height: 100%;
    .image_zoom{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 23px;
      background-color: #FFFFFF;
      color: #000000;
      width: 40px;
      height: 40px;
      cursor: pointer;
      :hover{
        background-color: rgba(0, 0, 0, 0.7);
        color: #FFFFFF;
      }
    }
    .image{
      width: 100%;
      img{
        float: left;
        width: 100%;
      }
    }
  }
  .react-player{
    background-color: #000000;
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const DialogStyled = styled(Dialog)`
  .MuiPaper-root{
    max-width: 800px!important;
    .dialog_image{
      width: 100%;
    }
    img{
      float: left;
      width: 100%;
    }
    .image_zoom{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 23px;
      background-color: #FFFFFF;
      color: #000000;
      width: 40px;
      height: 40px;
      cursor: pointer;
      :hover{
        background-color: rgba(0, 0, 0, 0.7);
        color: #FFFFFF;
      }
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`



const ContentType = (props) => {

  const { _id,
          index,
          media,
          course,
          className,
          history,
          location,
          playStatus,
          setPlayStatus,
          isLastSlide,
          setIsLastSlide,
          lastSlideIndex,
          handleRefreshData,
          handleTakeEvaluation } = props;

  const { save_content_viewed } = useContext(ContentContext);

  const theme = useTheme();
  const [ openDialogImage, setOpenDialogImage ] = React.useState(false);
  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const [ _playing, set_Playing ] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));



  const _player = useRef(null);
  const trainingEl = useRef(null);

  const handleOnReady = (e) => {

  }
  const handleVideoSeek = (e) => {

  }

  const handleVideoStop = () => {

  }

  const handleVideoStart = () => {

  }

  const handleVideoPlay = () => {
    //alert("asd")
    //_player.current.currentTime = 0;
    setPlayStatus(true);
  }

  const handleProgress = (e) => {
    let progress = ((e.target.currentTime / e.target.duration) * 100);
    if(!onVideoEnded && progress >= 95){
      setOnVideoEnded(true);
    };
  }
  const handleVideoEnded = () => {
    
    // let modules_arr = [];
    // let next_module_id = null;
    // let next_module_index = null;
     
    if(course.status === STATUS_COURSE_COMPLETED || course.status === STATUS_COURSE_VIEWED){
      return null;
    }

    const status_data = getModulesStatusFromCourse(course, _id);
    //console.log("status_data", status_data)
    /*
    course.moduls.map((module, index) => {

      if(module._id === _id){
        if(course.progressive_mode){
          if(course.moduls[index+1]){
            next_module_id = course.moduls[index+1]._id;
          }
        }
        modules_arr.push( { _id: module._id, status: STATUS_COURSE_VIEWED } );
      }else{
        modules_arr.push( { _id: module._id, status: module.status } );
      }

    })

    let course_status = STATUS_COURSE_COMPLETED;

    modules_arr.forEach(module => {
      if(module.status !== STATUS_COURSE_VIEWED && module.status !== STATUS_COURSE_COMPLETED){
        course_status = STATUS_COURSE_IN_PROGRESS;
      }
      if(next_module_id){
        if(next_module_id === module._id){
          module.status = STATUS_COURSE_UNLOCKED;
        }
      }
    });
    */

    
    if(index === lastSlideIndex && isLastSlide){
      const payload = { course_id: course._id, moduls: JSON.stringify(status_data.modules_arr), status: status_data.course_status }
      const response = save_content_viewed(payload);
      response.then(res => {
        handleRefreshData(payload);
        handleTakeEvaluation(_id);
      })
    }
  }

  useEffect(() => {
    if(onVideoEnded){
      handleVideoEnded();
    }
    return () => {}
  }, [onVideoEnded])

  useEffect(() => {
    if(playStatus === false){
      if(_player){
        _player.current.pause();
        _player.current.currentTime = 0;
      }
    }

    if(media.content_type.toLowerCase() == "video"){

    }

    return () => {}
  }, [playStatus])

  const handleClickOpen = () => {
    setOpenDialogImage(true);
  };

  const handleClose = () => {
    setOpenDialogImage(false);
  };


  useEffect(() => {
    if(index === lastSlideIndex && isLastSlide){

    }
    return () => {
    }
  }, [isLastSlide])

  useEffect(() => {

    // document.addEventListener("contextmenu", (event) => {
    //   event.preventDefault();
    // });

    return () => {

      // document.removeEventListener("contextmenu", (event) => {
      //   event.preventDefault();
      // });

    }
  }, [])


  return (

    <WrapperStyled>

      <video
        ref={_player}
        id="miVideo"
        controls
        controlsList="nodownload"
        width="100%"
        height="100%"
        className={`react-player`}
        onPlay={handleVideoPlay}
        onTimeUpdate={handleProgress}
        onContextMenu={(event)=>event.preventDefault()}
      >
        <source src={`${media.media}#t=01`} type="video/mp4" />
      </video>

    </WrapperStyled>

  )
}

export default ContentType;
