import React, { useCallback } from 'react';
import styled from 'styled-components';
//import { useHistory } from 'react-router';

import { breakpoints } from '../../../_helpers/breakpoints';

const Wrapper = styled.ul`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;


`
const AnswerOption = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  :last-child {
    margin-bottom: 0;
  }

  .bullet{
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #ccc;
  }
  .description{
    display: flex;
    margin-left: 7px;
    font-size: 16px;
    font-weight: 600;
  }
  :hover{
    background-color: #FFD200;
    .bullet{
      border-color: #FFFFFF;
    }
  }
  &.selected{
    background-color: #FFD200;
    .bullet{
      border-color: #FFFFFF;
      background-color: #FFFFFF;
    }
  }
`


const MultipleChoice = (props) => {

  const { answers, setAnswerSelected, answerSelected } = props;

  const handleClick = useCallback((id) => {
    setAnswerSelected(id);

  }, []);


  return (
    <Wrapper breakpoints={breakpoints} className="choice_element">

      {answers ?
        answers.map((item, index) => {
          return(
            <AnswerOption key={index} className={answerSelected == item._id ? 'selected' : ''} onClick={(e) => handleClick(item._id)}>
              {/*<div className="bullet"></div>*/}
              <div className="description">
                {item.text}
              </div>
            </AnswerOption>
          )
        })
        :
        <div>loading</div>
      }


    </Wrapper>
  )

}

export default MultipleChoice;
