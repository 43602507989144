/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import storage from 'local-storage-fallback';
import { aws4Interceptor } from "aws4-axios";

const connect = axios.create({
  timeout: 30000,
  headers: {
  },
});

const interceptor = aws4Interceptor(
  {
    region: "us-east-2",
    service: "execute-api",
  },
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_KEY,
  }
)

connect.interceptors.request.use(
  config => {

    const token = storage.getItem('protagonistas_token');

    if (token) {
    } else { }

    return config;
  },
  () => {
    return null;

  },
);

connect.interceptors.request.use(interceptor);

connect.interceptors.response.use(
  res => {
    if(res.data.status === -2){
      return null;
    }
    return res;
  },
  async error => {
    return Promise.reject(error);
  },
);

export default connect;
