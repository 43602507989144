import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa";

import CircularProgress from '@material-ui/core/CircularProgress';

import { } from '../../_elements';

import { ContentContext } from '../../_context';

const WrapperStyled = styled.div`

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 25px;
    margin-top: 10px;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #f1f1f1;/* */
    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      font-size: 20px;
      line-height: 20px;
    }
    .number{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 30px;;
        p{
            margin: 0;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
        }
    }
    .time_bar{
      
      width: calc(100% - 55px);
      box-sizing: border-box;
      .base{
        box-sizing: border-box;
        position: relative;
        .inner_base{
          position: absolute;
          margin-top: 5px;
          width: calc(100% - 10px);
          margin-left: 5px;
          .bar{
            width: ${props => props._progress}%;
            height: 15px;
            transition: width 0.5s;
            /* background-color: #FFC600; */
            background: rgb(216,1,15);
            background: -moz-linear-gradient(118deg, rgba(216,1,15,1) 0%, rgba(233,154,0,1) 100%);
            background: -webkit-linear-gradient(118deg, rgba(216,1,15,1) 0%, rgba(233,154,0,1) 100%);
            background: linear-gradient(118deg, rgba(216,1,15,1) 0%, rgba(233,154,0,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d8010f",endColorstr="#e99a00",GradientType=1);
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }

`

const Timer = (props) => {

    const { isPlaying,
            setIsPlaying,
            count,
            setCount,
            initTimer,
            onTimeEnd,
            timeOut,
            setTimeOut } = props

    const { evaluationActive,
            setEvaluationActive,
            evaluation,
            setEvaluation,
            take_user_evaluation } = useContext(ContentContext);
    
    const [ progress, setProgress ] = useState(100);

    useEffect(() => {

        let countdown;
        if (isPlaying) {
            countdown = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
        }
        return () => clearInterval(countdown);

    }, [isPlaying]);

    useEffect(() => {

        console.log("count", count)
        if(count !== null){
            const porcentaje = (count / initTimer) * 100;
            //console.log("porcentaje", porcentaje)
            setProgress(porcentaje);
            //setProgress((prevProgress) => prevProgress - porcentaje);
            if(count === 0){
                setTimeOut(true);
                onTimeEnd();
                setIsPlaying(false);
                setCount(initTimer);
            }
        }
        return () => {}

    }, [count])

    return (
        <WrapperStyled 
            className={''}
            _progress={progress}
        >
            <div className='icon'>
                <FaRegClock />
            </div>
            <div className='number'>
                <p>{count}</p>
            </div>
            <div className='time_bar'>
                <div className='base'>
                    <div className='inner_base'>
                    <div className='bar'></div>
                    </div>
                </div>
            </div>

        </WrapperStyled>
    )
}

export default Timer;
