import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Transition, Spring, animated, config } from 'react-spring'
import { breakpoints } from '../../_helpers/breakpoints';
import { ContentContext } from '../../_context';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div`

    position: relative;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 600px;

    .ico_laptop{
      position: absolute;
      width: 100%;
      img{
        float: left;
        width: 100%;
      }
    }
    .ico_avion{
      position: absolute;
      width: 170px;
      right: 0;
      top: 30px;
      img{
        float: left;
        width: 100%;
      }
    }
    .ico_joystick{
      position: absolute;
      width: 80px;
      left: 30px;
      top: 110px;
      img{
        float: left;
        width: 100%;
      }
    }
    .ico_bidon{
      position: absolute;
      width: 90px;
      left: 140px;
      top: 120px;
      img{
        float: left;
        width: 100%;
      }
    }
    .ico_surtidor{
      position: absolute;
      width: 130px;
      left: 5px;
      top: 210px;
      img{
        float: left;
        width: 100%;
      }
    }
    .ico_valija{
      position: absolute;
      width: 90px;
      right: 25px;
      top: 280px;
      img{
        float: left;
        width: 100%;
      }
    }
    .ico_tickets{
      position: absolute;
      width: 130px;
      left: 5px;
      top: 400px;
      img{
        float: left;
        width: 100%;
      }
    }
    .ico_popcorn{
      position: absolute;
      width: 220px;
      right: 0;
      top: 500px;
      img{
        float: left;
        width: 100%;
      }
    }


    //background-color: black;

    //background-color: grey;

    @media ${breakpoints.sm} {
      display: flex;
    }
    @media ${breakpoints.md} {
    }
    @media ${breakpoints.lg} {
    }

`

const ShellBoxAddon = (props) => {
  const { sessionsStatData,
          coursesStatData,
          showMoreStats } = useContext(ContentContext);

  const { search, pathname } = useLocation();
  const [ show, setShow ] = useState(true);
  const [ showSection, setShowSection ] = useState(false);

  useEffect(() => {

    if(pathname){
      if(pathname == '/shellbox'){
        setShowSection(true);
      }else{
        setShowSection(false);
      }
    }

    return () => { }
  }, [pathname])

  /*
  if(!showSection){
    return null;
  }
  */

  return (
    <Wrapper breakpoints={breakpoints}>

      <Spring
        delay={200}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_laptop"><animated.div style={styles}><img src="/images/img_shellbox_icons.png" /></animated.div></div>}
      </Spring>

      {/*
      <Spring
        delay={700}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_avion"><animated.div style={styles}><img src="/images/Avion0000.png" /></animated.div></div>}
      </Spring>

      <Spring
        delay={1200}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_joystick"><animated.div style={styles}><img src="/images/Joystick0000.png" /></animated.div></div>}
      </Spring>

      <Spring
        delay={1700}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_bidon"><animated.div style={styles}><img src="/images/Bidon0000.png" /></animated.div></div>}
      </Spring>

      <Spring
        delay={2100}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_surtidor"><animated.div style={styles}><img src="/images/Surtidor0000.png" /></animated.div></div>}
      </Spring>

      <Spring
        delay={2400}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_valija"><animated.div style={styles}><img src="/images/Valija0000.png" /></animated.div></div>}
      </Spring>

      <Spring
        delay={2800}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_tickets"><animated.div style={styles}><img src="/images/Tickets0000.png" /></animated.div></div>}
      </Spring>

      <Spring
        delay={3000}
        from={{ opacity: 0, filter: "blur(20px)" }}
        to={[
          { opacity: 1, filter: "blur(0)" }
        ]}
        config={config.molasses}
        >
        {styles => <div className="ico_popcorn"><animated.div style={styles}><img src="/images/Popcorn0000.png" /></animated.div></div>}
      </Spring>
      */}

    </Wrapper>
  )

}

export default ShellBoxAddon;
