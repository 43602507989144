import React, { useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import useMediaQuery from "../../_hooks/useMediaQuery";
import { ButtonYellow } from '../../_elements';
import { COLORS } from '../../_helpers/constants';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    color: ${COLORS.primary};

    .line_top{
      margin-bottom: 5px;
    }
    .line_bottom{
      margin-top: 10px;
    }
    .text{
      margin-bottom: 10px;
      p{
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
      }
    }
    .bt_download{

    }
    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`
const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const LoginStats = (props) => {

  const isSm = useMediaQuery('(min-width: 768px)');

  const { showMoreStats,
          setShowMoreStats, } = useContext(ContentContext);

  const { data } = props;

  const handleShowMoreStats = () => {
    setShowMoreStats(false);
  }

  if(!showMoreStats){
    return null;
  }

  return (
    <Wrapper breakpoints={breakpoints}>
      {/*
      <div className="line_top">
        <img src="/images/lineaescudo.svg" />
      </div>
      */}
      <div className="text">
        <p>Comunicación Promoción</p>
      </div>
      <ButtonYellow
          className="bt_download"
          onClick={() => window.open("https://drive.google.com/drive/folders/1G31Y7p5LAEtGoaWeszGdhn6BI9dtsc7O?usp=sharing") }
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
        >
          <p>Descargar</p>
      </ButtonYellow>
      {/*
      <div className="line_bottom">
        <img src="/images/lineaescudo.svg" />
      </div>
      */}
    </Wrapper>
  )

}

export default LoginStats;
