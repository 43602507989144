import React, { useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '~/_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '~/_elements';

import ChangePassword from '~/_components/Profile/ChangePassword';

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import firebase from '~/firebase';
import { getAuth, RecaptchaVerifier } from "firebase/auth";

//import { ContentContext } from '~/_context';
import { AuthContext } from '~/_context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const WrapperLogin = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 90vw;//calc(100% - 30px);
  padding-top: 10px;
  padding-bottom: 50px;
  //background-color: gray;

  @media ${breakpoints.xs} {
    width: 500px;
  }
  @media ${breakpoints.sm} {
    width: 768px;
  }
  @media ${breakpoints.md} {
    width: 951px;
  }
  @media ${breakpoints.lg} {
    width: 1160px;
  }
`

const ModalOtpStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .inner_modal_validate_phone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;
    .loader_code{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      svg{
        color: #FFD200;
      }
    }
    .contentPassword{
      .bt_send_password{
        width: 100%!important;
      }
    }
    .field{
      display: flex;
      flex-direction: column;
      width: 100%;
      label{
        margin-bottom: 5px;
        font-size: 3vw;
        font-weight: 500;
      }
      input{
        border: 1px solid #e1e3eb;
        padding: 2vw 3vw 2vw 3vw;
      }
      .box_auth_code{
        width: 80vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input{
          width: 12vw;
          height: 14vw;
          border-radius: 10px;
          box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.12);
          font-size: 8vw;
          line-height: 14vw;
          font-weight: 500;
          text-align: center;
        }
      }
    }
    .actions{
      margin-top: 10px;
      .loader{
        svg{
          width: 33px;
          height: 33px;
          color: #CCCCCC!important;
        }
      }
    }

    .errorMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      border: 1px dashed #F20000;
      background-color: #FF9494;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
      //color: #F20000;
      p{

      }
    }
    .successMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      background-color: #B8FF6D;
      //#F7DECC,#AD8D75,#B8FF6D,#1B110A,#E0C0A8,#F7DECC,#AD8D75,#B8FF6D
      border: 1px dashed #33E46D;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
    }
    .error_code_message{
      width: 100%;
      text-align: center;
      font-weight: 600;
      color: #000000;
    }
  }

  @media ${breakpoints.xs} {
    .inner_modal_validate_phone{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 450px;
      padding: 25px;

      .field{
        display: flex;
        flex-direction: column;
        width: 100%;
        label{
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 500;
        }
        input{
          border: 1px solid #e1e3eb;
          padding: 10px 15px 10px 15px;
        }
        .box_auth_code{
          width: 400px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          input{
            width: 60px;
            height: 70px;
            border-radius: 10px;
            box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.12);
            font-size: 40px;
            line-height: 70px;
            font-weight: 500;
            text-align: center;
          }
        }
      }
    }
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`

const Otp = (props) => {

  const { openOtp, setOpenOtp, phone, setUserPhone, dni } = props;
  //const { changePassword } = useContext(ContentContext);
  const { changePasswordRecover } = useContext(AuthContext);

  const confirmationResultRef = useRef(null);
  const reCAPTCHAsolvedStatus = useRef(false);
  const reCAPTCHARef = useRef(null);

  //const [phone, setPhone] = useState("");
  //const [open, setOpen] = useState(openOtp);
  const handleOpen = () => setOpenOtp(true);
  const handleClose = () => setOpenOtp(false);

  const [loadingFormPhone, setLoadingFormPhone] = useState(false);
  const [loadingFormCode, setLoadingFormCode] = useState(false);
  const [successFormPhone, setSuccessFormPhone] = useState(null);
  const [successFormCode, setSuccessFormCode] = useState(null);

  //const [reCAPTCHAsolved, setReCAPTCHAsolved] = useState(false);

  const [showChangePass, setShowChangePass] = useState(false);
  const [showAddPhone, setShowAddPhone] = useState(false);
  const [showAddCode, setShowAddCode] = useState(null);
  const [showErrorNoCode, setShowErrorNoCode] = useState(false);

  const [authCode1, setAuthCode1] = useState("");
  const [authCode2, setAuthCode2] = useState("");
  const [authCode3, setAuthCode3] = useState("");
  const [authCode4, setAuthCode4] = useState("");
  const [authCode5, setAuthCode5] = useState("");
  const [authCode6, setAuthCode6] = useState("");

  const [ haveErrorAuthCode, setHaveErrorAuthCode ] = useState({
    authCode1: { status: false, message: "" },
    authCode2: { status: false, message: "" },
    authCode3: { status: false, message: "" },
    authCode4: { status: false, message: "" },
    authCode5: { status: false, message: "" },
    authCode6: { status: false, message: "" },
	});

  const [ haveError, setHaveError ] = useState({
    phone: { status: false, message: "" },
    code: { status: false, message: "" },
	});

  const handleSendPhone = (e) => {
    e.preventDefault();

    let error = false;

    resetFormErrorObject();

    let haveErrorProcess = {...haveError};

    if(phone.length <= 0){
      error = true;
      haveErrorProcess.phone.status = true;
    }

    setHaveError(haveErrorProcess);

    if(error){
      return null;
    }

    onSignInSubmit();


  }



  const resetFormErrorObject = () => {

    if (Object.keys(haveError).length) {
      Object.keys(haveError).map(function (key, index) {
        haveError[key] = { status: false };
      });
    }

  }


  const setUpRecaptcha = () => {

    if(!window.recaptchaVerifier){
      const auth = getAuth();
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          reCAPTCHAsolvedStatus.current = true;
        }
      }, auth);
    }

  }

  const onSignInSubmit = () => {

    setLoadingFormPhone(true);

    setUpRecaptcha();
    setUserPhone(null);
    const appVerifier = window.recaptchaVerifier;
    reCAPTCHARef.current = appVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((confirmationResult) => {

        setSuccessFormPhone(true);
        setLoadingFormPhone(false);

        setShowAddPhone(false);
        setShowAddCode(true);

        confirmationResultRef.current = confirmationResult;

      }).catch((error) => {

        switch(error.code) {
          case "auth/too-many-requests":

            setSuccessFormPhone(false);
            setTimeout(closeFormErrorMessage, 3500);

            break;
        }


        resetCaptcha();
        setShowAddCode(false);
        setShowErrorNoCode(true);
        setLoadingFormPhone(false);

      });

  }

  const resetCaptcha = () => {

  }

  const sendConfirmationCode = () => {

    setLoadingFormCode(true);
    const otpCode = authCode1 + authCode2 + authCode3 + authCode4 + authCode5 + authCode6;

    window.confirmationResult = confirmationResultRef.current;
    confirmationResultRef.current.confirm(otpCode).then((result) => {

      setLoadingFormCode(false);
      setSuccessFormCode(true);
      setShowAddCode(false);
      setShowChangePass(true);

    }).catch((error) => {

      setSuccessFormCode(false);
      setLoadingFormCode(false);
      setTimeout(closeFormErrorMessage, 3500);

    });

  }


  function closeFormErrorMessage() {
    setSuccessFormCode(null);
    setSuccessFormPhone(null);
  }

  const handleOnChangeAuthCode = (e) => {

    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    switch (name) {
      case "authCode-1":
        setAuthCode1(value);
        break;
      case "authCode-2":
        setAuthCode2(value);
        break;
      case "authCode-3":
        setAuthCode3(value);
        break;
      case "authCode-4":
        setAuthCode4(value);
        break;
      case "authCode-5":
        setAuthCode5(value);
        break;
      case "authCode-6":
        setAuthCode6(value);
        break;

      default:
        break;
    }

    const nextfield = document.querySelector(
      `input[name=authCode-${fieldIntIndex + 1}]`
    );

    if (nextfield !== null) {
      nextfield.focus();
    }

  }

  useEffect(() => {

    if(openOtp == false){

      if(window.recaptchaVerifier){
        resetCaptcha();
      }

      setUserPhone(null);

      setLoadingFormPhone(false);
      setLoadingFormCode(false);
      setSuccessFormPhone(null);
      setSuccessFormCode(null);

      setShowChangePass(false);
      setShowAddPhone(false);
      setShowAddCode(null);
      setShowErrorNoCode(false);

      setAuthCode1("");
      setAuthCode2("");
      setAuthCode3("");
      setAuthCode4("");
      setAuthCode5("");
      setAuthCode6("");

      confirmationResultRef.current = null;
      reCAPTCHAsolvedStatus.current = false;
      reCAPTCHARef.current = null;

      setHaveErrorAuthCode({
        authCode1: { status: false, message: "" },
        authCode2: { status: false, message: "" },
        authCode3: { status: false, message: "" },
        authCode4: { status: false, message: "" },
        authCode5: { status: false, message: "" },
        authCode6: { status: false, message: "" },
      });

      setHaveError({
        phone: { status: false, message: "" },
        code: { status: false, message: "" },
      });
    }

    return () => {}
  }, [openOtp])

  useEffect(() => {

    if(phone){
      onSignInSubmit();
    }
    return () => {}
  }, [phone])

  return (
    <Wrapper breakpoints={breakpoints}>

      <ModalOtpStyled
        open={openOtp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openOtp}>

            <div className="inner_modal_validate_phone">

              {/*showAddPhone ?
              <>
                <div className="field">
                    <label htmlFor="phone">Ingresá tu teléfono</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      className={haveError.phone.status ? "is_invalid" : ""}
                    />
                </div>


                {successFormPhone != null && !successFormPhone ?
                  <div className="errorMessages">
                    <div className="innerErrorMessages">
                      HUBO UN ERRRO, VOLVE A INTENTARLO MAS TARDE
                    </div>
                  </div>
                  : <></>
                }


                <div className="actions">

                {loadingFormPhone ?
                <div className="loader">
                  <CircularProgress size={33} />
                </div>
                :


                  <ButtonYellow
                    className="bt_save"
                    onClick={handleSendPhone}
                    disabled={false}
                    css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                  >
                    <p>Enviar</p>
                  </ButtonYellow>
                }
                </div>
              </>
              : <></>*/}

              {showAddCode == null &&
              <div className="loader_code">
                <CircularProgress size={30} />
              </div>
              }

              {showErrorNoCode ?
              <div className="error_code_message">Hubo un error, inténtalo más tarde o para recuperar tu cuenta, te solicitamos que escribas a contacto@somosprotagonistas.com.ar con tus datos personales.</div>
              : <></>
              }

              {showAddCode ?
              <>
                {successFormCode == null || !successFormCode ?
                <div className="field">
                    <label htmlFor="phone">Ingresá el código que recibiste en tu télefono</label>
                    <div className="box_auth_code">
                      <input
                        type="text"
                        id="authCode-1"
                        name="authCode-1"
                        maxLength="1"
                        onChange={/*(e) => setAuthCode1(e.target.value)*/ handleOnChangeAuthCode}
                        value={authCode1}
                        //disabled={!successFormPhone}
                        className={haveErrorAuthCode.authCode1.status ? "is_invalid" : ""}
                      />
                      <input
                        type="text"
                        id="authCode-2"
                        name="authCode-2"
                        onChange={/*(e) => setAuthCode1(e.target.value)*/ handleOnChangeAuthCode}
                        value={authCode2}
                        //disabled={!successFormPhone}
                        className={haveErrorAuthCode.authCode2.status ? "is_invalid" : ""}
                      />
                      <input
                        type="text"
                        id="authCode-3"
                        name="authCode-3"
                        onChange={/*(e) => setAuthCode1(e.target.value)*/ handleOnChangeAuthCode}
                        value={authCode3}
                        //disabled={!successFormPhone}
                        className={haveErrorAuthCode.authCode3.status ? "is_invalid" : ""}
                      />
                      <input
                        type="text"
                        id="authCode-4"
                        name="authCode-4"
                        onChange={/*(e) => setAuthCode1(e.target.value)*/ handleOnChangeAuthCode}
                        value={authCode4}
                        //disabled={!successFormPhone}
                        className={haveErrorAuthCode.authCode4.status ? "is_invalid" : ""}
                      />
                      <input
                        type="text"
                        id="authCode-5"
                        name="authCode-5"
                        onChange={/*(e) => setAuthCode1(e.target.value)*/ handleOnChangeAuthCode}
                        value={authCode5}
                        //disabled={!successFormPhone}
                        className={haveErrorAuthCode.authCode5.status ? "is_invalid" : ""}
                      />
                      <input
                        type="text"
                        id="authCode-6"
                        name="authCode-6"
                        onChange={/*(e) => setAuthCode1(e.target.value)*/ handleOnChangeAuthCode}
                        value={authCode6}
                        //disabled={!successFormPhone}
                        className={haveErrorAuthCode.authCode6.status ? "is_invalid" : ""}
                      />
                    </div>
                </div>
                :<></>
                }

                {successFormCode != null && !successFormCode ?
                  <div className="errorMessages">
                    <div className="innerErrorMessages">
                      EL CODIGO INGRESADO ES INCORRECTO.
                    </div>
                  </div>
                  : <></>
                }

                <div className="actions">

                  {loadingFormCode ?
                  <div className="loader">
                    <CircularProgress size={33} />
                  </div>
                  :

                  <ButtonYellow
                    className="bt_save"
                    onClick={() => sendConfirmationCode()}
                    disabled={false}
                    css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                  >
                    <p>Enviar Código</p>
                  </ButtonYellow>
                  }
                </div>

              </>
              : <></>}

              {showChangePass ?
              <ChangePassword requestTo={changePasswordRecover} dni={dni} scope={"forgot"} />
              :<></>
              }


            </div>

        </Fade>

      </ModalOtpStyled>
      <div id="recaptcha-container"></div>
    </Wrapper>
  )

}

export default Otp;
