import React, { useRef, useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { breakpoints } from '../../_helpers/breakpoints';
import storage from 'local-storage-fallback';
import { saveAs } from 'file-saver';

import { SideLeft,
         SideRight,
         DashboardStats,
         Trivia } from '../../_components';

import { IcoCheck,
         IcoChevronLeft,
         IcoPDFFile,
         COLORS } from '../../_helpers/constants';

import { ImageRound,
         ButtonYellow,
         AudioPlayer } from '../../_elements';

import { getTrainingCategoryID,
         getTrainingID} from '../../_helpers/commons';

import { Modal, Fade, Backdrop, Box } from '@material-ui/core';

import 'react-alice-carousel/lib/alice-carousel.css';

import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { ContentContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const Wrapper = styled.div`

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  //padding-top: 15px;
  padding-bottom: 15px;
  .sideright{
    display: flex;
    flex-direction: column;
  }
  .image_training{
    width: 100%;
    img{
      width: 100%;
      float: left;
    }
  }
`
const TrainingVideo = styled.div`
  display: flex;
  width: 100%;
  .player-wrapper{
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 25px;
    .react-player{
      display: flex;
      width: 100%;
    }
  }
`
const TrainingData = styled.div`

  display: flex;
  width: 100%;
  margin-top: 20px;

  .data{

    display: flex;
    width: 100%;
    flex-direction: column;

    .tag_complete{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4C8F05;
      width: 90px;
      height: 20px;
      border-radius: 4px;
      margin-top: 10px;
      p{
        margin: 0;
        padding-top: 1px;
        font-size: 11px;
        line-height: 11px;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .description{
      margin-top: 15px;
      font-size: 13px;
      line-height: 21px;
      p{
        color: ${COLORS.primary};
        margin: 0;
      }
    }

    .bt_cuestionario{
      &.MuiButton-root{
        &.Mui-disabled{
          color: #000000!important;
          background-color: #FFFFFF!important;
          border: 1px solid #000000!important;
        }
      }
      p{
        padding: 0;
        margin: 0;
      }
      .ico{
        display: flex;
        margin-left: 7px;
        svg{
          height: 19px;
          path{
            fill: #000000;
          }
        }
      }
      :hover{
        .ico{
          svg{
            path{
              fill: #FFD200;
            }
          }
        }
      }
    }
    .bt_videos{
      margin-left: 10px;
      width: 130px;
      &.MuiButton-root{
        &.Mui-disabled{
          color: #000000!important;
          background-color: #FFFFFF!important;
          border: 1px solid #000000!important;
        }
      }
      p{
        padding: 0;
        margin: 0;
      }
      .ico{
        display: flex;
        margin-left: 7px;
        svg{
          height: 19px;
          path{
            fill: #000000;
          }
        }
      }
      :hover{
        .ico{
          svg{
            path{
              fill: #FFD200;
            }
          }
        }
      }
    }
    .training_bottom_actions{
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
      &.spacebetween{
        justify-content: space-between;
      }
    }
    .data_inner{

      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .avatar_image{
        display: flex;
      }
      .training_data{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .title{
          color: ${COLORS.primary};
          font-size: 15px;
          font-weight: 600;
        }
        .module_number{
          font-size: 13px;
          font-weight: 600;
          color: #BEBEBE;
        }
      }

      .training_actions{
        display: flex;
        margin-left: auto;

        .box_like{
          display: flex;
          flex-direction: row;
          margin-right: 10px;
          .like_count{
            display: flex;
            flex-direction: row;
            align-items: center;
            p{
              margin: 0;
              padding: 0;
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
            }
          }


          .bt_like{
            margin-right: 10px;
            cursor: pointer;
            svg{
              fill: #000000;
            }
            :hover{
              svg{
                fill: #FFD200;
              }
            }
          }

          .bt_dislike{
            margin-right: 10px;
            cursor: pointer;
            svg{
              fill: #FFD200;
            }
            :hover{
              svg{
                fill: #000000;
              }
            }
          }
        }
        .bt_materials{
          display: flex;
          flex-direction: row;
          cursor: pointer;
          svg{
            transition: none;
            fill: #000000;
          }
          p{
            margin: 0 5px 0 0;
            padding: 0;
            font-weight: 600;
          }
          :hover{
            svg{
              fill: #FFD200;
            }
            p{
              color: #FFD200;
            }
          }
        }
      }

    }

  }
`
const ContainerTrivia = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background-color: #FFFFFF;
  border-radius: 0;
  .inner_trivia{
    width: 100%;
    padding: 30px;
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

    width: 680px;
    min-height: 200px;
    border-radius: 16px;

  }
  @media ${breakpoints.md} {


  }
  @media ${breakpoints.lg} {


  }
`
const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

    justify-content: center;
    align-items: center;
    overflow-y: hidden;

  }
  @media ${breakpoints.md} {


  }
  @media ${breakpoints.lg} {


  }
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`
const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const HeaderTraining = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  color: ${COLORS.primary};
  .cateogry_title{
    display: flex;
    margin-left: 10px;
    p{
      //color: #FFFFFF;
      margin: 0;
      font-size: 26px;
      line-height: 26px;
      font-weight: 400;
    }
  }
  .button_back{
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    p{
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 16px;
      margin-left: 7px;
      color: #5B5E5F;
    }
    .icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 2px solid #5B5E5F;
      //border: 2px solid color: ${COLORS.primary};
      width: 34px;
      height: 34px;
      border-radius: 17px;

      svg{
        height: 15px;
        path{
          fill: #5B5E5F!important;
          //fill: #FFFFFF!important;
        }
      }
    }

    :hover{
      .icon{
        border: 2px solid #FFD200;
        svg{
          path{
            fill: #FFD200!important;
          }
        }
      }
      p{
        color: #FFD200;
      }
    }

  }
`
const MenuStyled = styled(Menu)`
  .MuiList-root{
    padding: 0;
  }
  .MuiPaper-root{
    background-color: #F5F6FA!important;
    .MuiListItem-button{
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 20px;
      border-bottom: 1px solid #ced1db;
      :last-child{
        border-bottom: 0;
      }
      :hover{
        background-color: #FFD200;
      }
    }
  }
`
const MenuItemStyled = styled(MenuItem)`
  .icon{

    svg{
      width: 22px;
      fill: #000000;
    }
  }
  p{
    margin: 0;
    color: #000000;
    margin-left: 7px;
    font-size: 15px;
    line-height: 15px;
    padding-top: 1px;
  }

  //
`
const TrainingPodcast = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .image_podcast{
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    img{
      float: left;
      width: 100%;
    }
  }
  .audio_player{
    width: 100%;
    position: absolute;
    bottom: 0;
  }
`



const TrainingContainer = (props) => {

  const { history, location } = props;

  const handleOnEndedCalled = useRef(false);
  const [ onVideoProgressStatus, setOnVideoProgressStatus ] = useState(false);
  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const [ statusCuestionarioFinish, setStatusCuestionarioFinish ] = useState(null);
  const [ quizButtonOn, setQuizButtonOn ] = useState(false);
  const [ isPDF, setIsPDF ] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const postLiked = true;

  const { loading,
          trainings,
          setTrainings,
          getInternalCourses,
          trainingSelected,
          setTrainingSelected,
          trainingCategorySelected,
          setTrainingCategorySelected,
          setCampusCategorySelectedData,
          setVideoViewed,
          courseData,
          setCourseData,
          campusCategorySelectedData,
          updateTrainingCertificates,
          updateTrainingStates } = useContext(ContentContext);

  const [ course, setCourse ] = useState(null);
  const [ courseVideos, setCourseVideos ] = useState(null);
  const [ evaluationId, setEvaluationId] = useState(null);
  const [ videoSelected, setVideoSelected ] = useState(null);
  const [ moduloIDVideoSelected, setModuloIDVideoSelected ] = useState(null);
  const [ contentIDVideoSelected, setContentIDVideoSelected ] = useState(null);
  const [ courseComplete, setCourseComplete ] = useState(false);
  const [ trainingSelectedId, setTrainingSelectedId ] = useState(null);

  const [ menuMaterials , setMenuMaterials ] = useState(null);
  const [ materials , setMaterials ] = useState(null);

  const [ imageTraining, setImageTraining ] = useState(null);

  const trainingEl = useRef(null);

  /*
  const materials = [{title: "Nombre archivo 1", url: "/pdfs/guia_de_implementacion_shell_evolux.pdf", type: "pdf"},
                     {title: "Nombre archivo 2", url: "/pdfs/guia_de_implementacion_shell_evolux.pdf", type: "pdf"},
                     {title: "Nombre archivo 3", url: "/pdfs/guia_de_implementacion_shell_evolux.pdf", type: "pdf"},
                     {title: "Nombre archivo 4", url: "/pdfs/guia_de_implementacion_shell_evolux.pdf", type: "pdf"},
                     {title: "Nombre archivo 5", url: "/pdfs/guia_de_implementacion_shell_evolux.pdf", type: "pdf"}];
  */
  const handleClickMenuMaterials = (event) => {
    setMenuMaterials(event.currentTarget);
  };

  const handleCloseMenuMaterials = (file, name) => {
    if(file){
      window.open(file, '_blank').focus();
      //saveAs(file, name);
    }
    setMenuMaterials(null);
  };
  /*
  const downloadFileFromUrl = (url, fileName = 'descarga') => {
    saveAs(url, replaceSpacesForDashes(removeAccents(fileName)), { autoBom: true });
  };
  */


  const getCourseData = (trainingIndex) => {

    let tIndex = null;
    if(trainingSelected){
      tIndex = trainingSelected;
    }else{
      tIndex = trainingIndex;
    }

    const response = getInternalCourses(tIndex);
      response.then(res => {
        setCourse(res);
      })
  }

  useEffect(() => {

    if(trainings){

      let training_id = null;

      if(!trainingSelected){

        if(!trainingCategorySelected){

          const resTrainingCategoryID = getTrainingCategoryID(location.pathname, trainings, 'training');
          const resTrainingID = getTrainingID(location.pathname, resTrainingCategoryID.training.courses, 'training');

          setCampusCategorySelectedData(resTrainingCategoryID.training);
          setTrainingCategorySelected(resTrainingCategoryID.index);
          setTrainingSelected(resTrainingID.index);
          training_id = resTrainingID.training._id;
          setTrainingSelectedId(training_id);
        }


      }

      getCourseData(training_id);

    }

    return () => {
      setCourse(null);
    }
  }, [trainings])

  useEffect(() => {

    let complete = false;

    if(course){


      if(course.modulos.length){
        setCourseVideos(course.modulos[0].contenidos);
        setQuizButtonOn(course.modulos[0].all_content_see);

        if(course.evaluation_finish){
          complete = course.evaluation_finish?.status === 1 ? true : false;
          setStatusCuestionarioFinish(complete);
          showQuiz(course.modulos[0].contenidos);
        }else{
          complete = course.modulos[0].all_content_see;
        }

        if(course.modulos[0].contenidos.length){

          if(course.modulos[0].contenidos[0].tipo_contenido == 'video'){

            setContentIDVideoSelected(course.modulos[0].contenidos[0]._id);
            setModuloIDVideoSelected(course.modulos[0]._id);
            setVideoSelected(course.modulos[0].contenidos[0]);

          }else if(course.modulos[0].contenidos[0].tipo_contenido == 'podcast'){

            setContentIDVideoSelected(course.modulos[0].contenidos[0]._id);
            setModuloIDVideoSelected(course.modulos[0]._id);
            setVideoSelected(course.modulos[0].contenidos[0]);
            setImageTraining(course.img_portada);

          }else{



            setVideoSelected(course.modulos[0].contenidos[0]);
            setImageTraining(course.img_portada);
            setStatusCuestionarioFinish(complete);
            setQuizButtonOn(!complete);


            //handleViewedContent();
          }

          //

        }

      }

      if(course.materials.length){
        setMaterials(course.materials);
      }

      setCourseComplete(complete);

    }

    return () => {}
  }, [course])

  useEffect(() => {

    if(videoSelected){

      if(videoSelected.tipo_contenido == 'image'){
        //console.log("course", course);
        //console.log("videoSelected.tipo_contenido", videoSelected);
        //console.log("Trainings", trainings);
        //console.log("trainings[trainingCategorySelected].courses", trainings[trainingCategorySelected].courses);


        if(!videoSelected.already_seen){
          //alert("image")
          setCourseComplete(true);
          handleViewedContent();
          //getCourseData(videoSelected._id);

          if(trainings[trainingCategorySelected].courses){
            const updateData = trainings[trainingCategorySelected].courses.map((c, i) => {
              if(c._id == course.id_curse){
                c.status = true;
              }
              return c;
            });
            //console.log("updateData", updateData)
            setTrainings(trainings);
          }

          //setTrainings
        }
      }
    }

    return () => {}
  }, [videoSelected])

  const openQuiz = (id_evaluation) => {
    setEvaluationId(id_evaluation);
    setOpen(true);
  }
  const closeQuiz = () => {
    setOpen(false);
  }



  const handleFinish = (approve) => {
    if(course?.evaluation_finish?.status !== 1){
      const updateCourse = {...course};
      updateCourse.evaluation_finish.status = approve;
      setCourse(updateCourse);
      if(approve == 1){
        /*
        if(course.modulos[0].contenidos[0].tipo_contenido == 'image'){
          handleViewedContent();
        }
        */

        endTraining(course);
      }
    }
  }

  const handleApprovedTraining = (course) => {
    const newTrainings = trainings.map((training, i) => {
      training.courses.map((item, e) => {
        if(item._id == course.id_curse){
          item.status = 1;
        }
      })
      return training;
    })
  }

  const handleBack = () => {
    const backTo = storage.getItem('training_category_target');
    history.push(backTo);
  }

  const handlChangeVideo = (index, item) => {

    setOnVideoEnded(false);
    handleOnEndedCalled.current = false;

    setContentIDVideoSelected(item._id);
    //setModuloIDVideoSelected(course.modulos[0]._id);
    setVideoSelected(item);
  }

  const handleVideoProgress = ((progress) => {
    if(!onVideoEnded && progress.played >= 0.95){
      setOnVideoEnded(true);



    };
  });

  const handleVideoEnded = () => {
    const payload = {
      "id_module": moduloIDVideoSelected,
      "id_content": contentIDVideoSelected,
    }

    const response = setVideoViewed(payload);
    response.then(res => {
      if(!videoSelected.already_seen){
        const courses = courseVideos.map((item, index) => {
          if(item._id === videoSelected._id){
            item.already_seen = true;
          }
          return item;
        })
        setCourseVideos(courses);

      }
      showQuiz(course.modulos[0].contenidos);
    })


  }

  const handleViewedContent = () => {

    const payload = {
      "id_module": course.modulos[0]._id,
      "id_content": course.modulos[0].contenidos[0]._id,
    }

    const response = setVideoViewed(payload);
    response.then(res => {

      if(!videoSelected.already_seen){
        const courses = courseVideos.map((item, index) => {
          if(item._id === videoSelected._id){
            item.already_seen = true;
          }
          return item;
        })
        setCourseVideos(courses);

      }

      const resTrainingCategoryID = getTrainingCategoryID(location.pathname, trainings, 'training');
      const resTrainingID = getTrainingID(location.pathname, resTrainingCategoryID.training.courses, 'training');
      /*
      setCampusCategorySelectedData(resTrainingCategoryID.training);
      setTrainingCategorySelected(resTrainingCategoryID.index);
      setTrainingSelected(resTrainingID.index);
      */
      //setTrainingSelectedId(training_id);

      //alert(resTrainingID.training._id)

      const response = getInternalCourses(resTrainingID.training._id);
      response.then(res => {
        setCourse(res);
      })


      //showQuiz(course.modulos[0].contenidos);

    })

  }

  const handleViewedPodcastContent = () => {

    const payload = {
      "id_module": course.modulos[0]._id,
      "id_content": course.modulos[0].contenidos[0]._id,
    }

    const response = setVideoViewed(payload);
    response.then(res => {

      if(!videoSelected.already_seen){
        const courses = courseVideos.map((item, index) => {
          if(item._id === videoSelected._id){
            item.already_seen = true;
          }
          return item;
        })
        setCourseVideos(courses);

      }
      endTraining(course);
      //showQuiz(course.modulos[0].contenidos);

    })

  }

  const showQuiz = (courses) => {



    if(courses){
      let allVideosComplete = true;
      courses.map((video, index) => {
        if(!video.already_seen){
          allVideosComplete = false;
        }
      })



      if(allVideosComplete){
        if(!course.evaluation_finish){
          endTraining(course);
        }
      }

      setQuizButtonOn(allVideosComplete);
    }

  }

  useEffect(() => {
    if(onVideoEnded && !handleOnEndedCalled.current){
      handleVideoEnded();
      handleOnEndedCalled.current = true;
    }
    return () => {}
  }, [onVideoEnded])

  const handleVideoStart = () => {
    setOnVideoEnded(false);
    handleOnEndedCalled.current = false;
    setOnVideoProgressStatus(true);
  }

  const handleVideoStop = () => {
    setOnVideoEnded(true);
    handleOnEndedCalled.current = true;
    setOnVideoProgressStatus(false);
  }

  const endTraining = (course) => {

    getCourseData();
    handleApprovedTraining(course);
    updateTrainingCertificates(trainings, course.id_curse);
    updateTrainingStates(trainings);

  }


  /*
  if(!course){
    return null;
  }
  */
  return (

    <Wrapper>
      {
          course == null &&
          <ContainerSpinner>
            <CircularProgressStyled />
          </ContainerSpinner>
        }

        {course && /* course.modulos[0] && */
        <Fragment>

          <MenuStyled
            id="simple-menu"
            anchorEl={menuMaterials}
            keepMounted
            open={Boolean(menuMaterials)}
            onClose={() => handleCloseMenuMaterials(null, '')}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'center', horizontal: 'right'}}
            transformOrigin={{vertical: 'center', horizontal: 'right'}}
          >
            {materials ?
              materials.length ?
                materials.map((item, index) => {
                  return(
                    <MenuItemStyled onClick={() => handleCloseMenuMaterials(item.link_download, item.name)}>
                      <div className="icon">
                        <IcoPDFFile />
                      </div>
                      <p>{item.name}</p>
                    </MenuItemStyled>
                  )
                })
              :<div>no hay resultados</div>
            :<div>cargando</div>}
          </MenuStyled>

          <ModalStyled
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <ContainerTrivia>
                <div className="inner_trivia">
                  <Trivia closeQuiz={closeQuiz} id_evaluation={evaluationId} data={course} finish={(approve) => handleFinish(approve)} />
                </div>
              </ContainerTrivia>
            </Fade>

          </ModalStyled>

          <HeaderTraining>
            <div className="button_back" onClick={handleBack}>
              <div className="icon">
                <IcoChevronLeft />
              </div>
              {/*<p>Volver</p>*/}
            </div>
            <div className="cateogry_title">
              <p>Categoría: {campusCategorySelectedData?.level ? campusCategorySelectedData.level : ''}</p>
            </div>
          </HeaderTraining>
          {(videoSelected != null && videoSelected.tipo_contenido == 'video') &&
          <TrainingVideo>
            <div className='player-wrapper'>
              <ReactPlayer
                className='react-player'
                url={videoSelected.media/*course.modulos[0].contenidos[0].media*/}
                width='100%'
                height='100%'
                controls={true}
                onProgress={(progress) => { handleVideoProgress(progress)}}
                onPlay={handleVideoStart}
                onStart={handleVideoStart}
                onStop={handleVideoStop}
                config={{ file: {
                  attributes: {
                    controlsList: 'nodownload'
                  }
                }}}
              />
            </div>
          </TrainingVideo>
          }
          {(videoSelected != null && videoSelected.tipo_contenido == 'image') &&
          <div className="image_training">
            <img src={imageTraining} />
          </div>
          }




          {/*<AudioPlayer elRef={trainingEl} />*/}





          {/*
          <AudioSpectrumComponent width={trainingEl.current?.clientWidth} height={100} />
          <TrainingPodcast>
            <audio id="audio-element"
              src="/sample-mp4-file.mp4"
              autoplay
              controls
              durationchange={handleAudioChange}

            >
            </audio>
          </TrainingPodcast>
          */}






          {(videoSelected != null && videoSelected.tipo_contenido == 'podcast') &&
          <TrainingPodcast>
            <div className="image_podcast">
              <img src={imageTraining} />
            </div>
            <div className="audio_player">
              <AudioPlayer elRef={trainingEl} handleViewedContent={handleViewedPodcastContent} url={videoSelected.media}/>
            </div>
          </TrainingPodcast>
          }

          <TrainingData ref={trainingEl}>
            <div className="data">
              <div className="data_inner">
                <div className="avatar_image">
                  <ImageRound className="image_round" css_styles={{width: 45, height: 45}}>
                    <img src={course.icon/*course.modulos[0].icon*/} />
                  </ImageRound>
                </div>
                <div className="training_data">
                  <div className="title">{course.name}</div>
                  <div className="module_number">Módulo 1</div>
                </div>

                <div className="training_actions">
                  {/*
                  <div className="box_like">
                    {postLiked ?
                      <div className="bt_dislike" onClick={ () => alert("bt_dislike") }>
                        <ThumbUpAltIcon />
                      </div>
                      :
                      <div className="bt_like" onClick={ () => alert("bt_like") }>
                        <ThumbUpAltOutlinedIcon />
                      </div>
                    }
                    <div className="like_count">
                      <p>10 Me gusta</p>
                    </div>
                  </div>
                  */}
                  {/*materials &&
                   materials.length &&
                    <div className="bt_materials" onClick={handleClickMenuMaterials}>
                      <p>Documento{materials.length > 1 ? 's' : ''}</p>
                      <DescriptionOutlinedIcon />
                    </div>
                  */}

                </div>
              </div>



              <div className="description">
                <p>
                  {course.description_large/*course.modulos[0].description_large*/}
                </p>
              </div>

              {courseComplete &&
              <div className="tag_complete">
                <p>COMPLETO</p>
              </div>
              }


              {course?.modulos[0]?.contenidos.length > 1 &&
              <div className="training_bottom_actions">
                {courseVideos && courseVideos.map((item, index) => {
                  return(
                    <ButtonYellow
                      className="bt_videos"
                      onClick={() => {
                        //if(!item?.status){
                          handlChangeVideo(index, item)
                        //}
                      }}
                      //disabled={item?.status ? true : false}
                      css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                    >

                      <p>{ 'VIDEO ' + (index+1) }</p>
                      {item?.already_seen ?
                      <div className="ico">
                        <IcoCheck />
                      </div>
                      :<></>
                      }
                    </ButtonYellow>
                  )
                })}

              </div>
              }



              <div className={`training_bottom_actions ${materials && materials.length && 'spacebetween'}`}>
                {materials &&
                   materials.length &&
                    <ButtonYellow
                      className="bt_cuestionario"
                      onClick={handleClickMenuMaterials}
                      //disabled={!quizButtonOn ? true : course?.evaluation_finish?.status <= 0 ? false : true}
                      css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                    >

                      <div className="ico">
                        <DescriptionOutlinedIcon />
                      </div>
                      <p>{ `DOCUMENTO ${materials.length > 1 ? 'S' : '' }` }</p>

                    </ButtonYellow>
                }
                {course.evaluation_finish &&
                <ButtonYellow
                  className="bt_cuestionario"
                  onClick={() => {
                    //alert(course?.evaluation_finish?.status)
                    if(course?.evaluation_finish?.status !== 1){
                      openQuiz(course.evaluation_finish._id)
                    }
                  }}
                  disabled={!quizButtonOn ? true : course?.evaluation_finish?.status <= 0 ? false : true}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                >

                  <p>{ 'CUESTIONARIO' }</p>
                  {statusCuestionarioFinish ?
                  <div className="ico">
                    <IcoCheck />
                  </div>
                  :<></>
                  }
                </ButtonYellow>
                }
              </div>

            </div>
          </TrainingData>



        </Fragment>
        }
    </Wrapper>
  )
}

export default TrainingContainer;
