export default [
  ,"Boluda"
,"Puto"
,"Puta"
,"Trolo"
,"Trola"
,"Gay"
,"Concha"
,"Pene"
,"Vagina"
,"Pete"
,"Pt"
,"Mierda"
,"Culo"
,"Forro"
,"Forra"
,"Pedófilo"
,"Ql"
,"Culiao"
,"Pito"
,"Verga"
,"Estúpido"
,"Violador"
,"Parió"
,"Pelotudo"
,"Pelotuda"
,"Nazi"
,"Hitler"
,"Satán"
,"Satan"
,"Estúpido"
,"Tarado"
,"Idiota"
,"Boludo"
,"Boluda"
,"Lesbiana"
,"Huevon"
,"Retardada"
,"Retardado"
,"Isis"
,"Down"
,"Pis"
,"Meo"
,"Leche"
,"Sorete"
,"Tetas"
,"Huevos"
,"Testículos"
,"Escroto"
,"Falo"
,"Fellatio"
,"Porno"
,"Mariuhana"
,"Faso"
,"Cocaina"
,"Paco"
,"Fafafa"
,"Cobani"
,"Pija"
,"Garcha"
,"Poronga"
,"Cagada"
,"Cagar"
,"Forrada"
,"Boliguayo"
,"Guasca"
,"Wasca"
,"Waska"
,"Wask"
,"Pezones"
,"Chota"
,"Choto"
,"Chotada"
,"Pinchila"
,"Tilinga"
,"Cajeta"
,"Cajetudo"
,"Cajetuda"
,"Papuda"
,"Conchuda"
,"Conchudo"
,"Mogólico"
,"Mogolico"
,"Gordinegro"
,"Gordinegra"
,"Pendejo"
,"Pendeja"
,"Cogida"
,"Porro"
,"Falopa"
,"Falopero"
,"Merquero"
,"Maconia"
,"Porrito"
,"Fasito"
,"Cachucha"
,"Cachufleta"
,"Clítoris"
,"Orto"
,"Putita"
,"Putito"
,"Culito"
,"Lechita"
,"Lechoso"
,"Pedo"
,"Pedito"
,"Boludito"
,"Boludita"
,"Homosexual"
,"Droga"
,"Tragaleche"
,"Tragasable"
,"Conchita"
,"Salamín"
,"Jodete"
,"Joda"
,"Joder"
,"Guacho"
,"Guacha"
,"Wacho"
,"Wacha"
,"Wachin"
,"Guachin"
,"Gila"
,"Gilada"
,"Giles"
,"Papo"
,"Totó"
,"Papusa"
,"Sudaca"
,"Sudaka"
,"Sudak"
,"Merluza"
,"Tomón"
,"Drogadicto"
,"Ojete"
,"Ojetudo"
,"Ortudo"
,"Pajero"
,"Pajera"
,"Jeropa"
,"Masturbación"
,"Masturbar"
,"Masturbatorio"
,"Pajeando"
,"Farlopa"
,"Frula"
,"Caquero"
,"Pitito"
,"Pito"
,"Ortuda"
,"Bolas"
,"Empomar"
,"Cagón"
,"Cagon"
,"Cagadera"
,"Diarrea"
,"Bulto"
,"vulto"
,"Bultear"
,"Bagarto"
,"Bagayero"
,"Chapar"
,"tranzar"
,"Transar"
,"Transa"
,"Maricón"
,"Marica"
,"Ramera"
,"Desnudo"
,"Verguita"
,"Vuergudo"
,"Vergón"
,"berga"
,"Tuca"
,"Tuquera"
,"Pitos"
,"Pitocorto"
,"Tobul"
,"Garompa"
,"kk"
,"Caca"
,"Culon"
,"Culón"
,"Choton"
,"Chotón"
,"Culaso"
,"Culazo"
,"Putazo"
,"Putaso"
,"Putón"
,"Putasa"
,"Putaza"
,"Pijazo"
,"Porongo"
,"Porongazo"
,"Porongaso"
,"Trolazo"
,"Trolaso"
,"Trolita"
,"Putasos"
,"Trolito"
,"Tonto"
,"Tonta"
,"Imbécil"
,"inbecil"
,"imvecil"
,"Imbecil"
,"Pelotudito"
,"Pelotudita"
,"Pornográfico"
,"Lechazo"
,"Sidoso"
,"Sidosa"
,"Putísima"
,"Putisima"
,"Soretón"
,"Soretito"
,"Pijita"
,"Longaniza"
,"Poronguita"
,"Pendejito"
,"Pendejita"
,"Estúpido"
,"Estúpida"
,"Estupido"
,"Estupida"
,"Chotita"
,"Tontita"
,"Tontito"
,"Preservativo"
,"Mierdita"
,"Tuquita"
,"Canceroso"
,"Cancerosa"
,"Wea"
,"Wn"
,"Weon"
,"Weón"
,"Cogidita"
,"Desnudita"
,"Desnuda"
,"Desnudito"
,"Ortitio"
,"Cacona"
,"Piyar"
,"Gilún"
,"Logi"
,"Travesti"
,"Forrito"
,"Trabuco"
,"Trava"
,"Salame"
,"Tetotas"
,"Mamada"
,"Mamas"
,"Pechos"
,"Tetazas"
,"Tetona"
,"Tetitas"
,"Entangado"
,"Entangada"
,"Sanjuda"
,"Sanjona"
,"Pijota"
,"Pijudo"
,"Vergota"
,"Vergotas"
,"Wasqueada"
,"Wasqueame"
,"Acabame"
,"Vergo"
,"Verguita"
,"Púbis"
,"Bubis"
,"Boobis"
,"Boobies"
,"Conchazo"
,"Prolapso"
,"Anal"
,"Boludazo"
,"Boludeces"
,"Pelotudeces"
,"Boludinas"
,"Pendejadas"
,"Entimbrado"
,"Entimbrada"
,"Erecto"
,"Erección"
,"Mierdas"
,"Mierdota"
,"Soretazo"
,"Soretaso"
,"Soretes"
,"Estupidito"
,"Estupidita"
,"Estupideces"
,"Estupidez"
,"Pelotudez"
,"Pelotudos"
,"Forros"
,"Forreados"
,"Forreadas"
,"Forrito"
,"Gorreado"
,"Yuta"
,"Forreada"
,"Mierdeado"
,"Gonorrea"
,"Leproso"
,"Mierdón"
,"Menstruación"
,"Fellatio"
,"Turk"
,"Turca"
,"Besonegro"
,"Chupón"
,"Chuponcito"
,"Peteada"
,"Lecheada"
,"Chupapija"
,"Vergamota"
,"Prepucio"
,"Lamerla"
,"Tetuda"
,"Cachuchita"
,"Cachu"
,"Papota"
,"Gey"
,"Homosesual"
,"Rosquete"
,"Cabecita"
,"Tararira"
,"Tirapedos"
,"Prostitución"
,"Violín"
,"Penetración"
,"Guarra"
,"Pechitos"
,"Eyaculación"
,"Eyaculo"
,"Gemir"
,"Argolluda"
,"Carajo"
,"Carajear"
,"Rastrero"
,"Tumbero"
,"Villero"
,"Zorra"
,"Zorrita"
,"Ocote"
,"Aborto"
,"Charuto"
,"Mandanga"
,"Burundanga"
,"Cáncer"
,"Coito"
,"Erotismo"
,"Erótico"
,"Facho"
,"Setiembre"
,"Feminazi"
,"Menopáusica"
,"Orgasmo"
,"Orgásmico"
,"Pezones"
,"Tuberculosis"
,"Tuberculoso"
,"Lepra"
,"Culón"
,"Culona"
,"Coño"
,"Coñito"
,"Coñazo"
,"Chilote"
,"Boludes"
,"Boludez"
,"Voludo"
,"Voluda"
,"Voludes"
,"Voludez"
,"Voludito"
,"Voludita"
,"Putah"
,"Putitah"
,"Putitoh"
,"Putoh"
,"Fachos"
,"Orgasmear"
,"Miar"
,"Miando"
,"Ratis"
,"YPF"
,"AXION"
,"PETROBRAS"
,"ESSO"
,"ASPRO"
,"OIL"
,"REFINOR"
,"PDVsur"
,"SERVICLUB"
,"INFINIA"
,"ELAION"
,"MOBIL"
,"ESSENCIAL"
,"mear"
,"pillar"
,"piyar"
,"garco"
,"garcar"
,"fuck"
,"fucking"
,"upite"
,"cola"
,"escabio"
,"eskvio"
,"eskbio"
,"escavio"
,"cotorra"
,"cotorrita"
,"p u t o"
,"put@"
,"Mogólico"
,"maricon"
,"maricona"
,"Vuergudo"
,"Culon"
,"Culón"
,"Choton"
,"Chotón"
,"Culaso"
,"Culazo"
,"Putazo"
,"Putaso"
,"Putón"
,"Putasa"
,"Putaza"
,"Pijazo"
,"Porongo"
,"Porongazo"
,"Porongaso"
,"Trolazo"
,"Trolaso"
,"Trolita"
,"Putasos"
,"Trolito"
,"Tonto"
,"Tonta"
,"Imbécil"
,"Imbecil"
,"Pelotudito"
,"Pelotudita"
,"Pornográfico"
,"Lechazo"
,"Sidoso"
,"Sidosa"
,"Putísima"
,"Putisima"
,"Soretón"
,"Soretito"
,"Pijita"
,"Longaniza"
,"Poronguita"
,"Pendejito"
,"Pendejita"
,"Estúpida"
,"Estupido"
,"Estupida"
,"Chotita"
,"Tontita"
,"Tontito"
,"Preservativo"
,"Mierdita"
,"Tuquita"
,"Canceroso"
,"Cancerosa"
,"Wea"
,"Wn"
,"Weon"
,"Weón"
,"Cogidita"
,"Desnudita"
,"Desnuda"
,"Desnudito"
,"Ortitio"
,"Cacona"
,"Piyar"
,"Gilún"
,"Logi"
,"Travesti"
,"Forrito"
,"Trabuco"
,"Trava"
,"Salame"
,"Tetotas"
,"Mamada"
,"Aureola"
,"Aureolas"
,"Mamas"
,"Pechos"
,"Tetazas"
,"Tetona"
,"Tetitas"
,"Entangado"
,"Entangada"
,"Sanjuda"
,"Sanjona"
,"Pijota"
,"Pijudo"
,"Vergota"
,"Vergotas"
,"Wasqueada"
,"Wasqueame"
,"Acabame"
,"Vergo"
,"Púbis"
,"Bubis"
,"Boobis"
,"Boobies"
,"Conchazo"
,"Prolapso"
,"Anal"
,"Boludazo"
,"Boludeces"
,"Pelotudeces"
,"Boludinas"
,"Pendejadas"
,"Entimbrado"
,"Entimbrada"
,"Erecto"
,"Erección"
,"Mierdas"
,"Mierdota"
,"Soretazo"
,"Soretaso"
,"Soretes"
,"Estupidito"
,"Estupidita"
,"Estupideces"
,"Estupidez"
,"Pelotudez"
,"Pelotudos"
,"Forros"
,"Forreados"
,"Forreadas"
,"Forrito"
,"Gorreado"
,"Yuta"
,"Forreada"
,"Mierdeado"
,"Gonorrea"
,"Leproso"
,"Mierdón"
,"Menstruación"
,"Turk"
,"Turca"
,"Besonegro"
,"Chupón"
,"Chuponcito"
,"Peteada"
,"Lecheada"
,"Chupapija"
,"Vergamota"
,"Prepucio"
,"Lamerla"
,"Tetuda"
,"Cachuchita"
,"Cachu"
,"Papota"
,"Gey"
,"Homosesual"
,"Rosquete"
,"Cabecita"
,"Tararira"
,"Tirapedos"
,"Prostitución"
,"Violín"
,"Penetración"
,"Guarra"
,"Pechitos"
,"Eyaculación"
,"Eyaculo"
,"Gemir"
,"Argolluda"
,"Carajo"
,"Carajear"
,"Rastrero"
,"Tumbero"
,"Villero"
,"Zorra"
,"Zorrita"
,"Ocote"
,"Aborto"
,"Charuto"
,"Mandanga"
,"Burundanga"
,"Cáncer"
,"Coito"
,"Coito"
,"Erotismo"
,"Erótico"
,"Facho"
,"Setiembre"
,"Feminazi"
,"Menopáusica"
,"Orgasmo"
,"Orgásmico"
,"Tuberculosis"
,"Tuberculoso"
,"Lepra"
,"Culón"
,"Culona"
,"Coño"
,"Coñito"
,"Coñazo"
,"Chilote"
,"Boludes"
,"Boludez"
,"Voludo"
,"Voluda"
,"Voludes"
,"Voludez"
,"Voludito"
,"Voludita"
,"Putah"
,"Putitah"
,"Putitoh"
,"Putoh"
,"Fachos"
,"Orgasmear"
,"Miar"
,"Miando"
,"Ratis"
,"Putas"
,"Putos"
,"Putaso"
,"Putazo"
,"Putasa"
,"Putaza"
,"Coger"
,"Cojer"
,"Pel0tudos"
,"Pelotud0s"
,"Pel0tud0s"
,"Pelotudo."
,"Peroltudos."
,"Mierd@"
,"Mierd@as"
,"Pelotud@"
,"Pelotud@s"
,"Putta"
,"Putto"
,"Puto."
,"Puto-"
,"Puta."
,"puta."
,"Idiotas"
,"Idiotta"
,"Idiottas"
,"Idiota"
,"Idiotas"
,"Idiota."
,"Idiotas."
,"Chupa"
,"Pijaaa"
,"0rto"
,"0rt0"
,"ort0"
,"remilputa"
,"remil"
,"put4"
,"put0"
,"pijas"
,"p1jas"
,"bobo"
,"boba"
,"chup@"
,"pijaz0"
,"ped0filo"
,"tr0la"
,"tr0lo"
,"trol0"
,"tr0l0"
,"chele"
,"pijaz"
,"YPF"
,"ypf"
,"bitch"
,"bitches"
,"huevón"
,"putos"
,"argolludas"
,"huevones"
,"trolaso"
,"trolos"
,"bobos"
,"bobas"
,"putoss"
,"putosss"
,"putossss"
,"putos!"
,"pelotudoss"
,"pelotudos!"
,"fachos"
,"bolud"
,"put"
,"conch"
,"tarad"
,"pelotud"
,"marika"
,"marik"
,"maric"
,"hijodepu"
,"orgasm"
,"contaminación"
,"contaminacion"
,"culeado"
,"culeada"
,"Caco"
,"menarca"
,"alcornoque"
,"basoso"
,"babosa"
,"cretino"
,"cretina"
,"kretino"
,"kretina"
,"yegua"
,"descerebrado"
,"descerebrada"
,"energúmeno"
,"lameculo"
,"lameculos"
,"malparido"
,"malparida"
,"pedorro"
,"pedorra"
,"gorda"
,"gordo"
,"piojoso"
,"piojosa"
,"berga"
,"bosta"
,"chupala"
,"2pala"
,"imbeciles"
,"imbécil"
,"cornudo"
,"cornuda"
,"pajita"
,"pajota"
,"petear"
,"inutil"
,"inútil"
,"fracasado"
,"fracasada"
,"fracaso"
,"esperma"
,"espermatozoide"
,"óvulo"
,"putis"
,"villera"
,"villerito"
,"villerita"
,"buacho"
,"buacha"
,"vuacho"
,"vuacha"
,"guachada"
,"wachada"
,"buachada"
,"falopera"
,"falopita"
,"careta"
,"kreta"
,"orina"
,"quilombo"
,"kilombo"
,"nabo"
,"navo"
,"vulva"
,"clítoris"
,"copular"
,"omosexual"
,"omosesual"
,"forrear"
,"forr"
,"recalcada"
,"croto"
,"crota"
,"meada"
,"meado"
,"masturbarte"
,"masturbacion"
,"masturbado"
,"masturbate"
,"fifar"
,"prostituta"
,"prostíbulo"
,"porronga"
,"poronguin"
,"tortillera"
,"kulo"
,"cojiendo"
,"cogiendose"
,"P.uto"
,"Pu.to"
,"Put.o"
,"P.u.to"
,"Pu.t.o"
,"P.ut.o"
,"P.uta"
,"Pu.ta"
,"Put.a"
,"P.u.ta"
,"Pu.t.a"
,"P.ut.a"
,"Trucha"
,"Trucho"
,"trucha"
,"trucho"
,"chanta"
,"chantada"
,"Chanta"
,"Chantada"
,"Mentiroso"
,"Mentirosa"
,"Mentiras"
,"Mentiras"
,"Sexy"
,"Sexual"
,"Sexi"
,"Sexopata"
,"Trans"
,"trans"
,"Binario"
,"binario"
,"Transexual"
,"Cisgénero"
,"Transgénero"
,"conchu2"
,"pelotu2"
,"bolu2"
,"cornu2",
,"conchu@"
,"pelotu@"
,"bolu@"
,"cornu@"
];
