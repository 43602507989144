import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { slug, getTrainingCategoryID } from '../../_helpers/commons';
import { breakpoints } from '../../_helpers/breakpoints';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ContentContext } from '../../_context';
import { IcoChevronLeft, COLORS } from '../../_helpers/constants';
import storage from 'local-storage-fallback';
import { TextsmsTwoTone } from '@material-ui/icons';

const Wrapper = styled.div`

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  //padding-top: 15px;
  padding-bottom: 15px;
  .sideright{
    display: flex;
    flex-direction: column;
  }
`
const HeaderCategory = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 3vw;
  color: ${COLORS.primary};
  //height: 450px;
  p{
    margin: 0;
    font-size: 5.5vw;
    line-height: 6vw;
    font-weight: 400;
    margin-left: 2vw;
  }
  .image{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .button_back{
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    p{
      padding: 0;
      margin: 0;
      font-size: 3vw;
      line-height: 3vw;
      margin-left: 1vw;
      color: #5B5E5F;
    }
    .icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      //border: 2px solid #5B5E5F;
      border: 2px solid ${COLORS.primary};
      width: 7vw;
      height: 7vw;
      border-radius: 3.5vw;

      svg{
        height: 3vw;
        path{
          //fill: #5B5E5F!important;
          fill: ${COLORS.primary}!important;
        }
      }
    }

    :hover{
      .icon{
        border: 2px solid #FFD200;
        svg{
          path{
            fill: #FFD200!important;
          }
        }
      }
      p{
        color: #FFD200;
      }
    }

  }

  @media ${breakpoints.xs} {
    margin-top: 15px;
    p{
      font-size: 26px;
      line-height: 30px;
      margin-left: 10px;
    }
    .button_back{
      p{
        font-size: 16px;
        line-height: 16px;
        margin-left: 7px;
      }
      .icon{
        width: 34px;
        height: 34px;
        border-radius: 17px;

        svg{
          height: 15px;
        }
      }
    }
  }
  @media ${breakpoints.sm} {
    margin-top: 0;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const Content = styled.div`
  display: flex;
  width: 100%;
`
const Trainings = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;

  @media ${breakpoints.xs} {
    flex-direction: row;
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const Training = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  width: 94vw;
  background-color: ${COLORS.secondary};
  flex-direction: column;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 13px -5px #000000;
  box-shadow: 0px 0px 13px -5px #000000;
  margin-bottom: 20px;
  padding-bottom: 10px;
  .inner{
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    .image{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 51vw;
      overflow: hidden;
      border-radius: 10px;
      background-color: #FFFFFF;
      img{
        width: 100%;
      }
    }
    .title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 10px;
      color: ${COLORS.primary};
      .title_text{
        font-size: 15px;
        font-weight: 700;
      }
      .tag_complete{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #4C8F05;
        width: 90px;
        height: 20px;
        border-radius: 4px;
        p{
          margin: 0;
          padding-top: 1px;
          font-size: 11px;
          line-height: 11px;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }
    .module_number{
      font-size: 13px;
      font-weight: 600;
      color: #BEBEBE;
      margin-top: 0;
    }
    .type_label{
      margin-top: 7px;
      display: flex;
      flex-direction: row;
      background-color: #FFD200;
      border-radius: 6px;
      p{
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .description{
      margin-top: 7px;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: ${COLORS.primary};
    }
    .status{
      position: absolute;
      right: 12px;
      .ico_check{
        position: absolute;
        width: 25px;
        right: 5px;
        top: 5px;
      }
    }


    :hover{
      .image{
        background-color: #FFD200;
        img{
          opacity: 0.8;
        }
      }
    }
  }

  @media ${breakpoints.xs} {
    width: 550px;
    .inner{
      padding: 10px;
      flex-direction: column;
      .image{
        width: 100%;
        height: 296px;
        border-radius: 10px;
      }
    }
  }
  @media ${breakpoints.sm} {
    width: 448px;
    .inner{
      padding: 10px;
      flex-direction: column;
      .image{
        width: 100%;
        height: 241px;
        border-radius: 10px;
      }
    }
  }
  @media ${breakpoints.md} {
    width: calc(50% - 10px);
    .inner{
      padding: 10px;
      flex-direction: column;
      .image{
        width: 100%;
        height: 164px;
        border-radius: 10px;
      }
    }
  }
  @media ${breakpoints.lg} {
    .inner{
      padding: 10px;
      flex-direction: column;
      .image{
        width: 100%;
        height: 220px;
        border-radius: 10px;
      }
    }
  }

`

const CategoryTrainingsContainer = (props) => {

  const { history, location } = props;
  const [ courses, setCourses ] = useState(null);
  const [ haveSubcat, setHaveSubcat] = useState(null);
  const [ subcategories, setSubcategories ] = useState({});

  const [ tiendaSubcategories, setTiendaSubcategories ] = useState([{sub: 'cafes', title: 'Cafés', status: false, description_large: 'Conocé las recetas de estos riquísimos cafés que todos nuestros clientes van a querer probar.', image: '/images/subcat_cafes.jpg', courses_id: ["6466a8bce2023d00087d625e", "628d45ce33195200092ef609", "628d49fc27d99b000942aee9", "628ea3d9214aa400093c188a", "6050f8531185b24008e18446", "61392ef83814168c11fa5835", "61392ef13814168c11fa5833", "61392f023814168c11fa5838", "6137916402b55fad34349965", "61392eeb3814168c11fa5832", "61392efc3814168c11fa5836"]},
                                                                    {sub: 'smoothie', title: 'Smoothies / Frape', status: false, description_large: 'Conocé el paso a paso para preparar estos riquísimos Smoothies & Frappé que todos nuestros clientes van a querer probar.', image: '/images/subcat_smoothie.jpg', courses_id: ["6213e4ee3369ebffe7c919df", "6213e65f3369ebffe7c919e2", "6213e66a3369ebffe7c919e3", "6213e6723369ebffe7c919e4", "6213e6783369ebffe7c919e5", "6213e67d3369ebffe7c919e6", "6213e6833369ebffe7c919e7", "6213e6893369ebffe7c919e8", "6213e6903369ebffe7c919e9", "6213e6993369ebffe7c919ea"]},
                                                                    {sub: 'tienda', title: 'Tienda Perfecta', status: false, description_large: 'Conocé cómo organizar una Tienda Perfecta.', image: '/images/subcat_tienda.jpg', courses_id: ["65241e5e0e47960008757999", "65031b66ab474d0008ecf73e"]}]);//, "641222b4831e400008f9cb64""64af092dc6acbe0009a15167", "62151f222e9c4297f05f704e"

  const { trainings,
          trainingCategorySelected,
          setTrainingCategorySelected,
          setTrainingSelected,
          campusCategorySelectedData,
          setCampusCategorySelectedData, } = useContext(ContentContext);

  const handleGoToTraining = (target, id_course) => {
    if(trainings[trainingCategorySelected].level == 'Tiendas'){
      const subcatActive = '';
      tiendaSubcategories.forEach(s => {
        s.courses_id.forEach(c => {
          if(id_course == c){
            storage.setItem('training_subcategory_target', s.sub);
          }
        })
      });
    }

    setTrainingSelected(id_course);
    history.push(location.pathname + '/' + target);
  };

  const handleBack = () => {
    if(Object.keys(subcategories).length){
      storage.setItem('training_subcategory_target', null);
      if(!haveSubcat){
        setHaveSubcat(true);
      }else{
        history.push('/campus');
      }
    }else{
      history.push('/campus');
    }
  }

  useEffect(() => {

    let trainingCategoryID = trainingCategorySelected;

    if(trainings){

      if(!trainingCategorySelected){

        const resTrainingCategoryID = getTrainingCategoryID(location.pathname, trainings, "category");
        setCampusCategorySelectedData(resTrainingCategoryID.training);
        setTrainingCategorySelected(resTrainingCategoryID.index);
        trainingCategoryID = resTrainingCategoryID.index;

      }
      setCourses(trainings[trainingCategoryID].courses);

      switch (trainings[trainingCategoryID].level) {
        case 'Tiendas':

          setHaveSubcat(true);
          if(storage.getItem('training_subcategory_target') != 'null'){
            const coursesSubActive = tiendaSubcategories.filter(s => s.sub == storage.getItem('training_subcategory_target'));
            handleGoToSubcategoryTraining(coursesSubActive[0], trainings[trainingCategoryID].courses);
          }

          handleSubcatStatus();
          setSubcategories(tiendaSubcategories);
          break;

        default:
          break;
      }

    }
    return () => {

    }
  }, [trainings])

  const handleGoToSubcategoryTraining = (s, courses) => {

    storage.setItem('training_subcategory_target', s.sub);
    const subCourses = [];
    s.courses_id.forEach(course => {
      const t = courses.filter(c => c._id == course);
      subCourses.push(t[0]);
    });
    setHaveSubcat(false);
    setCourses(subCourses);

  }

  const handleSubcatStatus = () => {

    let statusCount = 0;
    let status = false;

    if(trainings){
      const resTrainingCategoryID = getTrainingCategoryID(location.pathname, trainings, "category");

      if(resTrainingCategoryID.training.courses){
        const updateTiendaSubcategories = tiendaSubcategories.map(sub => {
          statusCount = 0;
          status = false;
          sub.courses_id.forEach(course => {

            const resp = resTrainingCategoryID.training.courses.filter(el => el._id == course);
            if(resp.length){
              if(resp[0].status){
                statusCount++;
              }
            }

          })
          if(statusCount == sub.courses_id.length){
            status = true;
          }

          sub.status = status;

          return sub;
        })

        setTiendaSubcategories(updateTiendaSubcategories);

      }
    }

  }

  return (
    <Wrapper>

      <HeaderCategory>
        <div className="button_back" onClick={handleBack}>
          <div className="icon">
            <IcoChevronLeft />
          </div>
          {/*<p>Volver</p>*/}
        </div>
        <div className="">
          <p>Categoría: {campusCategorySelectedData?.level ? campusCategorySelectedData.level : ''}</p>
        </div>
        {/*
        <div className="image">
          <img src="/images/header_image_temp.jpg" />
        </div>
        */}
      </HeaderCategory>

      <Content>

        {haveSubcat ?
          <Trainings>
            {
            Object.keys(tiendaSubcategories).length && tiendaSubcategories.map((s, index) => {

                return(
                  <Training
                    key={'subcategory-'+index}
                    onClick={() => handleGoToSubcategoryTraining(s, campusCategorySelectedData.courses)}
                  >


                  <div className="inner">
                      <div className="image">
                        <img src={s.image} />
                      </div>
                      <div className="title">
                        <div className="title_text">
                          {s.title}
                        </div>
                        {
                          s.status ?
                          <div className="tag_complete">
                            <p>COMPLETO</p>
                          </div>
                          : <></>
                        }
                      </div>
                      <div className="description">
                        {s.description_large}
                      </div>
                    </div>


                  </Training>
                )
              })
            }
          </Trainings>

        :
        <Trainings>
          {courses !== null ?
            courses ?
              courses.map((item, index) => {
                return (
                  <Training
                    key={'course-'+index}
                    onClick={() => handleGoToTraining(slug(item.name), item._id)}
                  >
                    <div className="inner">
                      <div className="status">
                        {/*
                        <div className="ico_check">
                          <IcoCheck />
                        </div>
                        */}

                      </div>
                      <div className="image">
                        <img src={item?.img_portada} />
                      </div>
                      <div className="title">
                        <div className="title_text">
                          {item?.name}
                        </div>
                        {
                          item?.status ?
                          <div className="tag_complete">
                            <p>COMPLETO</p>
                          </div>
                          : <></>
                        }
                      </div>

                      {/*
                      <div className="module_number">
                        {item.module_number}
                      </div>

                      <div className="type_label">
                        <p>
                          {item.type_label}
                        </p>
                      </div>
                      */}
                      <div className="description">
                        {item?.description_large}
                      </div>
                    </div>
                  </Training>
                )
              })
            : <div>no hay resultados</div>
          : <div>cargando</div>
        }
        </Trainings>
        }
      </Content>

    </Wrapper>
  )
}

export default CategoryTrainingsContainer;
