import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import useMediaQuery from "../../_hooks/useMediaQuery";
import { ButtonYellow } from '../../_elements';
import { COLORS } from '../../_helpers/constants';
import { useHistory } from 'react-router-dom';

import { RiCloseCircleLine } from "react-icons/ri";
import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 99999;
    //margin-bottom: 20px;

    color: ${COLORS.primary};

    .logo{
      width: calc(100% - 50px);
      margin-bottom: 15px;
    }

    .line_top{
      margin-bottom: 5px;
    }
    .line_bottom{
      margin-top: 10px;
    }
    .text{
      margin-bottom: 5px;
      p{
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
    }
    .bt{
      margin-bottom: 15px;
    }
    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`
const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;



  &.scrolleable{
    position: absolute;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .inner_modal_panel{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;//calc(100% - 6vw);
    //height: auto;
    //min-height: 100%;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 0;//16px;

    border-top: 9px solid #FF0019;
    border-left: 9px solid #FF0019;
    border-bottom: 9px solid #FFCD00;
    border-right: 9px solid #FFCD00;

    .box_encuenta{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .text{
      width: 85vw;
      img{
        float: left;
        width: 100%;
      }
    }
    .bt_open{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 230px;
      height: 50px;
      background: rgb(2,0,36);
      background: linear-gradient(34deg, rgba(2,0,36,1) 0%, rgba(255,205,0,1) 0%, rgba(255,0,25,1) 100%);
      border-radius: 10px;
      margin-top: 40px;
      cursor: pointer;
      p{
        font-size: 24px;
        line-height: 24px;
        font-weight: 800;
        color: #FFFFFF;
        margin: 0;
      }

    }

  }

  &.scrolleable{
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${breakpoints.xs} {
    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      //justify-content: center;
      //width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      //border-radius: 16px;

      .text{
        width: 500px;
        img{
          float: left;
          width: 100%;
        }
      }
    }
  }
  @media ${breakpoints.sm} {
    &.scrolleable{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      //justify-content: center;
      width: 600px;
      height: auto;
      min-height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;
    }
  }
  @media ${breakpoints.md} {
    .inner_modal_panel{
      width: 650px;
    }
  }
  @media ${breakpoints.lg} {

  }
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`






const Encuesta = (props) => {

  const isSm = useMediaQuery('(min-width: 768px)');
  const history = useHistory();

  const { showMoreStats,
          setShowMoreStats,
          userData } = useContext(ContentContext);

  const { data } = props;
  const [open, setOpen ] = useState(true);

  const handleShowMoreStats = () => {
    setShowMoreStats(false);
  }

  const goToSurvery = () => {

    history.push('/encuesta?uid='+userData._id);
    setOpen(false);
  }

  if(!userData){
    return null;
  }
  /*
  if(!showMoreStats){
    return null;
  }
  */

  return (
    <Wrapper breakpoints={breakpoints}>

      <ModalStyled
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        scroll={'paper'}
        className="scrolleable"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="inner_modal_panel">
            <ButtonClose
              onClick={() => setOpen(!open)}
            >
              <div className="icon">
                <RiCloseCircleLine />
              </div>
            </ButtonClose>

            <div className="box_encuenta">

              <div className="text">
                <img src="/images/text_modal_survey.svg" />
              </div>

              <div className="bt_open" onClick={goToSurvery}>
                <p>CLIC AQUÍ</p>
              </div>
            </div>


          </div>

        </Fade>
      </ModalStyled>


        {/*
      <div className="logo">
        <img src="/images/logo_embajadores.svg" />
      </div>


      <ButtonYellow
          className="bt"
          onClick={() => setOpen(!open)}
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
        >
          <p>Membresía</p>
      </ButtonYellow>
      */}

    </Wrapper>
  )

}

export default Encuesta;
