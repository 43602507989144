export const breakpoints = {
  xs: `(min-width: 576px)`,
  sm: `(min-width: 768px)`,
  md: `(min-width: 992px)`,
  lg: `(min-width: 1200px)`,
  xl: `(min-width: 1366px)`,
  xxl: `(min-width: 1440px)`,
  xxxl: `(min-width: 1680px)`,
  xxxxl: `(min-width: 1920px)`,
}
