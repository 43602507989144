import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import useMediaQuery from "../../_hooks/useMediaQuery";
import { ButtonYellow } from '../../_elements';
import { COLORS } from '../../_helpers/constants';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;

    color: ${COLORS.primary};

    .bt_video_landing{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 10px;
      background-color: #D8010F;
      box-sizing: border-box;
      padding: 2vw 0 2vw 0;
      cursor: pointer;
      .logo{
        width: 25vw;
        img{
          float: left;
          width: 100%;
        }
      }
      .text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: calc(100% - 30vw);
        font-size: 5vw;
        line-height: 5vw;
        font-weight: 800;
        color: #FFFFFF;
        text-align: center;
        text-transform: uppercase;
        border-left: 1px solid #FFFFFF;
        height: 10vw;
        padding-top: 1vw;
        margin-left: 4vw;
      }
      :hover{
        background-color: #FFD200;
      }
    }
    //background-color: grey;
    @media ${breakpoints.xs} {
      .bt_video_landing{
        padding: 13px 0 13px 0;
        cursor: pointer;
        .logo{
          width: 130px;
        }
        .text{
          width: calc(100% - 170px);
          margin-left: 20px;
          font-size: 25px;
          line-height: 25px;
          height: 50px;
          padding-top: 5px;
        }
      }
    }
    @media ${breakpoints.sm} {
      .bt_video_landing{
        padding: 13px 0 13px 0;
        cursor: pointer;
        .logo{
          width: 130px;
        }
        .text{
          width: calc(100% - 150px);
          font-size: 18px;
          line-height: 20px;
          height: 50px;
          padding-top: 6px;
        }
      }
    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {

    }

`

const ButtonVideoLanding = (props) => {

  const history = useHistory();
  const isSm = useMediaQuery('(min-width: 768px)');

  const { showMoreStats,
          setShowMoreStats,
          openModalEmbajadores,
          setOpenModalEmbajadores,
          userData, } = useContext(ContentContext);

  const handleGoTo = () => {

    setOpenModalEmbajadores(!openModalEmbajadores);
    //history.push('/video-landing?uid='+userData.dni);
    //window.open('https://beneficiosembajadores.com/', '_blank').focus();
  }

  if(!showMoreStats){
    //return null;
  }

  return (
    <Wrapper breakpoints={breakpoints}>
      {userData ?
      <div className='bt_video_landing' onClick={handleGoTo}>
        <div className="logo">
          <img src="/images/logo-shellvpower.png" />
        </div>
        <div className='text'>Meta</div>
      </div> : ""
      }
    </Wrapper>
  )

}

export default ButtonVideoLanding;

