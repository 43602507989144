import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import ContentType from './ContentType';
import { ContentContext } from '../../_context';

import Video from './Video';
import Podcast from './Podcast';
import Image from './Image';

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.scss';
import { Navigation, Pagination } from 'swiper';

const WrapperStyled = styled.div`

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //background-color: rgba(0, 0, 0, 0.5);
  width: 580px;
  //width: 440px;
  //height: 440px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 0;//0 15px 0 15px;
  .nav_prev,
  .nav_next{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    &.blocked{
      opacity: .3;
      cursor: default;
      :hover{
        background-color: rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
      }
    }
    :hover{
      background-color: #FFC600;
      color: #000000;
    }
  }
  .nav_prev{
    margin-right: 10px;
    left: 15px;
  }
  .nav_next{
    margin-left: 10px;
    right: 15px;
  }
  @media ${breakpoints.xs} {
    width: 540px;
    .nav_prev,
    .nav_next{
      position: unset;
    }
    .nav_next{

    }
  }
  @media ${breakpoints.sm} {
    width: 580px;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const SwiperStyled = styled(Swiper)`
  width: 95vw;
  //padding: 10px 0 13px 0;
  //overflow: hidden;
  .content-hide{
    display: none;
  }
  .content-show{
    display: block;
  }
  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;

    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    margin-top: -13px;
    position: absolute;
    top: 233px;
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: -30px;
    background-color: #000000!important;
  }
  .element{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 95vw;
    height: 95vw;
    margin-left: 0;
    :first-child{
      margin-left: 0;
    }
    .inner{
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      //background-color: pink;
    }
    >.image{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 500px;
      height: 500px;
      overflow: hidden;
      border-bottom: 5px solid white;
      img{
        float: left;
        height: 100%;
      }
    }
    .texts{
      margin-top: 7px;
      >.title{
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
      }
      >.description{
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
      }
    }
    :hover{
      .texts{
        >.title{
          color: #DD1D21;
        }
      }
      >.image{
        border-bottom: 5px solid #DD1D21;
      }
    }
  }
  @media ${breakpoints.xs} {
    //width: 544px;
    width: 460px;
    .element{
      width: 460px;
      height: 460px;
    }
  }
  @media ${breakpoints.sm} {
    //width: 448px;
    width: 500px;
    .element{
      width: 500px;
      height: 500px;
    }
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }

  /*position: absolute;*/
`


const ModuleContent = (props) => {

  const { course,
          liked,
          likes,
          elements,
          className,
          module,
          history,
          location,
          handleTakeEvaluation,
          handleRefreshData, } = props;

  const { save_content_viewed } = useContext(ContentContext);

  const [ playStatus, setPlayStatus ] = useState(false);
  const [ isLastSlide, setIsLastSlide ] = useState(false);
  const [ isFirstSlide, setIsFirstSlide ] = useState(true);
  const [ lastSlideIndex, setLastSlideIndex ] = useState(0);


  const sliderRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    setIsLastSlide(false);
    sliderRef.current.swiper.slidePrev();
    setPlayStatus(false);
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    setPlayStatus(false);
  }, []);

  const handleGoToTraining = (el) => {
    history.push(location.pathname + '/course/' + el._id);
  }

  const handleOnEndSlide = () => {
    setIsLastSlide(true);
  }

  const handleOnReachBeginning = () => {
    setIsFirstSlide(true);
  }

  const handleOnSlideChange = () => {

    setLastSlideIndex(sliderRef.current.swiper.activeIndex);

    if(sliderRef.current.swiper.activeIndex !== 0 && elements.length-1 !== sliderRef.current.swiper.activeIndex){
      setIsFirstSlide(false);
      setIsLastSlide(false);
    }
    if(sliderRef.current.swiper.activeIndex){
      if(elements.length-1 === sliderRef.current.swiper.activeIndex){
        setIsFirstSlide(false);
        setIsLastSlide(true);
      }
    }else{
      setIsFirstSlide(true);
      setIsLastSlide(false);
    }

  }

  return (

    <WrapperStyled className={className}>

      {elements.length > 1 ?
      <div className={`nav_prev${isFirstSlide ? ' blocked' : ''}`} onClick={() => { !isFirstSlide && handlePrev() }}>
        <FaChevronLeft />
      </div> : ""}

      <SwiperStyled
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        ref={sliderRef}
        modules={[Navigation]}
        spaceBetween={24}
        slidesPerView={"auto"}
        onSlideChange={handleOnSlideChange}
        //onSwiper={(swiper) => console.log(swiper)}
        onReachEnd={handleOnEndSlide}
        onReachBeginning={handleOnReachBeginning}
        className=""
      >
        {
          elements && elements.length && elements.map((el, i) => {
          return(
            <SwiperSlide>
              <div key={`modulo-content-element-${i}`} className='element'>
                <div key={`modulo-content-element-inner-${i}`} className='inner'>

                  {el.content_type.toLowerCase() === 'video' ? <Video
                                                    course={course}
                                                    isLastSlide={isLastSlide}
                                                    setIsLastSlide={setIsLastSlide}
                                                    media={el}
                                                    playStatus={playStatus}
                                                    setPlayStatus={setPlayStatus}
                                                    lastSlideIndex={lastSlideIndex}
                                                    index={i}
                                                    _id={module._id}
                                                    handleTakeEvaluation={handleTakeEvaluation}
                                                    handleRefreshData={handleRefreshData}
                                                  /> : ""}

                  {el.content_type.toLowerCase() === 'podcast' ? <Podcast
                                                      course={course}
                                                      isLastSlide={isLastSlide}
                                                      setIsLastSlide={setIsLastSlide}
                                                      media={el}
                                                      background={module.cover_image}
                                                      playStatus={playStatus}
                                                      setPlayStatus={setPlayStatus}
                                                      lastSlideIndex={lastSlideIndex}
                                                      index={i}
                                                      _id={module._id}
                                                      handleTakeEvaluation={handleTakeEvaluation}
                                                      handleRefreshData={handleRefreshData}
                                                    /> : ""}

                  {el.content_type.toLowerCase() === 'image' ? <Image
                                                    course={course}
                                                    isLastSlide={isLastSlide}
                                                    setIsLastSlide={setIsLastSlide}
                                                    media={el}
                                                    playStatus={playStatus}
                                                    setPlayStatus={setPlayStatus}
                                                    lastSlideIndex={lastSlideIndex}
                                                    index={i}
                                                    _id={module._id}
                                                    handleTakeEvaluation={handleTakeEvaluation}
                                                    handleRefreshData={handleRefreshData}
                                                  /> : ""}

                </div>
              </div>
            </SwiperSlide>
          )
          })
        }
      </SwiperStyled>

      {elements.length > 1 ?
      <div className={`nav_next${isLastSlide ? ' blocked' : ''}`} onClick={() => { !isLastSlide && handleNext() }}>
        <FaChevronRight />
      </div> : ""}

    </WrapperStyled>

  )
}

export default ModuleContent;
