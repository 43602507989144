import React from "react";
import SecurityDayContainer from "./SecurityDayContainer";

const SecurityDay = (props) => {
  return (
      <SecurityDayContainer location={props.location} history={props.history}/>
  );
};

export default SecurityDay;
