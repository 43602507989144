import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import useMediaQuery from "../../_hooks/useMediaQuery";
import { ButtonYellow } from '../../_elements';
import { COLORS } from '../../_helpers/constants';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    color: ${COLORS.primary};

    .logo{
      width: 100%;
      margin-bottom: 15px;
      img{
        float: left;
        width: 100%;
      }
    }

    .line_top{
      margin-bottom: 5px;
    }
    .line_bottom{
      margin-top: 10px;
    }
    .text{
      margin-bottom: 5px;
      p{
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
    }
    .bt{
      margin-bottom: 15px;
      background-color: #D8010F;
      border: 1px solid #D8010F;
      p{
        color: #FFFFFF!important;
      }
      :hover{
        background-color: #FFD200;
        p{
          color: #000000!important;
        }
      }
    }
    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`

const ButtonChallengeBox = (props) => {

  const history = useHistory();
  const isSm = useMediaQuery('(min-width: 768px)');

  const { showMoreStats,
          setShowMoreStats,
          userData, } = useContext(ContentContext);

  const handleGoTo = () => {
    history.push('/challengebox?uid='+userData.dni);
    //window.open('https://beneficiosembajadores.com/', '_blank').focus();
  }

  if(!showMoreStats){
    return null;
  }

  return (
    <Wrapper breakpoints={breakpoints}>

      <div className="logo">
        <img src="images/logo_challengebox.png" />
      </div>
      {/*
      <div className="text">
        <p>Comunicación Promocióna</p>
      </div>
      */}
      <ButtonYellow
          className="bt"
          onClick={() => handleGoTo()}
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
        >
          <p>Ingresá</p>
      </ButtonYellow>

    </Wrapper>
  )

}

export default ButtonChallengeBox;
