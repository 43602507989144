import 'babel-polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
//import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
//import * as serviceWorker from './serviceWorker';

import 'react-dates/lib/css/_datepicker.css';

import theme from '~/lib/theme';
import './custom.scss';
import './tailwind.css';

import 'typeface-roboto';
import App from './App';

import 'react-dates/initialize';
import { AuthProvider } from './_context';
//import store from './redux/store';
//import Maintenance from './pages/Maintenance';
import ProximamenteContainer from '../src/_components/Maintenance/ProximamenteContainer'

// GA
//ReactGA.initialize('UA-143658505-1');
//ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.initialize('G-31CRDNGF8K');
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

let isMaintenance = false;
/*
const urlQuery = window.location.search;
const query = new URLSearchParams(urlQuery);
//console.log(window.navigator.platform);
if(query.get("utm_source") === "mobile_app"){
  isMaintenance = false;
}else{
  isMaintenance = true;
}
*/


const TheApp = (
    <BrowserRouter>
      <AuthProvider>
        {isMaintenance ? <ProximamenteContainer /> : <App />}
      </AuthProvider>
    </BrowserRouter>
);

ReactDOM.render(TheApp, document.getElementById('root'));

//serviceWorker.register();
