import React, { useContext, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { ButtonYellow } from '../../_elements';
import axios from 'axios';
import { AuthContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useLocation } from 'react-router-dom';
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  background-image: ${props => props.isAuth ? 'none' : "url('/images/fondo-amarillo.jpg')"}; //url('/images/background_challengebox.jpg');
  background-repeat: repeat;
  background-attachment: fixed;

  .logo{
    width: 85vw;
    //width: 450px;
    margin-top: ${props => props.isAuth ? "0" : "4vw"};
    //margin-top: ${props => props.isAuth ? "0" : "20px"};
    margin-bottom: 6vw;
    //margin-bottom: 30px;
    img{
      float: left;
      width: 100%;
    }
  }
  .video_elements{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 95vw;
    margin-bottom: 25px;
    //background-color: skyblue;
    .loader{
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
    .video_element{
      width: 100%;
      border: 1px solid #FFFFFF;

      -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.35);
      box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.35);

      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 15px;
      background-color: rgba(255,255,255, 1);
      .content{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 10px;
        .actions{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
          .bt_like{
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            .loading_like{
              display: flex;
              height: 20px;
              width: 20px;
              svg{
                color: #F6CF46;
              }
            }
            &.is_liked{
              cursor: default;
            }
            .icon{
              display: flex;
              font-size: 20px;
              line-height: 20px;
              .liked{
                color: #F6CF46;
              }
            }
            p{
              font-size: 14px;
              line-height: 14px;
              margin: 0 0 0 5px;
              font-weight: 600;
            }
            :hover{

              .icon{
                color: #F6CF46;
              }
              p{
                color: #000000;
              }
            }
          }
          .likes{
            display: flex;
            flex-direction: row;
            font-size: 14px;
            line-height: 14px;
            p.value{
              margin: 0;
              font-weight: 600;
            }
            .me_gusta{
              margin-left: 5px;
            }
          }
        }
        .title{
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          margin-bottom: 7px;
        }
        .description{
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
        }
      }
      .video{
        height: 53vw;
      }
      :last-child{
        margin-right: auto;
        margin-left: 2.8vw;
      }
    }
  }
  @media ${breakpoints.xs} {
    .logo{
      width: 450px;
      margin-top: ${props => props.isAuth ? "0" : "20px"};
      margin-bottom: 30px;
    }
    .video_elements{
      width: 100%;
      .video_element{
        width: calc(100% / 2 - 10px);
        :last-child{
          margin-right: auto;
          margin-left: 20px;
        }
        .video{
          height: 150px;
        }
      }

    }
  }
  @media ${breakpoints.sm} {
    .video_elements{
      width: 100%;
      .video_element{
        width: 100%;
        :last-child{
        }
        .video{
          height: 252px;
        }
      }
    }
  }
  @media ${breakpoints.md} {
    .video_elements{
      width: 660px;
      .video_element{
        width: calc(100% / 2 - 10px);
        :last-child{
        }
        .video{
          height: 180px;
        }
      }
    }
  }
  @media ${breakpoints.lg} {
    .video_elements{
      width: 840px;
      .video_element{
        :last-child{
        }
        .video{
          height: 230px;
        }
      }
    }
  }

`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #FFD200!important;
  }
`

const ChallengeBoxContainer = () => {

  const history = useHistory();
  const { search, pathname } = useLocation();
  const [ uid, setUid ] = useState(null);
  const [ oid, setOid ] = useState(null);
  const { isAuth } = useContext(AuthContext);
  const [ videos, setVideos ] = useState(null);
  const [ loadingLike, setLoadingLike ] = useState("");


  useEffect(() => {


    if(pathname){
      if(pathname == '/challengebox'){
        if(search.length > 0) {
          const query = new URLSearchParams(search);
          if(query.get("uid") && query.get("uid").length > 0){
            setUid(query.get("uid"));
            const response = getVideos(query.get("uid"));
            response.then((res) => {

            })

          }else{

          }
        }else{

        }
      }
    }

    return () => {

    }
  }, [pathname])

  const getVideos = async (uid) => {

    //const requestURL = `http://localhost/protagonistas/challengebox.php`;
    const requestURL = `https://fabianhr.com/protagonistas/challengebox.php`;

    try {
      const params = new URLSearchParams()
            params.append('type', "get_videos");
            params.append('uid', uid);
            params.append('oid', oid);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await axios.post(requestURL, params, config);
      console.log("result.data.videos", result.data.videos)
      setVideos(result.data.videos);

      return result;

    } catch (error) {

    }
  }
const sendLike = async (video_id, liked) => {

    //const requestURL = `http://localhost/protagonistas/challengebox.php`;
    const requestURL = `https://fabianhr.com/protagonistas/challengebox.php`;

    try {
      const params = new URLSearchParams()
            params.append('type', "add_like");
            params.append('uid', uid);
            params.append('video_id', video_id);
            params.append('liked', liked);


      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await axios.post(requestURL, params, config);

      return result;

    } catch (error) {
    }

}

  const handleVideoProgress = ((progress) => {

  });

  const handleVideoStart = () => {

  }

  const handleVideoStop = () => {

  }

  const handleLike = (video_id, liked) => {
    setLoadingLike(video_id);
    const result = sendLike(video_id, liked);
    result.then(res => {

      const videos_update = videos.map((video, i) => {
        if(video.id == video_id){
          if(!video.liked){
            video.liked = true;
            video.likes += 1;
          }else{
            video.liked = false;
            video.likes = video.likes > 0 ? video.likes - 1 : 0;
          }
        }
        return video;
      })
      setLoadingLike("");
      setVideos(videos_update);

    })

  }

  useEffect(() => {

    //const res = getVideos();

    return () => {}
  }, [])


  return (
    <Wrapper isAuth={isAuth}>

      <div className="logo">
        <img src="images/logo_challengebox.png" />
      </div>

      <div className="video_elements">
        {videos ?
          videos.map((video, i) => {
            return(
              <div className="video_element">
                <div className="video">
                  <ReactPlayer
                    className='react-player'
                    url={video.url/*course.modulos[0].contenidos[0].media*/}
                    width='100%'
                    height='100%'
                    controls={true}
                    //onProgress={(progress) => { handleVideoProgress(progress)}}
                    //onPlay={handleVideoStart}
                    //onStart={handleVideoStart}
                    //onStop={handleVideoStop}
                    /*
                    config={{ file: {
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }}}
                    */
                  />
                </div>
                <div className="content">

                  <div className='actions'>
                    {/*
                    <div className={`bt_like${video.liked ? " is_liked" : ""}`} onClick={() => !video.liked && handleLike(video.id, video.liked)}>
                      <div className='icon'>
                        {video.liked ?
                          <AiFillLike className='liked' />
                        :
                          <>
                          {loadingLike == video.id ?
                            <div className='loading_like'>
                              <CircularProgress size={17} />
                            </div>
                            :
                            <AiOutlineLike />
                          }
                          </>
                        }
                      </div>
                      <p>Me Gusta</p>
                    </div>
                    */}
                    <div className='likes'>
                      <p className='value'>{video.likes}</p>
                      <div className='me_gusta'>Me Gusta</div>
                    </div>
                  </div>
                  <div className='title'>
                    {video.title}
                  </div>
                  <div className='description'>
                    {video.description}
                  </div>
                </div>
              </div>
            )
          })
        :
        <div className='loader'>
          <CircularProgressStyled />
        </div>
        }
      </div>
    </Wrapper>

  )
}

export default ChallengeBoxContainer;
