import axios from 'axios';
import { Auth } from 'aws-amplify';

export const handleError = error => {

};

const _connect = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
});

_connect.interceptors.request.use(
  async (config) => {

    await getToken(config);
    return config;

  },
  (error) => {
    console.error("error", error);
    return Promise.reject(error);
  },
);

_connect.interceptors.response.use(
  (res) => {

    if(res.data.status !== 1){
      return Promise.reject("error");
    }
    return res;
  },
  async (error) => {

    const originalConfig = error.config;
    if (error.response) {
      /*
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        // Do something, call refreshToken() request for example;
        // return a request
        return axios_instance(config);
      }
      */

      if (error.response.status === 401) {
        // Do something

        //alert("401")
        return Promise.reject(error.response.data);
      }

      if (error.response.status !== 401) {
        // Do something
        //alert("!401")
        return Promise.reject(error.response.data);
      }
    }

    return Promise.reject(error);

  }

);

const getToken = async (config) => {

  await Auth.currentSession().then(res=>{
    const idToken = res.getIdToken();

    //const temp_token = "eyJraWQiOiJKXC9wb3N2WEZqRkFZUWwzdkFJYVhaT2dBMnF4dzZqd3pPMFkwSE5CNU9RZz0iLCJhbGciOiJSUzI1NiJ9.eyJvcmlnaW5fanRpIjoiYmNjYjY2NjMtOTMzYy00YTQ4LWFhNGUtOGM3ZDhiMzg2OTE3Iiwic3ViIjoiZTFmYmE1YzAtMjA3MS03MDY0LTg5MWUtOTkxM2NiZDg1ODcwIiwiYXVkIjoiMmJwY2JxNG1xMGJsZml1b2FjYzAyamI1bmUiLCJjb2duaXRvOmdyb3VwcyI6WyJvbmVfc3AiXSwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTkzNDAzMTMsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yX3JDTmhEUXNHbSIsImNvZ25pdG86dXNlcm5hbWUiOiJzcF90ZXN0QHNwLmNvbSIsImV4cCI6MTcxOTM0MzkxMywiaWF0IjoxNzE5MzQwMzEzLCJqdGkiOiI0OWRhYWJlNC1hN2FjLTQ5ZjUtOWYyNS1lMTQ2NTk1YWEyZWQifQ.XHAdu60g4aDRqe3217wsB_Wsi8Lr6DN1QOs8_JFyBI9zSGMBeh5RhXIzXVyTU88612Lt-WDBoYm4T9IUloCOhrS50rDzA2qYed9GpgBipa4AyCLIZu9ZJBdSd89YSJUFfztc0CwQEvaJYTC7AbyBnudD7wFTpBDk9imDk3zTlhnjHkJbUEQHmmCRg1ogU2K2prcR-n6P3U1eMXBtP6ndfMvNS3Q9G9mcvqkaeK7gwrwH20ZId_2nRocva7AY7vnh17kWpOhKFt7dlmL4wgJY2p9UyOGZVrfagNzpCWWnWEvIBr3KkoFvtGYxKyRuftUneDuXo5KLoKNNPJ6E8dtH4w";

    config.headers.Authorization = idToken.jwtToken;//temp_token;
  })

}

export default _connect;
