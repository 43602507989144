import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';

import { BiDislike, BiSolidDislike } from "react-icons/bi";
import { BsHeart, BsFillHeartFill } from "react-icons/bs";
import { MdZoomOutMap } from "react-icons/md";
import { BsChat } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { ContentContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Reactions from './Reactions';
import Media from './Media';
import Goto from './Goto';


import { isObject,
         getTotalReactions,
         getReactionName,
         getReactionIcon } from '../../_helpers/commons';


const WrapperStyled = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;//${props => props.scope == "drawer" ? "600px" : "100%"};
  //margin-right: 20px;
  box-sizing: border-box;
  .media{
    position: relative;
    //display: none;
    /* width: 97vw;
    height: 97vw; */
    width: ${props => props.scope === "drawer" ? "100vw" : "87vw"};
    height: ${props => props.scope === "drawer" ? "100vw" : "87vw"};
    overflow: hidden;
    border: ${props => props.scope == "drawer" ? "0" : "1px"} solid #E8E8E8;


    img{
      float: left;
      width: 100%;
    }
    .modal_close{
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
      font-size: 35px;
      display: flex;
      cursor: pointer;
      padding: 3px;
      border-radius: 25px;
      color: #000000;
      background-color: #FFFFFF;
      :hover{
        background-color: #FFC600;
        color: #FFFFFF;
      }
    }
  }
  .social{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 87vw;
    font-size: 12px;
    font-weight: 600;
    color: #556377;
    margin-top: 10px;
    box-sizing: border-box;
    padding: ${props => props.scope == "drawer" ? "5px 20px" : "0"};
    .zoom_post{
      display: flex;
      font-size: 20px;
      line-height: 20px;
      margin-right: 7px;
      cursor: pointer;
      :hover{
        color: #FFD200;
      }
    }
    .comments{
      display: flex;
      flex-direction: row;
      align-items: center;
      .bt_comments{
        cursor: ${props => props.scope == "drawer" ? "default" : "pointer"};
        .icon{
          display: flex;
          font-size: 22px;
        }
        :hover{
          .icon{
            color: ${props => props.scope == "drawer" ? "#556377" : "#DD1D21"};
          }
        }
      }

      .counter{
        margin-left: 5px;
      }
    }

    .reactions{
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 15px;
      z-index: 99;
      .box_reactions{
        display: ${props => props.showReactions ? 'flex' : 'none'};
        position: absolute;
        bottom: 30px;
        left: 0;
      }
      .box_reaction_button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        >.counter{
          margin-left: 5px;
        }
        >.loader{
          display: flex;
          svg{
            color: #DD1D21;
          }
        }
        >.loader_reaction{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 23px;
          svg{
            color: #DD1D21;
          }
        }
        >.icon{
          display: flex;
          font-size: 23px;
          width: 25px;
          cursor: pointer;
          .icon_reaction{
            width: 23px;
            img{
              float: left;
              width: 100%;
            }
          }
          .fill_heart{
            color: #DD1D21;
          }
          .outline_heart{
            color: #556377;
          }
          :hover{
            .fill_heart{
              color: #DD1D21;//${props => props.isLoading ? '#DD1D21' : '#FFFFFF'};
            }
            .outline_heart{
              color: #DD1D21;//${props => props.isLoading ? '#DD1D21' : '#FFFFFF'};
            }

          }
        }
      }

    }
    .date_published{
      margin-left: auto;
    }
  }
  .description{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    color: #252525;
    width: 87vw;
    box-sizing: border-box;
    padding: ${props => props.scope == "drawer" ? "5px 20px" : "0"};

    .title{
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
    .text{
      margin-top: 5px;
      width: 100%;
      a{
        color: #d8010f;
        font-weight: 600;
      }
      p{
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        white-space: wrap;
        margin: 0;
      }
    }
  }

  @media ${breakpoints.xs} {//576px
    .media{
      /* width: 546px;
      height: 546px; */
      width: ${props => props.scope === "drawer" ? "500px" : "546px"};
      height: ${props => props.scope === "drawer" ? "500px" : "546px"};
    }
    .social{
      width: 100%;
      /* .reactions{
        margin-right: 5px;
      } */
    }
    .description{
      width: 100%;
    }
  }
  @media ${breakpoints.sm} {//768px
    .media{
      /* width: 363px;
      height: 363px; */
      width: ${props => props.scope === "drawer" ? "600px" : "363px"};
      height: ${props => props.scope === "drawer" ? "600px" : "363px"};
    }
    /*
    .media{
      width: 47vw;
      height: 47vw;
      overflow: hidden;
      border: ${props => props.scope == "drawer" ? "0" : "1px"} solid #E8E8E8;
      img{
        float: left;
        width: 100%;
      }
    }
    */
  }
  @media ${breakpoints.md} {//992px
    .media{
      /* width: 390px;
      height: 390px; */
      width: ${props => props.scope === "drawer" ? "600px" : "390px"};
      height: ${props => props.scope === "drawer" ? "600px" : "390px"};
    }
  }
  @media ${breakpoints.lg} {//1200px
    .media{
      /* width: 490px;
      height: 490px; */
      width: ${props => props.scope === "drawer" ? "600px" : "490px"};
      height: ${props => props.scope === "drawer" ? "600px" : "490px"};
    }
  }
  @media ${breakpoints.xl} {//1366px
    .media{
      /* width: 385px;
      height: 385px; */
      width: ${props => props.scope === "drawer" ? "600px" : "385px"};
      height: ${props => props.scope === "drawer" ? "600px" : "385px"};
    }

  }
  @media ${breakpoints.xxl} {//1440px
    .media{
      /* width: 410px;
      height: 410px; */
      width: ${props => props.scope === "drawer" ? "600px" : "410px"};
      height: ${props => props.scope === "drawer" ? "600px" : "410px"};
    }
    /* .social{
      .reactions{
        margin-right: 15px;
      }
    } */
  }
  @media ${breakpoints.xxxl} {//1680px
    .media{
      /* width: 485px;
      height: 485px; */
      width: ${props => props.scope === "drawer" ? "600px" : "485px"};
      height: ${props => props.scope === "drawer" ? "600px" : "485px"};
    }
  }
  @media ${breakpoints.xxxxl} {//1920px
    .media{
      width: ${props => props.scope === "drawer" ? "600px" : "420px"};
      height: ${props => props.scope === "drawer" ? "600px" : "420px"};
    }
  }

`

const Post = (props) => {

  const { add_like_wall,
          userData,
          get_reactions_wall_according_id } = useContext(ContentContext);

  const { data,
          scope,
          className,
          isLoading,
          onClick,
          activeReactions,
          postSelected,
          setPostSelected,
          setPostMediaSelected,
          handleShowComments,
          handleModalPostMedia,
          posts,
          setPosts,
          postsCommentsReactions, 
          setPostsCommentsReactions,
          handleGetCommentsCounter } = props;

  const [ countTotalReactions, setCountTotalReactions ] = useState(0);
  const [ showReactions, setShowReactions ] = useState(false);
  const [ postData, setPostData ] = useState(null);
  const [ myReaction, setMyReaction ] = useState(null);
  const [ isLoadingReaction, setIsLoadingReaction ] = useState(false);
  const [ gotoData, setGotoData ] = useState(null);
  const [ reactionsData, setReactionsData ] = useState(null);
  const [ commentCounter, setCommentCounter ] = useState(0);

  const reactionsRef = useRef(null);

  const LinkifyText = ({text}) => {
    //console.log("text", text)

    
    // Expresión regular para detectar URLs en el texto
    const urlRegex = /(https?:\/\/[^\s]+)/g;
  
    // Función para dividir el texto e insertar los links
    const renderTextWithLinks = (text) => {
      return text.split(urlRegex).map((part, index) => {
        if (urlRegex.test(part)) {
          return (
            <a key={index} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        }
        return part;
      });
    };
  
    return <div>{renderTextWithLinks(text)}</div>;
    
  };

  useEffect(() => {
    if(data !== null){
      if(Object.keys(data).length){
        //console.log("data", data.description_short)
        //console.log(linkifyText(data.description_short));
        setPostData(data);
      }
    }
    return () => {}
  }, [data])

  useEffect(() => {
    /*
    if(postData){
      //console.log("postData", postData)
      const count_reactions = getTotalReactions(postData);
      setCountTotalReactions(count_reactions);
    }
    */
    return () => {}
  }, [postData])

  useEffect(() => {
    
    if(postsCommentsReactions.length){
      const postExist = postsCommentsReactions.filter(post => post.post_id === data._id);
      //console.log("😸", postExist.length)
      if(postExist.length){
        //console.log("🔥", postExist[0])
        setReactionsData(postExist[0]);
        if("user_reaction" in postExist[0]){
          if(postExist[0].user_reaction.length){
            setMyReaction(postExist[0].user_reaction[0].reaction_id);
          }
        }
      }
    }
  
    return () => {}
  }, [postsCommentsReactions])
  

  const handleReaction = () => {
    alert("lala")
  }

  const handleShowReactions = () => {
    reactionsRef.current = setTimeout(() => {
      setShowReactions(true);
    }, 300);
  }

  const handleCancelReactions = () => {
    clearTimeout(reactionsRef.current);
  }
  const handleCloseReactions = () => {
    setShowReactions(false);
  }
  const handleReactions = () => {
    //console.log("showReactions", showReactions)
    //console.log("myReaction", myReaction)
    
    
    //return null;
    if(!showReactions){
      if(!myReaction){
        const heart_filtered = activeReactions.filter(reaction => reaction.name === "heart");
        if(heart_filtered.length){
          const heart_id = heart_filtered[0]._id;
          //console.log("activeReactions", heart_id)
          handleDoReaction('hear', heart_id);
        }else{
          return null;
        }
      }else{
        handleDoReaction(getReactionName(activeReactions, myReaction), myReaction);
      }
    }
      
  }
  const handleComment = () => {
    setPostSelected(data);
    handleShowComments();
  }

  const handleDoReaction = (name, _id) => {
    // console.log("name, _id", name, _id);
    // return null;

    setIsLoadingReaction(true);

    let removedOnly = false;
    let isReaction = name !== 'like' ? true : false;
    let reaction_id = name !== 'like' ? _id : '';

    //const payload = { id_wall: data._id, reaction: isReaction, reaction_id: reaction_id }
    const payload = { post_id: data._id, reaction_id: _id }
    const response = add_like_wall(payload);

    response.then(res => {

      //console.log("😸", res, postsCommentsReactions)
      const postsCommentsReactionsUpdate = [...postsCommentsReactions];
      for (let index = 0; index < postsCommentsReactionsUpdate.length; index++) {
        //console.log(postsCommentsReactionsUpdate[index].post_id +"=-=-="+ res.data.single.posts);
        if(postsCommentsReactionsUpdate[index].post_id === res.data.single.posts){

          if(res.data.single.status_reaction){
            if(postsCommentsReactionsUpdate[index].user_reaction.length){
              postsCommentsReactionsUpdate[index].user_reaction[0].reaction_id = res.data.single.reaction_id;
            }else{
              postsCommentsReactionsUpdate[index].user_reaction = [{reaction_id: res.data.single.reaction_id}]
            }
          }else{
            postsCommentsReactionsUpdate[index].user_reaction = [];
          }
          let reactions_arr = [];
          res.data.single.reactions.forEach(reaction => {
            reactions_arr.push({reaction_id: reaction._id, count: reaction.count});
          });
          postsCommentsReactionsUpdate[index].reactions = reactions_arr;
          /*
          const reaction_counter_filtered = res.data.single.reactions.filter(reaction => reaction._id === res.data.single.reaction_id);
          console.log("reaction_counter_filtered", reaction_counter_filtered)
          

          if(reaction_counter_filtered.length){

            //console.log("@@@@@@@@", postsCommentsReactions[index].reactions)
            //console.log("########", res.data.single.reactions)
            //console.log("XXXXXXXX", reaction_counter_filtered)

            let exist_reaction = false;
            for (let e = 0; e < postsCommentsReactions[index].reactions.length; e++) {
              //console.log("- - - - - - - - - - - - - - - - - - - - - - - -")
              //console.log(e, " ---> " ,postsCommentsReactions[index].reactions[e].reaction_id +"==="+ res.data.single.reaction_id)
              console.log(postsCommentsReactions[index].reactions[e].reaction_id +"==="+ res.data.single.reaction_id, postsCommentsReactions[index].reactions[e].reaction_id === res.data.single.reaction_id)
              if(postsCommentsReactions[index].reactions[e].reaction_id === res.data.single.reaction_id){
                exist_reaction = true;
                //console.log(postsCommentsReactions[index].reactions[e]);
                postsCommentsReactions[index].reactions[e].count = reaction_counter_filtered[0].count;
              }
              //console.log("- - - - - - - - - - - - - - - - - - - - - - - -")
            }
            if(!exist_reaction){
              postsCommentsReactions[index].reactions.push({reaction_id: res.data.single.reaction_id, count: 1});
            }
          }
          */

          //postsCommentsReactionsUpdate[index].reactions = [...postsCommentsReactions[index].reactions, ...res.data.single.reactions];

          //setPostsCommentsReactions(prevItems => [...prevItems, ...res.data.single.posts_reactions]);

        }
        
      }

      if(res.data.single.status_reaction){
        setMyReaction(res.data.single.reaction_id);
      }else{
        setMyReaction(null);
      }
      setPostsCommentsReactions(postsCommentsReactionsUpdate);
      /*
      let update_post = {...postData};
      update_post.reaction_counter = res.data.single.reaction_counter;

      const count_reactions = getTotalReactions(update_post);
      setCountTotalReactions(count_reactions);

      const my_actual_reaction = postData.reactions.filter(r => r.user_id === userData._id);

      if(my_actual_reaction.length){

        const my_actual_reaction_remove = postData.reactions.filter(r => r.user_id !== userData._id);
        if(my_actual_reaction[0].reaction_id !== _id){
          if(name !== "like"){
            update_post.reactions = [...my_actual_reaction_remove, res.data.single.reactions];
          }
        }else{
          update_post.reactions = my_actual_reaction_remove;
          removedOnly = true;
        }
      }else{
        if(name !== "like"){
          update_post.reactions = [...postData.reactions, res.data.single.reactions];
        }
      }

      if(removedOnly){
        setMyReaction(null);
      }else{
        setMyReaction(res.data.single.reactions ? res.data.single.reactions.reaction_id : null);
      }

      update_post.liked = res.data.single.status_like;
      update_post.likes_counter = res.data.single.count_likes ? res.data.single.count_likes : 0;
      setPostData(update_post);
      */

      if(scope === "drawer"){

        /*
        const post_updated = posts.map((p, i) => {
          if(p._id === postData._id){
            return update_post;
          }else{
            return p;
          }
        })
        setPosts(post_updated);
        */

      }

      setIsLoadingReaction(false);

    })

  }

  const handleZoom = () => {
    setPostMediaSelected(postData);
  }

  

  useEffect(() => {

    if(postsCommentsReactions){


      const postExist = postsCommentsReactions.filter(post => post.post_id === data._id);
      //console.log("🔥", postExist)
      if(postExist.length){
        let count = 0;
        postExist[0].reactions.forEach(reaction => {
          count = count + reaction.count;
        });
        
        setCommentCounter(postExist[0].comment_count)
        setCountTotalReactions(count);
        
        //setReactionsData(postExist[0]);
      }
    }
  
    return () => {}
  }, [postsCommentsReactions])



  
  useEffect(() => {
    
    //console.log("🏄 ----", commentCounter)
  
    return () => {}
  }, [commentCounter])


  if(!postData){
    return null;
  }

  return (

    <WrapperStyled
      scope={scope}
      className={className}
      isLoading={isLoading}
      showReactions={showReactions}
      //onClick={() => { !isLoading && onClick()}}
    >
      <div className='media'>
        {scope === "drawer" ?
          <div className='modal_close' onClick={() => handleShowComments()}>
            <AiOutlineCloseCircle />
          </div>
          :""
        }
        <Media
          data={data}
          scope={scope}
          medias={data?.medias}
          posts={posts}
          setPosts={setPosts}
        />
      </div>

      <div className='social'>

        {postData?.medias[0].media_type !== "survey" ?
        <div className='zoom_post' onClick={handleZoom}>
          <MdZoomOutMap />
        </div> : ""}

        <div className='reactions'>
          <Reactions
            className="box_reactions"
            activeReactions={activeReactions}
            onMouseLeave={handleCloseReactions}
            handleClose={handleCloseReactions}
            showReactions={showReactions}
            setShowReactions={setShowReactions}
            doReaction={handleDoReaction}
            data={postData}
            reactionsData={reactionsData}
            postsCommentsReactions={postsCommentsReactions}
            setPostsCommentsReactions={setPostsCommentsReactions}
          />

          <div className='box_reaction_button'>
            {!isLoadingReaction ?
            <div
              className='icon'
              onMouseDown={handleShowReactions}
              onMouseUp={handleCancelReactions}
              onClick={handleReactions}
            >
              {myReaction ?
                  <div className="icon_reaction">{getReactionIcon(activeReactions, myReaction)}</div>
                :
                <BsHeart className={`outline_heart`} />
              }
              {/* {postData?.liked ?
                <BsFillHeartFill className={`fill_heart`} />
              :
                myReaction ?
                  <div className="icon_reaction">{getReactionIcon(activeReactions, myReaction)}</div>
                :
                <BsHeart className={`outline_heart`} />
              } */}
            </div>
            :
            <div className='loader_reaction'>
              <CircularProgress size={14} />
            </div>
            }
          </div>


          <div className='counter'>
            {countTotalReactions > 1 ? `${countTotalReactions} Reacciones` : `${countTotalReactions} Reacción`}
          </div>
        </div>
        {data._id !== '6626a5a8b978ae00081852cc' ?
        <div className='comments'>
          <div className='bt_comments'>
            <div className='icon' onClick={() => { scope !== "drawer" && handleComment()} }>
              <BsChat />
            </div>
          </div>
          <div className='counter'>
            {commentCounter == 1 ? `${commentCounter} Comentario` : `${commentCounter} Comentarios`}
          </div>
        </div> : ""}

        {postData && "go_to" in postData ?
            <Goto go_to={postData.go_to} />
        : ""}
        {/* <div className='share'>
          <div className='icon'></div>
        </div> */}
        <div className='date_published'>
          {data.date_publish}
        </div>
      </div>

      <div className='description'>
        <div className='title'>
          {data.title}
        </div>
        <div className='text'>
          <LinkifyText text={data.description_short} />
          {/* <p dangerouslySetInnerHTML={{ __html: data.description_short }}></p> */}
        </div>
      </div>

    </WrapperStyled>

  )
}

export default Post;
