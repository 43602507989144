import React, { useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '~/_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '~/_elements';
import Countdown, { zeroPad } from 'react-countdown';
import { useHistory } from 'react-router-dom';

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import { ContentContext } from '~/_context';
import { AuthContext } from '~/_context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
`
const WrapperLogin = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 768px;//calc(100% - 30px);
  padding-top: 10px;
  padding-bottom: 50px;
  //background-color: gray;

  @media ${breakpoints.sm} {
    width: 768px;
  }
  @media ${breakpoints.md} {
    width: 951px;
  }
  @media ${breakpoints.lg} {
    width: 1160px;
  }
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #FFFFFF!important;
  }
`

const ModalOtpStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999!important;


  .inner_modal_validate_phone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    padding: 5vw;
    /*background-color: #FFFFFF;*/
    outline: 0!important;
    border-radius: 16px;

    background-image: url("/images/back_videolanding.jpg");
    background-size: cover;
    background-position: center center;

    box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.25);

    .countdown_modal_title{
      color: #FFD200;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
    .countdown_modal_text{
      color: #FFFFFF;
      font-size: 15px;
      margin-top: 10px;
      font-weight: 600;
    }
    .countdown_modal_close{
      color: #FFD200;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      margin-top: 10px;
    }
    .actions{
      margin-top: 2vw;
    }
    .countdown{
      display: flex;
      flex-direction: row;
      justify-content: center;
      box-sizing: border-box;
      padding: 2vw 2vw 2vw 2vw;
      text-align: center;
      color: #FFFFFF;
      background-color: rgba(0,0,0,0.35);
      border-radius: 6px;
      .timer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .dots{
          font-size: 9vw;
          line-height: 9vw;
          font-weight: 600;
          padding-top: 0.7vw;
          margin-left: 1vw;
          margin-right: 1vw;
        }
        .days,
        .hours,
        .minutes,
        .seconds{
          .value{
            font-size: 11vw;
            line-height: 11vw;
            font-weight: 600;
          }
          .label{
            font-size: 3.2vw;
            line-height: 3.2vw;
            font-weight: 500;
            color: #FFD200;
            margin-top: 1vw;
          }
        }
      }
    }
    .countdown_ended{
      text-align: center;
      font-size: 6vw;
      font-weight: 600;
      color: #FFFFFF;
      margin-bottom: 3vw;
    }
    .countdown_info{
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo{
        width: 40vw;
        margin-bottom: 4vw;
        img{
          float: left;
          width: 100%;
        }
      }
      .text{
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #FFFFFF;
        font-size: 3.5vw;
        font-weight: 500;
        line-height: 4.3vw;
        margin-bottom: 3vw;
        .line1{
          font-size: 5vw;
          line-height: 5vw;
          font-weight: 800;
          color: #FFD200;
        }
        .line2{
          font-size: 3.5vw;
          line-height: 4vw;
          margin-top: 1vw;
        }
        .line3{
          font-size: 5vw;
          line-height: 5vw;
          font-weight: 800;
          margin-top: 2vw;
          color: #FFD200;
        }
        .line4{
          font-size: 3.5vw;
          line-height: 4vw;
          margin-top: 1.3vw;
        }
      }
    }
    .loader{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      width: 100%;

    }
  }
  @media ${breakpoints.sm} {
    .inner_modal_validate_phone{
      width: 570px;
      padding: 25px;
      .actions{
        margin-top: 10px;
      }
      .countdown{
        padding: 10px 10px 10px 10px;
        .timer{
          .dots{
            font-size: 45px;
            line-height: 45px;
            padding-top: 3px;
            margin-left: 5px;
            margin-right: 5px;
          }
          .days,
          .hours,
          .minutes,
          .seconds{
            .value{
              font-size: 55px;
              line-height: 55px;
            }
            .label{
              font-size: 14px;
              line-height: 14px;
              margin-top: 5px;
            }
          }
        }
      }
      .countdown_ended{
        font-size: 30px;
        margin-bottom: 15px;
      }
      .countdown_info{
        .logo{
          width: 200px;
          margin-bottom: 20px;
        }
        .text{
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 15px;
          .line1{
            font-size: 23px;
            line-height: 23px;
          }
          .line2{
            font-size: 16px;
            line-height: 20px;
            margin-top: 5px;
          }
          .line3{
            font-size: 23px;
            line-height: 23px;
            margin-top: 10px;
          }
          .line4{
            font-size: 16px;
            line-height: 20px;
            margin-top: 7px;
          }
        }
      }
    }
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`

const RegisterPhone = (props) => {

  const { openOtp, setOpenOtp, dni } = props;

  const history = useHistory();

  const { userData,
          setUserData,
          editUserData,
          needYouPhone,
          setNeedYouPhone } = useContext(ContentContext);

  const { changePasswordRecover } = useContext(AuthContext);

  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {

    if (completed) {
    // Render a completed state
      return (
        userData ?
          <>
          <div className="countdown_info">
            <div className="logo">
              <img src="/images/logo-shellvpower.png" />
            </div>
            <div className="text">
              <div className='text_line line1'>¡SUMATE A LA EVOLUCIÓN!</div>
              <div className='text_line line2'>Descubrí la nueva tecnología de nuestro combustible.</div>
            </div>
          </div>

          <div className="actions">
            <ButtonYellow
              className="bt_save"
              onClick={goToVideoLanding}
              disabled={false}
              css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
              >
                <p>Ingresar</p>
            </ButtonYellow>
          </div>
          </>

        :
          <div className="loader">
            <CircularProgressStyled />
          </div>
      );
    } else {
    // Render a countdown
      return (
        <>
        <div className="countdown_info">
          <div className="logo">
            <img src="/images/logo-shellvpower.png" />
          </div>
          <div className="countdown_modal_title">¡CON EL POWER DE SIEMPRE EL EVENTO LO REPROGRAMAMOS PARA EL 1 DE JUNIO A LAS 13HS!</div>
          <div className="countdown_modal_text">
          Tomá en cuenta las siguientes recomendaciones:<br/>
          1) Ingresar unos minutos antes para poder revisar tu acceso, conexión de internet y salida de audio.<br/>
          2) Chequear tu última actualización de App si vas a acceder desde ese dispositivo.<br/>
          3) Si tuviste algún problema podrás disfrutar de la repetición a las 15hs.<br/>
          </div>
          <div className="countdown_modal_close">¡Gracias a todos, los esperamos!</div>
          {/*
          <div className="text">
            <div className='text_line line1'>¡SUMATE A LA EVOLUCIÓN!</div>
            <div className='text_line line2'>Descubrí la nueva tecnología de nuestro combustible.</div>
            <div className='text_line line3'>¿CUÁNDO?</div>
            <div className='text_line line4'>EL 31 DE MAYO EN PROTAGONISTAS.</div>
          </div>
          */}
        </div>
        {/*
        <div className="countdown">
          <div className='timer'>
            <div className='days'>
              <div className='value'>{zeroPad(days)}</div>
              <div className='label'>{days == 1 ? "DÍA" : "DÍAS"}</div>
            </div>
            <div className='dots'>:</div>
            <div className='hours'>
              <div className='value'>{zeroPad(hours)}</div>
              <div className='label'>{hours == 1 ? "HORA" : "HORAS"}</div>
            </div>
            <div className='dots'>:</div>
            <div className='minutes'>
              <div className='value'>{zeroPad(minutes)}</div>
              <div className='label'>{minutes == 1 ? "MIN" : "MINS"}</div>
            </div>
            <div className='dots'>:</div>
            <div className='seconds'>
              <div className='value'>{zeroPad(seconds)}</div>
              <div className='label'>{seconds == 1 ? "SEG" : "SEGS"}</div>
            </div>
          </div>
        </div>
        */}
        </>
      );
    }
  };

  useEffect(() => {

    setOpen(!open);

    return () => {}
  }, [])


  const goToVideoLanding = () => {
    handleClose();
    history.push('/video-landing?uid='+userData.dni);
  }
//
  return (
    <Wrapper breakpoints={breakpoints}>

      <ModalOtpStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>

          <div className="inner_modal_validate_phone">





            <Countdown
              zeroPadDays={2}
              zeroPadTime={2}
              renderer={renderer}
              date={new Date(1685635200000)}//1685548800000
            />

          </div>

        </Fade>

      </ModalOtpStyled>
    </Wrapper>
  )

}

export default RegisterPhone;
