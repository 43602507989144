import React, { useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import useMediaQuery from "../../_hooks/useMediaQuery";
import { ButtonYellow } from '../../_elements';
import { COLORS } from '../../_helpers/constants';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    //margin-top: 20px;
    margin-bottom: 20px;

    color: ${props => props.colorPallete.primary};

  .bt_show_more{
    display: flex;
    width: 100%!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }
  .box_logs{
    display: flex;
    flex-direction: column;
    width: 100%;

    .table{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      margin-top: 5px;
      border: 1px solid ${props => 'rgba('+props.colorPallete.border+', 1)'};
    }

    .header_logs{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .title{
        font-weight: 700;
        font-size: 16px;
      }
      .bt_prev_month{
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #D8010F;
        cursor: pointer;
        p{
          display: flex;
          font-size: 12px;
          margin: 0;
          padding: 0;
        }
        .ico{
          display: flex;
          width: 5px;
          height: 8px;
          margin-left: 3px;
          svg{
            fill: #CCCCCC;
          }
        }

        :hover{
          color: #000000;
          .ico{
            svg{
              .st0{
                fill: #000000!important;
              }
            }
          }
        }
      }

    }
    .head_table{
      display: flex;
      flex-direction: row;
      width: 100%;

      .inner{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        background-color: ${props => props.colorPallete.altbackground};
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
        .title_fecha{
          font-size: 12px;
          width: 110px;
          color: ${props => props.colorPallete.primary};
          //background-color: pink;
        }
        .title_ingreso{
          font-size: 12px;
          width: 110px;
          color: ${props => props.colorPallete.primary};
          //background-color: skyblue;
        }
      }
    }
    ul{
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0;
      padding: 0;

      height: 99px;
      overflow-y: scroll;

      color: ${COLORS.primary};

      li{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin: 0;

        border-bottom: 1px solid ${props => 'rgba('+props.colorPallete.border+', 1)'};

        .label{
          font-size: 12px;
          width: 110px;
          color: ${props => props.colorPallete.primary};
          //background-color: pink;
        }
        .value{
          font-size: 12px;
          width: 110px;
          color: ${props => props.colorPallete.primary};
          //background-color: skyblue;
        }
      }
      li:last-child{
        border-bottom: 0;
      }
    }
  }
    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`
const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const LoginStats = (props) => {

  const isSm = useMediaQuery('(min-width: 768px)');

  const { showMoreStats,
          setShowMoreStats,
          colorPallete, } = useContext(ContentContext);

  const { data } = props;

  const handleShowMoreStats = () => {
    setShowMoreStats(false);
  }

  if(!showMoreStats){
    return null;
  }

  return (
    <Wrapper breakpoints={breakpoints} colorPallete={colorPallete}>

      <div className="box_logs">
        <div className="header_logs">
          <div className="title">
            Ingresos del mes
          </div>
          {/*
          <div className="bt_prev_month">
            <p>Mes anterior</p>
            <div className="ico">
              <IcoChevronRight />
            </div>
          </div>
          */}
        </div>

        <div className="table">
          <div className="head_table">
            <div className="inner">
              <div className="title_fecha">Fecha</div>
              <div className="title_ingreso">Ingreso</div>
            </div>
          </div>

          <ul>
            {data !== null ?
              data ?
                data.map((item, index) => {
                  return(
                    <li key={'log-'+index}>
                      <div className="label">
                        {item.date}
                      </div>
                      <div className="value">
                        {item.count_session}
                      </div>
                    </li>
                  )
                })
              :<div className=""></div>
            :
            <ContainerSpinner>
              <CircularProgressStyled />
            </ContainerSpinner>
          }
          </ul>
        </div>
      </div>

      {!isSm && showMoreStats &&
      <ButtonYellow
          className="bt_show_more"
          onClick={() => handleShowMoreStats()}
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
        >
          <p>Ocultar</p>
      </ButtonYellow>
      }

    </Wrapper>
  )

}

export default LoginStats;
