import React, { useContext } from 'react';
import styled from 'styled-components';
//import Posts from '../../_components/Feed';
import Wall from  '../../_components/Wall';
import { AuthContext } from '../../_context';
//import PuntosExtraQatar from '~/_components/Popups/PuntosExtraQatar';


const Wrapper = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    //padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    //border-bottom: 3px solid #FFFFFF;
    //cursor: pointer;

`

const HomeContainer = () => {

  const { isAuth } = useContext(AuthContext);
  if(!isAuth){
    return null;
  }

  return (
    <Wrapper>
      <Wall />
    </Wrapper>

  )
}

export default HomeContainer;
