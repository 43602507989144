import React from "react";
import BaristasContainer from "./BaristasContainer";

const Baristas = (props) => {
  return (
      <BaristasContainer location={props.location} history={props.history}/>
  );
};

export default Baristas;
