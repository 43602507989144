/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import connect from '../_helpers/connect';
import _connect from '../_helpers/new_connect';
import storage from 'local-storage-fallback';
import { AuthContext } from './index';

export const FeedContext = React.createContext({});

export const FeedProvider = ({ children }) => {

  const { logout } = useContext(AuthContext);

  const [isFetching, setIsFetching] = useState(false);
  const [posts, setPosts] = useState(null);
  const [pages, setPages] = useState({
    currentPage: 1,
    lastPage: 1,
  });

  const apiUrl = `${process.env.REACT_APP_BASE_URL}/interceptor/wall/get_post`;

  const normalizeData = (data) => {
    const normalized = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      let media = element.media.length > 0 ? element.media : null;

      if(media){
        const mediaArray = [];
        for (let n = 0; n < media.length; n++) {
          const mediaObject = {
            id: media[n]._id,
            type: media[n].type,
            url: media[n].media
          }
          mediaArray.push(mediaObject);
        }
        //media =
        media = mediaArray;
      }

      let addLink = '';
      if(element._id == "638904bd7e409b00084169a9" || element._id == "638a56175833a500085fe0e7" || element._id == "638d2e5955eced0008415a38" || element._id == "6390d405ab84dd00089ac18d" || element._id == "639871c71afc0f0008f30036" || element._id == "639a36380eedab0008c95d76"){
        addLink = ' <br/>Ingresá a la sección haciendo <a href="http://somosprotagonistas.com.ar/shellbox">clic aquí</a>';
      }

      const fixed_data = {id: element._id,
                          post_type: media ? media[0].type : "",//element.post_type,
                          title: element.titulo,//element.title,
                          text: element.descripcion ? element.descripcion + addLink : '',
                          date_publish: element.date_publish,
                          media: media,
                          likes: element.likes,
                          liked: element.liked,
                          accept_comments: true,//element.accept_comments,
                          accept_likes: true,/*element.accept_likes*/}

      normalized.push(fixed_data);


    }
    return normalized;
  }

  useEffect(() => {

    const fetchData = async () => {
      try {
        /*
        const {
          data: { data, last_page: lastPage },
        } = await connect.get(`${apiUrl}`);
        */
        const token = storage.getItem('protagonistas_token');
        const result = await _connect.get(`${apiUrl}`, {
          headers: {
            'token': token,
          },
        });

        setIsFetching(false);

        const normalized = normalizeData(result.data.single);

        if(posts && posts.length){
          setPosts(prevState => [...prevState, ...normalized]);
        }else{
          setPosts(normalized);
        }


      } catch (error) {
        logout();
        console.error(error);
      }
    };

    if (isFetching) {
      fetchData();
    }

  }, [isFetching, pages, posts]);

  const loadPosts = async () => {

    const { currentPage, lastPage } = pages;

    if (isFetching || currentPage > lastPage) {
      return;
    }

    setIsFetching(true);
  };

  const sendLike = async (payload) => {

    try{
    const token = storage.getItem('protagonistas_token');

    const params = new URLSearchParams()
          params.append('id_post', payload.id_post)
          params.append('token', token)

          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }

    //const result = await connect.post(`https://dev.1onesolutions.com/wall/add_liked`, params, config);
    const result = await _connect.post(`${process.env.REACT_APP_BASE_URL}/interceptor/wall/add_liked`, params, config);

    return result;
    }catch(error){
      logout();
    }
    //await connect.post(`${apiUrl}/like`, payload);
    //return (await ownAction) ? connect.post(`${apiUrl}/${action}`, payload) : connect.delete(`${apiUrl}/${action}/${id}`);
  };

  const sendDislike = async (payload) => {
    const { like_id } = payload;
    return await connect.delete(`${apiUrl}/like/${like_id}`);

    //return (await ownAction) ? connect.post(`${apiUrl}/${action}`, payload) : connect.delete(`${apiUrl}/${action}/${id}`);
  };


  return(
    <FeedContext.Provider
      value={{
        posts,
        setPosts,
        loadPosts,
        isFetching,
        sendLike,
        sendDislike
      }}
    >
        {children}
    </FeedContext.Provider>
  )
};

