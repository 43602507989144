import styled from "styled-components";
import { breakpoints } from "~/_helpers/breakpoints";

export const WrappedStyled = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    .container_categories{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        .container_category{
            width: 100%;
        }
    }
    @media ${breakpoints.xs} {

    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {

    }
`