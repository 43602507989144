import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.scss';
import { Navigation, Pagination } from 'swiper';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  overflow: hidden;
  margin-left: 0;
  box-sizing: border-box;
  .header_category{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    >.title{
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 0;
    }
    >.bt_view_more{
      margin-left: 30px;
      padding-bottom: 4px;
      cursor: pointer;
      p{
        padding: 0;
        margin: 0;
        font-weight: 600;
        color: #D8010F;
      }
      :hover{
        p{
          color: #000000;
        }
      }
    }
  }
  .elements{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    .element{
      display: flex;
      flex-direction: column;
      width: 190px;
      margin-left: 15px;
      :first-child{
        margin-left: 0;
      }
      >.image{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 190px;
        overflow: hidden;
        img{
          float: left;
          width: 100%;
        }
      }
      .texts{
        margin-top: 10px;
        >.title{
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
        }
        >.description{
          font-size: 14px;
          line-height: 18px;
          margin-top: 5px;
        }
      }
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const SwiperStyled = styled(Swiper)`
  width: 100%;
  //width: 93vw;
  padding: 10px 0 13px 0;
  //overflow: hidden;
  .content-hide{
    display: none;
  }
  .content-show{
    display: block;
  }
  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;

    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    margin-top: -13px;
    position: absolute;
    top: 69px;
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: 10px;
  }
  &.swiper_destacado{
    .prev, .next{
      top: 128px;
    }
  }
  &.swiper_contenido{
    .prev, .next{
      top: 88px;
    }
  }
  .element{
    display: flex;
    flex-direction: column;
    width: 160px;
    margin-left: 30px;
    cursor: pointer;
    :first-child{
      margin-left: 0;
    }

    >.image{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 165px;
      overflow: hidden;
      border-bottom: 5px solid white;
      border-radius: 35px;
      img{
        float: left;
        width: 100%;
      }
    }
    .texts{
      margin-top: 2px;
      >.title{
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        margin-top: 7px;
      }
      >.description{
        font-size: 13px;
        line-height: 17px;
        margin-top: 5px;
      }
      .box_label_status{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        .label_status{
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          color: #FFFFFF;
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;

          .retry,
          .pending,
          .viewed,
          .approved,
          .process{
            padding: 7px 10px;
            box-sizing: border-box;
            border-radius: 6px;
          }
          .retry{
            background-color: #C5000D;
          }
          .pending{
            background-color: #616161;
          }
          .viewed{
            background-color: #FFC600;
          }
          .approved{
            background-color: #006F45;
          }
          .process{
            background-color: #FDA701;
          }
        }
      }
    }
    :hover{
      .texts{
        >.title{
          color: #DD1D21;
        }
      }
      >.image{
        border-bottom: 5px solid #DD1D21;
      }
    }
  }
  @media ${breakpoints.xs} {
    //width: 544px;
  }
  @media ${breakpoints.sm} {
    //width: 448px;
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }

  /*position: absolute;*/
`

const SwiperFilterStyled = styled(Swiper)`
  display: flex;
  margin-left: 0;
  //width: 93vw;
  //padding: 10px 0 13px 0;
  //overflow: hidden;
  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;

    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    margin-top: -13px;
    position: absolute;
    top: 69px;
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: 10px;
  }
  @media ${breakpoints.xs} {
    //width: 544px;
  }
  @media ${breakpoints.sm} {
    //width: 448px;
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }

  /*position: absolute;*/
`

const Feature = (props) => {

  const { id,
          title,
          elements,
          history,
          location,
          className } = props;

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleGoToTraining = (el) => {
    history.push(`${location.pathname}/course/${id}:${el._id}`);//location.pathname + '/course/' + id + el._id
  }
  const handleGoToCategory = (id) => {
    history.push(location.pathname + '/category/' + id);
  }

  if(!elements.length){
    return null;
  }

  return (

      <WrapperStyled
        className={className}
      >
        <div className='header_category'>
          <div className='title'>{ title }</div>
          {elements && elements.length > 0 ?
          <div className="bt_view_more" onClick={() => handleGoToCategory(id)}>
            <p>Ver todos</p>
          </div> : ""}
        </div>

        <SwiperStyled
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}

            modules={[Navigation]}
            spaceBetween={23}
            slidesPerView={"auto"}
            //onSlideChange={() => console.log('slide change')}
            //onSwiper={(swiper) => console.log(swiper)}
            className="swiper_destacado"
          >
            {
              elements && elements.length && elements.map((el, i) => {
                return(
                <SwiperSlide>
                  <div className='element' onClick={() => handleGoToTraining(el)}>
                    <div className='image'>
                      <img src={el.icon ? el.icon : el.cover_image ? el.cover_image : ""} />
                    </div>
                    <div className='texts'>
                      <div className='box_label_status'>
                        <div className='label_status'>
                          { el.status === -1 ? <div className='retry'>REINTENTAR</div> : "" }
                          { el.status === 1 ? <div className='pending'>PENDIENTE</div> : "" }
                          { el.status === 2 ? <div className='viewed'>VISTO</div> : "" }
                          { el.status === 3 ? <div className='approved'>COMPLETO</div> : "" }
                          { el.status === 5 ? <div className='process'>EN CURSO</div> : "" }
                        </div>
                      </div>
                      <div className='title'>{ el.title }</div>
                      {/* <div className='description'>{ el.description_short }</div> */}
                    </div>
                  </div>
                </SwiperSlide>
                )
              })
            }

            <div ref={prevRef} className="prev">
              <FaChevronLeft />
            </div>
            <div ref={nextRef} className="next">
              <FaChevronRight />
            </div>

          </SwiperStyled>
      </WrapperStyled>

  )
}

export default Feature;
