import { createMuiTheme } from '@material-ui/core/styles';

const typography = {
  useNextVariants: true,
  fontFamily: [
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(','),
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#fff',
      dark: '#cccccc',
      contrastText: '#000000',
    },
    secondary: {
      light: '#ffff56',
      main: '#ffd110',
      dark: '#c7a000',
      contrastText: '#000000',
    },
    background: {
      paper: '#282828',
    },
    text: {
      secondary: '#ffd110',
    },
    type: 'dark',
  },
  props: {
    MuiCheckbox: {
      disableRipple: true,
    },
  },
  typography,
});

export const whiteTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff523b',
      main: '#d8010f',
      dark: '#9e0000',
      contrastText: '#000000',
    },
    secondary: {
      light: '#ffff56',
      main: '#ffd110',
      dark: '#c7a000',
      contrastText: '#000000',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
    },
  },
  typography,
});

export default theme;
