import React, { useContext, useState, useEffect, useRef } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { ButtonYellow } from '../../_elements';
import axios from 'axios';
import { AuthContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useLocation } from 'react-router-dom';
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { RiFullscreenFill, RiFullscreenExitLine } from "react-icons/ri";

import ReactFullscreen from 'react-easyfullscreen';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    .loader{
      position: absolute;
      z-index: 99999;
      top: calc(50% - 20px);
      svg{
        color: #FFD200;
      }
    }
    .player{
      display: flex!important;
      flex-direction: column!important;
      justify-content: center!important;
    }
    .fullscree_actions{
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 99998;
      display: flex;
      flex-direction: row;
      right: 10px;
      bottom: 10px;
      .fullscree_on,
      .fullscree_off{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        border: 0;
        background-color: rgba(0, 0, 0, 0.75);
        color: #FFFFFF;
        box-sizing: border-box;
        padding: 10px;
        font-size: 25px;
        line-height: 25px;
        border-radius: 6px;
        cursor: pointer;
        :hover{
          background-color: rgba(255, 255, 255, 0.75);
          color: #000000;
        }
      }
      .fullscree_off{
        display: ${props => props.fullscreen ? "flex" : "none"};
      }
      .fullscree_on{
        display: ${props => props.fullscreen ? "none" : "flex"};
      }
    }
    .player{
      width: 100%;
      height: 100%;

      iframe{
        width: 100%!important;
        height: 100%!important;

      }
    }
    .bt_play{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      width: 100px;
      height: 50px;
      margin-top: 10px;
      border: 1px solid #000000;
      p{
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        color: #FFFFFF;
        margin: 0;
      }
      cursor: pointer;
      :hover{
        background-color: #FFFFFF;
        border: 1px solid #000000;
        p{
          color: #000000;
        }
      }
    }
    .react-player{
      &.hide{
        opacity: 0;
      }
      &.show{
        opacity: 1;
      }
    }
    @media ${breakpoints.xs} {
    }
    @media ${breakpoints.sm} {
    }
    @media ${breakpoints.md} {
    }
    @media ${breakpoints.lg} {
    }

`
const VideoLandingContainer = (props) => {

  const { transmisionEnded,
          open,
          setOpen,
          setTransmisionStatus,
          handleGetTimeVideo,
          setDataAction,
          isFullscreen,
          setIsFullscreen,
          release } = props;

  const { isAuth } = useContext(AuthContext);

  const history = useHistory();
  const { search, pathname } = useLocation();
  const [ uid, setUid ] = useState(null);
  const [ oid, setOid ] = useState(null);
  const [ data, setData ] = useState(null);
  const [ _playing, set_Playing ] = useState(false);
  const [ showVideo, setShowVideo ] = useState("hide");
  const [ seekTo, setSeekTo ] = useState(null);
  const [ fullscreen, setFullscreen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const videoEl = useRef(null);
  const _player = useRef(null);

  const handleVideoSeek = (e) => {
    console.log("handleVideoSeek");

  }

  const handleVideoEnded = () => {

    setTransmisionStatus("ended");
    setDataAction("end", release);
    setOpen(false);

  }

  const handleVideoPlaying = () => {

    setIsLoading(false);

  }

  useEffect(() => {

    let stime = handleGetTimeVideo();
    if(release){
      stime = 0;
    }
    setSeekTo(stime);

    return () => {}
  }, [])

  const handleFullscreen = () => {

    setIsFullscreen(!isFullscreen);

  }

  return (
    <Wrapper isAuth={isAuth} fullscreen={fullscreen}>

      {isLoading ?
        <div className='loader'>
          <CircularProgress />
        </div>
      : ""
      }

      <ReactFullscreen>
        {({ ref, onRequest, onExit }) => (
          <div
            ref={ref}
            style={{ display: "flex", justifyContent: "center", alignContent: "center", backgroundColor: '#000000', width: '100%', height: '100%' }}
          >
            {!release ?
            <div className='fullscree_actions'>
              <button onClick={() => { onRequest(); setFullscreen(true); }} className="fullscree_on">
                <RiFullscreenFill />
              </button>
              <button onClick={() => { onExit(); setFullscreen(false); }} className="fullscree_off">
                <RiFullscreenExitLine />
              </button>
            </div> : ""
            }

            {seekTo != null ?
          <Vimeo
            className={"player"}
            video="831685265"
            autoplay
            start={seekTo}
            controls={release}
            onEnd={handleVideoEnded}
            onPlaying={handleVideoPlaying}
            responsive
          /> : ""
        }

          </div>
        )}
      </ReactFullscreen>

      <div className='' onClick={handleFullscreen}>fullscreen</div>

    </Wrapper>
  )
}

export default VideoLandingContainer;
