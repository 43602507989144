import React, { useState, useContext, useEffect, useRef, useCallback, Fragment } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import ReactPlayer from 'react-player';
import { AudioPlayer } from '../../_elements';
import { MdZoomOutMap,
         MdZoomInMap } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../../_context';
import { LightgalleryProvider } from "react-lightgallery";
import { LightgalleryItem } from "react-lightgallery";

import { Modal, Backdrop, Fade } from "@material-ui/core";

import "lightgallery.js/dist/css/lightgallery.css";

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .lg-backdrop{
    &.in{
    opacity: 0.7!important;
  }
  }

  .image_viewer{
    position: relative;
    width: 100%;
    height: 100%;
    .image_zoom{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 23px;
      background-color: #FFFFFF;
      color: #000000;
      width: 40px;
      height: 40px;
      cursor: pointer;
      :hover{
        background-color: rgba(0, 0, 0, 0.7);
        color: #FFFFFF;
      }
    }
    .image{
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${props => props.isVertical ? 'auto' : '100%'};
      width: ${props => props.isVertical ? '100%' : 'auto'};
      overflow: hidden;
      img{
        display: flex;
        height: ${props => props.isVertical ? 'auto' : '100%'};
        width: ${props => props.isVertical ? '100%' : 'auto'};
      }
      /* img{
        float: left;
        width: 100%;
      } */
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const DialogStyled = styled(Dialog)`
  .MuiPaper-root{
    //max-width: 1100px!important;

    //max-height: ${props => props.isVertical ? '700px' : 'auto'}!important;
    //max-width: ${props => props.isVertical ? 'auto' : '1100px'}!important;

    .dialog_image{
      /* width: 100%; */
      max-height: ${props => props.isVertical ? '700px' : 'auto'}!important;
      max-width: ${props => props.isVertical ? '700px' : '1100px'}!important;
    }
    img{
      float: left;
      max-height: ${props => props.isVertical ? '700px' : 'auto'}!important;
      max-width: ${props => props.isVertical ? '700px' : '1100px'}!important;
      /* width: 100%; */
    }
    .image_zoom{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 23px;
      background-color: #FFFFFF;
      color: #000000;
      width: 40px;
      height: 40px;
      cursor: pointer;
      :hover{
        background-color: rgba(0, 0, 0, 0.7);
        color: #FFFFFF;
      }
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const ModalStyled = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  .img{

      position: relative;
      .image_zoom{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 5px;
        right: 5px;//calc(10% + 5px);
        font-size: 23px;
        background-color: #FFFFFF;
        color: #000000;
        width: 40px;
        height: 40px;
        cursor: pointer;
        :hover{
          background-color: rgba(0, 0, 0, 0.7);
          color: #FFFFFF;
        }
      }

  }
  &:hover{
    background-color: red;
  }
  .img{
    outline: none;
  }
`



const ContentType = (props) => {

  const { _id,
          index,
          media,
          className,
          history,
          location,
          background,
          playStatus,
          setPlayStatus,
          isLastSlide,
          lastSlideIndex,
          handleTakeEvaluation,
          handleRefreshData } = props;

  const { save_content_viewed } = useContext(ContentContext);

  const theme = useTheme();
  const [ openDialogImage, setOpenDialogImage ] = React.useState(false);
  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const [ _playing, set_Playing ] = useState(false);
  const [ isVertical, setIsVertical ] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClickOpen = () => {
    setOpenDialogImage(true);
  };

  const handleClose = () => {
    setOpenDialogImage(false);
  };

  useEffect(() => {
    if(media){
      const image = new Image();
      image.src = media.media;

      image.onload = () => {
        if (image.naturalHeight > image.naturalWidth) {
          setIsVertical(true);
        } else {
          if (image.naturalHeight === image.naturalWidth) {
            setIsVertical(true);
          }else{
            setIsVertical(false);
          }
        }
      };
    }
  }, []);

  useEffect(() => {
    if((index === lastSlideIndex) && isLastSlide){
      const payload = { id_content: _id }
      const response = save_content_viewed(payload);
      response.then(res => {
        handleRefreshData();
        handleTakeEvaluation(_id);
      })
    }
    return () => {}
  }, [isLastSlide])

  return (

    <WrapperStyled
      isVertical={isVertical}
      background={background}
    >
      <LightgalleryProvider
          lightgallerySettings={
              {
                controls: false,
                download: false,
                counter: false,
                zoom: false,
                fullScreen: false,
                // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
              }
          }
          galleryClassName="my_custom_classname"
      >





      {media.content_type.toLowerCase() == "image" &&
        <div className='image_viewer'>
          <LightgalleryItem group="any" src={media.media}>
            <div className='image_zoom' onClick={handleClickOpen}>
              <MdZoomOutMap />
            </div>
            {/* <img src={media.media} /> */}
          </LightgalleryItem>


          {/* <DialogStyled
            fullScreen={fullScreen}
            open={openDialogImage}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            isVertical={isVertical}
          >
            <div className='image_zoom' onClick={handleClose}>
              <MdZoomInMap />
            </div>
            <div className='dialog_image'>
              <img src={media.media} />
            </div>
          </DialogStyled> */}

          <div className='image'>

            <img src={media.media} />
          </div>

        </div>
      }
    </LightgalleryProvider>
    </WrapperStyled>

  )
}

export default ContentType;
