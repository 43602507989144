import { number } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';
//import GameTimer from '../../_components/Games/GameTimer';
import { FaLock, FaUnlock } from "react-icons/fa";
import { GiPodiumWinner } from "react-icons/gi";
import axios from 'axios';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .counter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 5px solid #FFD200;
    border-radius: 50%;
    p{
      margin: 0;
      font-size: 25px;
      font-weight: 600;

    }
  }
  .timer{

  }
  .finded_text{
    font-size: 20px;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    .number{
      color: #FFD200;
      font-weight: 600;
    }
  }
  .letters{
    display: flex;
    flex-direction: row;
    width: 100%;
    //grid-template-columns: repeat(10, 1fr);
    //grid-gap: 10px;
    .letter{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 9.3vw;
      height: 9.3vw;
      border: 1px solid #000000;
      font-size: 4.5vw;
      cursor: pointer;
      text-transform: uppercase;
      background-color: rgba(216, 1, 15, 1);
      color: #FFFFFF;
      outline: 0;
      :hover{
        background-color: rgba(255, 210, 0, .5);
      }

      &.selected{
        background-color: rgba(255, 210, 0, 1);
        color: #000000;

      }
      &.finded{
        //background-color: rgba(255, 210, 0, 1);
        //color: #000000;
        background-color: rgba(0,0,0,1);
        color: #FFD200;
        font-weight: 700;
        font-size: 5vw;
        border: 1px solid #FFD200;
      }
      :disabled{
        cursor: default;
      }
    }
  }
  .inner_game{
    position: relative;
    width: 100%;
    .overlay_game{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.85);
      //border-radius: 10px;
      z-index: 8;
      font-size: 45px;
      .game_locked{
        display: flex;
        flex-direction: column;
        align-items: center;
        .game_locked_message{
          margin-top: 10px;
          width: 150px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          text-align: center;
        }
      }
      .game_unlocked{
        display: flex;
        flex-direction: column;
        align-items: center;
        .bt_play{
          width: 150px;
          margin-top: 15px;
        }
      }
      .game_winner{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 80px;
        color: #FFD200;
        .game_winner_message{
          margin-top: 10px;
          width: 240px;
          .title{
            font-size: 24px;
            line-height: 30px;
            font-weight: 600;
            text-align: center;
            color: #FFFFFF;
          }
          .aclaration{
            font-size: 17px;
            line-height: 17px;
            font-weight: 600;
            text-align: center;
            color: #FFFFFF;
            margin-top: 5px;
          }
        }
      }
    }
  }

  @media ${breakpoints.xs} {
    .letters{
      .letter{
        width: 43px;
        height: 43px;
        font-size: 21px;
        &.finded{
          font-size: 26px;
        }
      }
    }
  }
  @media ${breakpoints.sm} {
  }
  @media ${breakpoints.md} {
  }
  @media ${breakpoints.lg} {
  }
`

const Soup = (props) => {

    const { uid,
            history,
            location,
            timeEnded,
            setTimeEnded,
            //timerSeconds,
            setGameResult,
            setOpenResult,
            gameData,
            setGameData,
            setWordsToFind } = props;

    const [ grid, setGrid ] = useState([]);
    const [ countFinded, setCountFinded ] = useState(0);
    const [ selected, setSelected ] = useState('');
    //const [ wordSelecting, setWordSelecting ] = useState('');
    const [ wordsClicked, setWordsClicked ] = useState([]);
    const [ wordsFinded, setWordsFinded ] = useState([]);
    const [ timerSeconds, setTimerSeconds ] = useState(60);
    const [ showOverlayGame, setShowOverlayGame ] = useState(true);
    const [ wordsList, setWordsList ] = useState([]);

    const [timeLeft, setTimeLeft] = useState(60);
    const [intervalId, setIntervalId] = useState(null);

    const verticalWords = ['RESIDUOS', 'ARENA', 'CONO', 'CONTAMINAR', 'ABSORVENTE', 'PALA', 'SEGURIDAD', 'NAFTA', 'CONTENEDOR', 'DIESEL'];
    const horizontalWords = ['GUANTES', 'SURTIDOR', 'PARADA', 'CORTE', 'DERRAME', 'CARGA', 'ZAPATOS', 'DESPACHO', 'PELIGRO', 'POLVO', 'BOLSA'];

    const mixArray = _array => {
      for (let i = _array.length - 1; i > 0; i--) {
        let randomIndex = Math.floor(Math.random() * (i + 1));
        let temporal = _array[i];
        _array[i] = _array[randomIndex];
        _array[randomIndex] = temporal;
      }
    }

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';//abcdefghijklmnopqrstuvwxyz
    function insertWordsGridNulled(_matrix){


      const size = 10;
      let withError = false;

      for (let n = 0; n <= 2; n++) {
        const row = Math.floor(Math.random() * (size - horizontalWords[n].length + 1));
        const col = Math.floor(Math.random() * size);

        let hcount = 0;
        const addWordh = checkHorizontalPlace(_matrix, horizontalWords[n], row, col, hcount);
        if(addWordh){
          for (let i = 0; i < horizontalWords[n].length; i++) {
            _matrix[addWordh[0]][addWordh[1] + i] = horizontalWords[n][i];
          }

          setWordsList(prev => [...prev, horizontalWords[n]]);
        }else{
          withError = true;
        }
      }

      for (let n = 0; n <= 1; n++) {
        const row = Math.floor(Math.random() * (size - verticalWords[n].length + 1));
        const col = Math.floor(Math.random() * size);

        let vcount = 0;
        const addWordv = checkVerticalPlace(_matrix, verticalWords[n], row, col, vcount);
        if(addWordv){
          for (let i = 0; i < verticalWords[n].length; i++) {
            _matrix[addWordv[0] + i][addWordv[1]] = verticalWords[n][i];
          }
          setWordsList(prev => [...prev, verticalWords[n]]);
        }else{
          withError = true;
        }
      }

      for (let i = 0; i < _matrix.length; i++) {
        for(let n = 0; n < _matrix[i].length; n++){
          if(_matrix[i][n] == null){
            const randomChar = alphabet[Math.floor(Math.random() * alphabet.length)];
            _matrix[i][n] = randomChar;
          }
        }
      }

      if(withError){
        return null;
      }else{
        return _matrix;
      }

    }

    const checkVerticalPlace = (_matrix, word, row, col, count) => {


      const size = 10;
      let havePlace = true;
      let _row = row;
      let _col = col;
      count++;

      for (let i = 0; i < word.length; i++) {
        if(_matrix[_row + i][_col] != null){
          havePlace = false;
        }
      }

      if(!havePlace){
        _row = Math.floor(Math.random() * (size - word.length + 1));
        _col = Math.floor(Math.random() * size);
        if(count <= 10){
          return checkVerticalPlace(_matrix, word, _row, _col, count);
        }else{
          return false;
        }
      }

      return [_row, _col];

    }

    const checkHorizontalPlace = (_matrix, word, row, col, count) => {

      const size = 10;
      let havePlace = true;
      let _row = row;
      let _col = col;
      count++

      for (let i = 0; i < word.length; i++) {
          if(_matrix[_row][_col + i] === undefined){
            havePlace = false;
          }else{
            if(_matrix[_row][_col + i] != null){
              havePlace = false;
            }
          }
      }

      if(!havePlace){
        _row = Math.floor(Math.random() * size);
        _col = Math.floor(Math.random() * (size - word.length + 1));
        if(count <= 10){
          return checkHorizontalPlace(_matrix, word, _row, _col, count);
        }else{
          return false;
        }
      }

      return [_row, _col];

    }

const startSoup = () => {

  setWordsList([]);
  const matrix = [[null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null],
                  [null, null, null, null, null, null, null, null, null, null]];

  mixArray(verticalWords);
  mixArray(horizontalWords);

  const newGrid = insertWordsGridNulled([...matrix]);
  if(!newGrid){
    startSoup();
  }else{

    setGrid(newGrid);
  }

}

useEffect(() => {
  if(wordsList.length >= 5){
    setWordsToFind(wordsList.join(' - '));
  }
  return () => {}
}, [wordsList])


useEffect(() => {

  startSoup()

  return () => {}

}, []);

useEffect(() => {

  if(wordsClicked.length){
    let wordFinded = '';
    wordsClicked.map(wc => {
      const splitted = wc.split("-");
      const char = grid[splitted[0]][splitted[1]];
      wordFinded = wordFinded + char;
    })

    const vwordIsFinded = verticalWords.filter(word => wordFinded.includes(word));
    const hwordIsFinded = horizontalWords.filter(word => wordFinded.includes(word));

    if(hwordIsFinded.length){
      let after_row_value = 0;
      let after_col_value = 0;
      for(let n = 0; n < wordsClicked.length; n++){
        let splitted_word = wordsClicked[n].split("-");
        if(n != 0 && after_row_value != splitted_word[1]){
          return null;
        }
        if(n != 0 && splitted_word[0] != after_col_value){
          return null;
        }
        after_row_value = parseInt(splitted_word[1]) + 1;
        after_col_value = splitted_word[0];
      }
    }

    if(vwordIsFinded.length){
      let after_row_value = 0;
      let after_col_value = 0;
      for(let n = 0; n < wordsClicked.length; n++){
        let splitted_word = wordsClicked[n].split("-");
        if(n != 0 && after_row_value != splitted_word[0]){
          return null;
        }
        if(n != 0 && splitted_word[1] != after_col_value){
          return null;
        }
        after_row_value = parseInt(splitted_word[0]) + 1;//splitted_word[0];
        after_col_value = splitted_word[1];
      }
    }


    if(vwordIsFinded.length || hwordIsFinded.length){
      setWordsFinded(prev => [...prev, ...wordsClicked]);
      setWordsClicked([]);
      setCountFinded(countFinded + 1);
    }
  }

  return () => {}
}, [wordsClicked])


useEffect(() => {
  if(countFinded == 5){

    saveEndGame(true);

    const update = {...gameData};
    update.soup.can_play = false;
    update.soup.game_status = true;
    setGameData(update);

    clearInterval(intervalId)
    setOpenResult(true);
    setGameResult("win");
    setTimeLeft(timeLeft);
  }

return () => {}
}, [countFinded])




function handleLetterClick(row, col) {
  const char = grid[row][col];
  const newSelected = selected + char;

  let update_wordsClicked = [];
  const wc = row + "-" + col;
  const wc_exist = wordsClicked.filter(w => w == wc);
  if(wc_exist.length){
    update_wordsClicked = wordsClicked.filter(w => w != wc);
  }else{
    update_wordsClicked = [...wordsClicked];
    update_wordsClicked.push(wc);
  }
  setWordsClicked(update_wordsClicked);



  //setWordSelecting(wordSelecting + char);

  setSelected(newSelected);
}

const isWordSelected = (letter) => {

  const wc_exist = wordsClicked.filter(w => w == letter);
  if(wc_exist.length){
    return true;
  }else{
    return false;
  }

}

const isWordFinded = (letter) => {

  const wc_exist = wordsFinded.filter(w => w == letter);

  if(wc_exist.length){
    return true;
  }else{
    return false;
  }

}

useEffect(() => {


  for(let i = 0; i < wordsClicked.length; i++) {
    let wordsClickedSplited = wordsClicked[i].split('-');
  }

  return () => {}
}, [wordsClicked])

const handleStartGame = () => {

  const start = saveStartGame();
  start.then((res) => {
    if(res.data.success){

      const update = {...gameData};
      update.soup.can_play = false;
      update.soup.game_status = false;
      setGameData(update);

      startSoup();
      setShowOverlayGame(false);
      const id = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
      setIntervalId(id);
    }
  })
}

useEffect(() => {

  if(timeLeft == 0){
    //setShowOverlayGame(true);
    clearInterval(intervalId)

    if(countFinded < 5){

      saveEndGame(false);

      const update = {...gameData};
      update.soup.can_play = false;
      update.soup.game_status = false;
      setGameData(update);

      setOpenResult(true);
      setGameResult("lose");

    }

  }else{

  }

  return () => {}
}, [timeLeft]);

const saveStartGame = async () => {

  //const requestURL = `http://localhost/protagonistas/diaseguridad.php`;
  const requestURL = `https://fabianhr.com/protagonistas/diaseguridad.php`;

  try {
    const params = new URLSearchParams()
          params.append('type', "set_start_game");
          params.append('uid', uid);
          params.append('game', "soup");

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const result = await axios.post(requestURL, params, config);
    return result;

  } catch (error) {

  }
}

const saveEndGame = async (game_result) => {

  //const requestURL = `http://localhost/protagonistas/diaseguridad.php`;
  const requestURL = `https://fabianhr.com/protagonistas/diaseguridad.php`;

  try {
    const params = new URLSearchParams()
          params.append('type', "set_end_game");
          params.append('uid', uid);
          params.append('game', "soup");
          params.append('result', game_result);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const result = await axios.post(requestURL, params, config);
    return result;

  } catch (error) {

  }
}





  return (
    <StyledWrapper>

        <div className="inner_game">

          <div className="counter">
            <p>{timeLeft}</p>
          </div>
          <div className="finded_text">Descubriste: <span className="number">{countFinded}</span> de 5</div>

          {showOverlayGame &&
          <div className='overlay_game'>
            {
              gameData.soup.game_status ?
                <div className='game_winner'>
                  <GiPodiumWinner />
                  <div className='game_winner_message'>
                    <div className='title'>¡FELICITACIONES!</div>
                    <div className='aclaration'>Ya completaste esta actividad</div>
                  </div>
                </div>
              :
              <div className='game_unlocked'>
                <FaUnlock />
                <ButtonYellow
                  className="bt_play"
                  onClick={() => handleStartGame()}
                  disabled={false}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                >
                  <p>JUGAR</p>
                </ButtonYellow>
              </div>
            }
            {/*
              gameData.soup.can_play ?
              <div className='game_unlocked'>
                <FaUnlock />
                <ButtonYellow
                  className="bt_play"
                  onClick={() => handleStartGame()}
                  disabled={false}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                >
                  <p>JUGAR</p>
                </ButtonYellow>
              </div>
            :
              gameData.soup.game_status ?
                <div className='game_winner'>
                  <GiPodiumWinner />
                  <div className='game_winner_message'>
                    <div className='title'>¡FELICITACIONES!</div>
                    <div className='aclaration'>Ya completaste esta actividad</div>
                  </div>
                </div>
              :
                <div className='game_locked'>
                  <FaLock />
                  <div className='game_locked_message'>
                    Ya jugaste tu partida de hoy.<br/>¡Volvé mañana!
                  </div>
                </div>
            */}
          </div>
          }

            {grid && grid.map((row, rowIndex) => (
              <div className='letters' key={rowIndex}>
                {row.map((char, colIndex) => (
                  <button
                    className={`letter${(isWordSelected(rowIndex+"-"+colIndex) ? " selected" : "")}${(isWordFinded(rowIndex+"-"+colIndex) ? " finded" : "")}`}
                    key={`${rowIndex}-${colIndex}`}
                    onClick={() => handleLetterClick(rowIndex, colIndex)}
                    disabled={!timeEnded ? isWordFinded(rowIndex+"-"+colIndex) : timeEnded}
                  >
                    {char}
                  </button>
                ))}
              </div>
            ))}
        </div>

    </StyledWrapper>
  )
}

export default Soup;
