import React from "react";
import CourseContainer from "./CourseContainer";

const Course = (props) => {
  return (
      <CourseContainer location={props.location} history={props.history} match={props.match}/>
  );
};

export default Course;
