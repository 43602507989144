import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import ReactPlayer from 'react-player'
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { path } from 'ramda';

const Wrapper = styled.div`
  position: relative;

  &.w-controls {

  }

  .carousel-inner {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 65%;
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
  }

  .carousel-indicators {
    bottom: -2.8rem;

    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #EC1C24;
      margin: 0 0.4rem;
    }
  }

  .video_player{

    @media ${breakpoints.sm} {
      width: 350px;
      height: 350px;
    }
    @media ${breakpoints.md} {
      //width: 336px;
      //height: 336px;
      width: ${props => props.onDrawer ? '530px!important' : "336px!important"};
      height: ${props => props.onDrawer ? '530px!important' : "336px!important"};
    }
    @media ${breakpoints.lg} {
      width: ${props => props.onDrawer ? '530px!important' : "410px!important"};
      height: ${props => props.onDrawer ? '530px!important' : "410px!important"};
    }

    //width: ${props => props.onDrawer ? '530px!important' : "420px!important"};
    //height: ${props => props.onDrawer ? '295px!important' : "275px!important"};

  }
`;

const EmbedMedia = styled.div`
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
`;

const CarouselCounter = styled.div`
  max-height: 24px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.8rem;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 0.5rem;
  z-index: 999;
  line-height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Video = ({ post, onDrawer }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const media = path(['media'], post);
  const showControls = media && media.length > 1;

  const carouselProps = {
    controls: showControls,
    indicators: showControls,
    interval: null,
    nextIcon: null,//<span aria-hidden="true" className="carousel-control-next-icon" />,
    prevIcon: null,//<span aria-hidden="true" className="carousel-control-prev-icon" />,
    onSlid: eventKey => setActiveIndex(eventKey),
  };

  return (
    <Wrapper className={`post_video ${showControls ? 'w-controls' : ''}`} onDrawer={onDrawer} breakpoints={breakpoints}>
      {showControls ? (
        <CarouselCounter>
          {activeIndex + 1} / {media.length}
        </CarouselCounter>
      ) : null}
      <Carousel {...carouselProps}>
        {media.map((item, i) => {
          return (
            <Carousel.Item key={i}>
                <ReactPlayer className="video_player" url={item.url} controls />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default Video;
