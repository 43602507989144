import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';
import useMediaQuery from "../../_hooks/useMediaQuery";
import { ButtonYellow } from '../../_elements';
import { COLORS } from '../../_helpers/constants';

import { RiCloseCircleLine } from "react-icons/ri";
import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    color: ${COLORS.primary};

    .logo{
      width: calc(100% - 50px);
      margin-bottom: 15px;
    }

    .line_top{
      margin-bottom: 5px;
    }
    .line_bottom{
      margin-top: 10px;
    }
    .text{
      margin-bottom: 5px;
      p{
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
    }
    .bt{
      margin-bottom: 15px;
    }
    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`
const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  &.scrolleable{
    position: absolute;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .inner_modal_panel{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;//calc(100% - 6vw);
    height: auto;
    min-height: 100%;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 0;//16px;
  }

  &.scrolleable{
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${breakpoints.xs} {
    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      //justify-content: center;
      //width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      //border-radius: 16px;

    }
  }
  @media ${breakpoints.sm} {
    &.scrolleable{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      //justify-content: center;
      width: 600px;
      height: auto;
      min-height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;
    }
  }
  @media ${breakpoints.md} {
    .inner_modal_panel{
      width: 900px;
    }
  }
  @media ${breakpoints.lg} {

  }
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`






const Embajadores = (props) => {

  const isSm = useMediaQuery('(min-width: 768px)');

  const { showMoreStats,
          setShowMoreStats, } = useContext(ContentContext);

  const { data } = props;
  const [open, setOpen ] = useState(false);

  const handleShowMoreStats = () => {
    setShowMoreStats(false);
  }

  const handleGoTo = () => {
    window.open('https://beneficiosembajadores.com/', '_blank').focus();
  }

  if(!showMoreStats){
    return null;
  }

  return (
    <Wrapper breakpoints={breakpoints}>

      <ModalStyled
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        scroll={'paper'}
        className="scrolleable"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="inner_modal_panel">
            <ButtonClose
              onClick={() => setOpen(!open)}
            >
              <div className="icon">
                <RiCloseCircleLine />
              </div>
            </ButtonClose>

            <div className="box_embajador">
              MEMBRESIA CUPONSTAR
            </div>


          </div>

        </Fade>
      </ModalStyled>



      <div className="logo">
        <img src="/images/logo_embajadores.svg" />
      </div>
      {/*
      <div className="text">
        <p>Comunicación Promocióna</p>
      </div>
      */}
      <ButtonYellow
          className="bt"
          onClick={() => handleGoTo()}
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
        >
          <p>MEMBRESIA CUPONSTAR</p>
      </ButtonYellow>

    </Wrapper>
  )

}

export default Embajadores;
