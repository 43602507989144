import React, { useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../../_elements';
import { MdZoomOutMap,
         MdZoomInMap } from "react-icons/md";

const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-sizing: border-box;
  //padding: 7px 7px;
  //border-radius: 25px;
  width: 100%;

  .image_zoom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 23px;
    background-color: #FFFFFF;
    color: #000000;
    width: 40px;
    height: 40px;
    cursor: pointer;
    :hover{
      background-color: rgba(0, 0, 0, 0.7);
      color: #FFFFFF;
    }
  }

  .image{
    width: 100%;
    img{
      float: left;
      width: 100%;
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }


`

const Image = (props) => {

  const { data,
          className,
          isLoading,
          onClick } = props;


  const handleClose = () => {

  }

  return (

    <WrapperStyled
      className={className}
      isLoading={isLoading}
    >

      <div className='image'>
        <img src={data.media_url} />
      </div>

    </WrapperStyled>

  )
}

export default Image;
