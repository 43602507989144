import React, { useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { BsHeart, BsFillHeartFill } from "react-icons/bs";

import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 7px 7px;
  border-radius: 25px;
  //width: 220px;

  box-shadow: 4px 0px 7px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 4px 0px 7px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 4px 0px 7px 0px rgba(0,0,0,0.15);

  .reaction_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 7px;
    margin-right: 7px;
    .counter{
      margin-left: 5px;
      font-size: 13px;
      line-height: 13px;
      font-weight: 500;
    }
    .icon{
      width: 25px;
      height: 25px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      .fill_heart{
        display: flex;
        font-size: 26px;
        color: #DD1D21;
      }
      :hover{
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
      }
      img{
        float: left;
        width: 100%;
      }
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const Reactions = (props) => {

  const { data,
          className,
          isLoading,
          onClick,
          activeReactions,
          onMouseLeave,
          showReactions,
          setShowReactions,
          doReaction,
          postsCommentsReactions,
          setPostsCommentsReactions,
          reactionsData, 
          setReactionsData } = props;

  useEffect(() => {
    //console.log("isLoading", data)

    return () => {}
  }, [data])

  const [ isOver, setIsOver ] = useState(false);

  const handleMouseOver = () => {
    setIsOver(true);
  }
  const handleMouseLeave = () => {
    //alert("asd")
    setIsOver(false);
    onMouseLeave();
  }

  useEffect(() => {
    const handleClick = event => {
      if(showReactions){
        if(!isOver){
          //console.log("click");
          handleMouseLeave();
        }
      }
    };

    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [showReactions, isOver]);

  const getReactionCount = (reaction_id) => {

    const reactions_filtered = reactionsData.reactions.filter(data => data.reaction_id === reaction_id);
    if(reactions_filtered.length){
      //console.log("😎", reactions_filtered);
      return reactions_filtered[0].count;
    }else{
      return 0;
    }
    //setReactionsData
   // console.log("reaction_id", reaction_id)

   /*
    const reaction = reactionsData.filter(reaction => reaction._id === reaction_id);
    if(reaction.length){
      return reaction[0].count;
    }else{
      return 0;
    }
    */

    /*
    const postsCommentsReaction = postsCommentsReactions.filter(comment_reaction => comment_reaction._id === data._id);
    if(postsCommentsReaction.length){
      //console.log("----> postsCommentsReactions", postsCommentsReaction[0], " ** ", postsCommentsReaction[0].reactions)
    }
    */
    /*
    

    if(reaction === "like"){
      return data.likes_counter ? data.likes_counter : 0;
    }else{
      const result = data.reaction_counter.filter(r => r.name == reaction);
      if(result.length){
        return result[0]?.count ? result[0].count : 0;
      }else{
        return 0;
      }
    }
      */
  }
  useEffect(() => {
    //console.log("--->isOver", isOver)
    //<div className='fill_heart'><AiFillHeart /></div>
    return () => {}
  }, [isOver])
  /*
  useEffect(() => {

    if(postsCommentsReactions){
      const postExist = postsCommentsReactions.filter(post => post.post_id === data._id);
      if(postExist.length){
        console.log("🔥", postExist[0])
        setReactionsData(postExist[0]);
      }
    }
  
    return () => {}
  }, [postsCommentsReactions])
    

  useEffect(() => {
    console.log("-> reactionsData", reactionsData)
  
    return () => {}
  }, [reactionsData])
  */

  if(!reactionsData && !postsCommentsReactions.length && !data){
    return null;
  }

  return (

    <WrapperStyled
      className={className}
      isLoading={isLoading}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      //onClick={() => { !isLoading && onClick()}}
    >
      {activeReactions && activeReactions.map((reaction, index) => {
        //console.log("reaction --> ", reaction)
        return(
          <div className='reaction_icon'>
            <div className='icon' onClick={() => doReaction(reaction.name, reaction._id)}>
              {reaction.name == "heart" && <BsFillHeartFill className={`fill_heart`} />/*<img src={'./images/icon_reaction_like.png'} />*/}
              {reaction.name == "thinking" && <img src={'./images/icon_reaction_thinking.png'} />}
              {reaction.name == "party" && <img src={'./images/icon_reaction_party.png'} />}
              {reaction.name == "clap" && <img src={'./images/icon_reaction_clap.png'} />}
              {reaction.name == "smile" && <img src={'./images/icon_reaction_smile.png'} />}
            </div>
            <div className='counter'>{(data && reactionsData) && getReactionCount(reaction._id)}</div>
          </div>
        )
      })}

    </WrapperStyled>

  )
}

export default Reactions;
