import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import { AiFillEyeInvisible,
         AiFillEye } from "react-icons/ai";

import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import "moment/locale/es";
moment.locale('es');

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .session_timeout{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    .inner{
      padding: 20px;
      width: calc(100% - 50px);
      background-color: rgba(255, 255, 255, 1);
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      border-radius: 12px
    }
  }
  .box_error{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(255,255,255, 0.85);
    width: 100%;
    height: 100%;
    z-index: 999;
    .box_error_inner{
      width: calc(100% - 50px);
      border: 1px solid #ececec;
      border-radius: 6px;
      padding: 10px 10px;
      background-color: #FFFFFF;
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      .title{
        font-weight: 500;
        //color: red;
        padding-bottom: 7px;
        margin-bottom: 12px;
        border-bottom: 1px solid #ececec;
      }
      .messages{
        p{
          color: red;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .single{
    display: flex;
    flex-direction: column;
    width: 100%;
    &.fecha_nacimiento{
      //margin-top: 5px;
      >label{
        margin-bottom: 5px!important;
      }
    }
  }
  .double{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .field{
      width: calc(50% - 7px);
      
      .field_phone{
        display: flex;
        flex-direction: row;
        .prefix{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          font-size: 15px;
          line-height: 15px;
          font-weight: 600;
          background-color: #edeef0;
        }
      }
    }
  }
  .tiple{
    display: flex;
    flex-direction: row;
  }
  .field{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    &.field_password{
      margin-top: 15px;
      position: relative;
      .password_show{
        width: 50px;
        right: 0;
        top: 21px;
        text-align: center;
        position: absolute;
        font-size: 30px;
        line-height: 41px;
        padding-bottom: 1px;
        cursor: pointer;
        :hover{
          color: #FFD200;
        }
      }
    }
    >label{
      display: flex;
      width: 100%;
      margin-bottom: 1px;
      color: #242424;
    }
    input,
    select{
      width: 100%;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 18px;
      border: 1px solid #edeef0;
      
      ::placeholder {
        color: #a8a8a8;
        font-size: 16px;
      }
      :focus{
        outline: none;
      }
      *:focus {
        outline: none;
      }
      &.error{
        border: 1px solid red!important;
      }
    }
    &.dia,
    &.mes,
    &.ano{
      width: calc(33% - 5px);
    }
    
    small{
      color: #797979;
      font-size: 13px;
      line-height: 17px;
      margin-top: 5px;
    }
  }
  .nacimiento{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    label{
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 5px;
    }
  }
`

const Input = styled.input`
  
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .loader{
    margin-top: 15px!important;
    height: 40px;
  }
`
const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  p{
    color: #E02020;
    width: 100%;
    font-size: 14px;
    text-align: right;
  }
`

const CompleteUser = (props) => {

  const { userAttributes,
          userAuth,
          setShowLogin } = props;

  const { Auth,
          login,
          isAuthLoading,
          loginError,
          setLoginError,
          signIn,
          setNewPasswordRequired } = useContext(AuthContext);

  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ repeatPassword, setRepeatPassword ] = useState("");
  const [ passwordShow, setPasswordShow ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ birthDateDay, setBirthDateDay ] = useState("");
  const [ birthDateMonth, setBirthDateMonth ] = useState("");
  const [ birthDateYear, setBirthDateYear ] = useState("");
  const [ yearSelect, setYearSelect ] = useState(null);
  const [ gender, setGender ] = useState("");
  
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ formError, setFormError ] = useState([]);
  const [ formErrorMessages, setFormErrorMessages ] = useState([]);
  const [ repeatPasswordShow, setRepeatPasswordShow ] = useState(false);
  const [ sessionTimeout, setSessionTimeout ] = useState(false);
 

  const [ errorFields, setErrorFields ] = useState({user: false, password: false});

  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const yearLimit = moment().subtract(18, 'years').year();

  const [ haveError, setHaveError ] = useState({
    first_name: { status: false, message: "" },
    last_name: { status: false, message: "" },
    email: { status: false, message: "" },
    phone: { status: false, message: "" },
    birth_date: { status: false, message: "" },
  });

  const handleFormSubmit = async () => {
    
    let formErrorMessagesArr = [...formErrorMessages];

    if(!firstName.length || !lastName.length || !gender.length || !email.length || !phone.length || !password.length || !repeatPassword.length || !birthDateDay.length || !birthDateMonth.length || !birthDateYear.length){
      formErrorMessagesArr.push("- Todos los campos son obligatorios.");
    }else{
      if(!validateEmail(email)){
        formErrorMessagesArr.push("- El Email que ingresaste es incorrecto, recordá que debe contener @ y .");
      }
      if (password !== repeatPassword) {
        formErrorMessagesArr.push("- Las contraseñas no coinciden.");
      }
      if (password.length) {
        if(!validarPass(password)){
          formErrorMessagesArr.push("- La contraseña debe tener al menos 8 caracteres, al menos una letra mayúscula y al menos un carácter especial.");
        }
      }
    }

    if(formErrorMessagesArr.length){
      setFormErrorMessages(formErrorMessagesArr);
    }else{
      try {
          console.log("TRY")
          setLoading(true);
          let update_pass = await Auth.completeNewPassword(userAuth, password)
          const updatedAttributes = {}
          updatedAttributes.name = firstName
          updatedAttributes.family_name = lastName
          updatedAttributes.birthdate = birthDateYear + "-" + birthDateMonth + "-" + birthDateDay;
          updatedAttributes.gender = gender
          updatedAttributes.phone_number = '+549' + phone
          let update = await Auth.updateUserAttributes(update_pass, updatedAttributes);
          setLoading(false);
          setLoginError(false);
          setShowLogin(true);
          setNewPasswordRequired({});
      } catch (error) {
          console.log("CATCH")
          setLoading(false);
          const errorMessage = error.message || error.toString()
          if (errorMessage.includes('Invalid session for the user, session is expired')) {
            setSessionTimeout(true);
          } else {
            let formErrorMessagesArr = [...formErrorMessages];
            formErrorMessagesArr.push("- Ha ocurrido un error al intentar cambiar la contraseña.");
            setFormErrorMessages(formErrorMessagesArr);
          }
      }
    }

  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const validarPass = (pass) => {
    // Verificar longitud de la contraseña
    if (pass.length < 8) {
        return false
    }

    // Verificar que tenga al menos una mayúscula
    const mayusculaRegex = /[A-Z]/
    if (!mayusculaRegex.test(pass)) {
        return false
    }

    // Verificar que tenga al menos un carácter especial
    const caracterEspecialRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/
    if (!caracterEspecialRegex.test(pass)) {
        return false
    }

    // Si pasa todas las verificaciones, es válida
    return true
}

  const fillSelect = () => {
    let items = [];
    for (let year = yearLimit; year > 1900; year--) {
      items.push(year);
    }
    setYearSelect(items);
  }

  const handleCloseError = () => {
    setFormErrorMessages([]);
  }

  useEffect(() => {
    
    fillSelect();
  
    return () => {}
  }, [])
  
  useEffect(() => {

    console.log("userAttributes", userAttributes)
    if(userAttributes){

      if("name" in userAttributes){
        setFirstName(userAttributes.name);
      }
      if("family_name" in userAttributes){
        setLastName(userAttributes.family_name);
      }
      if("email" in userAttributes){
        setEmail(userAttributes.email);
      }
      if("gender" in userAttributes){
        setGender(userAttributes.gender);
      }

      const prefix = '+549';

      if("phone_number" in userAttributes){
        if(userAttributes.phone_number.startsWith(prefix)){
          setPhone(userAttributes.phone_number.substr(prefix.length));
        }else{
          setPhone(userAttributes.phone_number);
        }
      }

      if("birthdate" in userAttributes){
        if(userAttributes.birthdate){
          const birthdayFormat = moment(userAttributes.birthdate).format("yyyy-MM-DD");
      
          setBirthDateDay(userAttributes.birthdate.split('-')[2].substring(0,2));
          setBirthDateMonth(userAttributes.birthdate.split('-')[1]);
          setBirthDateYear(userAttributes.birthdate.split('-')[0]);
        }
      }

    }
    return () => {}
  }, [userAttributes])
  
  const handleGotoLogin = () => {
    setLoginError(false);
    setShowLogin(true);
    setNewPasswordRequired({});
  }

  return (
    <Wrapper>
      {sessionTimeout ? 
      <div className='session_timeout'>
        <div className='inner'>
          El tiempo de sesión finalizó, para cambiar tu contraseña volve a ingresar con tu dni y contraseña temporal
          <ButtonYellow className="bt_login" onClick={handleGotoLogin}>
            <p>CONTINUAR</p>
          </ButtonYellow>
        </div>
      </div> : "" }

      {formErrorMessages.length ? 
      <div className='box_error'>
        <div className='box_error_inner'>
          <div className='title'>Para continuar corrija los siguietes errores</div>
          <div className='messages'>
            {formErrorMessages.map((message, index) => {
              return(
                <p>{ message }</p>
              )
            })}
          </div>
          <ButtonYellow className="bt_login" onClick={handleCloseError}>
            <p>CERRAR</p>
          </ButtonYellow>
        </div>
      </div> : "" }

      <div className='double'>
        <div className='field'>
          <label>Nombre *</label>
          <input
            type="text"
            placeholder="Ingresá tu Nombre..."
            value={firstName}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setFirstName(e.currentTarget.value)}
          />
        </div>

        <div className='field'>
          <label>Apellido *</label>
          <input
            type="text"
            placeholder="Ingresá tu Apellido..."
            value={lastName}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setLastName(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className='single fecha_nacimiento'>
        <label>Fecha de Nacimiento *</label>
        <div className='nacimiento'>
          <div className='field dia'>
            <label>Dia *</label>
            <select
              value={birthDateDay == null ? "" : birthDateDay}
              onChange={(e) => { console.log("e.target.value", e.target.value); setBirthDateDay(e.target.value); } }
              className={haveError.birth_date.status ? "is_invalid" : ""}
            >
              <option value="">Día...</option>
              {
                days.map((day, index) => {
                  return(
                    <option vlaue={day}>{day}</option>
                  )
                })
              }
            </select>
          </div>
          <div className='field mes'>
            <label>Mes *</label>
            <select
              value={birthDateMonth == null ? "" : birthDateMonth}
              onChange={(e) => { setBirthDateMonth(e.target.value); }}
              className={haveError.birth_date.status ? "is_invalid" : ""}
            >
              <option value="">Mes...</option>
              {
                months.map((month, index) => {
                  return(
                    <option vlaue={month}>{month}</option>
                  )
                })
              }
            </select>
          </div>
          <div className='field ano'>
            <label>Año *</label>
            <select
              value={birthDateYear == null ? "" : birthDateYear}
              onChange={(e) => { setBirthDateYear(e.target.value); }}
              className={haveError.birth_date.status ? "is_invalid" : ""}
            >
              <option value="">Año...</option>
              {yearSelect && yearSelect.map((year, index) => {
                return <option key={index} value={year}>{year}</option>
              })}
            </select>
          </div>
        </div>
      </div>

      <div className='single'>
        <div className='field'>
          <label>Género *</label>
          <select
            type="text"
            placeholder="Ingresá tu Género..."
            value={gender}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setGender(e.currentTarget.value)}
          >
            <option value="">Selecciona una opción...</option>
            <option value="Mujer">Femenino</option>
            <option value="Hombre">Masculino</option>
            <option value="X">X</option>
          </select>
        </div>
      </div>

      <div className='double'>
        <div className='field'>
          <label>Celular *</label>
          <div className='field_phone'>
            <div className='prefix'>+549</div>
            <input
              type="text"
              placeholder="Ingresá tu Celular..."
              value={phone}
              className={errorFields.user ? 'error' : '' }
              onChange={(e) => setPhone(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className='field'>
          <label>Email *</label>
          <input
            type="text"
            placeholder="Ingresá tu Email..."
            value={email}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
      </div>
        
      <div className='single'>
        <div className='field field_password'>
          <label>Contraseña *</label>
          <input
            type={passwordShow ? 'text' : 'password'}
            placeholder="Contraseña..."
            value={password}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <div className="password_show" onClick={() => setPasswordShow(!passwordShow)}>
            { passwordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
          </div>
          <small>* Debe contener más de 8 carácteres<br/>* Debe contener al menos 1 letra mayúscula<br/>* Debe contener al menos 1 carácter extraño (ej: $!#?¡¿)</small>
        </div>
      </div>

      <div className='single'>
        <div className='field field_password'>
          <label>Confirman Contraseña *</label>
          <input
            type={repeatPasswordShow ? 'text' : 'password'}
            placeholder="Confirman Contraseña..."
            value={repeatPassword}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setRepeatPassword(e.currentTarget.value)}
          />
          <div className="password_show" onClick={() => setRepeatPasswordShow(!repeatPasswordShow)}>
            { repeatPasswordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
          </div>
        </div>
      </div>

      {/* <Field className="field_password">
        <Label>Apellido:</Label>
        <Input
          type={passwordShow ? 'text' : 'password'}
          placeholder="Ingresá tu Contraseña."
          value={password}
          className={errorFields.password ? 'error' : '' }
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <div className="password_show" onClick={() => setPasswordShow(!passwordShow)}>
            { passwordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
        </div>
      </Field> */}

      {/* {formError &&
        <ErrorMessage>
          <p>Hay un error en el D.N.I o contraseña ingresada.</p>
        </ErrorMessage>
      } */}

      <Actions>

        {loading ?
        <Spinner className="loader" />
        :
        <ButtonYellow className="bt_login" onClick={handleFormSubmit}>
          <p>ENVIAR</p>
        </ButtonYellow>
        }

      </Actions>

    </Wrapper>
  )
}

export default CompleteUser;
