import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import { breakpoints } from '../../_helpers/breakpoints';
import { AiOutlineCloseCircle } from "react-icons/ai";
import Media from './Media';

const Wrapper = styled.div`

    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: black;

    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  outline: 0!important;
  border-radius: 16px;

  position: absolute;
  top: 20px!important;
  overflow: scroll;
  margin: 0 auto;

  height: 100%;

  .inner{
    border-radius: 16px;
    overflow: hidden;
    width: calc(100vw - 30px);
    //width: 650px;
    //background-color: #000000;
    outline: 0!important;

    //-webkit-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0,0.5);
    //box-shadow: 0px 0px 15px -3px rgba(0, 0, 0,0.5);

    .media{
      position: relative;
      width: calc(100vw - 30px);//97vw;
      height: calc(100vw - 30px);//97vw;
      overflow: hidden;
      //border: ${props => props.scope == "drawer" ? "0" : "1px"} solid #E8E8E8;

      .modal_close{
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        font-size: 35px;
        display: flex;
        cursor: pointer;
        padding: 3px;
        border-radius: 25px;
        color: #000000;
        background-color: #FFFFFF;
        :hover{
          background-color: #FFC600;
          color: #FFFFFF;
        }
      }

      img{
        float: left;
        width: 100%;
      }
    }
  }
  :focus{
    outline: none!important;
  }

  .carousel-control-prev-icon{
    width: 40px!important;
    height: 40px!important;
  }
  .carousel-control-next-icon{
    width: 40px!important;
    height: 40px!important;
  }

  @media ${breakpoints.xs} {//576px
    .inner{
      width: calc(100vw - 30px);
      //margin-left: 15px;
      .media{
        width: calc(100vw - 30px);
        height: calc(100vw - 30px);
      }
    }
  }
  @media ${breakpoints.sm} {//768px
    .inner{
      width: 363px;
      //margin-left: 0;
      .media{
        width: 363px;
        height: 363px;
      }
    }
  }
  @media ${breakpoints.md} {//992px
    .inner{
      width: 550px;
      .media{
        width: 550px;
        height: 550px;
      }
    }
  }
  @media ${breakpoints.lg} {//1200px
    .inner{
      width: 600px;
      .media{
        width: 600px;
        height: 600px;
      }
    }
  }
  @media ${breakpoints.xl} {//1366px
    .inner{
      .media{
        width: 600px;//385px;
        height: 600px;//385px;
      }
    }
  }
  @media ${breakpoints.xxl} {//1440px
    .inner{
      width: 650px;
      .media{
        width: 650px;
        height: 650px;
      }
    }
  }
  @media ${breakpoints.xxxl} {//1680px
    .inner{
      width: 800px;
      .media{
        width: 800px;
        height: 800px;
      }
    }
  }
  @media ${breakpoints.xxxxl} {//1920px
    .inner{
      .media{
        width: 800px;
        height: 800px;
      }
    }
  }

`

const ModalPostMedia = (props) => {

  const { postMediaSelected,
          setPostMediaSelected } = props;

  const [ openModal, setOpenModal ] = useState(true);

  const handleClose = () => {
    setPostMediaSelected(null);
    setOpenModal(false);
  }

  return (
    <ModalStyled
      disableAutoFocus={true}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      breakpoints={breakpoints}
    >
      <Fade in={openModal}>
        <div className="inner">
          <div className='media'>
            <div className='modal_close' onClick={() => handleClose()}>
              <AiOutlineCloseCircle />
            </div>
            <Media
              data={postMediaSelected}
              scope={"modal_media"}
              medias={postMediaSelected?.medias}
            />
          </div>
        </div>
      </Fade>

    </ModalStyled>
  )

}

export default ModalPostMedia;
