import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    padding-top: 4vw;
    //background-color: #F4F5F9;
    //margin-top: 5vw;

    .inner{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90vw;
      //height: 422px;
      padding: 6vw 6vw 6vw 6vw;
      border-radius: 16px;
      background-color: #FFFFFF;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
      margin-bottom: 4vw;

      .left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80vw;
        .atencion{
          width: 70vw;
          display: flex;
          align-self: center;
          margin-bottom: 2vw;
          img{
            width: 100%;
          }
        }
        .texto1{
          margin-top: 2.5vw;
          margin-bottom: 2.5vw;
          width: 100%;//calc(100% - 10vw);

          p{
            margin: 0;
            font-size: 3.5vw;
            font-weight: 600;
            line-height: 5vw;
            text-align: left;
          }
          .wait{
              text-align: center;
              font-size: 4.5vw;
              line-height: 5vw;
              margin-bottom: 5vw;
              .nowrap{
                white-space: nowrap;
              }
            }
        }
        .texto2{
          //margin-top: 1vw;
          p{
            margin: 0 0 2vw 0;
            font-size: 3.3vw;
            line-height: 4.3vw;
            text-align: left;
          }
        }
        .texto3{
          margin-top: 4vw;
          p{
            margin: 0;
            font-size: 4vw;
            line-height: 4.8vw;
            font-weight: 800;
            color: #E10A17;
            text-align: left;
          }
        }
      }
      .right{
        width: 80vw;
        margin-top: 0;
        .box_working{
          //margin-top: 50px;
          .image_working{

          }
          .texto_yavolvemos{
            text-align: center;
            font-size: 3.8vw;
          }
        }

        .texto4{
          margin-top: 1vw;
          p{
            width: 100%;
            text-align: left;
            margin: 0;
            font-size: 3vw;
            .mail{
              color: #E10A17;
            }
          }
        }
      }
    }

    @media ${breakpoints.xs} {
      justify-content: flex-start;
      padding-top: 30px;
      .inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        //height: 422px;
        padding: 30px 30px 30px 30px;
        border-radius: 16px;
        margin-bottom: 30px;
        .left{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .atencion{
            width: 300px;
            margin-bottom: 10px;
          }
          .texto1{
            margin-top: 0;
            width: 100%;//calc(100% - 20px);
            margin-bottom: 20px;
            p{
              font-size: 18px;
              line-height: 25px;
            }
            .wait{
              text-align: center;
              font-size: 23px;
              line-height: 27px;
              margin-bottom: 25px;
            }
          }
          .texto2{
            //margin-top: 20px;
            p{
              margin: 0 0 10px 0;
              font-size: 16px;
              line-height: 22px;
            }
          }
          .texto3{
            margin-top: 20px;
            p{
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
        .right{
          width: 340px;
          .box_working{
            margin-top: 0px;
            .image_working{

            }
            .texto_yavolvemos{
              text-align: center;
              font-size: 23px;
              font-weight: 600;
              margin-top: 10px;
              margin-bottom: 30px;
            }
          }

          .texto4{
            margin-top: 5px;
            p{
              width: 100%;
              text-align: left;
              font-size: 14px;
            }
          }
        }
      }
    }
    @media ${breakpoints.sm} {

      justify-content: flex-start;
      padding-top: 30px;
      .inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 600px;
        //height: 422px;
        padding: 30px 30px 30px 30px;
        border-radius: 16px;
        margin-bottom: 30px;
        .left{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .atencion{
            width: 400px;
            margin-bottom: 10px;
          }
          .texto1{
            margin-top: 10px;
            width: 100%;//calc(100% - 20px);
            p{
              font-size: 18px;
              line-height: 25px;
            }
            .wait{
              text-align: center;
              font-size: 23px;
              line-height: 27px;
              margin-bottom: 25px;
            }
          }
          .texto2{
            //margin-top: 20px;
            p{
              margin: 0 0 10px 0;
              font-size: 16px;
              line-height: 22px;
            }
          }
          .texto3{
            margin-top: 20px;
            p{
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
        .right{
          width: 500px;
          .box_working{
            margin-top: 0;
            .image_working{

            }
          }

          .texto4{
            margin-top: 5px;
            p{
              width: 100%;
              text-align: left;
              font-size: 14px;
            }
          }
        }
      }
    }
    @media ${breakpoints.md} {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 30px;
      overflow: auto;
      .inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 800px;
        //height: 422px;
        padding: 30px 30px 30px 30px;
        border-radius: 16px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        .left{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .atencion{
            width: 400px;
            margin-bottom: 10px;
          }
          .texto1{
            margin-top: 10px;
            width: 100%;//calc(100% - 20px);
            p{
              font-size: 18px;
              line-height: 25px;
            }
            .wait{
              text-align: center;
              font-size: 25px;
              line-height: 30px;
              margin-bottom: 25px;
            }
          }
          .texto2{
            //margin-top: 20px;
            p{
              margin: 0 0 10px 0;
              font-size: 16px;
              line-height: 22px;
            }
          }
          .texto3{
            margin-top: 20px;
            p{
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
        .right{
          width: 700px;
          .box_working{
            margin-top: 0;
            .image_working{

            }
          }

          .texto4{
            margin-top: 5px;
            p{
              width: 100%;
              text-align: left;
              font-size: 14px;
            }
          }
        }
      }
    }
    @media ${breakpoints.lg} {

      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-color: #F4F5F9;
      padding-top: 30px;
      .inner{
        display: flex;
        flex-direction: column;
        //width: 770px;
        width: 1100px;
        //height: 422px;
        padding: 30px 30px 30px 30px;
        border-radius: 16px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        .left{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          .atencion{
            width: 400px;
            margin-bottom: 10px;
            img{
              width: 100%;
            }
          }

          .texto1{
            margin-top: 10px;
            width: 100%;//calc(100% - 20px);
            p{
              margin: 0;
              font-size: 16px;
              font-weight: 500;
              line-height: 23px;
              text-align: left;
            }
            .wait{
              text-align: center;
              font-size: 25px;
              line-height: 30px;
              margin-bottom: 25px;
            }
          }
          .texto2{
            //margin-top: 20px;
            p{
              margin: 0 0 10px 0;
              font-size: 16px;
              line-height: 22px;
              text-align: left;
            }
          }
          .texto3{
            margin-top: 20px;
            p{
              margin: 0;
              font-size: 20px;
              line-height: 26px;
              font-weight: 800;
              color: #E10A17;
              text-align: left;
            }
          }
        }
        .right{
          width: 700px;
          .box_working{
            margin-top: 0;
            .image_working{

            }
          }

          .texto4{
            margin-top: 5px;
            p{
              width: 100%;
              text-align: center;
              margin: 0;
              font-size: 14px;
            }
          }
        }
      }

    }
`

const ProximamenteContainer = ({children, className}) => {

  return (
    <Wrapper>
      <div className="inner">

        <div className="left">

          <div className="atencion">
            <img src="/images/atencion.svg" />
          </div>

          {/* <div className="texto1">
            <p className='wait'>¡Estamos ultimando detalles, <span className="nowrap">volvé en un ratito!</span></p>
            <p>Para ese momento, tené presente las siguientes indicaciones:</p>
          </div>
          <div className="texto2">
            <p>- Desde el Celular: Descargar la aplicación disponible en iOS y Android con el nombre "Somos Protagonistas". La aplicación anterior deberás desinstalarla.</p>
            <p>- Desde la Web: Continuá utilizando la versión web en www.somosprotagonistas.com.ar.</p>
            <p>- Para ingresar a tu cuenta, utilizar DNI y la contraseña protagonistas2023</p>
            <p>- Para ingresar al panel, sp_DNI (sp_ y tu DNI) y la contraseña protagonistas2023</p>
            <p>Por motivos de seguridad, reestablecimos todas las contraseñas, podrán ser modificadas accediendo desde el perfil.</p>
          </div> */}
          {/* <div className="texto2">
            <p>Además, una web renovada bajo el mismo nombre<br/>www.somosprotagonistas.com.ar</p>
          </div>
          <div className="texto3">
            <p>¿ESTÁS LISTO<brgit status/>PARA TODO LO QUE SE VIENE?</p>
          </div> */}

        </div>

        <div className="right">
          <div className="box_working">
            <div className="texto_yavolvemos">
              <p>¡Continuamos con las tareas de mantenimiento!<br/>Te esperamos mañana para que ingreses nuevamente desde la web y actualices tu app desde el store de tu celular.</p>{/*Estamos realizando tareas de mantenimiento. ¡Ya volvemos!*/}
            </div>
            <div className="image_working">
              <img src="/images/working.svg" />
            </div>
            <div className="texto4">
              <p>Ante cualquier duda o consulta para soporte, comunicante a <span className="mail">contacto@somosprotagonistas.com.ar</span></p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )

}

export default ProximamenteContainer;
