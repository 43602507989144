import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import { truncate } from '../../_helpers/commons';
import { useHistory, useLocation } from 'react-router-dom';
//import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiCheckCircle } from "react-icons/bi";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  cursor: ${props => props.data.media.length ? 'pointer' : 'default'};
  >.inner{
    background-image: ${props => props.data.icon ? 'url('+props.data.icon+')' : ''};
    background-size: contain;
    width: 150px;
    height: 150px;
    border-radius: 30px;
    border: 2px solid #CECECE;
    background-color: #FFFFFF;
    box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.3);
    :hover{
      border-color: ${props => props.data.media.length ? '#FFD200' : '#CECECE'};
    }
  }
  .title{
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  >.viewed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: rgba(255,255,255,0.5);
    font-size: 28px;
    line-height: 28px;
    padding-top: 4px;
    padding-right: 4px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 10px;
    width: 40px;
    height: 40px;
    z-index: 9;
    top: 0;
    right: 0;
  }
`

const Contenido = (props) => {

  const { data,
          handleContentClick } = props;

  const history = useHistory();

  const { login,
          isAuthLoading,
          loginError } = useContext(AuthContext);

  const handleClick = () => {
    handleContentClick(data);
    //history.push('/shellbox/content/1');
  }

  return (
    <Wrapper data={data} onClick={ () => data.media.length ? handleClick() : null }>
      {data.viewed ?
        <div className="viewed">
          <BiCheckCircle />
        </div>
      :
        ""
      }
      <div className="inner"></div>
      <div className="title">{truncate(data.title, '...', 30)}</div>
    </Wrapper>
  )
}

export default Contenido;
