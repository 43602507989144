import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ImageRound,
         ButtonYellow } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';

import { IcoCheck,
         IcoChevronLeft,
         IcoPDFFile,
         COLORS } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import { openInNewTab } from '../../_helpers/commons';
import { Trivia } from '../../_components';

import Materials from './Materials';
import VideoPager from './VideoPager';
import { Modal, Fade, Backdrop, Box } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  .data{

    display: flex;
    width: 100%;
    flex-direction: column;

    .tag_complete{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4C8F05;
      width: 90px;
      height: 20px;
      border-radius: 4px;
      margin-top: 10px;
      p{
        margin: 0;
        padding-top: 1px;
        font-size: 11px;
        line-height: 11px;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .description{
      margin-top: 15px;
      font-size: 13px;
      line-height: 21px;
      p{
        color: ${props => props.color.description};//${COLORS.primary};
        margin: 0;
      }
    }

    .bt_cuestionario{
      display: flex;
      margin-left: auto;
      &.MuiButton-root{
        &.Mui-disabled{
          color: #000000!important;
          background-color: #FFFFFF!important;
          border: 1px solid #000000!important;
        }
      }
      p{
        padding: 0;
        margin: 0;
      }
      .ico{
        display: flex;
        margin-left: 7px;
        svg{
          height: 19px;
          path{
            fill: #000000;
          }
        }
      }
      :hover{
        .ico{
          svg{
            path{
              fill: #FFD200;
            }
          }
        }
      }
    }
    .bt_videos{
      margin-left: 10px;
      width: 130px;
      &.MuiButton-root{
        &.Mui-disabled{
          color: #000000!important;
          background-color: #FFFFFF!important;
          border: 1px solid #000000!important;
        }
      }
      p{
        padding: 0;
        margin: 0;
      }
      .ico{
        display: flex;
        margin-left: 7px;
        svg{
          height: 19px;
          path{
            fill: #000000;
          }
        }
      }
      :hover{
        .ico{
          svg{
            path{
              fill: #FFD200;
            }
          }
        }
      }
    }
    .training_bottom_actions{
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
      &.spacebetween{
        justify-content: space-between;
      }
    }
    .data_inner{

      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .avatar_image{
        display: flex;
      }
      .training_data{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .title{
          color: ${COLORS.primary};
          font-size: 15px;
          font-weight: 600;
        }
        .module_number{
          font-size: 13px;
          font-weight: 600;
          color: ${props => props.color.module};
        }
      }

      .training_actions{
        display: flex;
        margin-left: auto;

        .box_like{
          display: flex;
          flex-direction: row;
          margin-right: 10px;
          .like_count{
            display: flex;
            flex-direction: row;
            align-items: center;
            p{
              margin: 0;
              padding: 0;
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
            }
          }


          .bt_like{
            margin-right: 10px;
            cursor: pointer;
            svg{
              fill: #000000;
            }
            :hover{
              svg{
                fill: #FFD200;
              }
            }
          }

          .bt_dislike{
            margin-right: 10px;
            cursor: pointer;
            svg{
              fill: #FFD200;
            }
            :hover{
              svg{
                fill: #000000;
              }
            }
          }
        }
        .bt_materials{
          display: flex;
          flex-direction: row;
          cursor: pointer;
          svg{
            transition: none;
            fill: #000000;
          }
          p{
            margin: 0 5px 0 0;
            padding: 0;
            font-weight: 600;
          }
          :hover{
            svg{
              fill: #FFD200;
            }
            p{
              color: #FFD200;
            }
          }
        }
      }

    }

  }
`
const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

    justify-content: center;
    align-items: center;
    overflow-y: hidden;

  }
  @media ${breakpoints.md} {


  }
  @media ${breakpoints.lg} {


  }
`
const ContainerTrivia = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background-color: #FFFFFF;
  border-radius: 0;
  .inner_trivia{
    width: 100%;
    padding: 30px;
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

    width: 680px;
    min-height: 200px;
    border-radius: 16px;

  }
  @media ${breakpoints.md} {


  }
  @media ${breakpoints.lg} {


  }
`

const TrainingData = (props) => {

  const { title,
          description,
          icon,
          courseComplete,
          courseVideos,
          materials,
          evaluation_finish,
          statusCuestionarioFinish,
          handlChangeVideo,
          quizId,
          colors,
          onClickTrigger
        } = props;

  //const [ quizId, setQuizId ] = useState(null);
  const [ approve, setApprove ] = useState(false);
  const [ openQuiz, setOpenQuiz ] = useState(false);


  const handleQuizOpen = () => setOpenQuiz(true);
  const handleQuizClose = () => setOpenQuiz(false);

  const handleFinish = (approve) => {
    alert(approve)
    setApprove(approve);
  }

  return (
    <Wrapper color={colors}>

      <ModalStyled
        open={openQuiz}
        onClose={handleQuizClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openQuiz}>
          <ContainerTrivia>
            <div className="inner_trivia">
              <Trivia closeQuiz={setOpenQuiz} id_evaluation={quizId} finish={handleFinish} />
            </div>
          </ContainerTrivia>
        </Fade>

      </ModalStyled>


      <div className="data">
        <div className="data_inner">
          <div className="avatar_image">
            <ImageRound className="image_round" css_styles={{width: 45, height: 45}}>
              <img src={icon/*course.modulos[0].icon*/} />
            </ImageRound>
          </div>
          <div className="training_data">
            <div className="title">{title}</div>
            <div className="module_number">Módulo 1</div>
          </div>
        </div>

        <div className="description">
          <p>
            {description}
          </p>
        </div>

        {(courseVideos !== null && courseVideos.length > 1) &&
          <VideoPager courseVideos={courseVideos} />
        }

        <div className={`training_bottom_actions ${materials && materials.length && 'spacebetween'}`}>
          {materials &&
              materials.length &&
              <Materials materials={materials} onClickTrigger={onClickTrigger} />
          }

        </div>
        {/*
        <ButtonYellow
          className="bt_cuestionario"
          onClick={() => {
            if(approve !== true){
              setOpenQuiz(true);
              //openQuiz(quizId)
            }
          }}
          disabled={approve}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
        >

          <p>{ 'CUESTIONARIO' }</p>
          {statusCuestionarioFinish ?
          <div className="ico">
            <IcoCheck />
          </div>
          :<></>
          }
        </ButtonYellow>
        */}
      </div>




    </Wrapper>
  )
}

export default TrainingData;
