import React, { useState, useContext, useEffect, Fragment, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../_helpers/breakpoints';
import { AudioPlayer } from '../../../_elements';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../../_elements';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;

  .audio_player{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: relative;
    .audio_player_background{

      background-image: ${props => props.background ? 'url('+props.background+')' : ''};
      //background-image: ${props => props.background ? 'url('+props.background+')' : ''};

       /* Add the blur effect */
      //filter: blur(8px);
      //-webkit-filter: blur(8px);
      opacity: .25;

      /* Full height */
      width: 100%;
      height: 100%;

      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .audio_player_inner{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      height: 100%;
      position: absolute;
      //background-color: skyblue;
      canvas{
        width: 100%!important;
        height: 250px!important;
      }
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const Reactions = (props) => {

  const { data,
          className,
          isLoading,
          onClick,
          background,
          playStatus,
          setPlayStatus } = props;

  const trainingEl = useRef(null);

  const handleViewedPodcastContent = () => {

  }


  return (

    <WrapperStyled
      className={className}
      isLoading={isLoading}
      background={background}
      //onClick={() => { !isLoading && onClick()}}
    >
        <div className="audio_player">
          <div className='audio_player_background'></div>
          <div className='audio_player_inner'>
            <AudioPlayer
              elRef={trainingEl}
              handleViewedContent={handleViewedPodcastContent}
              url={data.media_url}
              _bars={55}
              _bar_x={17}
              _bar_width={10}
              playStatus={playStatus}
              setPlayStatus={setPlayStatus}
              newCampus={true}
            />
          </div>
        </div>

    </WrapperStyled>

  )
}

export default Reactions;
