import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { ButtonHeader, ImageRound, ButtonActionRounded, ButtonYellow} from '../../_elements';
import { BsMegaphone, BsListUl } from "react-icons/bs";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { PiBookOpenText } from "react-icons/pi";
import { LuFolderUp } from "react-icons/lu";
import ReactPlayer from 'react-player';
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCarSport } from "react-icons/io5";
import Button from '@mui/material/Button';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import { AuthContext,
         ContentContext } from '../../_context';

import { downloadFileFromUrl, 
         incentivoTiendas, 
         bocas_seguridad_2024, 
         dnis_seguridad_2024, 
         show_baristas } from '../../_helpers/commons';

import { ReactComponent as MetaIconSVG } from '../../_assets/images/meta_icono.svg';
import { ReactComponent as BaristaIcon } from '../../_assets/images/baristas_icon.svg';
import { ReactComponent as MetaIconAutitoSVG } from '../../_assets/images/meta_icono_autito.svg';

const WrapperStyled = styled.div`
    position: fixed;
    display: none;
    width: 110px;
    min-height: 100%;
    background-color: #FFFFFF;
    border-right: 1px solid #F6F6F6;
    z-index: 9;
    box-sizing: border-box;
    >.inner{
      width: 100%;
      margin-top: 90px;
      ul{
        list-style: none;
        padding: 0;
        li{
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px;
          padding-bottom: 20px;
          cursor: pointer;

          .line_selected{
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 3px;
            height: 100%;
            background-color: #D8010F;
          }
          .icon{
            display: flex;
            font-size: 20px;
            line-height: 20px;
            
            &.campus{
              font-size: 25px;
            }
            &.products{
              font-size: 25px;
            }
            &.autitos{
              width: 32px;
              //height: 27px;
              svg{
                fill: #212529;
              }
            }
            &.meta{
              width: 27px;
              height: 27px;
              svg{
                fill: #212529;
              }
            }
          }
          p{
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            padding: 0;
            margin: 10px 0 0 0;
            text-align: center;
          }

          :hover{
            .line_selected{
              display: flex;
            }
            .icon{
              color: #D8010F;
              &.meta{
                svg{
                  fill: #D8010F;
                }
              }
              &.autitos{
                svg{
                  fill: #D8010F;
                }
              }
            }
            p{
              color: #D8010F;
            }
          }
          &.selected{
            .line_selected{
              display: flex;
            }
            .icon{
              color: #D8010F;
              &.meta{
                svg{
                  fill: #D8010F;
                }
              }
              &.autitos{
                svg{
                  fill: #D8010F;
                }
              }
            }
            p{
              color: #D8010F;
            }
          }


        }
      }
    }
    @media ${breakpoints.xs} {//576px

    }
    @media ${breakpoints.sm} {//768px

    }
    @media ${breakpoints.md} {//992px
      display: flex;
    }
    @media ${breakpoints.lg} {//1200px

    }
    @media ${breakpoints.xl} {//1366px

    }
    @media ${breakpoints.xxl} {//1440px

    }
    @media ${breakpoints.xxxl} {//1680px

    }
    @media ${breakpoints.xxxxl} {//1920px

    }
`
const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  &.scrolleable{
    position: absolute;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .inner_modal_embajador{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 6vw);
    height: auto;
    padding: 5vw;
    //background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;

    background-image: url("/images/back_videolanding.jpg");
      background-size: cover;
      background-position: center;
  }
  .inner_modal_panel{
    .modal_message{
      text-align: center;
      width: calc(100% - 50px);
      font-size: 20px;
      line-height: 27px;
    }
    .actions{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
      .bt_go_panel{
        margin-bottom: 7px;
        width: 100%;
      }
      .bt_download_panel{
        margin-bottom: 0;
        width: 100%;
      }
      .bt_tutorial_indicadores{
        width: 100%;
        margin-bottom: 5px;
      }
      .bt_tutorial_goto{
        width: 100%;
      }
      .aclaration_panel{
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        text-align: center;
        margin-top: 7px;
        margin-bottom: 15px;
      }
      
    }
    .box_goto_panel{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
      .aclaration_panel{
        margin-bottom: 15px;
      }
    }
    .video{
      display: flex;
      width: 100%;
      margin-top: 10px;
      .player-wrapper{
        display: flex;
        width: 100%;
        overflow: hidden;
        border-radius: 10px;
        .react-player{
          display: flex;
          width: 100%;
        }
      }
      .temp_image_video{
        width: 100%;
        img{
          float: left;
          width: 100%;
        }
      }
    }

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 6vw);
    height: auto;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;

    .title{
      width: 73vw;
      margin-top: 0;
      p{
        font-size: 4vw;
        line-height: 4.5vw;
        font-weight: 600;
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }

    .text{
      width: 73vw;
      margin-top: 3vw;
      //margin-bottom: 30px;
      p{
        font-size: 3.4vw;
        line-height: 4.1vw;
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }
  }
  .inner_modal_contact_message{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 0;

    .errorMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      border: 1px dashed #F20000;
      background-color: #FF9494;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
      p{

      }
    }
    .successMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      background-color: #B8FF6D;
      //#F7DECC,#AD8D75,#B8FF6D,#1B110A,#E0C0A8,#F7DECC,#AD8D75,#B8FF6D
      border: 1px dashed #33E46D;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
    }

    .title{
      width: 80vw;
      margin-top: 0;
      //margin-bottom: 6vw;
      p{
        text-align: left;
        font-size: 7vw;
        line-height: 8.5vw;
        width: 100%;
      }
    }

    .contact_form{
      display: flex;
      flex-direction: column;
      width: 100%;

      .field_channel{
        margin-bottom: 20px;
      }
      .field_subject{
        margin-bottom: 20px;
      }
      .field_message{
        margin-bottom: 10px;
      }
      .modal_actions{
        .loader{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 250px;
          height: 40px;
          //margin-top: 6px;
          svg{
            color: #FFD200;
          }
        }
      }

      .is_invalid{
        border: 1px solid red!important;
      }
      .fix_is_invalid{
        border: 0;
      }

      .field{
        label{
          display: flex;
          width: 100%;
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 7px;
        }
        input{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        textarea{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        select{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
      }
    }
  }

  &.scrolleable{
    padding-top: 0;
    padding-bottom: 0;
  }
  .inner_modal_embajador{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    outline: 0!important;
    border-radius: 0;
    /*
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 600px;
      min-height: 100%;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 0;
    */

      .box_embajador{
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        box-sizing: border-box;
        border-radius: 16px;
        padding: 25px 25px 25px 25px;

        background-color: rgba(0, 0, 0, 0.5);

        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

        .station_select{
          display: flex;
          flex-direction: column;
          margin-bottom: 4vw;
          label{
            margin: 0 0 3px 0;
            font-weight: 600;
          }
          select{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            height: 46px;
            border-radius: 6px;
          }
        }
        .update_date{
          width: 100%;
          text-align: center;
          font-size: 14px;
          margin-top: 20px;
          color: #FFFFFF;
        }
        .top{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background-color: pink;
          .text{
            /*
            width: calc(100% - 32vw);
            margin-right: 2vw;
            */
            .name{
              color: #FFFFFF;
              font-weight: 600;
              font-size: 4vw;
              line-height: 4.5vw;
            }
            .station{
              color: #999999;
              font-weight: 600;
              font-size: 3vw;
              line-height: 4.2vw
            }
          }
          .logo{
            width: 30vw;
            img{
              float: left;
              width: 100%;
            }
          }
        }
        .bottom{
          display: flex;
          margin-top: 4vw;
          //background-color: skyblue;
          .metrics{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .metric{
              display: flex;
              flex-direction: column;
              align-items: center;
              background-color: #FFD200;
              width: calc(50% - 2vw);
              padding-top: 2vw;
              padding-bottom: 2vw;
              border-right: 1px solid #FFFFFF;
              border-left: 1px solid #FFFFFF;
              border-radius: 6px;
              border-bottom: 2px solid #FFFFFF;
              .title{
                width: calc(100% - 6vw);
                text-align: center;
                color: #D8010F;
                font-weight: 600;
                font-size: 3.2vw;
                line-height: 4.2vw;
                border-bottom: 1px solid #FFFFFF;
                padding-bottom: 1vw;
                margin-bottom: 1vw;
              }
              .value{
                font-weight: 800;
                font-size: 6vw;
                line-height: 6vw;
                letter-spacing: -2px;
                color: #FFFFFF;
                margin-top: 0.6vw;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .ranking{
        margin-top: 6vw;

        .table_top{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 2.6vw;
          padding-bottom: 2.6vw;
          border: 2px solid #FFD200;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

          .table_title{
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 3.2vw;
            line-height: 3.2vw;
          }
        }
        .table_bottom{
          width: 100%;
          display: flex;
          flex-direction: column;
          .table_bottom_element{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.6vw;
            margin-bottom: 0.6vw;
            padding-top: 0.6vw;
            padding-bottom: 0.6vw;
            &.back2{
              background-color: #F7F6F6;
            }
            .table_content{

              width: 100%;
              text-align: center;
              &.posicion{
                font-size: 3.6vw;
                font-weight: 700;
                padding-right: 2vw;
                padding-left: 2vw
              }
              &.boca{
                font-size: 3.6vw;
                font-weight: 700;
                padding-right: 2vw;
                padding-left: 2vw
              }
              &.razonsocial{
                font-size: 2.6vw;
                line-height: 3.4vw;
                font-weight: 700;
                text-transform: uppercase;
                padding-right: 2vw;
                padding-left: 2vw;
                padding: 1vw 2vw 1vw 2vw;
              }
            }
          }
        }
      }

    }

  @media ${breakpoints.xs} {
    
    .inner_modal_embajador{
      display: flex;
      flex-direction: column;
      width: 100%;//calc(100% - 30px);
      height: auto;
      padding: 25px;
      //background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 0;



      .box_embajador{
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        box-sizing: border-box;
        border-radius: 16px;
        padding: 25px 25px 25px 25px;



        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

        .station_select{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          label{
            margin: 0 0 3px 0;
            font-weight: 600;
          }
          select{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            height: 46px;
            border-radius: 6px;
          }
        }
        .top{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background-color: pink;
          .text{
            .name{
              font-size: 20px;
              line-height: 24px;
            }
            .station{
              font-size: 15px;
              line-height: 21px;
            }
          }
          .logo{
            width: 120px;
            img{
              float: left;
              width: 100%;
            }
          }
        }
        .bottom{
          display: flex;
          margin-top: 20px;
          //background-color: skyblue;
          .metrics{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            .metric{
              display: flex;
              flex-direction: column;
              align-items: center;
              background-color: #FFD200;
              width: calc(50% - 10px);
              padding-top: 10px;
              padding-bottom: 10px;
              border-right: 1px solid #FFFFFF;
              border-left: 1px solid #FFFFFF;
              border-radius: 6px;
              border-bottom: 0;
              .title{
                width: calc(100% - 30px);
                text-align: center;
                color: #D8010F;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                border-bottom: 1px solid #FFFFFF;
                padding-bottom: 5px;
                margin-bottom: 5px;
              }
              .value{
                font-weight: 800;
                font-size: 30px;
                line-height: 30px;
                letter-spacing: -2px;
                color: #FFFFFF;
                margin-top: 3px;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .ranking{
        margin-top: 30px;

        .table_top{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 13px;
          padding-bottom: 13px;
          border: 2px solid #FFD200;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

          .table_title{
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
          }
        }
        .table_bottom{
          width: 100%;
          display: flex;
          flex-direction: column;
          .table_bottom_element{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
            margin-bottom: 3px;
            padding-top: 3px;
            padding-bottom: 3px;
            &.back2{
              background-color: #F7F6F6;
            }
            .table_content{

              width: 100%;
              text-align: center;
              &.posicion{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.boca{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.razonsocial{
                font-size: 13px;
                line-height: 17px;
                font-weight: 700;
                text-transform: uppercase;
                padding-right: 10px;
                padding-left: 10px;
                padding: 5px 10px 5px 10px;
              }
            }
          }
        }
      }
    }

    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{

        width: calc(100% - 45px);
        margin-top: 0;
        //background-color: pink;
        //margin-bottom: 30px;
        p{
          font-size: 22px;
          line-height: 27px;
          font-weight: 600;
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }


      .text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100% - 45px);
        margin-top: 5px;
        //margin-bottom: 30px;
        p{
          font-size: 18px;
          line-height: 22px;
          width: calc(100% - 100px);
          text-align: center;
          margin: 0;
        }
      }

    }
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: calc(100% - 40px);
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.sm} {
    
    &.scrolleable{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .inner_modal_embajador{
      display: flex;
      flex-direction: column;
      width: 600px;
      height: auto;
      min-height: unset;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .box_embajador{
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        box-sizing: border-box;
        border-radius: 16px;
        padding: 25px 25px 25px 25px;

        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

        .station_select{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          label{
            margin: 0 0 3px 0;
            font-weight: 600;
          }
          select{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            height: 46px;
            border-radius: 6px;
          }
        }
        .top{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background-color: pink;
          .text{
            .name{
              font-size: 20px;
              line-height: 24px;
            }
            .station{
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .bottom{
          display: flex;
          margin-top: 20px;
          //background-color: skyblue;
          .metrics{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .metric{
              display: flex;
              flex-direction: column;
              align-items: center;
              background-color: #FFD200;
              padding-top: 10px;
              padding-bottom: 10px;
              border-right: 1px solid #FFFFFF;
              border-left: 1px solid #FFFFFF;
              border-radius: 6px;
              .title{
                width: calc(100% - 30px);
                text-align: center;
                color: #D8010F;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                border-bottom: 1px solid #FFFFFF;
                padding-bottom: 5px;
                margin-bottom: 5px;
              }
              .value{
                font-weight: 800;
                font-size: 30px;
                line-height: 30px;
                letter-spacing: -2px;
                color: #FFFFFF;
                margin-top: 3px;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .ranking{
        margin-top: 30px;

        .table_top{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 13px;
          padding-bottom: 13px;
          border: 2px solid #FFD200;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

          .table_title{
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
          }
        }
        .table_bottom{
          width: 100%;
          display: flex;
          flex-direction: column;
          .table_bottom_element{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
            margin-bottom: 3px;
            padding-top: 3px;
            padding-bottom: 3px;
            &.back2{
              background-color: #F7F6F6;
            }
            .table_content{

              width: 100%;
              text-align: center;
              &.posicion{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.boca{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.razonsocial{
                font-size: 13px;
                line-height: 17px;
                font-weight: 700;
                text-transform: uppercase;
                padding-right: 10px;
                padding-left: 10px;
                padding: 5px 10px 5px 10px;
              }
            }
          }
        }
      }

    }
    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 600px;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;
      
      .title{
        width: calc(100% - 45px);
        margin-top: 0;
        //background-color: pink;
        //margin-bottom: 30px;
        p{
          font-size: 22px;
          line-height: 27px;
          font-weight: 600;
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }


      .text{
        width: calc(100% - 45px);
        margin-top: 5px;
        //margin-bottom: 30px;
        p{
          font-size: 18px;
          line-height: 22px;
          width: calc(100% - 30px);
          text-align: center;
          margin: 0;
        }
      }
    }
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 700px;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.md} {
    .inner_modal_panel{
      width: 900px;
      .actions{
        flex-direction: row;
        .bt_go_panel{
          margin-bottom: 0;
          width: calc(33% - 3px);
        }
        .bt_download_panel{
          margin-bottom: 0;
          width: calc(33% - 3px);
        }
        .bt_tutorial_indicadores{
          width: calc(33% - 3px);
          margin-bottom: 0;
        }

      }

      .text{
        width: calc(100% - 45px);
        margin-top: 5px;
        //margin-bottom: 30px;
        p{
          font-size: 18px;
          line-height: 22px;
          width: calc(100% - 150px);
          text-align: center;
          margin: 0;
        }
      }
    }
  }
  @media ${breakpoints.lg} {

  }
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`

const Sidebar = ({children, className}) => {

  let history = useHistory();
  let location = useLocation();

  const { setCourse,
          setTrainingHome,
          setCrumbs,
          userData,
          baristas } = useContext(ContentContext);

  const [ openModalPanel, setOpenModalPanel ] = useState(null);
  const { logout, activeRoute, setActiveRoute } = useContext(AuthContext);
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ swapVideo, setSwapVideo ] = useState(true);

  const open = Boolean(anchorEl);
  const termsURL = '/pdfs/Funcionalidades_nuevo_panel_de_nominas_e_indicadores_2708_20224.pdf';

  const goRoute = (route) => {

    switch (route) {
      case "campus":
        setTrainingHome(null);
        setCourse(null);
        setCrumbs(null);
        break;
    }

    setActiveRoute(route);
    history.push('/'+route);

  }

  const handleDownloadDocument = () => {
    downloadFileFromUrl(termsURL, 'funcionalidades_nuevo_panel_de_nominas_e_indicadores.pdf');
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    //setOpenModalPanel(false)
    setAnchorEl(null);
  };

  const handleGotoPanel = () => {
    setOpenModalPanel(!openModalPanel);
  }
  const handleGoToPanelFromPop = () => {
    window.open("https://prod.dotspanel.com", '_blank').focus();
  }

  const handleGoToBaristas = () => {
    //window.open("http://localhost:6102/baristas", '_blank').focus();
    //window.open("https://staging-rewrite.somosprotagonistas.com.ar/baristas", '_blank').focus();
    window.open("https://somosprotagonistas.com.ar/baristas", '_blank').focus();
  }

  const handleCloseModalPanel = () => setOpenModalPanel(false);
  const handleVideoProgress = ((progress) => {});
  const handleVideoStart = () => {}
  const handleVideoStop = () => {}
  const endTraining = (course) => {}

  // useEffect(() => {
  //   console.log("userData", userData)

  //   return () => {}
  // }, [userData])

  useEffect(() => {
    const pathNameSplited = location.pathname.split('/');
    console.log("pathNameSplited", pathNameSplited)
    setActiveRoute(pathNameSplited[1]);
    return () => {}
  }, [location])

  // useEffect(() => {
  //   console.log("anchorEl", anchorEl)

  //   return () => {}
  // }, [anchorEl])


  return (
    <WrapperStyled breakpoints={breakpoints} className={className}>







<ModalStyled

open={openModalPanel}
onClose={handleCloseModalPanel}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
BackdropComponent={Backdrop}
BackdropProps={{
  timeout: 500,
}}
>
<Fade in={openModalPanel}>



  <div className="inner_modal_panel">
    <ButtonClose
      onClick={() => handleGotoPanel()}
    >
      <div className="icon">
        <RiCloseCircleLine />
      </div>
    </ButtonClose>

    {/* <div className="modal_message">
      <strong>Estimados Empresarios y Capitanes,</strong> les informamos que el Panel está actualmente en mantenimiento y estará operativo nuevamente en la próxima semana.<br/>Les notificaremos en cuanto esté disponible para su revisión.<br/><br/><strong>¡Agradecemos su comprensión!</strong>
    </div> */}

    <div className="title">
      <p>Tutorial Navegacion Panel</p>
    </div>
    
    <div className="video">
      {swapVideo ?
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={"https://fabianhr.com/protagonistas/videos/Tutorial_Panel_Navegacion_2024.mp4"}
          //url={"/videos/Tutorial_Panel_2023.mp4"}
          width='100%'
          height='100%'
          volume="1"
          controls={true}
          onProgress={(progress) => { handleVideoProgress(progress)}}
          onPlay={handleVideoStart}
          onStart={handleVideoStart}
          onStop={handleVideoStop}
          config={{ file: {
            attributes: {
              controlsList: 'nodownload'
            },
          }
        }}
        />
      </div>
      :
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={"https://fabianhr.com/protagonistas/videos/Tutorial_Indicadores_2024.mp4"}
          //url={"/videos/Tutorial_Indicadores.mp4"}
          width='100%'
          height='100%'
          volume="1"
          controls={true}
          onProgress={(progress) => { handleVideoProgress(progress)}}
          onPlay={handleVideoStart}
          onStart={handleVideoStart}
          onStop={handleVideoStop}
          config={{ file: {
            attributes: {
              controlsList: 'nodownload'
            }
          }
        }}
        />
      </div>
      }

    </div>

    <div className="actions">
    
      <ButtonYellow
        className="bt_tutorial_indicadores"
        onClick={() => setSwapVideo(!swapVideo)}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>TUTORIAL INDICADORES</p>
      </ButtonYellow>

      <ButtonYellow
        className="bt_tutorial_indicadores"
        onClick={() => setSwapVideo(!swapVideo)}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>TUTORIAL NÓMINAS</p>
      </ButtonYellow>

      <ButtonYellow
        className="bt_download_panel"
        onClick={() => handleDownloadDocument()}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>DESCARGAR DOCUMENTO</p>
      </ButtonYellow>

      

    </div>

    <div className='box_goto_panel'>
      <div className="aclaration_panel"><strong>Te recomendamos que visualices primero el tutorial para conocer el nuevo método de alta y contraseñas. Ante la necesidad de soporte podrás programar una reunión remota enviando un mail a contacto@somosprotagonistas.com.ar</strong></div>
      <ButtonYellow
        className="bt_tutorial_goto"
        onClick={() => {handleGoToPanelFromPop(); handleClose();}}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>IR AL PANEL</p>
      </ButtonYellow>
    </div>



    
    {/* <div className="title">
      <p>Tutorial Navegacion Panel</p>
    </div>
    <div className="text">
      <p>Conocé el paso a paso para la extracción de reportes, visualizar nuevos objetivos y carga de nóminas</p>
    </div>

    <div className="video">
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={"/videos/Tutorial_Panel_2023.mp4"}
          width='100%'
          height='100%'
          volume="1"
          controls={true}
          onProgress={(progress) => { handleVideoProgress(progress)}}
          onPlay={handleVideoStart}
          onStart={handleVideoStart}
          onStop={handleVideoStop}
          config={{ file: {
            attributes: {
              controlsList: 'nodownload'
            },
          },
          vimeo: {
            preload: true,
            muted: 1,
            controls: true,
          },
        }}
        />
      </div>
    </div> */}


    {/* <div className="video">
      {swapVideo ?
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={"/videos/Tutorial_Panel_2023.mp4"}
          width='100%'
          height='100%'
          volume="1"
          controls={true}
          onProgress={(progress) => { handleVideoProgress(progress)}}
          onPlay={handleVideoStart}
          onStart={handleVideoStart}
          onStop={handleVideoStop}
          config={{ file: {
            attributes: {
              controlsList: 'nodownload'
            },
          },
          vimeo: {
            preload: true,
            muted: 1,
            controls: true,
          },
        }}
        />
      </div>
      :
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={"/videos/Tutorial_Indicadores.mp4"}
          width='100%'
          height='100%'
          volume="1"
          controls={true}
          onProgress={(progress) => { handleVideoProgress(progress)}}
          onPlay={handleVideoStart}
          onStart={handleVideoStart}
          onStop={handleVideoStop}
          config={{ file: {
            attributes: {
              controlsList: 'nodownload'
            }
          },
          vimeo: {
            preload: true,
            muted: 1,
            controls: true,
          },
        }}
        />
      </div>
      }

    </div> */}

    {/* <div className="actions"> */}
      {/* <div className="aclaration_panel">Te recomendamos que visualices primero el tutorial para conocer el nuevo acceso y la navegación de las funcionalidades actualizadas. Ante la necesidad de soporte podrás programar una reunión remota enviando un mail a contacto@somosprotagonistas.com.ar</div>
      <ButtonYellow
        className="bt_tutorial_goto"
        onClick={() => {handleGoToPanelFromPop(); handleClose();}}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>IR AL PANEL</p>
      </ButtonYellow> */}

      {/* <ButtonYellow
        className="bt_tutorial_indicadores"
        onClick={() => setSwapVideo(!swapVideo)}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>TUTORIAL INDICADORES</p>
      </ButtonYellow>

      <ButtonYellow
        className="bt_download_panel"
        onClick={() => handleDownloadDocument()}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>DESCARGAR DOCUMENTO</p>
      </ButtonYellow>

      <ButtonYellow
        className="bt_tutorial_indicadores"
        onClick={() => setSwapVideo(!swapVideo)}
        disabled={false}
        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
      >
        <p>TUTORIAL NÓMINAS</p>
      </ButtonYellow> */}

    {/* </div> */}
  </div>

</Fade>
</ModalStyled>








      <div className='inner'>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={() => {handleGoToPanelFromPop(); handleClose();}}>Ir al Panel</MenuItem> */}
        {/* <MenuItem onClick={() => {handleGotoPanel(); handleClose();}}>Ir al Panel</MenuItem>
        <MenuItem onClick={() => {handleGotoPanel(); handleClose();}}>Tutorial</MenuItem> */}
        <MenuItem onClick={() => {handleGotoPanel(); handleClose();}}>Tutorial / Acceso</MenuItem>
      </Menu>


        <ul>
          <li
            className={`${activeRoute === '' ? 'selected' : ''}`}
            onClick={() => goRoute("")}
          >
            <div className='icon'>
              <BsMegaphone />
            </div>
            <p>Muro</p>
            <div className='line_selected'></div>
          </li>
          <li
            className={`${activeRoute === 'campus' ? 'selected' : ''}`}
            onClick={() => goRoute("campus")}
          >
            <div className='icon campus'>
              <PiBookOpenText />
            </div>
            <p>Campus</p>
            <div className='line_selected'></div>
          </li>
          <li
            className={`${activeRoute === 'catalogo' ? 'selected' : ''}`}
            onClick={() => goRoute("catalogo")}
          >
            <div className='icon products'>
              <BsListUl />
            </div>
            <p>Catálogo</p>
            <div className='line_selected'></div>
          </li>
          
          {(userData?.can_use_panel || userData?.role === "Admin" || userData?.role === "District Manager" || userData?.role === "Territory Manager" || userData?.role === "Empresario / Gerente" || userData?.captain === true) ?
          <li
            className={``}
            onClick={handleClick}
            aria-controls="simple-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <div className='icon products'>
              <LuFolderUp />
            </div>
            <p>Panel</p>
            <div className='line_selected'></div>

          </li> : ""}

          {/* <li
            className={``}
            onClick={() => goRoute("trivia")}
            aria-controls="simple-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <div className='icon products'>
              <IoExtensionPuzzleOutline />
            </div>
            <p>Trivia</p>
            <div className='line_selected'></div>

          </li>  */}

          {/* {dnis_seguridad_2024.includes(userData?.dni) ?
          <li
            //className={``}
            className={`${activeRoute === 'semana-de-la-seguridad' ? 'selected' : ''}`}
            onClick={() => goRoute("semana-de-la-seguridad")}
            aria-controls="simple-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <div className='icon products'>
              <IoExtensionPuzzleOutline />
            </div>
            <p>Trivia<br/>Seguridad</p>
            <div className='line_selected'></div>

          </li> : ""} */}

          {/* {userData && incentivoTiendas(userData?.pos) &&
          <li
            className={`${activeRoute === 'incentivo-tiendas' ? 'selected' : ''}`}
            onClick={() => goRoute("incentivo-tiendas")}
          >
            <div className='icon meta'>
              <MetaIconSVG fill={"rgb(109, 105, 239)"} className={""} /> 
            </div>
            <p>Incentivo Tiendas</p>
            <div className='line_selected'></div>
          </li>} */}

          {userData ?
          <li
            className={`${activeRoute === 'incentivo-autitos' ? 'selected' : ''}`}
            onClick={() => goRoute("incentivo-autitos")}
          >
            <div className='icon autitos'>
              <MetaIconAutitoSVG fill={"rgb(109, 105, 239)"} className={""} />  
            </div>
            <p>Meta Autitos</p>
            <div className='line_selected'></div>
          </li> : ""}
          




          {/* {show_baristas.includes(userData?.dni) ? */}
          
            <li
              className={`${activeRoute === 'baristas' ? 'selected' : ''}`}
              onClick={() => handleGoToBaristas()/*goRoute("baristas")*/}
            >
              <div className='icon meta'>
                <BaristaIcon fill={"rgb(109, 105, 239)"} className={""} /> 
              </div>
              <p>Copa Barista</p>
              <div className='line_selected'></div>
            </li> 
          
          {/* : ""} */}

        </ul>
      </div>
    </WrapperStyled>
  )

}




export default Sidebar;
