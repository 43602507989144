import { number } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';

const StyledWrapper = styled.div`

  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;

  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);

  .inner_result{
    //width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 370px;
    height: 190px;
    background-color: #000000;
    border-radius: 6px;
    box-sizing: border-box;

    box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.25);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.25);

    padding: 20px 20px 20px 20px;
    color: #FFFFFF;
    .result{
      font-size: 25px;
      line-height: 30px;
      font-weight: 700;
      color: #FFD200;
    }
    .result_aclaration{
      font-size: 22px;
      line-height: 25px;
      margin-top: 9px;
    }
    .inner_result_texts{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
    }
  }

  .bt_close{

  }
  @media ${breakpoints.xs} {
  }
  @media ${breakpoints.sm} {
  }
  @media ${breakpoints.md} {
  }
  @media ${breakpoints.lg} {
  }
`

const OverlayResult = (props) => {

  const { open,
          setOpen,
          result,
          setShowGame,
          setOpenGame,
          hangleCheckWin } = props;

  useEffect(() => {
    console.log("result", result)

    return () => {}
  }, [result])

  const handleCloseModalResult = () => {
    setOpen(false);
    setShowGame(null);
    setOpenGame(false);
    hangleCheckWin();
  }

  return (
    <StyledWrapper>
      <div className="inner_result">
        <div className="inner_result_texts">
          {result == "win" &&
            <>
            <div className="result">GANASTE</div>
            <div className="result_aclaration">Felicitaciones completaste el juego</div>
            </>
          }
          {result == "lose" &&
            <>
            <div className="result">ESTA VEZ NO PUDO SER</div>
            <div className="result_aclaration">¡Volvé  a intentarlo!</div>
            </>
          }
        </div>

        <ButtonYellow
          className="bt_close"
          onClick={() => handleCloseModalResult()}
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
        >
          <p>CERRAR</p>
        </ButtonYellow>

      </div>
    </StyledWrapper>
  )
}

export default OverlayResult;
