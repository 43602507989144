import { number } from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';
import { useHistory, useLocation } from 'react-router-dom';
import GameTimer from '../../_components/Games/GameTimer';
import Soup from '../../_components/Games/Soup';
import CompleteWord from '../../_components/Games/CompleteWord';
import ImageCorrect from '../../_components/Games/ImageCorrect';
import OverlayResult from '../../_components/Games/OverlayResult';
import { RiCloseCircleLine } from "react-icons/ri";
import { GrDocumentPdf } from "react-icons/gr";
import { AiFillCheckCircle } from "react-icons/ai";
import { AuthContext, ContentContext } from '../../_context';
import { FaLock, FaUnlock } from "react-icons/fa";
import { bocas_seguridad_2024, dnis_seguridad_2024, downloadFileFromUrl } from "../../_helpers/commons";

import { IcoCheck,
         IcoChevronLeft,
         IcoPDFFile,
         COLORS } from '../../_helpers/constants';

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

const StyledWrapper = styled.div`
  background-color: ${props => props.isOffline ? "#FFC600" : "transparent"};
  display: flex;
  flex-direction: column;
  width: 100%;
  //justify-content: center;
  align-items: center;
  .no-ranking{
    margin-top: 15px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }
  .logo{
    margin-top:  ${props => props.isOffline ? "30px" : "20px"};
    width: calc(100% - 40px);
  }
  .text{
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .box_end{
      box-sizing: border-box;
      background-color: #FFFFFF;
      border-radius: 12px;
      width: 100%;
      padding: 20px 25px 20px 25px;
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      p{
        width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        padding: 0;
        margin: 0;
        font-weight: 600;
      }
    }
  .box_ranking{
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 12px;
    margin-top: 10px;
    width: 100%;
    padding: 20px 25px 25px 25px;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    .table_head{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      color: #FFC600;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 7px;
      padding-bottom: 5px;
      border-bottom: 2px solid #FFC600;
      .pos{
        width: 150px;
      }
      .boca{
        width: 150px;
      }
      .points{
        width: 100px;
        margin-left: auto;
      }
    }
    .title{
      font-size: 25px;
      line-height: 25px;
      font-weight: 600;
      margin-bottom: 20px;
      max-height: 50px;
    }
    .positions{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      .position{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background-color: #FFFFFF;
        margin-right: 7px;
        margin-bottom: 7px;
        border-bottom: 1px solid #FFC600;
        padding-bottom: 5px;
        .pos{
          width: 150px;
          font-size: 16px;
          font-weight: 600;
        }
        .boca{
          width: 150px;
          font-size: 16px;
          font-weight: 600;
        }
        .total{
          width: 100px;
          font-size: 16px;
          font-weight: 600;
          margin-left: auto;
        }
      }
    }
  }
  .box_others{
    margin-top: 10px;
    width: 100%;
    border-radius: 12px;
    padding: 20px 25px 25px 25px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    .table_head{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #FFC600;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 2px solid #FFC600;
      .answered{
        width: 100px;
      }
    }
    .title{
      font-size: 25px;
      line-height: 25px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .others{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 300px;
      overflow-y: auto;
      .other{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #FFC600;
        .expert{

        }
        .answered{
          width: 100px;
        }
        &.hide{
          opacity: 0.5;
        }
      }
    }
  }
  .box_user{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 25px 25px 25px 25px!important;
    .text_progress{
      text-align: center;
      margin-bottom: 20px;
    }
    .progress_{
      display: flex;
      justify-content: center;
      flex-direction: row;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 25px;
        padding-right: 25px;
        border-right: 1px solid lightgray;
        :last-child{
          margin-right: 0;
          padding-right: 0;
          border: 0;
        }
        .label{
          font-size: 13px;
          line-height: 13px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .value{
          font-size: 50px;
          line-height: 50px;
          font-weight: 800;
          letter-spacing: -2px;
        }
      }
    }
  }
  .box_question,
  .box_user{
    width: 100%;
    padding: 20px 25px 25px 25px;
    border-radius: 12px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    .question{
      margin-bottom: 15px;
      .text{
        font-size: 22px;
        line-height: 27px;
        font-weight: 600;
      }
    }
    .answers{
      .answer{
        padding: 13px;
        border: 1px solid rgba(255, 198, 0, 0.75);
        //background-color: rgba(255, 198, 0, 0.5);
        margin-bottom: 7px;
        cursor: pointer;
        &:last-child{
          margin-bottom: 0;
        }
        &.selected{
          border: 1px solid rgba(255, 198, 0, 1);
          background-color: rgba(255, 198, 0, 1);
          color: #FFFFFF;
          font-weight: 600;
        }
        :hover{
            border: 1px solid rgba(255, 198, 0, 1);
            background-color: rgba(255, 198, 0, 1);
            color: #FFFFFF;
            font-weight: 600;
        }

      }
    }
    .actions{
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .result_response{
      font-size: 19px;
      line-height: 19px;
      width: 100%;
      text-align: center;
      padding-top: 5px;
      .highlight{
        font-weight: 600;
        &.correct{
          color: #FFC600;
        }
        &.incorrect{
          color: #D8010F;
        }
      }
    }
  }
  .box_actions{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
    .bt_download{
      margin-right: 15px;
      background-color: #D8010F;
      border: 1px solid #D8010F;
      p{
        color: #FFFFFF;
      }
      :hover{
        background-color: #FFFFFF;
        border: 1px solid #D8010F;
        p{
          color: #D8010F;
        }
      }
      :last-child{
        margin-right: 0;
      }
    }
  }
  @media ${breakpoints.xs} {
    .logo{
      //margin-top: 10px;
      width: 500px;
    }
    /* .logo{
      margin-top: 10px;
      width: 500px;
    }
    .text{
      width: 700px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;
    }
    .box_ranking{
      box-sizing: border-box;
      background-color: #FFFFFF;
      border-radius: 12px;
      margin-top: 10px;
      width: 750px;
    } */
  }
  @media ${breakpoints.sm} {

    .logo{
      //margin-top: 10px;
      width: 500px;
    }
    .text{
      width: 700px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;
    }
    .box_ranking{
      box-sizing: border-box;
      background-color: #FFFFFF;
      border-radius: 12px;
      margin-top: 10px;
      width: 750px;
    }
    .box_end{
      box-sizing: border-box;
      background-color: #FFFFFF;
      border-radius: 12px;
      width: 750px;
      padding: 20px 25px 20px 25px;
      p{
        width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        padding: 0;
        margin: 0;
        font-weight: 600;
      }
    }
    .box_others{
      margin-top: 10px;
      width: 750px;
      border-radius: 12px;
      padding: 20px 25px 25px 25px;
    }
    .box_question,
    .box_user{
      width: 750px;
      padding: 20px 25px 25px 25px;
    }
    .box_user{
      .progress_{
        .item{
          .label{
            font-size: 13px;
            line-height: 13px;
          }
          .value{
            font-size: 65px;
            line-height: 65px;
          }
        }
      }
    }
    /* .box_actions{
      width: 750px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 25px;
      .bt_download{
        margin-right: 15px;
        margin-top: 0;
      }
    } */

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const ModalStyled = styled(Modal)`

  @media ${breakpoints.xs} {
    
  }
  @media ${breakpoints.sm} {
  
  }
  @media ${breakpoints.md} {
  
  }
  @media ${breakpoints.lg} {

  }

`
const LoadingStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  color: #FFFFFF;
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 10px;
  top: 10px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #FFFFFF;
    }
  }
`

const SecurityDayContainer = (props) => {

  const history = useHistory();
  const { search, pathname } = useLocation();
  const [ dni, setDni ] = useState(null);
  const [ boca, setBoca ] = useState(null);
  const { isAuth } = useContext(AuthContext);
  const { userData } = useContext(ContentContext);

  const [ timeEnded, setTimeEnded ] = useState(false);
  const [ timerSeconds, setTimerSeconds ] = useState(null);
  const [ showGame, setShowGame ] = useState("");
  const [ openResult, setOpenResult ] = useState(false);
  const [ gameResult, setGameResult ] = useState(null);
  const [ open, setOpen ] = useState(true);
  const [ openGame, setOpenGame ] = useState(false);
  const [ modalSize, setModalSize ] = useState(500);
  const [ gameData, setGameData ] = useState(null);
  const [ wordsToFind, setWordsToFind ] = useState("");

  const [ data, setData ] = useState(null);
  const [ optionSelected, setOptionSelected ] = useState(null);
  const [ optionResultSelected, setOptionResultSelected ] = useState(null);
  const [ isOffline, setIsOffline ] = useState(false);
  const [ questionNumber, setQuestionNumber ] = useState(0);
  const [ question, setQuestion ] = useState(null);
  const [ responseToSend, setResponseToSend ] = useState([]);



  useEffect(() => {
    if(!isAuth){
      if(pathname){
        if(pathname == '/semana-de-la-seguridad'){
          if(search.length > 0) {
            const query = new URLSearchParams(search);
            if(query.get("dni") && query.get("dni").length > 0 && query.get("boca") && query.get("boca").length > 0){
              setDni(query.get("dni"));
              setBoca(query.get("boca"));
              setIsOffline(true);
            }else{
            }
          }
        }
      }
    }
    return () => {}

  }, [pathname, isAuth])

  useEffect(() => {
    
    if(isAuth){
      if(userData){
        setDni(userData.dni);
        setBoca(userData.pos[0].code);
      }
    }
  
    return () => {}
  }, [isAuth, userData])
  
  const handleSelectAnswer = (answer) => {

    setOptionSelected(answer.id);
    setOptionResultSelected(answer.result);

  }

  const handleSend = () => {

    const payload = { question_id: question.id, answer_id: optionSelected, result: optionResultSelected };
    const responseToSend_update = [...responseToSend];
    responseToSend_update.push(payload);
    setResponseToSend(responseToSend_update);
    if(questionNumber > 0){
      getData({type: "participate", dni: dni, boca: boca, questions: JSON.stringify(responseToSend_update)});
    }else{
      setQuestionNumber(1);
    }

  }

  const instructivoURL = '/pdfs/instructivo_trivia_seguridad_2024.pdf';

  const handleDownloadInstructivo = () => {
    downloadFileFromUrl(instructivoURL, 'instructivo_trivia_seguridad_2024.pdf');
  }

  const getData = async (payload) => {

    //const requestURL = `http://localhost:8888/protagonitas_landings/diaseguridad2024.php`;
    const requestURL = `https://fabianhr.com/protagonistas/diaseguridad2024.php`;

    try {
      const params = new URLSearchParams();

      if(Object.keys(payload).length){
          Object.keys(payload).map(function(key) {
              params.append(key, payload[key]);
          })
      }

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const result = await axios.post(requestURL, params, config);
      
      setData(result.data);

      switch (payload.type) {
        case "get_question":
          //setQuestion(result.data.question);
          break;
      
        default:
          break;
      }

      //setGameData(result.data);

      return result;

    } catch (error) {

    }
  }

  useEffect(() => {
    if(dni && boca){
      const payload = { type: "get_question", dni: dni, boca: boca };
      getData(payload);
    }

    return () => {}
  }, [dni, boca])

  useEffect(() => {
    if(data){
      if("questions" in data){
        if(data.questions.length){
          setQuestion(data.questions[0]);
        }
      }
    }
    return () => {}
  }, [data])

  useEffect(() => {
    
    if(data){
      if("questions" in data){
        setQuestion(data.questions[1]);
      }
    }
  
    return () => {}
  }, [questionNumber])
  
  useEffect(() => {
    
    if(dni){
      if(!dnis_seguridad_2024.includes(dni)){
        history.push('/');
      }
    }
  
    return () => {}
  }, [dni])
  
  

  if(!dni || !boca || !data){
    return (
      <LoadingStyled>
          <CircularProgress size={45} color={"#FFFFFF"}/>
      </LoadingStyled>
    );
  }

  return (
    <StyledWrapper
      className="security_day"
      isAuth={isAuth}
      isOffline={isOffline}
    >
      
      <div className='logo'>
        <img src="images/logo_semana_seguridad.svg" />
      </div>

      <div className='text'>
        Participá vos y toda la estación para lograr posicionarse en el ranking.<span style={{whiteSpace: "nowrap", fontWeight: "600"}}>¡SUMATE Y DEMOSTRÁ TU COMPROMISO CON LA SEGURIDAD!</span>
      </div>

      <div className='box_user'>

        <div className='text_progress'>
          La progresión está dada por el % de cantidad respuestas que realices correctamente sobre la cantidad de preguntas otorgadas en todo el juego
        </div>

        <div className='progress_'>
          <div className='item'>
            <div className='label'>Correctas</div>
            <div className='value'>{ data?.own?.corrects ? data.own.corrects : 0 }</div>
          </div>
          <div className='item'>
            <div className='label'>Progreso</div>
            <div className='value'>{ data?.own?.total }%</div>
          </div>
        </div>
        
      </div>

      <div className='box_end'>
        <p>La trivia ha finalizado</p>
      </div>

      {/* {data.success ?
        <>
        
        {data.action === "play" ?
        <div className='box_question'>
          <div className='question'>
            <div className='text'>{ question?.description }</div>
          </div>
          <div className='answers'>
            {question && question.answers.map((answer, index) => {
              return(
                <div className={`answer${optionSelected === answer.id ? " selected" : ""}`} onClick={ () => handleSelectAnswer(answer) }>{ answer.description }</div>
              )
            })}
          </div>

          <div className="actions">
              <ButtonYellow
                className="bt_save_form"
                onClick={ () =>  optionSelected ? handleSend() : null  }
                disabled={optionSelected ? false : true}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
              >
                <p>{questionNumber === 1 ? "ENVIAR" : "SIGUIENTE"}</p>
              </ButtonYellow>
            </div>

        </div> : ""}

        {data.action === "played" ? 
        <div className='box_question'>
          {optionResultSelected ?
            (responseToSend.filter(res => res.result === true)).length ?
            <div className='result_response'><span className='highlight correct'>¡Felicitaciones</span>, respondiste correctamente { responseToSend ? (responseToSend.filter(res => res.result === true)).length : "0" } { responseToSend ? (responseToSend.filter(res => res.result === true)).length === 1 ? "pregunta" : "preguntas" : "preguntas" } de la partida del día!</div>
            : <div className='result_response'><span className='highlight incorrect'>¡No respondiste correctamente ninguna pregunta!</span></div>
            :
            (responseToSend.filter(res => res.result === true)).length ?
            <div className='result_response'><span className='highlight correct'>¡Felicitaciones</span>, respondiste correctamente { responseToSend ? (responseToSend.filter(res => res.result === true)).length : "0" } { responseToSend ? (responseToSend.filter(res => res.result === true)).length === 1 ? "pregunta" : "preguntas" : "preguntas" } de la partida del día!</div>
            : <div className='result_response'><span className='highlight incorrect'>¡No respondiste correctamente ninguna pregunta!</span></div>
          }
        </div> : ""}
        
        </>
        :
        data.error === "already_participated" ?
        <div className='box_question'>
          <div className='result_response'><span className='highlight'>¡Ya respondiste la partida del día!</span></div>
        </div>
        :
        <>otro error</>
      } */}

      {data.ranking ? 
      <div className='box_ranking'>
        <div className='title'>Ranking</div>
        <div className='table_head'>
          <div className='pos'>Posición</div>
          <div className='boca'>Boca</div>
          <div className='points'>Puntos</div>
        </div>
        {data.ranking.length ? 
        <div className='positions'>
          {data.ranking.map((ranking, index) => {
            return(
              <div className='position'>
                <div className='pos'>{ index + 1 }</div>
                <div className='boca'>{ ranking.boca }</div>
                <div className='total'>{ ranking.total }%</div>
              </div>
            )
          })}
        </div>
        : <div className='no-ranking'>No hay resultados para mostrar</div>}
      </div>
      : "" }

      {/* {data.others ? 
      <div className='box_others'>
        <div className='title'>{ data.date }: Progreso de tus compañeros</div>
        <div className='table_head'>
          <div className='pos'>Experto</div>
          <div className='answered'>Respondió</div>
        </div>
        <div className='others'>
          {data.others.map((other, index) => {
            return(
              <div className={`other`}>
                <div className='expert'>{`${other.first_name} ${other.last_name}`}</div>
                <div className='answered'>{`${other.answered ? "SI" : "NO"}`}</div>
              </div>
            )
          })}
        </div>
      </div>
      : "" } */}

      <div className="box_actions">

        <ButtonYellow
          className="bt_download"
          onClick={ () => handleDownloadInstructivo() }
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
        >
          <p>INSTRUCTIVO</p>
        </ButtonYellow>

        {/* <ButtonYellow
          className="bt_download"
          onClick={ () =>  optionSelected ? handleSend() : null  }
          disabled={false}
          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
        >
          <p>REGLAMENTO</p>
        </ButtonYellow> */}

      </div>
      

    </StyledWrapper>
  )
}

export default SecurityDayContainer;
