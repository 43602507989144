import React, { useState, useContext, useEffect, Fragment } from 'react';
import { path } from 'ramda';
import styled from 'styled-components';
import { ButtonYellow } from '../../../_elements';
import { Logo,
         Spinner } from '../../../_helpers/constants';
import { AuthContext } from '../../../_context/AuthContext';
import { ContentContext } from '../../../_context';
import storage from 'local-storage-fallback';
import { breakpoints } from '../../../_helpers/breakpoints';
import Content from '../../../_components/Training/Content';
import Like from '../../../_components/Training/Like';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumb from '../../../_components/Training/Breadcrumb';
import { AiOutlineCheckCircle } from "react-icons/ai";
import Evaluation from '~/_components/Evaluation';
import Modal from '@mui/material/Modal';

const WrapperStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  align-items: flex-start;
  width: 100%;
  >.loader{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 20px;
    svg{
      color: #FFC600;
    }
  }
  .header_category{
    display: flex;
    flex-direction: row;
    .category_image{
      border-radius: 10px;
      overflow: hidden;
      width: 130px;
      img{
        float: left;
        width: 100%;
      }
    }
    .category_data{
      margin-left: 15px;
      .name{
        font-size: 28px;
        line-height: 33px;
        font-weight: 600;
      }
      .description{
        width: 400px;
        margin-top: 5px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  >.elements{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    .element{
      display: flex;
      flex-direction: column;
      width: 160px;
      margin-right: 30px;
      cursor: pointer;
      :first-child{
        margin-left: 0;
      }

      >.image{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 165px;
        overflow: hidden;
        border-bottom: 5px solid white;
        border-radius: 30px;
        img{
          float: left;
          width: 100%;
        }
      }
      .texts{
        margin-top: 2px;
        >.title{
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          margin-top: 7px;
        }
        >.description{
          font-size: 13px;
          line-height: 17px;
          margin-top: 5px;
        }
        .box_label_status{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          width: 100%;
          .label_status{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            color: #FFFFFF;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;

            .retry,
            .pending,
            .viewed,
            .approved,
            .process{
              padding: 7px 10px;
              box-sizing: border-box;
              border-radius: 6px;
            }
            .retry{
              background-color: #C5000D;
            }
            .pending{
              background-color: #616161;
            }
            .viewed{
              background-color: #FFC600;
            }
            .approved{
              background-color: #006F45;
            }
            .process{
              background-color: #FDA701;
            }
          }
        }
      }
      :hover{
        .texts{
          >.title{
            color: #DD1D21;
          }
        }
        >.image{
          border-bottom: 5px solid #DD1D21;
        }
      }
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`


const CategoryContainer = (props) => {

  const { history, location, match } = props;

  const { login,
          isAuthLoading,
          loginError } = useContext(AuthContext);

  const { campus,
          get_home_categorie,
          setCourse,
          crumbs,
          setCrumbs, } = useContext(ContentContext);

  const [ courseExternal, setCourseExternal ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleGoToTraining = (el) => {
    history.push(`/campus/course/${match.params.id_category}:${el._id}`);
    //history.push('/campus/course/' + el._id);
  }

  useEffect(() => {

    setCrumbs([{_id: null, label: "Campus", type: "section", route: `/campus`}])
    setLoading(true);
    
    if(match.params.id_category){

      const course_data = campus.categories.filter(category => category._id === match.params.id_category);
      console.log("course_data", course_data)
      if(course_data.length){
        setLoading(false);
        setCourseExternal(course_data[0]);
      }
      /*
      const response = get_home_categorie(match.params.id_category);
      response.then((res) => {
        setLoading(false);
        setCourseExternal(res.data.single.categorie);
        //console.log("res", res)
      })
      */
    }
    

    return () => {}
  }, [])

  useEffect(() => {

    return () => {
      setCourse(null);
      setCrumbs([]);
    }
  }, [])


  return (
    <WrapperStyled>

      {loading ?
        <>cargando</>
      :
        <Fragment>

        <Breadcrumb
          crumbs={crumbs}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          {...props}
        />

          <div className='header_category'>
            {(courseExternal && courseExternal.cover_image) ?
            <div className='category_image'>
              <img src={courseExternal.cover_image} alt="" />
            </div> : ""}
            <div className='category_data'>
              <div className='name'>{courseExternal && courseExternal.category_name}</div>
              <div className='description'>{courseExternal && courseExternal.description}</div>
            </div>
          </div>
          <div className='elements'>
            {courseExternal && courseExternal.repository.map((el, i) => {
              return(
                <div className='element' onClick={() => handleGoToTraining(el)}>
                  <div className='image'>
                    <img src={el.icon ? el.icon : el.cover_image ? el.cover_image : ""} />
                  </div>
                  <div className='texts'>
                    <div className='box_label_status'>
                      <div className='label_status'>
                        { el.status === -1 ? <div className='retry'>REINTENTAR</div> : "" }
                        { el.status === 1 ? <div className='pending'>PENDIENTE</div> : "" }
                        { el.status === 2 ? <div className='viewed'>VISTO</div> : "" }
                        { el.status === 3 ? <div className='approved'>COMPLETO</div> : "" }
                        { el.status === 5 ? <div className='process'>EN CURSO</div> : "" }
                      </div>
                    </div>
                    <div className='title'>{ el.title }</div>
                    {/* <div className='description'>{ el.description_short }</div> */}
                  </div>
                </div>
            )})}
          </div>
        </Fragment>
      }

    </WrapperStyled>
  )
}

export default CategoryContainer;
