import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import ReactPlayer from 'react-player';
import { AudioPlayer } from '../../_elements';
import { MdZoomOutMap,
         MdZoomInMap } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContentContext } from '../../_context';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .image_viewer{
    position: relative;
    width: 100%;
    height: 100%;
    .image_zoom{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 23px;
      background-color: #FFFFFF;
      color: #000000;
      width: 40px;
      height: 40px;
      cursor: pointer;
      :hover{
        background-color: rgba(0, 0, 0, 0.7);
        color: #FFFFFF;
      }
    }
    .image{
      width: 100%;
      img{
        float: left;
        width: 100%;
      }
    }
  }
  .react-player{
    background-color: #000000;
  }
  .audio_player{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: relative;
    .audio_player_background{

      background-image: ${props => props.background ? 'url('+props.background+')' : ''};
      //background-image: ${props => props.background ? 'url('+props.background+')' : ''};

       /* Add the blur effect */
      filter: blur(4px);
      -webkit-filter: blur(4px);

      /* Full height */
      width: 100%;
      height: 100%;

      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .audio_player_inner{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      height: 100%;
      position: absolute;
      //background-color: skyblue;
      canvas{
        width: 440px!important;
        height: 250px!important;
      }
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const DialogStyled = styled(Dialog)`
  .MuiPaper-root{
    max-width: 800px!important;
    .dialog_image{
      width: 100%;
    }
    img{
      float: left;
      width: 100%;
    }
    .image_zoom{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 23px;
      background-color: #FFFFFF;
      color: #000000;
      width: 40px;
      height: 40px;
      cursor: pointer;
      :hover{
        background-color: rgba(0, 0, 0, 0.7);
        color: #FFFFFF;
      }
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`



const ContentType = (props) => {

  const { media,
          className,
          history,
          location,
          background,
          playStatus,
          setPlayStatus } = props;

  const { save_content_viewed } = useContext(ContentContext);

  const theme = useTheme();
  const [ openDialogImage, setOpenDialogImage ] = React.useState(false);
  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const [ _playing, set_Playing ] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));



  const _player = useRef(null);
  const trainingEl = useRef(null);

  const handleOnReady = (e) => {

  }

  const handleViewedPodcastContent = () => {

  }

  useEffect(() => {

    if(media.content_type.toLowerCase() == "podcast"){
      if(playStatus === false){
        //_player?.current.seekTo(0);
      }
    }

    return () => {}
  }, [playStatus])

  const handleClickOpen = () => {
    setOpenDialogImage(true);
  };

  const handleClose = () => {
    setOpenDialogImage(false);
  };


  return (

    <WrapperStyled background={background}>

      {media.content_type.toLowerCase() == "image" &&
        <div className='image_viewer'>
          <div className='image_zoom' onClick={handleClickOpen}>
            <MdZoomOutMap />
          </div>

          <DialogStyled
            fullScreen={fullScreen}
            open={openDialogImage}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <div className='image_zoom' onClick={handleClose}>
              <MdZoomInMap />
            </div>
            <div className='dialog_image'>
              <img src={media.media} />
            </div>
          </DialogStyled>
          <div className='image'>
            <img src={media.media} />
          </div>
        </div>

      }
      {media.content_type.toLowerCase() == "podcast" &&
        <div className="audio_player">
          <div className='audio_player_background'></div>
          <div className='audio_player_inner'>
            <AudioPlayer
              elRef={trainingEl}
              handleViewedContent={handleViewedPodcastContent}
              url={media.media}
              _bars={55}
              _bar_x={17}
              _bar_width={10}
              playStatus={playStatus}
              setPlayStatus={setPlayStatus}
              newCampus={true}
            />
          </div>
        </div>
      }

    </WrapperStyled>

  )
}

export default ContentType;
