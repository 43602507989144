import React, { useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { FaChevronRight } from "react-icons/fa";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../_context';

const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #F6F6F6;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 15px;
  margin-bottom: 20px;
  .loader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg{
      display: flex;
      color: #FFD200!important;
    }
  }
  .elements{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .element{
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      color: #484D63;
      cursor: pointer;
      white-space: nowrap;
      margin-top: 3px;
      margin-bottom: 3px;
      :hover{
        color: #FFC600;
        //text-decoration: underline;
      }
    }
    .chevron-icon{
      display: flex;
      font-size: 13px;
      line-height: 13px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const Breadcrumb = (props) => {

  const { liked,
          likes,
          className,
          onClick,
          crumbs,
          history,
          location,
          match,
          isLoading,
          setIsLoading,
          socpe = null,
          handleGoToCustom } = props;

  const {setTrainingHome,
         setCourse,
         setModule,
         setCrumbs, } = useContext(ContentContext);



  const handleGoTo = (el) => {

    if(socpe === "products"){
      handleGoToCustom(el);
      return null;
    }

    switch (el.route) {
      case '/campus':
        setTrainingHome(null);
        setCourse(null);
        setModule(null);
        setCrumbs(null);
        break;
    }
    history.push(el.route);

  }

  return (

      <WrapperStyled
        className={className}
        onClick={onClick}
      >
        {!isLoading ?
        <div className='elements'>
          {crumbs && crumbs.length ? crumbs.map((crumb, index) => {
            const add_chevron = (crumbs.length - 1) == index ? false : true;
            return(
              <Fragment>
                <div className='element' onClick={() => handleGoTo(crumb)}>{crumb.label}</div>
                {add_chevron && <div className='chevron-icon'><FaChevronRight /></div>}
              </Fragment>
            )
          }) : "..."}
        </div>
        :
        <div className="loader">
          <CircularProgress size={17} />
        </div>
        }
      </WrapperStyled>

  )
}

export default Breadcrumb;
