import React from "react";
import WallContainer from "./WallContainer";

const Wall = (props) => {
  return (
      <WallContainer
        {...props}
        //location={props.location}
        //history={props.history}
      />
  );
};

export default Wall;
