import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-right: 15px;

    @media ${breakpoints.sm} {
      width: 285px;
    }
    @media ${breakpoints.md} {
      width: 285px;
    }
    @media ${breakpoints.lg} {
      width: 285px;
    }
`

const SideLeft = ({children, className}) => {

  return (
    <Wrapper breakpoints={breakpoints} className={className}>
      {children}
    </Wrapper>
  )

}

export default SideLeft;
