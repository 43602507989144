import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { breakpoints } from '../../../_helpers/breakpoints';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
    padding: 0;

    .description{
      font-size: 22px;
      line-height: 28px;
    }


`

const QuestionContainer = (props) => {

  const { description,
          className } = props;

  return (
    <Wrapper className={className} breakpoints={breakpoints}>
      <div className="description">{description}</div>
    </Wrapper>
  )

}

export default QuestionContainer;
