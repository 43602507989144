import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { COLORS } from '../../_helpers/constants';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

const ButtonComment = styled.a`

  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  cursor: pointer;
  border: 0;
  background-color: #FFFFFF;

  .icon{
    display: flex;
    color: #000000;
    //background-color: pink;
    svg{
      font-size: 20px;
    }
  }
  :hover{
    background-color: #FFD200;

    .icon{
      color: #FFFFFF;
    }
  }

`
const ButtonLike = styled.a`

  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 17px;
  cursor: pointer;
  border: 0;
  background-color: #FFFFFF;

  .icon{
    display: flex;
    color: #000000;
    //background-color: pink;
    svg{
      font-size: 20px;
    }
  }
  :hover{
    background-color: #FFD200;

    .icon{
      color: #FFFFFF;
    }
  }

  &.dislike{
    .icon{
      color: #FFD200;
    }
    :hover{
      .icon{
        color: #FFFFFF;
      }
    }
  }

`
const ActionsContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  p{
    padding: 0;
    margin: 0;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    //padding-top: 3px;
    margin-left: 3px;
    color: ${COLORS.primary};
  }
  /*
  position: absolute;
  align-self: flex-end;
  right: 10px;
  margin-top: -28px;
  */

  .button_message{
    margin-right: 4px;
  }
  .button_like{

  }

  /*
  @media ${breakpoints.sm} {
    width: 200px;
  }
  @media ${breakpoints.md} {
    width: 300px;
  }
  @media ${breakpoints.lg} {
    width: 300px;
  }
  */
`
const LikeCounter = styled.div`
  margin-left: 7px;
`
const DatePublish = styled.div`
  display: flex;
  margin-left: auto;
`
const Actions = ( props ) => {

  const [ postLikes, setPostLikes ] = useState(0);
  const [ postLiked, setPostLiked ] = useState(0);

  const { post_id,
          accept_comments,
          post,
          handleComment,
          handleLike,
          handleDislike,
          handleOnClickMedia,
          onDrawer,
          onShellbox } = props;

  useEffect(() => {
    if(post){
      setPostLikes(post.likes);
      setPostLiked(post.liked);
    }
    return () => {}
  }, [])

  const handleAddLike = (post_id) => {
    setPostLiked(true);
    setPostLikes(prevState => prevState + 1);



    handleLike(post_id);
  }

  const handleAddDislike = (post_id, post_likes_id) => {
    setPostLiked(false);
    setPostLikes(prevState => prevState - 1);
    handleDislike(post_id);
  }

  return (
    <ActionsContainer>
      {post.post_type === "image" &&
      <ButtonComment className="button_message" onClick={() => handleOnClickMedia(post)}>
        <div className="icon">
          <ZoomOutMapIcon />
        </div>
      </ButtonComment>
      }
      {!onDrawer && accept_comments &&
      <ButtonComment className="button_message" onClick={() => handleComment(post_id)}>
        <div className="icon">
          <ChatBubbleOutlineIcon />
        </div>
      </ButtonComment>
      }
      {!post.liked ?
      <ButtonLike className="like" onClick={() => handleAddLike(post_id)} >
        <div className="icon">
          <ThumbUpAltOutlinedIcon />
        </div>
      </ButtonLike>
      :
      <ButtonLike className="dislike" onClick={() => handleAddDislike(post_id, post.likes.id)} >
        <div className="icon">
          <ThumbUpAltIcon />
        </div>
      </ButtonLike>
      }

      {!onShellbox &&
      <LikeCounter>
        <p>
          {post.likes} Me Gusta
        </p>
      </LikeCounter>
      }

      <DatePublish>
        <p>
          {post.date_publish}
        </p>
      </DatePublish>
    </ActionsContainer>
  );
};

export default Actions;
