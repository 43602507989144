import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const MenuStyled = styled(Menu)`
  .MuiList-root{
    padding: 0;
  }
  .MuiPaper-root{
    background-color: #F5F6FA!important;
    .MuiListItem-button{
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 20px;
      border-bottom: 1px solid #ced1db;
      :last-child{
        border-bottom: 0;
      }
      :hover{
        background-color: #FFD200;
      }
    }
  }
  .bt_documentos{
    margin-right: auto;
  }
`
const MenuItemStyled = styled(MenuItem)`
  .icon{

    svg{
      width: 22px;
      fill: #000000;
    }
  }
  p{
    margin: 0;
    color: #000000;
    margin-left: 7px;
    font-size: 15px;
    line-height: 15px;
    padding-top: 1px;
  }

  //
`



const Materials = (props) => {

  const { materials, onClickTrigger } = props;
  const [ menuMaterials, setMenuMaterials ] = useState(false);

  const handleClickMenuMaterials = (event) => {
    setMenuMaterials(event.currentTarget);
  };

  const handleCloseMenuMaterials = (file, name) => {
    if(file){
      window.open(file, '_blank').focus();
      onClickTrigger();
      //saveAs(file, name);
    }
    setMenuMaterials(null);
  };

  return (

      <>
        <MenuStyled
            id="simple-menu"
            anchorEl={menuMaterials}
            keepMounted
            open={Boolean(menuMaterials)}
            onClose={() => handleCloseMenuMaterials(null, '')}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'center', horizontal: 'right'}}
            transformOrigin={{vertical: 'center', horizontal: 'right'}}
          >
            {materials ?
              materials.length ?
                materials.map((item, index) => {
                  return(
                    <MenuItemStyled onClick={() => handleCloseMenuMaterials(item.link_download, item.name)}>
                      <div className="icon">
                        <IcoPDFFile />
                      </div>
                      <p>{item.name}</p>
                    </MenuItemStyled>
                  )
                })
              :<div>no hay resultados</div>
            :<div>cargando</div>}
          </MenuStyled>

          <ButtonYellow
            className="bt_documentos"
            onClick={handleClickMenuMaterials}
            //disabled={!quizButtonOn ? true : course?.evaluation_finish?.status <= 0 ? false : true}
            css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
          >

            <div className="ico">
              <DescriptionOutlinedIcon />
            </div>
            <p>{ `DOCUMENTO ${materials.length > 1 ? 'S' : '' }` }</p>

          </ButtonYellow>
      </>



  )
}

export default Materials;
