import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { IcoChevronLeft } from '../../_helpers/constants';
import { Trivia } from '../../_components';
import { ContentContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AiFillStar,
         AiOutlineCheck } from "react-icons/ai";
import { GoCheck } from "react-icons/go";

import { ButtonYellow,
         MultipleChoice,
         MultipleImages,
         QuizQuestionCounter,
         QuizQuestion,
         QuizMessageEnding, } from '../../_elements';

const StyledWrapper = styled.div`
  width: 100%;
`
const CircularProgressStyled = styled(CircularProgress)`
  margin-right: 2px;
  svg{
    color: #FFD200!important;
  }
`




const StyledContainerTrivia = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .loader_{
    margin-top: 25px;
  }
  &.color1{
    .choice_element{
      li{
        border-color: #57ecea!important;
        &.selected{
          background-color: #57ecea!important;
          .description{
            color: #000000!important;
          }
        }
        .description{
          color: #000000!important;
        }
        :hover{
          background-color: #57ecea!important;
          .description{
            color: #000000!important;
          }
        }
      }
    }

    .bt_continuar{
      margin-top: 10px;

      border-color: #57ecea!important;
      :hover{
        background-color: #20939e!important;
        border-color: #20939e!important;
        p{
          color: #FFFFFF!important;
        }
      }
    }

    .loader{
      svg{
        color: #57ecea!important;
      }
    }

  }
  &.color2{
    .choice_element{
      li{
        border-color: #ea5df1!important;
        &.selected{
          background-color: #ea5df1!important;
        }
        .description{
          color: #000000!important;
        }
        :hover{
          background-color: #ea5df1!important;
        }
      }
    }

    .bt_continuar{
      margin-top: 10px;
      background-color: #ea5df1!important;
      border-color: #ea5df1!important;
      p{
        color: #000000!important;
      }
      :hover{
        background-color: #8f0b95!important;
        border-color: #8f0b95!important;
      }
    }

    .loader{
      svg{
        color: #ea5df1!important;
      }
    }
  }
  .inner{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .content_question{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &.hide{
        opacity: 0.5;
      }
    }
    .trivia_progress{
      display: flex;
      width: 100%;
      height: 15px;
      margin-top: 10px;
      .trivia_progress_inner{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .progress_text{
          font-size: 18px;
          line-height: 18px;
          font-weight: 700;
          color: #FFFFFF;
          margin-right: 10px;
          p{
            margin: 0;
          }
        }
        .bar{
          display: flex;
          width: calc(100% - 150px);
          background-color: rgba(0, 0, 0, 0.4);
          .bar_inner{
            display: flex;
            width: ${props => props.percentage ? props.percentage : 0}%;
            height: 15px;
            background-color: #FFD200;
          }
        }
        .percent{
          font-size: 18px;
          line-height: 18px;
          font-weight: 700;
          color: #FFFFFF;
        }
      }
    }



    .question_text{
      align-self: flex-start;
      p{
        font-size: 20px;
        font-weight: 600;
        padding: 0;
        margin: 10px 0 20px 0;
        color: #000000;
      }
    }
    .texto{
      align-self: flex-start;
      margin-top: 0;
      margin-bottom: 25px;
      text-align: center;
      p{
        font-size: 22px;
        font-weight: 700;
        padding: 0;
        margin: 0;
        color: #000000;
        font-size: 25px;
        &.destaca{
          font-weight: 500;
          font-size: 20px;
          margin-top: 15px;
          &.yajugaste{
            text-shadow: 2px 1px 0px #E95EEF;
          }
        }
      }
    }
    .result_status{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0);
      z-index: 1;

      .correct{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 200px;
        border-radius: 10px;
        background-color: #151e4d;
        padding: 20px;

        -webkit-box-shadow: 0px 0px 15px -7px #000000;
        box-shadow: 0px 0px 15px -7px #000000;
        .result_title_correct{
          margin: 0;
          padding: 0;
          font-size: 17px;
          line-height: 33px;
          font-weight: 500;
          color: #FFFFFF;//rgba(75,181,67);
          text-transform: uppercase;
          text-align: center;
          .result{
            font-size: 33px;
            font-weight: 800;
          }
        }
      }
      .incorrect{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 200px;
        border-radius: 10px;
        background-color: #FFFFFF;
        padding: 20px;

        border: 1px solid rgba(0, 0, 0, 0.17);
        -webkit-box-shadow: 0px 0px 13px -7px #000000;
        box-shadow: 0px 0px 13px -7px #000000;

        .result_title{
          font-size: 18px;
          font-weight: 600;
          padding: 0;
          margin: 0;
          color: red;
        }
        .result_correct{
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          margin: 0;
          margin-top: 10px;
        }
        .text_correct{
          padding: 0;
          margin: 0;
          font-size: 17px;
          font-weight: 500;
          border: 1px dashed rgb(75,181,67);
          width: calc(100% - 30px);
          padding: 10px 15px 10px 15px;
          text-align: center;
          margin-top: 5px;
          margin-bottom: 10px;
          background-color: rgba(75,181,67, 0.2)
        }
      }

    }
  }

  @media ${breakpoints.xs} {

    .inner{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
    }
  }
  @media ${breakpoints.sm} {
    .inner{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 80px);
      .texto{
        text-align: left;
      }
      .result_status{
        width: 100%;
        height: 100%;

        .correct{
          width: 400px;
          min-height: 200px;
          border-radius: 10px;
          padding: 20px;
          .result_title_correct{
            font-size: 17px;
            line-height: 33px;
            font-weight: 500;
            .result{
              font-size: 33px;
              font-weight: 800;
            }
          }
        }
        .incorrect{
          width: 450px;
          min-height: 200px;
          border-radius: 10px;
          padding: 20px;

          .result_title{
            font-size: 18px;
          }
          .result_correct{
            font-size: 14px;
            margin-top: 10px;
          }
          .text_correct{
            font-size: 17px;
            width: calc(100% - 30px);
            padding: 10px 15px 10px 15px;
            margin-top: 5px;
            margin-bottom: 10px;
          }
        }

      }
    }
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const StyledHeaderCategory = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 3vw;
  //height: 450px;
  p{
    margin: 0;
    font-size: 5.5vw;
    line-height: 6vw;
    font-weight: 400;
    margin-left: 2vw;
    color: #000000;
  }
  .image{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .button_back{
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    p{
      padding: 0;
      margin: 0;
      font-size: 3vw;
      line-height: 3vw;
      margin-left: 1vw;
      color: #000000;
    }
    .icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 2px solid #000000;
      width: 7vw;
      height: 7vw;
      border-radius: 3.5vw;

      svg{
        height: 3vw;
        path{
          fill: #000000!important;
        }
      }
    }

    :hover{
      .icon{
        border: 2px solid #FFD200;
        svg{
          path{
            fill: #FFD200!important;
          }
        }
      }
      p{
        color: #FFD200;
      }
    }

  }

  @media ${breakpoints.xs} {
    margin-top: 15px;
    p{
      font-size: 26px;
      line-height: 30px;
      margin-left: 10px;
    }
    .button_back{
      p{
        font-size: 16px;
        line-height: 16px;
        margin-left: 7px;
      }
      .icon{
        width: 34px;
        height: 34px;
        border-radius: 17px;

        svg{
          height: 15px;
        }
      }
    }
  }
  @media ${breakpoints.sm} {
    margin-top: 0;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const StyledCategorySelector = styled.ul`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;


`
const StyledCategorySelectorItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  list-style: none;
  border-radius: 20px;
  //border: 1px solid #ccc;
  border: 0;
  margin: 0;
  margin-bottom: 10px;
  cursor: pointer;
  min-width: 240px;
  //width: 100%;
  padding: 12px 10px 12px 17px;
  background-color: #FFFFFF;
  outline: 0;
  &.color1{
    background-color: #57ecea!important;
    //border-color: #20939e!important;
    :hover{
      background-color: #20939e!important;
      //border-color: #20939e!important;
    }
  }
  &.color2{
    background-color: #ea5df1!important;
    :hover{
      background-color: #8f0b95!important;
      //border-color: #8f0b95!important;
    }
  }
  &.cat_complete{
    opacity: 1;
    &.color1{
      background-color: rgba(87, 236, 234, 0.5)!important;
      p{
        color: rgba(255, 255, 255, 0.5);
      }
      .icon{
        opacity: 0.5;
      }
    }
    &.color2{
      background-color: rgba(143, 11, 143, 0.5)!important;
      p{
        color: rgba(255, 255, 255, 0.5);
      }
      .icon{
        opacity: 0.5;
      }
    }
  }
  .icon{
    /*margin-left: 15px;*/
    font-size: 21px;
    line-height: 21px;
    color: #FFD200;
    .icon_trivia_plus{
      width: 19px;
      margin-right: 1px;
    }
  }
  .icon_check{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 10px;
    font-size: 25px;
    line-height: 25px;
    color: #FFD200;
    opacity: 1!important;
  }
  p{
    font-size: 19px;
    line-height: 19px;
    /*padding: 10px 20px 10px 10px;*/
    margin: 0;
    font-weight: 500;
    margin-left: 10px;
    padding-top: 2px;
  }
  :last-child {
    margin-bottom: 0;
  }

  .bullet{
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #ccc;
  }
  .description{
    display: flex;
    margin-left: 7px;
    font-size: 16px;
    font-weight: 600;
  }
  :focus {
    outline: 0;
  }
  :hover{
    cursor: ${prop => prop.catLoading ? "default" : "pointer"};
    background-color: ${prop => prop.catLoading ? "#FFFFFF" : "#FFD200"};
    .bullet{
      border-color: #FFFFFF;
    }
    /*
    .icon{
      color: ${prop => prop.catLoading ? "#FFD200" : "#FFFFFF"};
    }
    .loader{
      svg{
        color: ${prop => prop.catLoading ? "#FFFFFF!important" : "#FFFFFF!important"};
      }
    }
    */
  }
  .loader{
    svg{
      color: #FFFFFF!important;
    }
  }
  &:disabled{
    cursor: default;
    .icon{
      color: #FFD200;
    }
    background-color: #FFFFFF;
  }
  &.selected{
    background-color: #FFD200;
    .bullet{
      border-color: #FFFFFF;
      background-color: #FFFFFF;
    }
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {
    align-self: flex-start;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`

const TriviaJugar = (props) => {

  const colors = [{normal: '57ecea', hover: '20939e'},
                  {normal: 'ea5df1', hover: '8f0b95'},];

  const { getTrivia,
          getQuestionsTrivia,
          setAnswerTrivia,
          trivia,
          setTrivia,
          triviaProgress,
          setTriviaProgress} = useContext(ContentContext);

  const { history, location } = props;
  const [ section, setSection ] = useState('init');
  const [ startTrivia, setStartTrivia ] = useState(false);
  const [ evaluationId, setEvaluationId ] = useState(null);

  const [ disabledContinue, setDisabledContinue ] = useState(true);
  const [ questions, setQuestions ] = useState(null);
  const [ questionSelected, setQuestionSelected ] = useState(null);
  const [ orderQuestion, setOrderQuestion ] = useState(0);

  const [ answerSelected, setAnswerSelected ] = useState(null);
  const [ answers, setAnswers ] = useState([]);
  const [ categoryId, setCategoryId ] = useState(null);
  const [ questionId, setQuestionId ] = useState(null);
  const [ triviaId, setTriviaId ] = useState(null);
  const [ responseStatus, setResponseStatus ] = useState(null);
  const [ correctAnswer, setCorrectAnswer ] = useState("");
  const [ isLoading, setIsLoading ] = useState(null);
  const [ buttonTitle, setButtonTitle ] = useState("Jugar");

  const [ triviaState, setTriviaState ] = useState("start");
  const [ resultStatus, setResultStatus ] = useState(null);

  const [ categorySelectedId, setCategorySelectedId ] = useState(null);
  const [ categoriesComplete, setCategoriesComplete ] = useState([]);

  const [ categoryColor, setCategoryColor ] = useState("");
  const [ waitAnswerResult, setWaitAnswerResult ] = useState(false);
  const [ doneToday, setDoneToday ] = useState(false);

  const course = null;

  const openQuiz = (id_evaluation) => {
    setEvaluationId(id_evaluation);
    //setOpen(true);
  }
  const closeQuiz = () => {
    //setOpen(false);
  }
  const handleFinish = () => {
    //setOpen(false);
  }
  const handleBack = () => {
    //setTriviaState("start");
    history.push('/trivia');
  }

  useEffect(() => {

    handleGetTrivia();

    return () => {
      setTrivia(null)
    }

  }, [])



  const handleGetTrivia = () => {

    getTrivia();

  }

  useEffect(() => {
    if(trivia){
      setTriviaId(trivia._id);

      const catCompleted = [];
      trivia.games_categories.forEach(cat => {
        if(cat.contest_today){
          catCompleted.push(cat._id);
        }
      });
      setCategoriesComplete(catCompleted);

      if(trivia.games_categories.length > 0 && catCompleted.length > 0){
        if(trivia.games_categories.length == catCompleted.length){
          setDoneToday(true);
        }
      }

    }
  }, [trivia])

  const getCategoryQuestion = (cat, color) => {

    setCategoryColor(color);
    setAnswers([]);
    setAnswerSelected(null);
    setCorrectAnswer("");
    setButtonTitle(cat.name);
    setIsLoading(true);
    setCategoryId(cat._id);

    const payload = {id_category: cat._id, id_game: triviaId}
    const questions = getQuestionsTrivia(payload);
    questions.then((res) => {
      setCategorySelectedId(res.id_category);
      setQuestions(res.questions);
      setQuestionSelected(res.questions[0]);
      setOrderQuestion(0);
      setIsLoading(false);

      setTriviaState("play");
    })

  }

  const handleQuizResponse = (e) => {
    e.preventDefault();

    setWaitAnswerResult(true);

    const payload = {id_question: questionSelected._id,
                     id_options_select: JSON.stringify([{"id":answerSelected}]),
                     id_category: categoryId,
                     id_game: triviaId,
                     correct: questionSelected.option_correct[0] == answerSelected}

    //return null;
    const result = setAnswerTrivia(payload);
    result.then(res => {
      if(questionSelected.option_correct[0] != answerSelected){
        const correct = questionSelected.options.filter(option => option._id == questionSelected.option_correct[0]);
        setCorrectAnswer(correct[0].text);
        setResponseStatus(false);
      }else{
        setResponseStatus(true);
      }

      setAnswers([...answers, payload]);
    })

  }

  const handleNextQuestion = () => {

    setWaitAnswerResult(false);

    if(answers.length < questions.length/*(Number(questions.length)+1)*/){
      //alert(answers.length +" < "+ (Number(questions.length)+1))
      const nextQuestionOrder = orderQuestion+1;
      setOrderQuestion(nextQuestionOrder);
      setQuestionSelected(questions[nextQuestionOrder]);
      setResponseStatus(null);
      setAnswerSelected(null);
    }else{

      handleGetTrivia();

      const catCompleted = [...categoriesComplete];
      catCompleted.push(categorySelectedId);
      setCategoriesComplete(catCompleted);

      setResponseStatus(null);
      setTriviaState("start");
      //triviaResult();
    }
    /*
    setCategoryId(null);
    setQuestionId(null);
    setAnswerSelected(null);
    setQuestionSelected(null);
    setCorrectAnswer("");
    setResponseStatus(null);
    */
  }

  const triviaResult = () => {

    setTriviaState("result");
    let win = true;
    if(questions.length == answers.length){
      answers.map((answer, index) => {
        if(answer.correct != true){
          win = false;
        }
      })



    }

    setResultStatus(win);

  }


  const handleCategoryComplete = (cat_id) => {



    //if(Object.keys(trivia).length)

    if(trivia){

      const exist = categoriesComplete.filter(cat => cat == cat_id);

      const category = trivia.games_categories.filter(cat => cat._id == cat_id);

      if(!exist.length){
        return category[0].contest_today;
      }else{
        return exist.length ? true : false;
      }

    }

    return false;

  }
  /*
  if(trivia && !Object.keys(trivia).length){
    return null;
  }
  */

  return (
    <StyledWrapper>

      <StyledHeaderCategory>
        <div className="button_back" onClick={handleBack}>
          <div className="icon">
            <IcoChevronLeft />
          </div>
        </div>
        <div className="">
          <p>{ buttonTitle }</p>
        </div>
      </StyledHeaderCategory>

      {triviaState == "start" ?
        <StyledContainerTrivia percentage={triviaProgress ? triviaProgress.percentage : 0}>
          <div className="inner">

            {!trivia && <CircularProgressStyled className="loader_" size={40} />}

            {trivia && trivia.games_categories.length > 0 &&
            <>
            {/*
            <div className="trivia_progress">
              <div className="trivia_progress_inner">
                <div className="progress_text">
                  <p>Progreso</p>
                </div>
                <div className="bar">
                  <div className="bar_inner"></div>
                </div>
                <div className="percent">{triviaProgress ? triviaProgress.percentage : '0'}%</div>
              </div>
            </div>
            */}


              <div className="texto">
                <p>Seleccioná la categoría para comenzar a jugar</p>
                {!doneToday ?
                <p className="destaca">¡Una partida por día!</p>
                :
                <p className="destaca yajugaste">¡Ya jugaste por hoy, te esperamos mañana!</p>
                }
              </div>





              {trivia.games_categories.map((cat, index) => {
                return(
                  <StyledCategorySelectorItem disabled={handleCategoryComplete(cat._id)} className={`${handleCategoryComplete(cat._id) ? 'cat_complete ' + (index % 2 ? 'color1' : 'color2') : ((index % 2) ? 'color1' : 'color2')}`} onClick={() => !isLoading && getCategoryQuestion(cat, (index % 2) ? 'color1' : 'color2')} catLoading={isLoading}>

                    <div className="icon">
                      {(isLoading && categoryId == cat._id) ? <CircularProgressStyled className="loader" size={18} /> : <div className="icon_trivia_plus"><img src="/images/ico_trivia_plus.svg" /></div>}
                    </div>
                    <p>{cat.name}</p>

                    {/*handleCategoryComplete(cat._id) &&
                    <div className="icon_check">
                      <GoCheck />
                    </div>
                    */}

                  </StyledCategorySelectorItem>
                )
              })}
              </>
            }
          </div>
        </StyledContainerTrivia>
        : <></>
      }

      {triviaState == "play" ?
      <StyledContainerTrivia className={categoryColor}>
        <div className="inner">

          {waitAnswerResult ?
          <div className="result_status">



              <div className="correct">

              { responseStatus == null ?
                <CircularProgressStyled className="loader" size={35} />
              :
                <>
                <p className="result_title_correct">Tu respuesta fue<br/><span className="result">{ responseStatus ? 'correcta' : 'incorrecta' }</span></p>
                <ButtonYellow
                  className="bt_continuar"
                  onClick={() => handleNextQuestion()}
                  css_styles={{background:{normal: '#81eae7', hover: '#000000'}, width: '250px'}}
                >
                  <p>{ 'CONTINUAR' }</p>
                </ButtonYellow>
                </>
              }
              </div>


          </div>
          : <></>
          }

          <div className={`content_question ${waitAnswerResult ? 'hide' : '' }`}>

            <div className="question_text">
              <p>{questionSelected.question}</p>
            </div>

            <MultipleChoice
              answers={questionSelected.options}
              setAnswerSelected={setAnswerSelected}
              answerSelected={answerSelected}
            />

            <ButtonYellow
              className="bt_login"
              onClick={(e) => !waitAnswerResult && handleQuizResponse(e)}
              disabled={answerSelected ? false : true}
              css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
            >
              <p>{ 'ENVIAR' }</p>
            </ButtonYellow>

          </div>

        </div>
      </StyledContainerTrivia>
      : <></>
      }

      {triviaState == "result" ?
        resultStatus == null ?
          <>carga</>
        :
        resultStatus == true ?
        <>win</>
        :
        <>lose</>
        : <></>
      }

    </StyledWrapper>
  )
}

export default TriviaJugar;
