import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Otp from '~/_components/Layout/Otp';
import { ButtonYellow } from '../../_elements';
import { AuthContext } from '../../_context/AuthContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AiFillEyeInvisible,
         AiFillEye } from "react-icons/ai";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-color: #F5F6FA;*/
  .error_response{
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 19px;
    color: red;
    text-align: center;
  }
  .title,
  .description{
    width: 100%;
    text-align: left;
  }
  .title{
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 3px;
    font-weight: 500;
  }
  .description{
    margin-bottom: 15px;
  }
  .success_message{
    margin-top: 10px;
    margin-bottom: 20px;
    color: forestgreen;
  }
  .box_error{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(255,255,255, 0.85);
    width: 100%;
    height: 100%;
    z-index: 999;
    .box_error_inner{
      width: calc(100% - 50px);
      border: 1px solid #ececec;
      border-radius: 6px;
      padding: 10px 10px;
      background-color: #FFFFFF;
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      .title{
        font-weight: 500;
        //color: red;
        padding-bottom: 7px;
        margin-bottom: 12px;
        border-bottom: 1px solid #ececec;
      }
      .messages{
        p{
          color: red;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
`
const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  small{
    color: #797979;
    font-size: 13px;
    line-height: 17px;
    margin-top: 5px;
  }
  &.field_password{
    margin-top: 15px;
    .password_show{
      width: 50px;
      right: 0;
      top: 23px;
      text-align: center;
      position: absolute;
      font-size: 30px;
      line-height: 41px;
      padding-bottom: 1px;
      cursor: pointer;
      :hover{
        color: #FFD200;
      }
    }
  }
`
const Label = styled.label`
  display: flex;
  width: 100%;
  margin-bottom: 3px;
  color: #242424;
`
const Input = styled.input`
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  border: 1px solid #edeef0;
  ::placeholder {
    color: #a8a8a8;
    font-size: 16px;
  }
  :focus{
    outline: none;
  }
  *:focus {
    outline: none;
  }
  &.error{
    border: 1px solid red!important;
  }
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /*
  .loader{
    margin-top: 15px!important;
    height: 40px;
  }
  */
  .loader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;//width250px;
    height: 40px;
    margin-top: 15px;
    svg{
      color: #FFD200;
    }
  }
`
const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
  p{
    color: #E02020;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
`

const Retrieve = () => {

  const { Auth,
          login,
          loginError,
          getPhoneToValidate } = useContext(AuthContext);

  const [openOtp, setOpenOtp] = useState(false);
  const [userPhone, setUserPhone] = useState(null);
  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ repeatPassword, setRepeatPassword ] = useState("");
  
  const [ retrieveError, setRetrieveError ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ insertCode, setInsertCode ] = useState(false);//
  const [ code, setCode ] = useState("");
  const [ responseError, setResponseError ] = useState("");
  const [ successMessage, setSuccessMessage ] = useState("");
  const [ formErrorMessages, setFormErrorMessages ] = useState([]);
  const [ passwordShow, setPasswordShow ] = useState(false);
  const [ repeatPasswordShow, setRepeatPasswordShow ] = useState(false);
  
  const [ errorFields, setErrorFields ] = useState({user: false, password: false, repeatPassword: false, code: false});

  /*
  const handleFormSubmit = async () => {
    
    setLoading(true);
    setErrorFields({ user: !user ? true : false })
    if(!user){

      setLoading(false);
      return null;

    }
    const result = getPhoneToValidate(user);
    result.then(res => {

      if(res.status <= 0){
        setRetrieveError(true);
        //alert("ERROR: " + res.mensaje)
      }
      if(res.status == 1){
        setUserPhone(res.phone)
      }

      setLoading(false);

    })

    return null;

  };
  */

  const resetPassword = async () => {

    if(!user.length){

      setErrorFields({ user: !user ? true : false })

    }else{

      try {
        setLoading(true);
        const reset = await Auth.forgotPassword("sp_"+user)
        //console.error('not error', reset)
        //setErrorMessage(languageFile.code_sent_successfully)
        setLoading(false);
        setInsertCode(true)
        
      } catch (error) {
        setRetrieveError(true);
        /*
        let error_type = error.split(":");
        if(error_type === "UserNotFoundException"){
          console.log("UserNotFoundException");
        }
        */
        // 
        //console.log("----> ", error)
        setLoading(false);
        /*
        setErrorMessage(error.toString())
        console.error('Error during reset', error)
        */
      }

    }

  }

  const validateCode = async () => {

    let haveError = false;
    //let errorFieldsUpdate = {...errorFields};
    let formErrorMessagesArr = [...formErrorMessages];

    if(!code.length || !password.length || !repeatPassword.length){
      formErrorMessagesArr.push("- Todos los campos son obligatorios.");
    }else{
      if (password !== repeatPassword) {
        formErrorMessagesArr.push("- Las contraseñas no coinciden.");
      }
      if (password.length) {
        if(!validarPass(password)){
          formErrorMessagesArr.push("- La contraseña debe tener al menos 8 caracteres, al menos una letra mayúscula y al menos un carácter especial.");
        }
      }
    }

    setFormErrorMessages(formErrorMessagesArr);
    /*
    if(!user.length){
      haveError = true;
      errorFieldsUpdate.user = true;
    }
    if(!code.length){
      haveError = true;
      errorFieldsUpdate.code = true;
    }
    if(!password.length){
      haveError = true;
      errorFieldsUpdate.password = true;
    }
    */
    
    //setErrorFields(errorFieldsUpdate);
    
    if(formErrorMessagesArr.length){

      return null;

    }else{

      try {
          //console.log("user, code, password", user, code, password)
          setLoading(true);
          const reset = await Auth.forgotPasswordSubmit("sp_"+user, code, password);
          setLoading(false);
          setSuccessMessage("La contraseña se modificó con éxito.")
          //console.error('not error', reset)
          //setErrorMessage(languageFile.password_reset)
      } catch (error) {
          setResponseError(error.toString());
          setLoading(false);
          //setErrorMessage(error.toString())
          //console.error('Error during reset', error)
      }
      
    }
}

const validarPass = (pass) => {
  // Verificar longitud de la contraseña
  if (pass.length < 8) {
      return false
  }

  // Verificar que tenga al menos una mayúscula
  const mayusculaRegex = /[A-Z]/
  if (!mayusculaRegex.test(pass)) {
      return false
  }

  // Verificar que tenga al menos un carácter especial
  const caracterEspecialRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/
  if (!caracterEspecialRegex.test(pass)) {
      return false
  }

  // Si pasa todas las verificaciones, es válida
  return true
}

const handleCloseError = () => {
  setFormErrorMessages([]);
}

  /*
  useEffect(() => {
    if(openOtp != true){
      if(userPhone){
        setOpenOtp(true);
      }
    }
    return () => {}
  }, [userPhone])
  */

  /* <Otp openOtp={openOtp} setOpenOtp={setOpenOtp} phone={userPhone} setUserPhone={setUserPhone} dni={user} /> */

  return (
    <Wrapper>

      {formErrorMessages.length ? 
      <div className='box_error'>
        <div className='box_error_inner'>
          <div className='title'>Para continuar corrija los siguietes errores</div>
          <div className='messages'>
            {formErrorMessages.map((message, index) => {
              return(
                <p>{ message }</p>
              )
            })}
          </div>
          <ButtonYellow className="bt_login" onClick={handleCloseError}>
            <p>CERRAR</p>
          </ButtonYellow>
        </div>
      </div> : "" }

      {!insertCode ?
        <>
        <div className='title'>
          Recuperar contraseña
        </div>
        <div className='description'>
          Ingresá tu D.N.I. para recuperar el acceso a tu cuenta.
        </div>
        <Field>
          <Label>D.N.I.:</Label>
          <Input
            type="text"
            placeholder="Ingresá tu D.N.I."
            value={user}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setUser(e.currentTarget.value)}
          />
        </Field>

        {retrieveError &&
          <ErrorMessage>
            <p>Envía un mail a contacto@somosprotagonistas.com.ar con tu DNI, Mail y Número Celular para revisar tu acceso.</p>
          </ErrorMessage>
        }

        <Actions>

          {loading ?
          <div className="loader">
            <CircularProgress size={30} />
          </div>
          :
          <ButtonYellow className="bt_login" onClick={resetPassword}>
            <p>ENVIAR</p>
          </ButtonYellow>
          }

        </Actions>
        </>
      :
        <>
          <div className='title'>
            Recuperar contraseña
          </div>
          <div className='description'>
            Ingresá el código que recibiste en tu E-Mail y una nueva constraseña.
          </div>
          {!successMessage.length ? 
            <>

              <Field>
                <Label>Código *:</Label>
                <Input
                  type="text"
                  placeholder="Ingresá el código que recibiste en tu email."
                  value={code}
                  className={errorFields.code ? 'error' : '' }
                  onChange={(e) => setCode(e.currentTarget.value)}
                />
              </Field>

              <Field className="field_password">
                <Label>Contraseña *:</Label>
                <Input
                  type={passwordShow ? 'text' : 'password'}
                  placeholder="Ingresá tu nueva contraseña."
                  value={password}
                  className={errorFields.password ? 'error' : '' }
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
                <div className="password_show" onClick={() => setPasswordShow(!passwordShow)}>
                  { passwordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
                </div>
                <small>* Debe contener más de 8 carácteres<br/>* Debe contener al menos 1 letra mayúscula<br/>* Debe contener al menos 1 carácter extraño (ej: $!#?¡¿)</small>
              </Field>

              <Field className="field_password">
                <Label>Confirman Contraseña *:</Label>
                <Input
                  type={repeatPasswordShow ? 'text' : 'password'}
                  placeholder="Confirman Contraseña..."
                  value={repeatPassword}
                  className={errorFields.repeat_password ? 'error' : '' }
                  onChange={(e) => setRepeatPassword(e.currentTarget.value)}
                />
                <div className="password_show" onClick={() => setRepeatPasswordShow(!repeatPasswordShow)}>
                  { repeatPasswordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
                </div>
              </Field>

              {responseError.length ? 
              <div className='error_response'>
                { responseError }
              </div> : ""}
            
              <Actions>

                {loading ?
                <div className="loader">
                  <CircularProgress size={30} />
                </div>
                :
                <ButtonYellow className="bt_login" onClick={validateCode}>
                  <p>ENVIAR</p>
                </ButtonYellow>
                }

              </Actions>

            </>
          : 

            <div className='success_message'>{ successMessage }</div>

          }
          
        </>
      }
      

    </Wrapper>
  )
}

export default Retrieve;
