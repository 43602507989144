import React, { useEffect } from 'react';
import styled from 'styled-components';
import Post from './Post';
import { breakpoints } from '../../_helpers/breakpoints';
import Drawer from '@material-ui/core/Drawer';
import CommentsList from './CommentsSeccion/CommentsList';
import { IoClose } from "react-icons/io5";

const DrawerComment = styled.div`

    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;

    @media ${breakpoints.xs} {
      width: 550px;
      margin-top: 8px;
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {

    }

`
const StyledDrawer = styled(Drawer)`
  .MuiPaper-root{
    background-color: #FFFFFF;


  }
`
const DrawerCommentPost = styled.div`
  display: flex;
  width: 95vw;
  padding-bottom: 15px;
  border-bottom: 1px solid #CCCCCC;

  @media ${breakpoints.xs} {
    //width: 200px;
    width: 530px;
  }
  @media ${breakpoints.sm} {
    width: 530px;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const DrawerCommentList = styled.div`
  display: flex;
  width: calc(100% - 30px);
  //background-color: #CCCCCC;
  margin-top: 15px;
  .row{
    margin: 0;
  }
`
const CloseDrawer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: rgb(255,210,0);
  z-index: 9999;
  font-size: 30px;

  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
`

const DrawerPost = (props) => {

  const { post, handleLike, handleDislike, handleComment, openDrawer, setOpenDrawer } = props;

  const handleDrawerComment = () => {
    setOpenDrawer(!openDrawer);
  }
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  }


  return (
    <>
    <StyledDrawer anchor={'right'} open={openDrawer} onClose={handleDrawerComment}>
      <DrawerComment className={"DrawerComment"}>
          <>
          <CloseDrawer onClick={() => handleCloseDrawer()}>
            <IoClose />
          </CloseDrawer>
          <DrawerCommentPost className={"postCommentClass"}>
            <Post
                key={`postComment-${post.id}`}
                index={post.id}
                post={post}
                post_id={post.id}
                handleLike={handleLike}
                handleDislike={handleDislike}
                handleComment={() => handleComment(post)}
                commentsClickable={false}
                showCommentBox={true}
                onDrawer={true}
              />
          </DrawerCommentPost>
          <DrawerCommentList>
            <CommentsList
              post={post}
            />
          </DrawerCommentList>
          </>
      </DrawerComment>
    </StyledDrawer>
    </>
  )

}

export default DrawerPost;
