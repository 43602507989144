import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { ButtonYellow } from '../../_elements';
import { RiCloseCircleLine } from "react-icons/ri";
import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';
import { AuthContext } from '../../_context';
import TermsText from './TermsText';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F6FA;

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const BoxLogin = styled.div`
  display: flex;
  width: calc(100% - 30px);
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;

  box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
  -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);

  @media ${breakpoints.xs} {
    width: 500px;
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 50px);
  .bt_login{
    margin-top: 15px;
  }
  .actions{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .bt_accept{
      width: 100%;
    }
    .bt_cancel{
      width: 100%;
      margin-top: 7px;
    }

  }
  .bt_show{
    width: 100%;
    margin-top: 7px;
  }

  @media ${breakpoints.xs} {
    .actions{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .bt_accept{
        width: calc(50% - 5px);
      }
      .bt_cancel{
        width: calc(50% - 5px);
        margin-top: 0;
      }
    }
    .bt_show{
      width: 100%;
    }
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`


const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const ContainerModalProduct = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: 100vw;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5vw;
  border-radius: 0;
  .modal_product{
    display: flex;
    flex-direction: column;

    p.title{
      width: calc(100% - 50px);
      font-size: 18px;
      font-weight: 600;
    }
    p.text{

    }
  }

  @media ${breakpoints.xs} {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 25px;
    border-radius: 16px;
    width: calc(100% - 30px);
  }
  @media ${breakpoints.sm} {
    width: 700px;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

/*

const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  padding-top: 0;
  margin-bottom: 0;

  .inner_modal_reglamento{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    padding: 25px;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 0;
    p.title{
      font-size: 18px;
      font-weight: 600;
    }
    p.text{

    }
  }
  @media ${breakpoints.xs} {
    padding-top: 10px;
    margin-bottom: 20px;
    .inner_modal_reglamento{
      width: calc(100% - 30px);
      height: auto;
      border-radius: 16px;
    }
  }
  @media ${breakpoints.sm} {
    .inner_modal_reglamento{
      width: 700px;
    }
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
*/
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`

const TermsContainer = () => {

  const { logout, setAgreeTerms, setTttcAgree } = useContext(AuthContext);

  const [ loading, setLoading ] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleShowReglamento = () => {
    setOpen(true);
  }

  const handleAcceptReglamento = () => {
    //setTttcAgree(true);
    //setLoading(false);

    setLoading(true);
    const payload = {status: 1};
    const result = setAgreeTerms(payload);

    result.then(res => {
      
      setTttcAgree(true);
      setLoading(false);

    }).catch(err => {

      setTttcAgree(false);
      setLoading(false);

    });

    /*
    result.then((res) => {
      if(res.status){
        setTttcAgree(true);
        setLoading(false);
      }
    })
    */

  }

  const handleCancel = () => {
    logout();
  }


  return (
    <Wrapper>

      <ModalStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>

        <ContainerModalProduct>

          <ButtonClose
            onClick={() => handleClose()}
          >
            <div className="icon">
              <RiCloseCircleLine />
            </div>
          </ButtonClose>

          <TermsText />

        </ContainerModalProduct>
        </Fade>

      </ModalStyled>


      <BoxLogin>
        <Inner>


            <div className="test">
              <p>Para navegar <strong>Somos Protagonistas</strong> te solicitamos que aceptes primero los Términos y Condiciones.</p>
            </div>
            {loading ?

            <CircularProgressStyled />

            :
            <>
            <div className="actions">

              <ButtonYellow
                className="bt_accept"
                onClick={() => handleAcceptReglamento()}
                disabled={false}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
              >
                <p>Aceptar</p>
              </ButtonYellow>

              <ButtonYellow
                className="bt_cancel"
                onClick={() => handleCancel()}
                disabled={false}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
              >
                <p>Cancelar</p>
              </ButtonYellow>

            </div>

            <ButtonYellow
              className="bt_show"
              onClick={() => handleShowReglamento()}
              disabled={false}
              css_styles={{background:{normal: '#FFD200', hover: '#000000'}}}
            >
              <p>Ver Reglamento</p>
            </ButtonYellow>
            </>
            }


        </Inner>
      </BoxLogin>
    </Wrapper>
  )

}

export default TermsContainer;
