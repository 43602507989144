import React, { useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { FaChevronRight } from "react-icons/fa";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonYellow } from '../../_elements';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IcoPDFFile } from '../../_helpers/constants';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";


const WrapperStyled = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.alignArrows};//space-between;
  background-color: #F6F6F6;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  margin-bottom: 20px;
  .loader{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    svg{
      color: #FFD200;
    }
  }
  .preview_module,
  .next_module{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    .arrow_left_icon,
    .arrow_right_icon{
      font-size: 20px;
      line-height: 20px;
      color: #212529;
    }
    .module_data{
      display: flex;
      flex-direction: row;
      align-items: center;
      .image{
        width: 70px;
        border: 1px solid #e1e1e1;
        img{
          float: left;
          width: 100%;
        }
      }
      .texts{
        display: none;
        flex-direction: column;
        font-size: 16px;
        line-height: 20px;
        .title{
          font-weight: 600;
        }
      }
    }
    :hover{
      .arrow_left_icon,
      .arrow_right_icon{
        color: #FFC600;
      }
      .module_data{
        .image{
          border: 1px solid #FFC600;
        }
        .texts{
          color: #FFC600;
        }
      }
    }
    &.blocked{
      opacity: .25;
      cursor: default;
      .arrow_left_icon,
      .arrow_right_icon{
        color: #212529;
      }
      .module_data{
        .image{
          border: 1px solid #212529;
        }
        .texts{
          color: #212529;
        }
      }
    }
  }
  .preview_module{
    .module_data{
      margin-left: 15px;
      .texts{
        margin-left: 15px;
      }
    }
  }
  .next_module{
    .module_data{
      margin-right: 15px;
      .texts{
        align-items: flex-end;
        margin-right: 15px;
      }
    }
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {
    .preview_module,
    .next_module{
      .module_data{
        .texts{
          display: flex;
        }
      }
    }
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`

const NavModules = (props) => {

  const { liked,
          likes,
          history,
          location,
          match,
          className,
          onClick,
          course,
          module,
          setModule,
          navLoading } = props;

  const [ nextModule, setNextModule ] = useState(null);
  const [ prevModule, setPrevModule ] = useState(null);

  useEffect(() => {
    if(course && module){
      if(course.moduls.length > 1){
        let module_index = null;
        course.moduls.map((m, index) => {
          if(m._id == module._id){
            module_index = index;
          }
        })
        if(module_index !== null){

          if(course.moduls[module_index-1]){
            let count_archives = 0;
            course.moduls[module_index-1].media_slides.map((media, index) => {
              if(media.content_type.toLowerCase() === "archives" || media.content_type.toLowerCase() === "pdf"){
                count_archives++;
              }
            })

            if(count_archives < course.moduls[module_index-1].media_slides.length){
              setPrevModule(course.moduls[module_index-1])
            }else{
              setPrevModule(null);
            }
          }else{
            setPrevModule(null);
          }


          if(course.moduls[module_index+1]){
            let count_archives = 0;
            course.moduls[module_index+1].media_slides.map((media, index) => {
                if(media.content_type.toLowerCase() === "archives" || media.content_type.toLowerCase() === "pdf"){
                  count_archives++;
                }
            })
            console.log("count_archives", count_archives)
            if(count_archives < course.moduls[module_index+1].media_slides.length){
              setNextModule(course.moduls[module_index+1])
            }else{
              setNextModule(null);
            }
          }else{
            setNextModule(null);
          }


        }
      }
    }

    return () => {}
  }, [course, module])

  useEffect(() => {
    return () => {
      setPrevModule(null);
      setNextModule(null);
    }
  }, [])


  const handleGoToModule = (el) => {
    setModule(null);
    history.push(`/campus/course/${match.params.id_course ? match.params.id_course : course._id}/module/${el._id}`);
  }

  useEffect(() => {
    console.log("navLoading", navLoading)

    return () => {}
  }, [navLoading])

  useEffect(() => {

    console.log("prevModule", prevModule);

    return () => {}
  }, [prevModule])

  useEffect(() => {

    console.log("nextModule", nextModule);

    return () => {}
  }, [nextModule])



  return (

      <WrapperStyled
        className={className}
        onClick={onClick}
        alignArrows={prevModule && nextModule ? 'space-between' : !prevModule ? "flex-end" : !nextModule ? "flex-start" : ""}
      >

        {prevModule &&
          <div className={`preview_module${prevModule.status === 0 ? ' blocked' : ''}`} onClick={() => { prevModule.status !== 0 && handleGoToModule(prevModule)} }>
            <div className='arrow_left_icon'>
              <HiArrowLeft />
            </div>
            <div className='module_data'>
              <div className='image'>
              <img src={`${prevModule.cover_image ? prevModule.cover_image : prevModule.icon ? prevModule.ico : ""}`} />
              </div>
              <div className='texts'>
                <div className='title'>
                  {prevModule.title}
                </div>
                <div className='description'>
                  Ver contenido anterior
                </div>
              </div>
            </div>
          </div>
        }

        {nextModule ?
          (navLoading && nextModule.status === 0) ?
          <div className='loader'>
            <CircularProgress size={35} />
          </div>
          :
          <div className={`next_module${nextModule.status === 0 ? ' blocked' : ''}`} onClick={() => { nextModule.status !== 0 && handleGoToModule(nextModule)} }>
            <div className='module_data'>

              <div className='texts'>
                <div className='title'>
                  {nextModule.title}
                </div>
                <div className='description'>
                  Ver contenido siguiente
                </div>
              </div>
              <div className='image'>
                <img src={`${nextModule.cover_image ? nextModule.cover_image : nextModule.icon ? nextModule.ico : ""}`} />
              </div>
            </div>
            <div className='arrow_right_icon'>
              <HiArrowRight />
            </div>
          </div>
        : ""}

      </WrapperStyled>

  )
}

export default NavModules;
